import ChildCard from "components/Parent/ChildCard";
import { CheckoutState, CheckoutAction } from "./types";

const validParentInformationData = ["name", "email", "phone_number"];

const CachedCheckoutState: CheckoutState =
  localStorage.getItem("checkoutState") &&
  JSON.parse(localStorage.getItem("checkoutState"));
const initialState: CheckoutState = CachedCheckoutState || {
  children: [
    {
      user_id: "",
      name: "",
      date_of_birth: "",
      difficulty: "beginner",
      slot_instance: {},
    },
  ],
  parent_information: {
    name: "",
    email: "",
    phone_number: "",
  },
  currentChildIndex: 0,
  subscriptionType: "supernova",
};

export default (
  state = initialState,
  action: CheckoutAction
): CheckoutState => {
  switch (action.type) {
    case "ADD_CHILD": {
      let foundChild
      if (action.childUsers) {
        foundChild = action.childUsers.find(
          (child: any) => child.id === action.userId
        );
      }
      console.log("*** foundChild", foundChild);
      console.log("*** state.children", action.childUsers);
      const user = action.userPayload;
      if (action.userPayload) {
        return {
          ...state,
          children: [
            ...state.children,
            {
              user_id: user.id,
              name: user.referral_code,
              date_of_birth: user.date_of_birth,
              difficulty: "beginner",
              slot_instance: {},
            },
          ],
        };
      }
      if (foundChild) {
        return {
          ...state,
          children: [
            ...state.children,
            {
              user_id: foundChild.id,
              name: foundChild.name,
              date_of_birth: foundChild.date_of_birth,
              difficulty: "beginner",
              slot_instance: {},
            },
          ],
        };
      }
      return {
        ...state,
        children: [
          ...state.children,
          {
            user_id: "",
            name: "",
            date_of_birth: "",
            difficulty: "beginner",
            slot_instance: {},
          },
        ],
      };
    }
    case "SET_CURRENT_CHILD_INDEX": {
      // const foundChild = state.children.find((child) => child.user_id === action.userId);
      return { ...state, currentChildIndex: action.index };
    }
    case "RESET_CHECKOUT": {
      return {
        children: [{ user_id: "1", slot_instance: {} }],
        currentChildIndex: 0,
        subscriptionType: "supernova",
        parent_information: { name: "", email: "", phone_number: "" },
      };
    }
    case "UPDATE_SUBSCRIPTION_PLAN": {
      return {
        ...state,
        subscriptionType: action.plan,
      };
    }
    case "DELETE_CHILD_BOOKING": {
      const { children } = state;
      console.log("children[action.childIndex", children[action.childIndex]);
      if (children[action.childIndex]) {
        const child = children[action.childIndex];
        const newChild = Object.assign({}, child);
        const updatedChildren = [...children];
        // remove old child
        updatedChildren.splice(action.childIndex, 1);
        return Object.assign({}, { ...state, children: updatedChildren });
      }
      return state;
    }
    case "UPDATE_PARENT_INFORMATION": {
      const { parent_information } = state;
      if (validParentInformationData.includes(action.key)) {
        const newParentInformation = Object.assign({}, parent_information, {
          [action.key]: action.value,
        });
        return Object.assign(
          {},
          { ...state, parent_information: newParentInformation }
        );
      } else {
        console.log("invalid parent information key");
      }
      break;
    }
    case "UPDATE_CHILD_BY_ID": {
      const { children } = state;
      let foundIndex = null;
      children.map((child, index) => {
        if (child.user_id === action.userId) {
          foundIndex = index;
        }
      });

      if (foundIndex) {
        const newChild = Object.assign({}, children[foundIndex], {
          ...action.value,
        });

        const updatedChildren = [...children];
        // remove old child
        updatedChildren.splice(foundIndex, 1, newChild);
        return Object.assign({}, { ...state, children: updatedChildren });
      }
      return state;
    }
    case "UPDATE_CHILD_BY_INDEX": {
      const { children } = state;
      // @ts-ignore
      const foundIndex = null;
      const child = children.find((child, index) => index === action.index);

      if (child) {
        const newChild = Object.assign({}, children[action.index], {
          ...action.value,
        });

        const updatedChildren = [...children];
        // remove old child
        updatedChildren.splice(action.index, 1, newChild);
        return Object.assign({}, { ...state, children: updatedChildren });
      }
      return state;
    }
    default:
      return state;
  }
};
