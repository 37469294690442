import SatelliteClient from "@codeverse/satellite-client";

export default {
  authenticateUser: (email: string, password: string) => {
    localStorage.removeItem("accessToken");
    const data: any = {
      password,
    };
    if (email.includes("@")) {
      data.email = email;
    } else {
      data.username = email;
    }
    return SatelliteClient.platform.post("/sessions", {
      headers: Object.assign(
        {},
        { Accept: "application/vnd.api+json" },
        { "content-type": "application/vnd.api+json" }
      ),
      data,
      removeOrganizationId: true,
      serializer: "sessions",
    });
  },
  checkSession: (access_token: string, current_user_id: string) => {
    console.log("checking session");
    return SatelliteClient.platform.get("/oauth/me", {
      headers: Object.assign(
        {},
        { Accept: "application/vnd.api+json" },
        { "content-type": "application/vnd.api+json" }
      ),
      query: {
        access_token,
        current_user_id,
      },
      removeOrganizationId: true,
    });
  },
};
