import React, { useEffect, useState, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Shared/NewModal';
import { closeModal, openModal } from 'store/modules/UI/actions';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

type Props = {
  history?: any;
  location?: any;
  match?: any;
  setBookingsUpdated?: any;
  bookings?: any;
};

const determineModalTitle = (currentStep: number) => {
  switch (currentStep) {
    case 1:
      return 'Select who this session is for';
    case 2:
      return 'Available Session Times';
    case 3:
      return 'Confirm new time';
    default:
      return '';
  }
}

const BookingSessionModal: React.FC<Props> = ({ }) => {
  const bookNewSession = useSelector((state: RootState) => state.ui.bookNewSession);
  const users = useSelector((state: RootState) => state.organizations.users);
  // const userBookingInstances = useSelector((state: RootState) => state.user.userBookingInstances);
  const dispatch: AppDispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [curentChildId, setCurrentChildId] = useState('');
  const [currentSlotInstance, setCurrentSlotInstance] = useState({});

  const iconClassNames = classNames('position-absolute btn-icon', {
    'd-none': currentStep === 1 || (currentStep === 2),
  })

  const handleSetChild = (userId: string) => {
    setCurrentChildId(userId);
    setCurrentStep(2);
  }

  return (
    <Modal
      isOpen={bookNewSession}
      title={determineModalTitle(currentStep)}
      modalName="bookNewSession"
      handleClose={() => {
        dispatch(closeModal('bookNewSession'));
        setCurrentStep(1);
      }}
      className="reschedule-modal d-flex flex-column"
      icon={() =>
        <div onClick={() => { setCurrentStep(currentStep - 1) }} style={{ top: '10px', left: '10px', zIndex: 10000 }} className={iconClassNames} >
          <i onClick={() => { }} style={{ top: '9px', left: '8px', zIndex: 10000 }} className="fas fa-lg fa-arrow-left position-absolute" />
        </div>
      }
      showHeader
    >
      <div className="reschedule-modal__content">
        {(currentStep === 1) && <Step1 setCurrentStep={setCurrentStep} handleSetChild={handleSetChild} />}
        {(currentStep === 2) && <Step2 setCurrentStep={setCurrentStep} curentChildId={curentChildId} setCurrentSlotInstance={setCurrentSlotInstance} />}
        {(currentStep === 3) &&
          <Step3
            currentSlotInstance={currentSlotInstance}
            curentChildId={curentChildId}
          />
        }
      </div>
    </Modal>
  );
}

export default BookingSessionModal;

