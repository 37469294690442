import { OrganizationsAction } from './types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import api from './api';

export const getOrganizationMembershipsRequest = (): OrganizationsAction => {
  return { type: 'GET_ORGANIZATION_MEMBERSHIPS_REQUEST' }
}

export const getOrganizationMembershipsSuccess = (payload: any, organizationId: string): OrganizationsAction => {
  return { type: 'GET_ORGANIZATION_MEMBERSHIPS_SUCCESS', payload, organizationId }
}

export const getOrganizationMembershipsFailure = (error: string): OrganizationsAction => {
  return { type: 'GET_ORGANIZATION_MEMBERSHIPS_FAILURE', error }
}

export const getOrganizationSubscriptionsRequest = (): OrganizationsAction => {
  return { type: 'GET_ORGANIZATION_SUBSCRIPTIONS_REQUEST' }
}

export const getOrganizationSubscriptionsSuccess = (payload: any, organizationId: string): OrganizationsAction => {
  return { type: 'GET_ORGANIZATION_SUBSCRIPTIONS_SUCCESS', payload, organizationId }
}

export const getOrganizationSubscriptionsFailure = (error: string): OrganizationsAction => {
  return { type: 'GET_ORGANIZATION_SUBSCRIPTIONS_FAILURE', error }
}

export const getPaymentMethodRequest = (): OrganizationsAction => {
  return { type: 'GET_PAYMENT_METHOD_REQUEST' }
}

export const getPaymentMethodSuccess = (payload: any): OrganizationsAction => {
  return { type: 'GET_PAYMENT_METHOD_SUCCESS', payload }
}

export const getPaymentMethodFailure = (error: string): OrganizationsAction => {
  return { type: 'GET_PAYMENT_METHOD_FAILURE', error }
}

export const getSubscriptionAddOnsRequest = (): OrganizationsAction => {
  return { type: 'GET_SUBSCRIPTION_ADD_ONS_REQUEST' }
}

export const getSubscriptionAddOnsSuccess = (payload: any): OrganizationsAction => {
  return { type: 'GET_SUBSCRIPTION_ADD_ONS_SUCCESS', payload }
}

export const getSubscriptionAddOnsFailure = (error: string): OrganizationsAction => {
  return { type: 'GET_SUBSCRIPTION_ADD_ONS_FAILURE', error }
}

export const getUserRequest = (): OrganizationsAction => {
  return { type: 'GET_USER_REQUEST' }
}

export const getUserSuccess = (payload: any): OrganizationsAction => {
  return { type: 'GET_USER_SUCCESS', payload }
}

export const getUserFailure = (error: string): OrganizationsAction => {
  return { type: 'GET_USER_FAILURE', error }
}

export const getUserDataRequest = (): OrganizationsAction => {
  return { type: 'GET_USER_DATA_REQUEST' }
}
export const getUserDataSuccess = (): OrganizationsAction => {
  return { type: 'GET_USER_DATA_SUCCESS' }
}

export const getPaymentMethod = (access_token: string, currentUserId: string, paymentMethodId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(getPaymentMethodRequest());
    api.getPaymentMethod(access_token, currentUserId, paymentMethodId)
      .then((response: any) => {
        dispatch(getPaymentMethodSuccess(response.data.data))
      })
  }
}

export const getOrganizationSubscriptionsData = (accessToken: string, currentUserId: string, organizationId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(getOrganizationSubscriptionsRequest());
    api.getOrganizationSubscriptions(accessToken, currentUserId, organizationId)
      .then((response: any) => {
        dispatch(getOrganizationSubscriptionsSuccess(response.data.data, organizationId));
      })
      .catch((e: string) => {
        dispatch(getOrganizationSubscriptionsFailure(e));
      });
  }
}

export const getOrganizationMembershipsData = (accessToken: string, currentUserId: string, organizationId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(getOrganizationMembershipsRequest());
    api.getOrganizationMemberships(accessToken, currentUserId, organizationId)
      .then((response: any) => {
        dispatch(getOrganizationMembershipsSuccess(response.data.data, organizationId));
      })
      .catch((e: string) => {
        dispatch(getOrganizationMembershipsFailure(e));
      });
  }
}

export const getUserData = (accessToken: string, memberships: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(getUserDataRequest());
    Promise.all(
      memberships.map((membership: any) => {
        return api.getUser(accessToken, membership.user.id)
          .then((response: any) => {
            dispatch(getUserSuccess(response.data.data));
          })
          .catch((e: string) => {
            dispatch(getUserFailure(e));
          });
      })
    )
      .then(() => {
        dispatch(getUserDataSuccess());
      })
  }
}