import React, { useEffect, useState, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Shared/NewModal';
import { closeModal, openModal } from 'store/modules/UI/actions';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';
import { GET_SUBSCRIPTION_ADD_ONS } from 'models/Subscription';
import { dataClientRequest } from '@codeverse/redux-data-client';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import InfoStep from './InfoStep';

type Props = {
  history?: any;
  location?: any;
  match?: any;
  setBookingsUpdated?: any;
  bookings?: any;
};

const determineModalTitle = (currentStep: number) => {
  switch (currentStep) {
    case 2:
      return 'Select who this Class is for';
    case 3:
      return 'Available Class Times';
    case 4:
      return 'Confirm Class time';
    default:
      return null;
  }
}

const BookingSessionModal: React.FC<Props> = ({ }) => {
  const bookFirstSession = useSelector((state: RootState) => state.ui.bookFirstSession);
  
  // const userBookingInstances = useSelector((state: RootState) => state.user.userBookingInstances);
  const dispatch: AppDispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [currentChildId, setCurrentChildId] = useState('');
  const [currentSlotInstance, setCurrentSlotInstance] = useState({});
  const [subscriptionAddOn, setSubscriptionAddOn] = useState({});

  const [currentBookings, setCurrentBookings] = useState({});

  const subscriptions = useSelector((state: RootState) => state.organizations.subscriptions);
  
  const currentSubscription = useMemo(() => {
    return subscriptions.find((subscription: any) => subscription.status === 'new')
  }, [subscriptions])

  useEffect(() => {
    if (currentSubscription) {
      dispatch(dataClientRequest({
        ...GET_SUBSCRIPTION_ADD_ONS,
        data: {
          id: currentSubscription.id,
        }
      }))
        .then((response: any) => {
          // setRetrievedAddOns(true)
          setSubscriptionAddOn(response.response.data.data[0])
        })
    }
  }, [currentSubscription]);

  const iconClassNames = classNames('position-absolute btn-icon', {
    'd-none': currentStep === 1 || (currentStep === 2),
  })

  const handleSetChild = (userId: string) => {
    setCurrentChildId(userId);
    setCurrentStep(3);
  }


  return (
    <Modal
      isOpen={bookFirstSession}
      title={determineModalTitle(currentStep)}
      modalName="bookFirstSession"
      handleClose={() => {
        dispatch(closeModal('bookFirstSession'));
        setCurrentBookings({});
        setCurrentChildId('');
        setCurrentSlotInstance({});
        setCurrentStep(1);
      }}
      className="reschedule-modal d-flex flex-column"
      icon={() =>
        <div onClick={() => { setCurrentStep(currentStep - 1) }} style={{ top: '10px', left: '10px', zIndex: 10000 }} className={iconClassNames} >
          <i onClick={() => { }} style={{ top: '9px', left: '8px', zIndex: 10000 }} className="fas fa-lg fa-arrow-left position-absolute" />
        </div>
      }
      showHeader={currentStep !== 1}
    >
      <div className="reschedule-modal__content">
        {(currentStep === 1) && <InfoStep subscriptionAddOn={subscriptionAddOn} setCurrentStep={setCurrentStep} />}
        {(currentStep === 2) && <Step1 currentBookings={currentBookings} subscriptionAddOn={subscriptionAddOn} setCurrentStep={setCurrentStep} handleSetChild={handleSetChild} />}
        {(currentStep === 3) && <Step2 subscriptionAddOn={subscriptionAddOn} currentBookings={currentBookings} setCurrentBookings={setCurrentBookings} setCurrentStep={setCurrentStep} currentChildId={currentChildId} setCurrentSlotInstance={setCurrentSlotInstance} />}
        {(currentStep === 4) &&
          <Step3
            subscriptionAddOn={subscriptionAddOn}
            currentBookings={currentBookings}
            currentSlotInstance={currentSlotInstance}
            currentChildId={currentChildId}
          />
        }
      </div>
    </Modal>
  );
}

export default BookingSessionModal;

