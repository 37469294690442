import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Badge, Input, Select } from "@codeverse/react-helios-ui";
import { useHistory } from "react-router-dom";
import { dataClientRequest } from "@codeverse/redux-data-client";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { RootState } from "store/state";
import { ChildUser } from "components/Parent/Schedule/BookSessionModal/Step1";

import { addChild as addChildToChildUsers } from "store/modules/User/actions";
import { CREATE_USER } from "models/User";
import {
  resetCheckout,
  updateChildByIndex,
  setCurrentChildIndex,
  addChild as addCheckoutChild,
  deleteChildBooking,
} from "store/modules/Checkout/actions";

import { AppDispatch } from "store";

// flows
// create acc -> add child
// add child -> refresh -> load first child / cached user

const SelectChild: React.FC<any> = ({ goToStepHash }) => {
  const [childName, setChildName] = useState("");
  const [birthday, setBirthday] = useState("");
  const dispatch: AppDispatch = useDispatch();

  const children = useSelector((state: RootState) => state.checkout.children);
  const currentChildIndex = useSelector(
    (state: RootState) => state.checkout.currentChildIndex
  );
  const childUsers = useSelector((state: RootState) => state.user.childUsers);

  const history = useHistory();

  const filteredChildUsers = useMemo(() => {
    const existingCheckoutUsers: Array<any> = [];
    children.map((child) => existingCheckoutUsers.push(child.user_id));
    return childUsers.filter(
      (child: any) => !existingCheckoutUsers.includes(child.id)
    );
  }, [children, childUsers, history.location.pathname]);

  const handleFirstCheckoutUser = (userId: string) => {
    dispatch(resetCheckout());
    dispatch(updateChildByIndex(0, { user_id: userId }));
    dispatch(setCurrentChildIndex(0));
    history.push("/parent/checkout/#select-time");
  };

  const handleNewCheckoutChild = (userId: string) => {
    // check if user exist
    let checkoutChild = null;
    let checkoutChildIndex = null;
    children.map((child, index) => {
      if (child.user_id === userId) {
        checkoutChildIndex = index;
        checkoutChild = child;
      }
    });
    if (checkoutChild) {
      dispatch(setCurrentChildIndex(checkoutChildIndex));
    } else {
      dispatch(setCurrentChildIndex(currentChildIndex + 1));
      dispatch(addCheckoutChild(userId, childUsers));
      history.push("/parent/checkout/#select-time");
    }
  };

  const handleSetChild = (userId: string) => {
    handleFirstCheckoutUser(userId);
  };

  return (
    <div className="m-width-checkout booked">
      <h2 className="step-title">
        <span style={{ color: "#FDBA17" }}>Select A</span> <br />
        <span style={{ color: "white" }}>Child</span>
      </h2>
      <div className="child-information-fields">
        <div className="reschedule-modal__content">
          <div className="child-select">
            {filteredChildUsers.map((childUser) => {
              return (
                <ChildUser handleSetChild={handleSetChild} user={childUser} />
              );
            })}
            <div
              className="child-user"
              onClick={() => {
                history.push("/parent/checkout/#add-child");
              }}
            >
              <div className="child-user__avatar child-user__avatar-add">
                <i className="fas fa-plus" />
              </div>
              <div className="child-user__name">Add User</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectChild;
