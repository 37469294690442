import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const HalfModal: React.FC<any> = ({ children, isOpen, handleClose }) => {
  return (
    <div className="half-modal-element">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="half-modal-element__shade"
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ type: 'spring', stiffness: 100, damping: 15 }}
            className="half-modal-element__content d-flex justify-content-center align-items-center"
            onClick={() => handleClose()}
          >
            <div className="half-modal-element__contents" onClick={e => e.stopPropagation()}>
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HalfModal;