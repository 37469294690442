
import React from 'react';

const Decorations = () => {
  return (
    <div className="org-login__panel-right" />
  );
};

export default Decorations;
