import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@codeverse/react-helios-ui';
import Modal from 'components/Shared/NewModal';
import { closeModal } from 'store/modules/UI/actions';
import { AppDispatch } from 'store';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { RootState } from 'store/state';
import { toast } from 'react-toastify';
import moment from 'moment';

import { UPDATE_BOOKING } from 'models/Bookings';

type Props = {
  history?: any;
  location?: any;
  match?: any;
  setBookingsUpdated?: any;
  bookings?: any;
  currentBooking: any;
  currentBookingInstance?: any;
};


const CancelModal: React.FC<Props> = ({ currentBooking, currentBookingInstance, setBookingsUpdated }) => {
  const cancelModal = useSelector((state: RootState) => state.ui.cancelModal);
  const [skipConfirmation, setSkipConfirmation] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  if (!currentBooking || !currentBookingInstance) {
    return null;
  }

  const cancelBookingInstance = () => {
    const isSingleBooking = currentBooking.first_visit_at === currentBooking.last_visit_at;
    if (isSingleBooking) {
      dispatch(dataClientRequest({
        ...UPDATE_BOOKING,
        data: {
          id: currentBooking.id,
          completed_reason: 'canceled',
        },
      }))
        .then(() => {
          setSkipConfirmation(true);
          toast.success('You have skipped this session.', {
            position: toast.POSITION.TOP_CENTER,
          });
          setBookingsUpdated(true);
        })
        .catch(() => {
          dispatch(closeModal('cancelModal'));
          toast.success('Failed to cancel session.', {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      dispatch(dataClientRequest({
        ...UPDATE_BOOKING,
        data: {
          id: currentBooking.id,
          excluded_dates: [...currentBooking.excluded_dates, currentBookingInstance.start_date],
        },
      }))
        .then(() => {
          setSkipConfirmation(true);
          toast.success('You have skipped this session.', {
            position: toast.POSITION.TOP_CENTER,
          });
          setBookingsUpdated(true);
        })
        .catch(() => {
          dispatch(closeModal('cancelModal'));
          toast.success('Failed to cancel session.', {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <Modal
      isOpen={cancelModal}
      title={skipConfirmation ? 'Session Skipped!' : 'Skip Session?'}
      modalName="cancelModal"
      handleClose={() => {
        dispatch(closeModal('cancelModal'));
      }}
      className="reschedule-modal d-flex flex-column"
      showHeader
    >
      <div className="reschedule-modal__content">
        {skipConfirmation ?
          <>
            {moment().isSame(currentBookingInstance.start_date, 'day') ?
              <div className="session-skip-header text-center">
                  We will let you guide know that you can't make it. <br /><br /> See you next time!
              </div>
              :
              <>
                <div className="credit-refund">
                  <div className="credit-refund__contents">
                    <div className="credit-refund__credit-gain">+1</div>
                    <div className="credit-refund__header">Unapplied Credit</div>
                  </div>
                </div>
                <div className="session-skip-header text-center">
                    The credit for this session has been added back to your account as an unapplied credit to be used in the future.
                </div>
              </>
            }

            <div className="w-100 reschedule-modal__actions">
              <Button
                onClick={() => {
                  dispatch(closeModal('cancelModal'));
                }}
                className="w-100 mt-auto"
                context="primary"
                style={{ marginTop: 'auto' }}
              >
                Done
              </Button>
            </div>
          </>
          :
          <>
            <div className="text-center">
              Let us and your guide know you can't make it by cancelling your session. Your child has unlimited access to our platform, so they can keep coding until it's time for their next class!
            </div>

            <div className="w-100 reschedule-modal__actions">
              <Button
                onClick={() => {
                  cancelBookingInstance();
                }}
                className="w-100 mb-3"
                context="primary"
              >
                Skip Session
              </Button>
              <Button
                onClick={() => {
                  dispatch(closeModal('cancelModal'));
                }}
                className="w-100"
                context="secondary"
              >
                Keep Session
              </Button>
            </div>
          </>
        }
      </div>
    </Modal >
  );
};

export default CancelModal;

