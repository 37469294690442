import React, { useEffect, useMemo, useState } from "react";
import SatelliteClient from "@codeverse/satellite-client";
import { dataClientRequest } from "@codeverse/redux-data-client";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@codeverse/react-helios-ui";
import queryString from "query-string";
import { toast } from "react-toastify";
import { Button, Card } from "@codeverse/react-helios-ui";
import moment from "moment-timezone";
import { AddToCalendarButton } from 'add-to-calendar-button-react';

import { RootState } from "store/state";
import { closeModal, openModal } from "store/modules/UI/actions";
import { SubscriptionsInfoBox } from "../../components/Parent/SubscriptionsInfoBox";
import {
  GET_ORGANIZATION_BOOKINGS,
  GET_ORGANIZATION_CREDITS,
} from "models/Organization";
import { GET_SUBSCRIPTION_ADD_ONS } from "models/Subscription";

import { ChildUsersBox } from "../../components/Parent/ChildUsersBox";

import { Modal } from "components/Shared/Modal";
import BraintreeCCForm from "components/Shared/BraintreeCCForm";
import { ReferralBox } from "components/Shared/ReferralBox";
import capitalizeFirstLetter from "utils/captializeFirstLetter";
import { AppDispatch } from "store";
import StreamBanner from "components/Parent/StreamBanner/StreamBanner";
import TrialSignupCard from "components/Parent/TrialSignupCard";

declare var heap: any;

const activeStatuses = ["active", "new", "trial", "pending"];
const validStatus = ["freemium"];

const FIRST_STREAM_ID = "7815ccb9-baa2-4a28-b66d-81cf9d1ebb32";

type Props = {
  history?: any;
  location?: any;
  hasSetChildUsers: any;
  pastDueSubscription: any;
  noActiveSubscriptions: any;
  currentSubscriptions: any;
  currentMemberships: any;
  childUsers: any;
  hasRetrievedMemberships: any;
  hasRetrievedUsers: any;
  width: any;
  isMobile: any;
  hasRetrievedSubscriptionAddOns: any;
  hasRetrievedPaymentMethods: any;
  setHasSetChildUsers: any;
  setPastDueSubscription: any;
  setNoActiveSubscriptions: any;
  setCurrentSubscriptions: any;
  setCurrentMemberships: any;
  setChildUsers: any;
  setHasRetrievedMemberships: any;
  setHasRetrievedUsers: any;
  setWidth: any;
  setIsMobile: any;
  setHasRetrievedSubscriptionAddOns: any;
  setHasRetrievedPaymentMethods: any;
  userHasAccessToPlatform: any;
};

type QueryState = {
  fromSurvey: string;
  checkout_confirmation: string;
  start_date: string;
  start_time: string;
  end_time: string;
};

export const Dashboard: React.FC<Props> = ({
  hasSetChildUsers,
  pastDueSubscription,
  noActiveSubscriptions,
  currentSubscriptions,
  currentMemberships,
  hasRetrievedMemberships,
  hasRetrievedUsers,
  width,
  isMobile,
  hasRetrievedSubscriptionAddOns,
  hasRetrievedPaymentMethods,
  setHasSetChildUsers,
  setPastDueSubscription,
  setNoActiveSubscriptions,
  setCurrentSubscriptions,
  setCurrentMemberships,
  setChildUsers,
  setHasRetrievedMemberships,
  setHasRetrievedUsers,
  setWidth,
  setIsMobile,
  setHasRetrievedSubscriptionAddOns,
  setHasRetrievedPaymentMethods,
  userHasAccessToPlatform,
  location,
  history,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [activeSub, setActiveSubscription] = useState(null);
  const [foundStream, setFoundStream] = useState(false);
  const priorityMembership = useSelector(
    (state: RootState) => state.user.priorityMembership
  );
  const currentSubscriptionId = useSelector(
    (state: RootState) => state.user.currentSubscriptionId
  );
  const updatePaymentMethodModal = useSelector(
    (state: RootState) => state.ui.updatePaymentMethodModal
  );
  const checkoutConfirmation = useSelector(
    (state: RootState) => state.ui.checkoutConfirmation
  );
  const accessToken =
    useSelector(
      (state: RootState) => state.session.access_token.access_token
    ) || "";
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const childUsers = useSelector((state: RootState) => state.user.childUsers);
  const paymentMethods = useSelector(
    (state: RootState) => state.organizations.paymentMethods
  );
  const organization = useSelector(
    (state: RootState) => state.organizations.organization
  );
  const subscriptions = useSelector(
    (state: RootState) => state.organizations.subscriptions
  );
  const subscriptionAddOns = useSelector(
    (state: RootState) => state.organizations.subscriptionAddOns
  );
  const bookings = useSelector((state: RootState) => state.user.bookings);

  const query = queryString.parse(location.search) as QueryState;

  const howKidsLearnRedirect = () => {
    window.open("https://www.youtube.com/watch?v=p7Y34_k3Ztk&feature=youtu.be");
  };

  const howToVideosRedirect = () => {
    window.open("https://www.youtube.com/playlist?list=PLejiSRxIXGkuRnc6zVE2BPK1x7WGr6etE");
  };

  const swagStoreRedirect = () => {
    window.open("https://codeverse-swag.myshopify.com/");
  };

  const faqRedirect = () => {
    window.open("https://www.codeverse.com/faq/");
  };

  const getOrganizationBookings = (page = 1) => {
    dispatch(
      dataClientRequest({
        ...GET_ORGANIZATION_BOOKINGS,
        data: {
          id: localStorage.getItem("currentOrganizationId"),
        },
        query: {
          per_page: 500,
          page,
        },
      })
    );
  };

  useEffect(() => {
    getOrganizationBookings();
  }, []);

  useEffect(() => {
    if (priorityMembership && currentUser) {
      const organization_classification =
        priorityMembership.organization.classification;
      const organization_status = priorityMembership.organization.status;
      const userProperties = Object.assign(
        {
          Name: currentUser.name,
          User_Scope: currentUser.scope,
          Organization_Id: priorityMembership.organization.id,
        },
        organization_classification && {
          Organization_Classification: organization_classification,
        },
        organization_status && { Organization_Status: organization_status }
      );
      //@ts-ignore
      if (window.heap) {
        //@ts-ignore
        window.heap.addUserProperties(userProperties);
      }
    }
  }, [priorityMembership, currentUser]);

  useEffect(() => {
    const newSubscription = subscriptions.find(
      (sub: any) => sub.status === "new" || sub.status === "pending"
    );
    if (
      bookings.length > 0 &&
      organization.status === "trial" &&
      newSubscription
    ) {
      const trialBookingIsComplete = bookings.find(
        (booking) =>
          booking.status === "completed" &&
          booking.occasion.id === "d2e9f65b-c86f-40c8-b9ce-4d3afc6dc8e8"
      );
      const otherBookings = bookings.filter(
        (booking: any) =>
          booking.status !== "completed" &&
          booking.occasion.id !== "d2e9f65b-c86f-40c8-b9ce-4d3afc6dc8e8"
      );
      if (trialBookingIsComplete && otherBookings.length === 0) {
        dispatch(openModal("bookFirstSession"));
      }
    }
  }, [bookings, organization, subscriptions]);

  useEffect(() => {
    const activeSubscription = subscriptions.find(
      (sub: any) =>
        sub.start_at &&
        moment(sub.start_at).isSameOrBefore(moment()) &&
        !sub.cancel_at &&
        activeStatuses.includes(sub.status)
    );

    if (activeSubscription) {
      dispatch(
        dataClientRequest({
          ...GET_SUBSCRIPTION_ADD_ONS,
          data: {
            id: activeSubscription.id,
          },
        })
      );
    }
  }, [subscriptions]);

  useEffect(() => {
    const activeSubscription = subscriptions.find(
      (sub: any) =>
        sub.start_at &&
        moment(sub.start_at).isSameOrBefore(moment()) &&
        !sub.cancel_at &&
        activeStatuses.includes(sub.status)
    );

    if (activeSubscription) {
      setActiveSubscription(activeSubscription);
      ///@ts-ignore
      if (window.heap) {
        ///@ts-ignore
        window.heap.addUserProperties({
          Subscription_Start_At: activeSubscription.start_at,
        });
      }
      if (subscriptionAddOns.length > 0) {
        ///@ts-ignore
        if (window.heap) {
          ///@ts-ignore
          window.heap.addUserProperties({
            Subscription_Add_On: subscriptionAddOns[0].product,
          });
        }
      }
    }
  }, [subscriptions, subscriptionAddOns]);

  useEffect(() => {
    if (query) {
      if (query.fromSurvey) {
        if (query.fromSurvey === "true") {
          toast.success(
            "A survey for this session has already been completed",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
      }
      if (
        query.checkout_confirmation &&
        query.checkout_confirmation === "true"
      ) {
        dispatch(openModal("checkoutConfirmation"));
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const iconStyle = {
    color: "#FFFFFF",
  };
  const textStyle = {
    color: "#FFFFFF",
    fontSize: ".9rem",
  };

  const getKidsNamesForConfirmation = () => {
    if (childUsers) {
      if (childUsers.length === 1) {
        return capitalizeFirstLetter(childUsers[0].name.split(" ")[0]);
      } else if (childUsers.length === 2) {
        return `${capitalizeFirstLetter(
          childUsers[0].name.split(" ")[0]
        )} & ${capitalizeFirstLetter(childUsers[1].name.split(" ")[1])}`;
      } else {
        return "your kids";
      }
    }
  };

  const Content = () => {
    return (
      <>
        {currentMemberships.length > 0 && (
          <div className="d-flex flex-wrap">
            {childUsers.length > 0 ? (
              <ChildUsersBox
                accessToken={accessToken}
                childUsers={childUsers}
                currentOrganizationId={priorityMembership.organization.id}
                priorityMembership={priorityMembership}
                isMobile={isMobile}
                userHasAccessToPlatform={userHasAccessToPlatform}
                subscriptionAddOns={subscriptionAddOns}
                activeSubscription={activeSub}
                history={history}
              />
            ) : (
              <div className="pb-3 w-100 fadein">
                <div className="card d-flex">
                  <div className="card-body">
                    <p className="card-text">No kids in this organization.</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {
          // currentSubscriptions && currentSubscriptions.length > 0
          //   ? <SubscriptionsInfoBox
          //     accessToken={accessToken}
          //     // currentOrganizationId={priorityMembership.organization.id}
          //     openModal={openModal}
          //     paymentMethods={paymentMethods}
          //     // setCurrentSubscription={setCurrentSubscription}
          //     // showExplorerOptions={showExplorerOptions}
          //     subscriptions={currentSubscriptions}
          //     userId={currentUser.id}
          //   />
          //   :
          //   <>
          //     {/* <h2 className="mt-4 mb-4 pb-2">Subscriptions</h2>
          //     <div className="pb-3 fadein-short">
          //       <div className="card d-flex">
          //         <div className="card-body">
          //           <p className="card-text">No Subscriptions yet!</p>
          //         </div>
          //       </div>
          //     </div> */}
          //   </>
        }
        {/* {
                hasRetrievedParentData &&
                <UserProfile
                  currentUser={currentUser}
                />
              } */}
        <h2 className="mt-4 mb-3 pb-2">Your Resources</h2>
        <div className="d-flex flex-wrap">
          {/* Portal Walkthrough Card */}
          <div className="child-card fadein-short col-md-6 col-12 pb-3 px-0 pl-2">
            <Card style={{ cursor: "pointer" }}>
              <div
                style={{
                  backgroundImage: "url(/img/portal-walkthrough.jpg)",
                  backgroundSize: "cover",
                }}
                onClick={() => howToVideosRedirect()}
                className="child-card__img-box"
              />
              <div
                onClick={() => howToVideosRedirect()}
                className="card-body py-2"
              >
                <div className="d-inline-flex align-items-center justify-content-between w-100">
                  <span
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    className="card-text align-left"
                  >
                    <strong>How-To Videos</strong>
                  </span>

                  <Button
                    className="child-card__sign-in-button"
                    context="secondary"
                    withoutSpan={
                      <>
                        <span style={textStyle}>GO</span>
                        <i
                          style={iconStyle}
                          className="fas fa-arrow-right pl-2"
                        ></i>
                      </>
                    }
                    onClick={() => howToVideosRedirect()}
                    type="button"
                    size="sm"
                  />
                </div>
              </div>
            </Card>
          </div>

          {/* How Kids Learn Card */}
          <div className="child-card fadein-short col-md-6 col-12 pb-3 px-0 pl-2">
            <Card style={{ cursor: "pointer" }}>
              <div
                style={{
                  backgroundImage: "url(/img/how-kids-learn.jpg)",
                  backgroundSize: "cover",
                }}
                onClick={() => howKidsLearnRedirect()}
                className="child-card__img-box"
              />
              <div
                onClick={() => howKidsLearnRedirect()}
                className="card-body py-2"
              >
                <div className="d-inline-flex align-items-center justify-content-between w-100">
                  <span
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    className="card-text align-left"
                  >
                    <strong>How Kids Learn</strong>
                  </span>

                  <Button
                    className="child-card__sign-in-button"
                    context="secondary"
                    withoutSpan={
                      <>
                        <span style={textStyle}>GO</span>
                        <i
                          style={iconStyle}
                          className="fas fa-arrow-right pl-2"
                        ></i>
                      </>
                    }
                    onClick={() => howKidsLearnRedirect()}
                    type="button"
                    size="sm"
                  />
                </div>
              </div>
            </Card>
          </div>

          {/* Swag Store Card */}
          <div className="child-card fadein-short col-md-6 col-12 pb-3 px-0 pl-2">
            <Card style={{ cursor: "pointer" }}>
              <div
                style={{
                  backgroundImage: "url(/img/swag-store.jpg)",
                  backgroundSize: "cover",
                }}
                onClick={() => swagStoreRedirect()}
                className="child-card__img-box"
              />
              <div
                onClick={() => swagStoreRedirect()}
                className="card-body py-2"
              >
                <div className="d-inline-flex align-items-center justify-content-between w-100">
                  <span
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    className="card-text align-left"
                  >
                    <strong>Swag Store</strong>
                  </span>

                  <Button
                    className="child-card__sign-in-button"
                    context="secondary"
                    withoutSpan={
                      <>
                        <span style={textStyle}>GO</span>
                        <i
                          style={iconStyle}
                          className="fas fa-arrow-right pl-2"
                        ></i>
                      </>
                    }
                    onClick={() => swagStoreRedirect()}
                    type="button"
                    size="sm"
                  />
                </div>
              </div>
            </Card>
          </div>

          {/* FAQ Card */}
          <div className="child-card fadein-short col-md-6 col-12 pb-3 px-0 pl-2">
            <Card style={{ cursor: "pointer" }}>
              <div
                style={{
                  backgroundImage: "url(/img/faq.jpg)",
                  backgroundSize: "cover",
                }}
                onClick={() => faqRedirect()}
                className="child-card__img-box"
              />
              <div onClick={() => faqRedirect()} className="card-body py-2">
                <div className="d-inline-flex align-items-center justify-content-between w-100">
                  <span
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    className="card-text align-left"
                  >
                    <strong>FAQ</strong>
                  </span>

                  <Button
                    className="child-card__sign-in-button"
                    context="secondary"
                    withoutSpan={
                      <>
                        <span style={textStyle}>GO</span>
                        <i
                          style={iconStyle}
                          className="fas fa-arrow-right pl-2"
                        ></i>
                      </>
                    }
                    onClick={() => faqRedirect()}
                    type="button"
                    size="sm"
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  };

  const showFreeTrial =
    organization.classification == "internal" ||
    organization.status == "lead" ||
    organization.status == "freemium";

  const showStreamRegistration =
    organization.classification == "internal" ||
    organization.status == "lead" ||
    organization.status == "freemium" ||
    organization.status == "trial";

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const currentUserId = localStorage.getItem("currentUserId");
    const currentOrganizationId = localStorage.getItem("currentOrganizationId");
    SatelliteClient.get(
      `${process.env.REACT_APP_PLATFORM_URL}/organizations/${currentOrganizationId}/streaming_registrations`,
      {
        headers: Object.assign(
          {},
          { Accept: "application/vnd.api+json" },
          { "content-type": "application/vnd.api+json" }
        ),
        query: {
          access_token: accessToken,
          current_user_id: currentUserId,
        },
      }
    ).then((response: any) => {
      const foundStream = response.data.data.find(
        (stream: any) => stream.stream.id === FIRST_STREAM_ID
      );
      setFoundStream(!!foundStream);
    });
  }, []);

  return (
    <>
      {true ? (
        <>
          {/* {
            isMobile &&
            <Alert className="d-lg-none mt-3" context="danger">
              Kid account access is only available on desktops or tablets.
            </Alert>
          } */}
          {priorityMembership &&
            !priorityMembership.organization.unlimited_ide_access && (
              <>
                {childUsers.length === 0 && (
                  <Alert className="mt-3" context="warning">
                    You don't have any Kid Accounts, click the 'Add Kid' button
                    to add your child.
                  </Alert>
                )}
              </>
            )}
          <div className="row">
            <div className="col-sm-12 col-md-8">
              {/* {(showStreamRegistration || foundStream) && (
                <StreamBanner foundStream={foundStream} />
              )} */}
              <div className="row mt-5 d-flex align-items-center">
                <h2 className="col-12 col-lg-6 mt-4 mb-3 pb-2">Kid Accounts</h2>

                <Button
                  className="ml-auto col-12 col-lg-3 mb-3"
                  context="secondary"
                  size="sm"
                  onClick={() => dispatch(openModal("addChild"))}
                >
                  <i className="add-icon fas fa-plus" />
                  Add Kid
                </Button>
              </div>
              <Content />
            </div>
            <div className="col-sm-12 col-md-4">
              {showFreeTrial ? (
                <TrialSignupCard />
              ) : (
                <div style={{ marginTop: "48px" }}>
                  <ReferralBox referralCode={currentUser.referral_code} />
                </div>
              )}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-4">
              <ReferralBox referralCode={currentUser.referral_code} />
            </div>

            <div className="col-md-4 mb-3">
              <ReferralBox referralCode={currentUser.referral_code} />
            </div>
          </div> */}
          <Modal
            isOpen={checkoutConfirmation}
            modalName="checkoutConfirmation"
            className="checkout-confirmation-modal"
          >
            <div>
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img
                  className="pb-4 mt-2 position-relative"
                  style={{ maxWidth: "125px", maxHeight: "125px" }}
                  src="/img/astronaut.svg"
                />
                <h2 className="w-100 text-center">Welcome to Codeverse!</h2>
              </div>
              <p>
                We’ve sent you an email confirmation and will reach out before
                your free session to make sure {getKidsNamesForConfirmation()}{" "}
                {childUsers.length === 1 ? "gets" : "get"} the best coding
                experience.
              </p>
              <Button
                className="w-100"
                context="tertiary"
                onClick={() => dispatch(closeModal("checkoutConfirmation"))}
              >
                Go to Parent Portal
              </Button>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '12px' }}>
              <AddToCalendarButton
                name="Codeverse Trial Class"
                startDate={query.start_date}
                startTime={query.start_time}
                endTime={query.end_time}
                timeZone={moment.tz.guess()}
                description={`
                [strong]How to Join:[/strong]
                [br]
                - Log in to your account at [url]account.codeverse.com[/url] [br]
                - Click on "Sign In" next to your child's name in order to join the session. [br]
                - Your child's Guide will be available 5 minutes before the session and you will see a "Join" button light up when it is time to begin! [br]
                - Make sure you are on a desktop or laptop computer with a working camera, microphone, and speaker. Try to avoid joining from an iPad, if possible, since this does not allow for screen sharing. [br]
                - Our platform supports Safari and Chrome web browsers, and works best on an up to date version of Google Chrome. You can download Google Chrome here or verify your version at [url]chrome://settings/help[/url]. 
                `}
                options={['Apple','Google','Yahoo','iCal']}
              ></AddToCalendarButton>

              </div>
            </div>
          </Modal>
          <Modal
            isOpen={updatePaymentMethodModal}
            title="Update Payment Method"
            modalName="updatePaymentMethodModal"
          >
            <BraintreeCCForm
              // accessToken={accessToken}
              closeModal={closeModal}
              dispatch={dispatch}
              currentSubscriptionId={currentSubscriptionId}
              // getToken={getToken}
              // userId={userId}
              // createPaymentMethod={createPaymentMethod}
              // setPaymentMethod={setPaymentMethod}
            />
          </Modal>
        </>
      ) : (
        <div>
          <div className="spacer" />
          <div className="spacer" />
          <div className="spacer" />
          <div className="nova-portal m-auto loader" />
        </div>
      )}
    </>
  );
};
