import React, { useEffect, useState, useRef, createRef } from 'react';
import Editor from '@codeverse/editor';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from '@codeverse/react-helios-ui';
import DiffTracker from '@codeverse/kidscript-patch-merge';

import { printNetworkQualityStats } from 'utils/printNetworkQualityStats';
import { pollAudioLevel } from 'components/Guide/Meeting/pollAudioLevel';
import AudioMeter from './Local/AudioMeter';


import { RootState } from 'store/state';
import { TrackNameTooLongError } from 'twilio-video';

type Props = {
  history?: any;
  location?: any;
  participant: any;
  addVideoTrack: any;
  removeVideoTrack: any;
  sendData: any;
  room: any;
  currentView: any;
  setName: any;
  audioOutputDeviceId: any;
  setParticipantVideoTracks: any;
  keyName: any;
  followingCursor: boolean;
  setFollowingCursor: any;
  currentUser: any;
};

const MAX_WATCH_INTERVAL = 3000;

type EDITOR_STATUS = 'connected' | 'detached' | 'undefined';

type MyState = {
  audioLevel: any;
  logEntries: any;
  // remote editor state
  currentDocument: any;
  localDocument: any;
};
export default class ParticipantTemp extends React.Component<Props, MyState> {
  private video: any;
  private canvas: any;
  private screen: any;
  private audio: any;
  private editor: any;
  private editorStatusTimeoutHandler: any;
  private dataTrackMessageListener: any;

  private videoTrack: any;
  private audioTrack: any;
  private dataTrack: any;
  private screenTrack: any;
  private canvasTrack: any;

  private editorId: any;
  private canvasId: any;
  private editorStatus: any;

  private diffTrackers: any;
  private editorWatchHandlers: any;
  private editorWatchIntervals: any;

  constructor(props: Props) {
    super(props);
    this.video = createRef();
    this.canvas = createRef();
    this.screen = createRef();
    this.audio = createRef();
    this.editor = null;

    this.videoTrack = null;
    this.audioTrack = null;
    this.dataTrack = null;
    this.screenTrack = null;
    this.canvasTrack = null;

    this.editorId = `${this.props.participant.identity}-Editor`
    this.canvasId = `${this.props.participant.identity}-Canvas`
    this.diffTrackers = [];
    this.editorWatchHandlers = [];
    this.editorWatchIntervals = [];
  }
  state: MyState = {
    // optional second annotation for better type inference
    audioLevel: 0,
    logEntries: [],
    // remote editor state
    currentDocument: null,
    localDocument: 0,
  };

  componentDidMount() {
    const { participant } = this.props;
    participant.tracks.forEach((publication: any) => {
      if (publication.isSubscribed) {
        this.trackSubscribed(participant.identity, publication.track);
      }
    });

    this.editor = new Editor(this.editorId, ['', ''], {
      initialDocument: 0,
    });
    //@ts-ignore
    window.editor = this.editor;
    //@ts-ignore
    window.editor.keyboard.disable();
    //@ts-ignore
    window.editor.cursor.disable();
    //@ts-ignore
    window.editor.keyboard.setDefault('enabled', false);
    this.editor.on('document:switch', () => {
      this.setState({ localDocument: this.editor.documents.getCurrentDocumentID() })
    });

    participant.on('trackSubscribed', (track: any) => this.trackSubscribed(participant.identity, track));
    participant.on('trackUnsubscribed', (track: any) => this.trackUnsubscribed(track));
  }

  trackSubscribed = (key: string, track: any) => {
    // log('trackSubscribed', track)
    switch (track.kind) {
      case 'video':
        // a dedicate stream of the uses game canvas
        if (track.name === 'canvas') {
          this.addCanvasTrack(track)
        }
        // a stream of their whole screen
        else if (track.name === 'screen') {
          this.addScreenTrack(track)
        }
        // the video feed from their camera
        else {
          this.props.addVideoTrack(key, track);
        }
        break;

      case 'audio':
        this.addAudioTrack(track)
        break;

      case 'data':
        this.addDataTrack(track);
        break;

      default:
        throw new Error(`unrecognised track kind ${track.kind}`)
    }
  }

  trackUnsubscribed = (track: any) => {

    switch (track.kind) {
      case 'video':
        if (track.name === 'canvas') {
          this.removeCanvasTrack(track)
        }
        else {
          this.removeVideoTrack(this.props.participant.identity)
        }
        break;

      case 'audio':
        this.removeAudioTrack(track)
        break;

      case 'data':
        this.removeDataTrack(track)
        break;

      default:
        throw new Error(`unrecognised track kind ${track.kind}`)
    }
  }

  removeVideoTrack = (key: string) => {
    this.props.removeVideoTrack(key);
    this.videoTrack = null;
  }

  removeScreenTrack = (track: any) => {
    this.screenTrack = null;
  }

  removeCanvasTrack = (track: any) => {
    track.detach();
    this.canvasTrack = null;
  }

  removeAudioTrack = (track: any) => {
    this.audioTrack = null;
  }

  removeDataTrack = (track: any) => {
    this.dataTrack = null;
  }

  addCanvasTrack = (track: any) => {
    this.canvasTrack = track;
    track.attach(this.canvas.current)
  }

  addScreenTrack = (track: any) => {
    track.attach(this.screen.current)
  }

  addAudioTrack = (track: any) => {
    this.audioTrack = track;
    this.startAudioLevelPolling();
    track.attach(this.audio.current);
    if (this.props.audioOutputDeviceId && this.audio.current) {
      this.audio.current.setSinkId('').then(() => {
        this.audio.current.setSinkId(this.props.audioOutputDeviceId);
      });
    }
  }

  startAudioLevelPolling = () => {
    pollAudioLevel(this.audioTrack, (level: any) => {
      this.setState({
        audioLevel: level,
      })
    })
  }

  addDataTrack = (track: any) => {
    this.getEditorStatus();

    this.dataTrack = track;

    this.dataTrackMessageListener = track.on('message', (payload: any) => {
      const { type, event, to, data } = JSON.parse(payload)

      // ignore messages intended for a specific participant which isnt this participant
      if (to !== null && to !== this.props.room.localParticipant.identity) {
        return;
      }

      if (type === 'status') {

        switch (event) {
          case 'participantAdded':
            this.sendDataFromLocal('status', 'participantAddedAck');
            this.sendDataFromLocal('user', 'setName', { name: this.props.currentUser.name.split(' ')[0] })
            break

          default:
            throw new Error(`unhandled status event ${event}`);

        }

      }

      if (type === 'editor') {

        switch (event) {
          case 'status': {
            this.editorStatus = 'connected';
            this.setCurrentDocument(data.currentDocument);
            for (let doc = 0; doc < data.documents.length; doc++) {
              this.diffTrackers[doc] = new DiffTracker(false, data.documents[doc].baseKidScript, data.documents[doc].version);
              // this.delayedCheckEditorForChanges(doc);
            }
            this.editor.setSource([this.diffTrackers[0].getCurrentKidScript(), this.diffTrackers[1].getCurrentKidScript()])
            // this.setCursor(data.currentDocument, data.sourcePosition.start, data.sourcePosition.end)
            break;
          }

          case 'appliedPatchVersions': {
            const diffTracker = this.diffTrackers[data.doc];
            if (diffTracker) {
              diffTracker.acceptVersions(data.acceptedVersions);
              diffTracker.rejectVersions(data.rejectedVersions);
            }
            break;
          }

          case 'sourceChanged': {
            const diffTracker = this.diffTrackers[data.doc];
            if (diffTracker) {
              const cursorStartAndEnd = [
                this.editor.cursor.sourcePosition.start.offset,
                this.editor.cursor.sourcePosition.end.offset
              ]
              const { acceptedVersions, rejectedVersions, cursorOffsets } = diffTracker.addRemotePatches(data.patches, cursorStartAndEnd);
              if (acceptedVersions.length || rejectedVersions.length) {
                this.sendDataFromLocal('editor', 'appliedPatchVersions', {
                  acceptedVersions,
                  rejectedVersions,
                  doc: data.doc,
                });
              }
              this.editor.setSource([this.diffTrackers[0].getCurrentKidScript(), this.diffTrackers[1].getCurrentKidScript()]);
              if (this.props.followingCursor) {
                // this.setCursor(data.currentDocument, data.sourcePosition.start, data.sourcePosition.end)
              } else {
                // this.setCursor(data.currentDocument, cursorOffsets[0], cursorOffsets[1])
              }
            }
            break;
          }

          case 'cursorChanged':
            if (this.props.followingCursor) {
              // this.setCursor(data.currentDocument, data.sourcePosition.start, data.sourcePosition.end)
            }
            break

          case 'documentSwitched': {
            this.setCurrentDocument(data.currentDocument)
            break
          }

          case 'ready':
            this.setCurrentDocument(data.currentDocument)
            this.editor.compiler.setSource(data.kidScript);
            // this.setCursor(data.currentDocument, data.sourcePosition.start, data.sourcePosition.end)
            break
          case 'clear':
            this.editor.setSource(['', '']);
            break;

          case 'detached':
            this.editorStatus = 'detached';
            break

          default:
            throw new Error(`unhandled editor event ${event}`);

        }

      }
    });
  }

  delayedCheckEditorForChanges = (doc: any) => {
    clearTimeout(this.editorWatchHandlers[doc]);
    const diffTracker = this.diffTrackers[doc];
    this.editorWatchHandlers[doc] = setTimeout(() => {
      if (this.editor && diffTracker) {
        const currentKidScript = this.editor.documents.getSource(doc);
        diffTracker.detectChanges(currentKidScript);
        const unacknowledgedPatches = diffTracker.getUnacknowledgedPatchesData();
        if (unacknowledgedPatches.length > 0) {
          // send the change to the remote client
          this.sendDataFromLocal('editor', 'sourceChanged', {
            doc,
            patches: unacknowledgedPatches,
            currentDocument: this.editor.documents.getCurrentDocumentID(),
            sourcePosition: this.editor.cursor.sourcePosition,
          });
          // when we find changes, we start looking for changes more frequently
          this.editorWatchIntervals[doc] = 1000;
        } else {
          // when there are no changes, we decrease the frequency of changes until we reach the max
          this.editorWatchIntervals[doc] += 100;
          if (this.editorWatchIntervals[doc] > MAX_WATCH_INTERVAL) {
            this.editorWatchIntervals[doc] = MAX_WATCH_INTERVAL;
          }
        }
      }
      // call this again after the expected interval
      this.delayedCheckEditorForChanges(doc);
    }, this.editorWatchIntervals[doc] || MAX_WATCH_INTERVAL);

  }

  sendDataFromLocal = (type: any, event: any, data: any = {}) => {
    this.props.sendData(type, event, data, this.props.participant.identity);
  }

  getEditorStatus = () => {
    this.sendDataFromLocal('editor', 'getStatus');
    clearTimeout(this.editorStatusTimeoutHandler);
    this.editorStatusTimeoutHandler = setTimeout(() => {
      if (typeof this.editorStatus === 'undefined') {
        this.getEditorStatus();
      }
    }, 499);
  }

  setCurrentDocument = (doc: any) => {
    if (this.props.followingCursor) {
      this.editor.switchDocument(doc)
    }
    if (this.state.currentDocument !== doc) {
      this.setState({ currentDocument: doc })
    }
  }

  setCursor = (doc: any, start: any, end: any) => {
    return;
    if (doc == this.editor.documents.getCurrentDocumentID()) {
      this.editor.cursor.setSourcePosition('remote', start, end)
    }
  }


  render() {
    return (
      <div className="EditorGameView">
        <audio
          autoPlay
          muted={false}
          ref={this.audio}
        />
        <div className="audio-meter-container">
          <AudioMeter audioLevel={this.state.audioLevel} audioEnabled={true} />
        </div>
        <div style={{ display: this.props.currentView === 'editor' ? 'flex' : 'none' }} className="editor-game-container">
          <div
            className="kidscript-editor"
            id={this.editorId}
          />
          <video
            className="game-canvas"
            autoPlay={true}
            muted={true}
            id={this.canvasId}
            ref={this.canvas}
          />
        </div>
        <div style={{ display: this.props.currentView === 'screen' ? 'flex' : 'none' }}>
          <div className="screen-capture-container">
            <video
              className="screen-capture"
              autoPlay={true}
              muted={true}
              id={this.canvasId}
              ref={this.screen}
            />
          </div>
        </div>
      </div>
    );
  }
}
