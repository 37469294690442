import { GuideState, GuideAction } from './types';

const initialState: GuideState = {
  currentMeeting: null,
}

export function GuideReducer(state = initialState, action: GuideAction): GuideState {
  switch (action.type) {
    case 'SET_CURRENT_MEETING':
      return { ...state, currentMeeting: action.payload }
    default:
      return state;
  }
}