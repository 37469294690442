export default function(limit: number, offset: number) {
  const date = new Date();
  const max = date.getFullYear() - offset;
  const min = max - limit;
  const value: any = null;
  const years = [{ value, label: 'YYYY' }];

  for (let i = max; i >= min; i--) {
    years.push({ value: i, label: i.toString() });
  }
  return years;
}
