import React, { useEffect, useState } from "react";
import { StepWizard } from "@codeverse/react-helios-ui";
import AddChild from "components/Checkout/AddChild/AddChild";
import ChoosePlan from "components/Checkout/ChoosePlan/ChoosePlan";
import SelectTime from "components/Checkout/SelectTime/SelectTime";
import Review from "components/Checkout/Review/Review";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/state";
import Booked from "components/Checkout/Booked/Booked";
import SelectChild from "components/Checkout/SelectChild/SelectChild";

type Props = {
  history?: any;
  location?: any;
  getParentDataFunc?: any;
};

const AnotherOne: React.FC<any> = () => <div>hi</div>;

const Checkout: React.FC<Props> = ({
  location,
  history,
  getParentDataFunc,
}) => {
  const [scrollContainer, setScrollContainer] = useState(true);
  const dispatch = useDispatch();
  const childUsers = useSelector((state: RootState) => state.user.childUsers);
  const checkout = useSelector((state: RootState) => state.checkout);
  useEffect(() => {
    // if (location.hash === '#choose-plan') {
    //   setScrollContainer(false)
    // } else {
    setScrollContainer(true);
    // }
  }, [location.hash]);

  useEffect(() => {
    localStorage.setItem("checkoutState", JSON.stringify(checkout));
  }, [checkout]);

  useEffect(() => {
    // if (childUsers.length === 1) {
    //   dispatch({ type: 'UPDATE_CHILD_BY_INDEX', index: 0, value: { user_id: childUsers[0].id, slot_instance: '' } })
    // }
  }, [childUsers]);

  return (
    <div className="checkout-container">
      <div className={scrollContainer ? "checkout-container-scroll" : "center"}>
        <div
          className="nova nova-white fadeIn"
          onClick={() => history.push("/parent/dashboard")}
        />

        <StepWizard
          initialStep={1}
          isHashEnabled
          className={`checkout-step-container${
            !scrollContainer ? " center" : ""
          }`}
        >
          <ChoosePlan hashKey={"choose-plan"} />
          <SelectChild hashKey={"select-child"} />
          <AddChild hashKey={"add-child"} />
          <SelectTime hashKey={"select-time"} />
          <Review hashKey={"review"} getParentDataFunc={getParentDataFunc} />
          <Booked hashKey={"booked"} />
        </StepWizard>
      </div>
    </div>
  );
};

export default Checkout;
