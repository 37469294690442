import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { toast } from "react-toastify";
import { AnyAction } from "redux";
import LogRocket from "logrocket";

import { getCookie, removeCookie, saveCookie } from "store/auth";
import api from "./api";

import {
  LoginRequest,
  LoginResponse,
  SessionCheckRequest,
  SessionAction,
} from "./types";

declare var heap: any;

export const loginRequest = (): SessionAction => {
  return { type: "LOGIN_REQUEST" };
};

export const loginSuccess = (payload: any): SessionAction => {
  return { type: "LOGIN_SUCCESS", payload };
};

export const loginFailed = (error: string): SessionAction => {
  return { type: "LOGIN_FAILED", error };
};

export const sessionCheckRequest = (): SessionAction => {
  return { type: "SESSION_CHECK_REQUEST" };
};

export const sessionCheckSuccess = (payload: SessionAction): SessionAction => {
  return { type: "SESSION_CHECK_SUCCESS", payload };
};

export const sessionCheckFailed = (error: string): SessionAction => {
  return { type: "SESSION_CHECK_FAILED", error };
};

export const rehydrateSessionFromCookies = (cookie: any): SessionAction => {
  return { type: "REHYDRATE_SESSION_FROM_COOKIES", cookie };
};

export const login = (
  payload: LoginRequest,
  redirect: any = "/",
  guide: boolean
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(loginRequest());
    return api
      .authenticateUser(payload.email, payload.password)
      .then((response: any) => {
        const user = response.data.data.user;
        let postLoginRedirect = redirect;
        if (guide) {
          if (user.scope !== "administrator" && user.scope !== "guide") {
            toast.error("Invalid Permissions: You must be a Codeverse Guide", {
              position: toast.POSITION.TOP_CENTER,
            });
            return payload.history.push("/login");
          }
          postLoginRedirect = "/guide/profile";
        }
        dispatch(loginSuccess(response.data.data));
        localStorage.setItem(
          "accessToken",
          response.data.data.access_token.access_token
        );
        saveCookie(response.data.data);
        localStorage.setItem("currentUserId", user.id);
        if (LogRocket) {
          LogRocket.identify(user.id, {
            name: user.name,
            email: user.email,
          });
        }
        // @ts-ignore
        if (window.heap) {
          // @ts-ignore
          window.heap.identify(user.referral_code);
          const userProperties = Object.assign({
            Name: user.name,
            User_Scope: user.scope,
            User_ID: user.id,
          });
          // @ts-ignore
          window.heap.identify(user.id);
          // @ts-ignore
          window.heap.addUserProperties(userProperties);
        }
        return payload.history.push(postLoginRedirect);
      });
  };
};

export const checkSession = (
  payload: SessionCheckRequest
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    return new Promise<void>((resolve) => {
      dispatch(sessionCheckRequest());
      return api
        .checkSession(payload.access_token, payload.current_user_id)
        .then((response: any) => {
          dispatch(sessionCheckSuccess(response.data.data));
          const user = response.data.data;

          if (localStorage.getItem("replaceSession") === null) {
            localStorage.setItem("currentUserId", user.id);
          }
          if (LogRocket) {
            LogRocket.identify(user.id, {
              name: user.name,
              email: user.email,
            });
          }
          const userProperties = Object.assign({
            Name: user.name,
            User_Scope: user.scope,
            User_ID: user.id,
          });
          // @ts-ignore
          if (window.heap) {
            // @ts-ignore
            window.heap.identify(user.referral_code);
            // @ts-ignore
            window.heap.addUserProperties(userProperties);
          }
          dispatch(loginSuccess({ user }));
          if (user.scope !== "administrator" && user.scope !== "guide") {
            if (payload.redirectAfterLogin.includes("/parent/")) {
              return payload.history.push(payload.redirectAfterLogin);
            } else {
              return payload.history.push("/parent/dashboard");
            }
          }

          return payload.history.push(payload.redirectAfterLogin);
        })
        .catch((e: Error) => {
          // removeCookie();
          dispatch(sessionCheckFailed("sorry"));
          if (payload.redirectAfterLogin) {
            if (payload.redirectAfterLogin.includes("/parent/")) {
              return payload.history.push(
                `/login?next=${payload.redirectAfterLogin}`
              );
            } else if (payload.redirectAfterLogin.includes("/guide/")) {
              return payload.history.push(
                `/login/guide?next=${payload.redirectAfterLogin}`
              );
            }
          }
          return payload.history.push("/login");
        });
    });
  };
};
