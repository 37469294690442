import SatelliteClient from '@codeverse/satellite-client';

export default {
  getOrganizationMemberships: (access_token: string, current_user_id: string, organization_id: string) => {
    return SatelliteClient.platform.get(`/organizations/${organization_id}/memberships`, {
      headers: {
        Accept: 'application/vnd.api+json',
        'content-type': 'application/vnd.api+json',
        authorization: `Bearer ${access_token}`,
      },
      query: {
        current_user_id,
      },
    });
  },
  getOrganizationSubscriptions: (access_token: string, current_user_id: string, organization_id: string) => {
    return SatelliteClient.platform.get(`/organizations/${organization_id}/subscriptions`, {
      headers: Object.assign({},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' },
        { authorization: `Bearer ${access_token}` },
      ),
      query: {
        current_user_id,
      },
    });
  },
  getPaymentMethod: (access_token: string, current_user_id: string, payment_method_id: string) => {
    return SatelliteClient.platform.get(`/payment_methods/${payment_method_id}`, {
      headers: Object.assign({},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' },
        { authorization: `Bearer ${access_token}` },
      ),
      query: {
        current_user_id,
      },
    });
  },
  getSubscriptionsAddOns: (access_token: string, current_user_id: string, subscription_id: string) => {
    return SatelliteClient.platform.get(`/subscriptions/${subscription_id}/subscription_add_ons`, {
      headers: Object.assign({},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' },
        { authorization: `Bearer ${access_token}` },
      ),
      query: {
        current_user_id,
      },
    });
  },
  getUser: (access_token: string, userId: string) => {
    return SatelliteClient.platform.get(`/users/${userId}`, {
      headers: {
        Accept: 'application/vnd.api+json',
        'content-type': 'application/vnd.api+json',
        authorization: `Bearer ${access_token}`,
      },
      query: {
        current_user_id: userId,
      },
    });
  },
}