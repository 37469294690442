import React from 'react';

type Props = {
  args: any;
}

const Matched: React.FC<Props> = ({ args }) => {
  return (
    <>
      <div className="fc-time">
        <span>{args.timeText}</span>
      </div>
  <div className="fc-title">{args.event.title}</div>
    </>
  )
}

export default Matched;
