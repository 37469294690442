import React from "react";
import axios from "axios";
import { connect } from "react-redux";

import { checkSession } from "store/modules/Session/actions";
import { getCookie, removeCookie, saveCookie } from "store/auth";
import queryString from "query-string";

type Props = {
  access_token: string;
  current_user_id: string;
  isAuthenticated: boolean;
  isFetching: boolean;
  history: any;
  location: any;
  dispatch: any;
};

export const createSession = (slt: string, callback: any) => {
  return axios
    .post(
      `${process.env.REACT_APP_PLATFORM_URL}/sessions`,
      {
        data: {
          attributes: {
            slt,
          },
          type: "sessions",
        },
      },
      {
        headers: Object.assign(
          {},
          { Accept: "application/vnd.api+json" },
          { "content-type": "application/vnd.api+json" }
        ),
      }
    )
    .then((response) => {
      if (slt && typeof callback === "function") {
        callback(response);
      } else {
        // TODO: Migrate other login to use this action
      }
    });
};

export function requireAuth(Component: React.ReactNode) {
  class Authenticated extends React.Component<Props> {
    // eslint-disable-next-line consistent-return
    UNSAFE_componentWillMount() {
      const { access_token, current_user_id, isAuthenticated, location } =
        this.props;
      const query: any = queryString.parse(location.search);
      const redirectAfterLogin =
        this.props.location.pathname +
        this.props.location.search +
        this.props.location.hash;

      if (!isAuthenticated) {
        if (query.slt) {
          localStorage.removeItem("currentUserId");
          localStorage.removeItem("accessToken");
          removeCookie();
          createSession(query.slt, (response: any) => {
            console.log("RESPONSE!!!", response);
            const { included } = response.data;
            const [user, accessToken]: any = included;
            localStorage.setItem(
              "accessToken",
              accessToken.attributes.access_token
            );
            localStorage.setItem("currentUserId", user.id);
            saveCookie({
              user: Object.assign({}, user.attributes, { id: user.id }),
              access_token: accessToken,
            });
            // @ts-ignore
            if (window.heap) {
              // @ts-ignore
              window.heap.identify(user.referral_code);
              const userProperties = Object.assign({
                Name: user.name,
                User_Scope: user.scope,
                User_ID: user.id,
              });
              // @ts-ignore
              window.heap.identify(user.id);
              // @ts-ignore
              window.heap.addUserProperties(userProperties);
            }
            return this.props.history.push("/parent/dashboard");
          });
        }
        if (
          query.access_token &&
          query.current_organization_id &&
          query.current_user_id
        ) {
          localStorage.setItem(
            "currentOrganizationId",
            query.current_organization_id
          );
          localStorage.setItem("currentUserId", query.current_user_id);
          localStorage.setItem("accessToken", query.access_token);
          localStorage.removeItem("checkoutState");
          console.log("CHECKING SESSION WITH QUERY");
          return this.props.dispatch(
            checkSession({
              access_token: query.access_token,
              current_user_id: query.current_user_id,
              redirectAfterLogin,
              history: this.props.history,
            })
          );
        }
        const currentUserId = localStorage.getItem("currentUserId");
        const accessToken = localStorage.getItem("accessToken");
        console.log(
          "CHECKING SESSION WITH LOCAL STORAGE",
          currentUserId,
          accessToken
        );
        return this.props.dispatch(
          checkSession({
            access_token: accessToken,
            current_user_id: currentUserId,
            redirectAfterLogin,
            history: this.props.history,
          })
        );
      }
    }

    render() {
      const { isAuthenticated, isFetching } = this.props;

      if (isFetching) return <div>Loading...</div>;

      const cookies = getCookie();

      return (
        <>
          {this.props.isAuthenticated === true ? (
            // @ts-ignore
            <Component {...this.props} />
          ) : null}
        </>
      );
    }
  }
  const mapStateToProps = (state: any) => {
    console.log("STATE", state);
    return {
      access_token:
        state.session.access_token && state.session.access_token.access_token,
      current_user_id: state.user.currentUser && state.user.currentUser.id,
      isAuthenticated: state.session.isAuthenticated,
    };
  };

  return connect(mapStateToProps)(Authenticated);
}

export default requireAuth;
