import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/state";
import { AppDispatch } from "store";
import moment from "moment-timezone";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faInfo } from "@fortawesome/pro-solid-svg-icons";
import { faPencil } from "@fortawesome/pro-duotone-svg-icons";
import SatelliteClient from "@codeverse/satellite-client";
import { dataClientRequest } from "@codeverse/redux-data-client";

import { GET_SUBSCRIPTION_ADD_ONS } from "models/Subscription";
import { openModal, closeModal } from "store/modules/UI/actions";
import { ReferralBox } from "components/Shared/ReferralBox";
// import ToggleInput from 'components/Shared/ToggleInput';
import Modal from "components/Shared/NewModal";
import ChildSignInButton from "./ChildSignInButton";
import ChildInfoModal from "./ChildInfoModal";
import ChildPasswordModal from "./ChildPasswordModal";

type Props = {
  isMobile?: any;
  history?: any;
};

const ChildSettings: React.FC<Props> = ({ isMobile, history }) => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const accessToken =
    useSelector(
      (state: RootState) => state.session.access_token.access_token
    ) || "";
  const selectedChildUserName = window.location.pathname.split("/")[3];
  const [selectedChildUser, setSelectedChildUser] = useState(null);
  const [selectedChildUserLoaded, setSelectedChildUserLoaded] = useState(false);
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [passwordLoaded, setPasswordLoaded] = useState(false);
  const editChildInfo = useSelector(
    (state: RootState) => state.ui.editChildInfo
  );
  const editChildPassword = useSelector(
    (state: RootState) => state.ui.editChildPassword
  );
  const [childFullName, setChildFullName] = useState("");
  const [childBirthDate, setChildBirthDate] = useState("");
  // const [privateAccount, setPrivateAccount] = useState(false);
  const [activeSub, setActiveSubscription] = useState(null);

  const activeStatuses = ["active", "new", "trial", "pending"];

  const subscriptions = useSelector(
    (state: RootState) => state.organizations.subscriptions
  );
  const subscriptionAddOns = useSelector(
    (state: RootState) => state.organizations.subscriptionAddOns
  );
  const priorityMembership = useSelector(
    (state: RootState) => state.user.priorityMembership
  );

  useEffect(() => {
    const activeSubscription = subscriptions.find(
      (sub: any) =>
        sub.start_at &&
        moment(sub.start_at).isSameOrBefore(moment()) &&
        !sub.cancel_at &&
        activeStatuses.includes(sub.status)
    );

    if (activeSubscription) {
      dispatch(
        dataClientRequest({
          ...GET_SUBSCRIPTION_ADD_ONS,
          data: {
            id: activeSubscription.id,
          },
        })
      );
    }
  }, [subscriptions]);

  useEffect(() => {
    const activeSubscription = subscriptions.find(
      (sub: any) =>
        sub.start_at &&
        moment(sub.start_at).isSameOrBefore(moment()) &&
        !sub.cancel_at &&
        activeStatuses.includes(sub.status)
    );

    if (activeSubscription) {
      setActiveSubscription(activeSubscription);
      // @ts-ignore
      if (window.heap) {
        // @ts-ignore
        window.heap.addUserProperties({
          Subscription_Start_At: activeSubscription.start_at,
        });
      }

      if (subscriptionAddOns.length > 0) {
        // @ts-ignore
        if (window.heap) {
          // @ts-ignore
          window.heap.addUserProperties({
            Subscription_Add_On: subscriptionAddOns[0].product,
          });
        }
      }
    }
  }, [subscriptions, subscriptionAddOns]);

  useEffect(() => {
    if (childFullName === "") {
      SatelliteClient.platform
        .get(`/users/${selectedChildUserName}`, {
          headers: Object.assign(
            {},
            { Accept: "application/vnd.api+json" },
            { "content-type": "application/vnd.api+json" }
          ),
          query: {
            access_token: accessToken,
            current_user_id: currentUser.id,
          },
        })
        .then((response: any) => {
          setSelectedChildUser(response.data.data);
          setChildFullName(response.data.data.name);
          if (response.data.data.date_of_birth) {
            const splitDOB = response.data.data.date_of_birth.split("-");
            setChildBirthDate(`${splitDOB[1]}/${splitDOB[2]}/${splitDOB[0]}`);
          }
          setChildFullName(response.data.data.name);
          setSelectedChildUserLoaded(true);
        })
        .catch((error: any) => {
          toast.error("Failed to fetch child info. Refresh and try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
          console.error(error);
        });
    }
  }, [childFullName]);

  useEffect(() => {
    if (selectedChildUserLoaded) {
      SatelliteClient.platform
        .get("/users/exists", {
          headers: Object.assign(
            {},
            { Accept: "application/vnd.api+json" },
            { "content-type": "application/vnd.api+json" }
          ),
          query: {
            username: selectedChildUserName,
          },
        })
        .then((response: any) => {
          setIsPasswordSet(response.data.can_login);
          setPasswordLoaded(true);
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }, [selectedChildUser, selectedChildUserLoaded]);

  interface EditButtonProps {
    handleOnClick: any;
  }
  const EditButton = ({ handleOnClick, ...props }: EditButtonProps) => {
    return (
      <div
        className="flex align-items-center justify-content-center rounded-20 bg-moon-lightest hover:bg-moon cursor-pointer text-center"
        style={{ height: "32px", width: "32px" }}
      >
        <FontAwesomeIcon
          icon={faPencil}
          color="#8D98C6"
          onClick={() => handleOnClick()}
        />
      </div>
    );
  };

  const openEditChildInfo = () => {
    dispatch(openModal("editChildInfo"));
  };

  const openEditPassword = () => {
    dispatch(openModal("editChildPassword"));
  };

  const leftColClasses = "col-12 col-md-6";
  const rightColClasses = "col-12 col-md-6";

  const settingHeadingStyles = {
    fontFamily: "Poppins-Medium, sans-serif",
  };

  return (
    <>
      <div className="py-4">
        <div className="col-12">
          <a
            onClick={() => history.push("/parent/dashboard/")}
            className="flex align-items-center mb-4"
          >
            <div
              className="flex align-items-center justify-content-center rounded-20 bg-moon-light text-center mr-2"
              style={{ height: "40px", width: "40px" }}
            >
              <FontAwesomeIcon icon={faArrowLeft} color="#474C72" />
            </div>
            <span className="font" style={{ color: "#0E1540" }}>
              Back
            </span>
          </a>
          <h2>{childFullName}'s Account Settings</h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 col-lg-7">
            <div className="card">
              <div
                className="card-title border-bottom px-24 py-3 mb-0 font-poppins-bold"
                style={{ borderColor: "RGBA(14, 21, 64, 0.08)" }}
              >
                Child Info
              </div>
              <div className="card-body p-0 relative">
                <div className="row px-24 pt-4 pb-3">
                  <div className={leftColClasses}>
                    <strong className="block mb-1 font-poppins-bold">
                      {childFullName}
                    </strong>
                    <div style={settingHeadingStyles}>
                      Birthday:{" "}
                      <span className="text-moon">
                        {childBirthDate || "Not set"}
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={openEditChildInfo}
                    className={`${rightColClasses} flex align-items-center justify-content-end`}
                  >
                    <EditButton handleOnClick={openEditChildInfo} />
                  </div>
                </div>
                <div
                  className="row px-24 py-3"
                  style={{ backgroundColor: "RGBA(238, 246, 253, 0.48)" }}
                >
                  <div className={leftColClasses}>
                    <div style={settingHeadingStyles}>Username</div>
                  </div>
                  <div className={rightColClasses}>
                    <span className="block text-neptune-dark font-poppins-medium text-right">
                      {selectedChildUserName}
                    </span>
                  </div>
                </div>
                <div className="row px-24 py-3">
                  <div className={leftColClasses}>
                    <div style={settingHeadingStyles}>Password</div>
                    {!isPasswordSet && (
                      <small className="font-poppins-regular text-moon">
                        Kids can use their username and password to login on
                        their own
                      </small>
                    )}
                  </div>
                  <div className={rightColClasses}>
                    {isPasswordSet && (
                      <div
                        className="flex align-items-center justify-content-end"
                        onClick={openEditPassword}
                      >
                        <span
                          className="inline-block text-moon mr-2"
                          style={{ fontSize: "1.25rem" }}
                        >
                          ••••••••••••••
                        </span>
                        <EditButton handleOnClick={openEditPassword} />
                      </div>
                    )}
                    {!isPasswordSet && (
                      <a
                        className="block text-sun-darkest text-right font-poppins-regular underline"
                        onClick={openEditPassword}
                      >
                        Set a password
                      </a>
                    )}
                  </div>
                </div>
                {/* <div className="row px-24 py-3" style={{ backgroundColor: 'RGBA(238, 246, 253, 0.48)' }}>
                  <div className={leftColClasses}>
                    <div style={settingHeadingStyles} className="flex">
                      Private account <span className="rounded flex align-items-center justify-content-center bg-moon-light ml-2" style={{ width: '24px', height: '24px' }}><FontAwesomeIcon size="xs" icon={faInfo} color="#ffffff" /></span>
                    </div>
                    <small className="font-poppins-regular text-moon">Only friends will be able to view and play games from Frankie’s profile.</small>
                  </div>
                  <div className={rightColClasses + ' flex justify-content-end align-items-center'}>
                    <ToggleInput value={privateAccount} onChangeFunction={setPrivateAccount} />
                  </div>
                </div> */}
                {!passwordLoaded && (
                  <div
                    className="absolute inset-0 bg-moon-light opacity-50"
                    style={{ borderRadius: "0 0 28px 28px" }}
                  >
                    <div className="nova-portal m-auto loader" />
                  </div>
                )}
              </div>
            </div>
            <div className="card my-4">
              <div className="card-body p-0">
                <div className="row px-24 py-3">
                  <div className="col-12 col-md-7">
                    <div className="font-poppins-bold">
                      Access child's account
                    </div>
                    <small
                      className="font-poppins-medium text-moon block mt-1"
                      style={{ lineHeight: "16px", fontSize: "12px" }}
                    >
                      Parents have full access to their child's account to view
                      their friend connections, profile and projects.
                    </small>
                  </div>
                  <div className="col-12 col-md-5 flex justify-content-end align-items-center">
                    <ChildSignInButton
                      childUser={selectedChildUser}
                      isMobile={isMobile}
                      activeSubscription={activeSub}
                      subscriptionAddOns={subscriptionAddOns}
                      priorityMembership={priorityMembership}
                      userHasAccessToPlatform
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 offset-lg-1">
            <ReferralBox referralCode={currentUser.referral_code} />
          </div>
        </div>
      </div>
      <Modal
        isOpen={editChildPassword}
        title={`Add a password for ${selectedChildUserName}`}
        modalName="changePassword"
        handleClose={() => {
          dispatch(closeModal("editChildPassword"));
        }}
        className="edit-password-modal"
        showHeader
      >
        <ChildPasswordModal
          currentUser={currentUser}
          childUser={selectedChildUser}
          dispatch={dispatch}
          passwordSet={isPasswordSet}
          setPasswordSet={setIsPasswordSet}
        />
      </Modal>
      <Modal
        isOpen={editChildInfo}
        title={"Edit Child Information"}
        modalName="changePassword"
        handleClose={() => {
          dispatch(closeModal("editChildInfo"));
        }}
        className="edit-password-modal"
        showHeader
      >
        <ChildInfoModal
          childUser={selectedChildUser}
          dispatch={dispatch}
          childFullName={childFullName}
          setChildFullName={setChildFullName}
          childBirthDate={childBirthDate}
          setChildBirthDate={setChildBirthDate}
        />
      </Modal>
    </>
  );
};

export default ChildSettings;
