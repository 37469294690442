import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from '@codeverse/react-helios-ui';
import queryString from 'query-string';

import { UserProfile } from 'components/Shared/UserProfile';
import { ReferralBox } from 'components/Shared/ReferralBox';
import { ProgramCertification } from 'components/Guide/Profile/ProgramCertification';
import { BookingPageInfoForm } from 'components/Guide/Profile/BookingPageInfoForm';
import { StripeIntegrationSection } from 'components/Guide/Profile/StripeIntegrationSection';
import { getGuideData, setPriorityMembership } from 'store/modules/User/actions';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { RootState } from 'store/state';
import { CREATE_STRIPE_ACCOUNT } from 'models/StripeAccount';
import { AppDispatch } from 'store';

import { GET_USER_GUIDE_PROFILE } from 'models/User';
import { GET_USER_STRIPE_ACCOUNT } from 'models/User';

type Props = {
  history?: any;
  location?: any;
};

export const GuideProfile: React.FC<Props> = ({ history, location }) => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const accessToken = useSelector((state: RootState) => state.session.access_token.access_token) || '';
  const [hasRetrievedGuideData, setHasRetrievedGuideData] = useState(false);
  const [guideProfile, setGuideProfile] = useState(null);
  const [stripeAccount, setStripeAccount] = useState(null);
  const priorityMembership = useSelector((state: RootState) => state.user.priorityMembership);
  const userMemberships = useSelector((state: RootState) => state.user.userMemberships);

  const query = queryString.parse(location.search);

  useEffect(() => {
    const currentUserId = localStorage.getItem('currentUserId') || '';

    Promise.all([
      dispatch(getGuideData(accessToken, currentUser.id || '')),
    ]).then((response: any) => {
      setHasRetrievedGuideData(true);
    })

    Promise.all([
      dispatch(dataClientRequest({
        ...GET_USER_GUIDE_PROFILE,
        data: {
          id: currentUserId,
        }
      })),
    ])
      .then((response: any) => {
        setGuideProfile(response[0].response.data.data);
      })
      .catch((error: any) => {
        setGuideProfile({});
      })

    dispatch(dataClientRequest({
      ...GET_USER_STRIPE_ACCOUNT,
      data: {
        id: currentUserId,
      },
      query: {
        filter: {
          include_account_link: true,
        },
      },
    }))
      .then((response: any) => {
        setStripeAccount(response)
      })
      .catch((error: any) => {
        setStripeAccount({});
      })
  }, []);

  useEffect(() => {
    const token = query.code;
    if (token) {
      dispatch(dataClientRequest({
        ...CREATE_STRIPE_ACCOUNT,
        data: {
          oauth_code: token,
          relationships: {
            user: { type: 'users', id: currentUser.id },
          }
        }
      }))
        .then((response: any) => {
          setStripeAccount(response);
        })
        .catch((error: any) => {
        })
    }
  }, [])

  useEffect(() => {
  }, [currentUser, priorityMembership])

  useEffect(() => {
    if (hasRetrievedGuideData && userMemberships.length > 0) {
      dispatch(setPriorityMembership(userMemberships[0]));
    }
  }, [userMemberships, hasRetrievedGuideData])

  return (
    <Row className="mt-5">
      {priorityMembership &&
        <>
          <Col md={8}>
            <UserProfile currentUser={currentUser} guide={true} />
            <ProgramCertification currentUser={currentUser} organizationId={priorityMembership.organization.id} />
            {guideProfile && <BookingPageInfoForm guideProfile={guideProfile} setGuideProfile={setGuideProfile} />}
            {stripeAccount && <StripeIntegrationSection stripeAccount={stripeAccount} />}
          </Col>
          <Col className="mb-3" md={4}>
            <ReferralBox referralCode={currentUser.referral_code} />
          </Col>
        </>
      }
    </Row>
  );
}