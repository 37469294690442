export default (state: any, action: any, reducerKey: string) => {
  // @ts-ignore
  if (action.reducerKey === reducerKey && action.stateKey && action.response) {
    // @ts-ignore
    if (Array.isArray(state[action.stateKey])) {
      const newRecords = [];
      // @ts-ignore
      if (Array.isArray(action.response.data.data)) {
        // @ts-ignore
        if (action.response.data.data.length === 0) {
          // @ts-ignore
          return { ...state, [action.stateKey]: [] };
        }
        // @ts-ignore
        if (action.resetData) {
          //@ts-ignore
          return { ...state, [action.stateKey]: action.response.data.data };
        }
        // @ts-ignore
        action.response.data.data.map((record) => {
          newRecords.push(record);
        });
      } else {
        // @ts-ignore
        newRecords.push(action.response.data.data);
      }
      // @ts-ignore
      const newState = [...state[action.stateKey]];
      newRecords.map((newRecord, index) => {
        const duplicateIndex = newState.findIndex(
          (record, index) => record.id === newRecord.id
        );
        if (duplicateIndex > -1) {
          newState.splice(duplicateIndex, 1, newRecord);
        } else {
          newState.push(newRecord);
        }
      });
      // @ts-ignore
      return { ...state, [action.stateKey]: newState };
    } else {
      // @ts-ignore
      console.log(action.response);
      return { ...state, [action.stateKey]: action.response.data.data };
    }
  } else {
    return state;
  }
};
