import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import ClassNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import { closeModal, openModal } from 'store/modules/UI/actions';
import enumerateDaysBetweenDates from 'utils/enumerateDaysBetweenDates';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';
import { dataClientRequest } from '@codeverse/redux-data-client';

import { GET_MEETING_PARTICIPATIONS } from 'models/Meeting';
import { CREATE_GUIDE_AVAILABILITY, GET_GUIDE_AVAILABILITY, UPDATE_GUIDE_AVAILABILITY, GET_USERS_PARTICIPATIONS, GET_OTHER_USER_MODEL_CONFIG, GET_GUIDE_SCHEDULABLES } from 'models/User';
import { GET_SLOT_INSTANCES } from 'models/SlotInstances';

import AddSlot from './SlotView/AddSlot';
import Availability from './SlotView/Availability';
import Matched from './SlotView/Matched';

import { MeetingCustomRecord } from 'containers/Guide/UpcomingSessions';
import CalendarModalActions from 'components/Guide/Schedule/CalendarModalActions';

import transformHourInteger from 'utils/transformHourInteger';
import { TIMEZONE } from 'store/modules/User/types';

const determineStartHour = (timezone: TIMEZONE) => {
  switch (timezone) {
    case 'America/Los_Angeles':
      return 7
    case 'America/Denver':
      return 8;
    case 'America/Chicago':
      return 9;
    case 'America/New_York':
      return 10;
  }
}
const determineStopHour = (timezone: TIMEZONE) => {
  switch (timezone) {
    case 'America/Los_Angeles':
      return '19'
    case 'America/Denver':
      return '20';
    case 'America/Chicago':
      return '21'
    case 'America/New_York':
      return '22';
  }
}

type Props = {
  guideSchedulables: any;
  dataFetched: boolean;
  occasion: string;
  lockCalendar: boolean;
  setEventsBuilt: any;
};
export type EVENT_TYPE = 'add-availability' | 'guide-available' | 'slot-booked' | 'excluded' | 'booked';
export interface EVENT {
  id?: string
  title: string
  start: any
  end: any
  className: string
  type: EVENT_TYPE
  occasion?: string
  guideAvailabilityId?: string,
  htmlTitle?: string,
}

const Calendar: React.FC<Props> = ({
  guideSchedulables,
  dataFetched,
  occasion,
  lockCalendar,
  setEventsBuilt,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const participations = useSelector((state: RootState) => state.user.participations);
  const guide_availabilities = useSelector((state: RootState) => state.user.guide_availabilities);
  const guide_availability_instances = useSelector((state: RootState) => state.user.guide_availability_instances);
  const timezone: TIMEZONE = useSelector((state: RootState) => state.user.currentUser.time_zone);

  const [currentEvent, setCurrentEvent] = useState<any>({});

  const [calendarApi, setCalendarApi] = useState<any>({});
  const calendarRef: any = useRef(null);

  const [fullCalendarEvents, setFullCalendarEvents] = useState<Array<any>>([]);
  const calendarClassNames = ClassNames('calendar', {
    'd-none': guideSchedulables.length === 0
  });

  // Build Participation, Availability, 
  useEffect(() => {
    let isSubscribed = true;
    if (dataFetched && occasion && calendarApi) {
      buildEvents()
        .then((events: any) => {
          if (isSubscribed) {
            setFullCalendarEvents(events);
            setEventsBuilt(true);
          }
        });
    }
    return () => isSubscribed = false;
  }, [dataFetched, occasion, guide_availabilities, lockCalendar, calendarApi, guide_availability_instances, timezone]);

  useEffect(() => {
    if (dataFetched && occasion) {
      const calendarApiRef = calendarRef.current.getApi();
      if (calendarApiRef) {
        setCalendarApi(calendarApiRef);
      }
    }
  }, [calendarRef, dataFetched, occasion]);

  const minTime = useMemo(() => {
    switch (timezone) {
      case 'America/Los_Angeles':
        return '7:00:00'
      case 'America/Denver':
        return '8:00:00'
      case 'America/Chicago':
        return '9:00:00'
      case 'America/New_York':
        return '10:00:00'
    }
  }, [timezone]);

  const maxTime = useMemo(() => {
    switch (timezone) {
      case 'America/Los_Angeles':
        return '20:00:00'
      case 'America/Denver':
        return '21:00:00'
      case 'America/Chicago':
        return '22:00:00'
      case 'America/New_York':
        return '23:00:00'
    }
  }, [timezone]);

  const buildEvents = async () => {
    setEventsBuilt(false);
    let eventsArray: Array<EVENT> = [];
    return await buildAvailabilityEvents(eventsArray)
      // .then((events: any) => buildParticipationEvents(events))
      .then((events: any) => buildAddSlots(events))
  }

  const buildAvailabilityEvents = (eventsArray: any) => {
    return new Promise(async (resolve, reject) => {
      const events: Array<EVENT> = [...eventsArray];
      const currentGuideAvailabilityInstances = guide_availability_instances.filter((guide_availability_instance) => guide_availability_instance.occasion_id === occasion)
      currentGuideAvailabilityInstances.map((guide_availability_instance) => {
        const momentStartObject = moment.tz(`${guide_availability_instance.start_date} ${guide_availability_instance.start_time}`, 'YYYY-MM-DD HH:mm', 'America/Chicago').tz(timezone)
        const momentEndObject = moment(`${guide_availability_instance.start_date} ${guide_availability_instance.end_time}`, 'YYYY-MM-DD HH:mm', 'America/Chicago').tz(timezone)
        const availabilityStart = `${momentStartObject.format('YYYY-MM-DD')}T${momentStartObject.format('HH')}:00`;
        const availabilityEnd = `${momentStartObject.format('YYYY-MM-DD')}T${momentStartObject.format('HH')}:50`;
        const newEvent: EVENT = {
          title: guide_availability_instance.capacity === 0 ? 'Booked' : 'Available',
          start: availabilityStart,
          end: availabilityEnd,
          className: guide_availability_instance.capacity === 0 ? 'booked' : 'guide-available',
          type: guide_availability_instance.capacity === 0 ? 'booked' : 'guide-available',
          guideAvailabilityId: guide_availability_instance.availability_id,
        };
        events.push(newEvent);
      });
      return resolve(events)
    });
  }

  const buildParticipationEvents = (eventsArray: any) => {
    return new Promise(async (resolve, reject) => {
      const events: Array<EVENT> = [...eventsArray];
      // const foundParticipations = participations.filter((participation) => {
      //   return (currentDateMoment.format('YYYY-MM-DD') === moment(participation.meeting_start_at).format('YYYY-MM-DD')) && (participation.user.id === localStorage.getItem('currentUserId'));
      // });
      const meetingRecords: Array<MeetingCustomRecord> = [];
      // Go through all the found participations and get other user data
      for (const participation of participations) {
        await dispatch(dataClientRequest({
          ...GET_MEETING_PARTICIPATIONS,
          data: {
            id: participation.meeting.id,
          },
        }))
          .then(async (participationPayload: any) => {
            const meetingRecord: MeetingCustomRecord = {
              meeting_start_at: participationPayload.response.data.data[0].meeting_start_at,
              meeting_end_at: participationPayload.response.data.data[0].meeting_end_at,
              meeting_starting_in_seconds: participationPayload.response.data.data[0].meeting_starting_in_seconds,
              meeting_id: participationPayload.response.data.data[0].meeting.id,
              users: [],
            };
            const filteredParticipations = participationPayload.response.data.data.filter((p: any) => p.user.id !== localStorage.getItem('currentUserId'));
            for (const p of filteredParticipations) {
              await dispatch(dataClientRequest({
                ...GET_OTHER_USER_MODEL_CONFIG,
                data: {
                  id: p.user.id,
                },
              }))
                .then((getOtherUserPayload: any) => {
                  meetingRecord.users.push(getOtherUserPayload.response.data.data);
                })
            };
            meetingRecords.push(meetingRecord)
          });
      };

      meetingRecords.map((meetingRecord: MeetingCustomRecord) => {
        let newEvent: EVENT = null;
        let replaceIndex = null;
        const foundEvent = events.find((event: EVENT, index: number) => {
          const isSame = moment.tz(event.start, 'America/Chicago').isSame(moment.tz(meetingRecord.meeting_start_at, 'America/Chicago'));
          if (isSame) {
            replaceIndex = index;
          }
          return isSame;
        });

        if (foundEvent) {
          events.splice(1, replaceIndex);
        }
        newEvent = {
          id: 'a',
          title: meetingRecord.users[0] ? `${meetingRecord.users[0].name}` : `No Match`,
          start: meetingRecord.meeting_start_at,
          end: meetingRecord.meeting_end_at,
          className: "slot-booked",
          type: "slot-booked",
        };
        events.push(newEvent);
      });
      return resolve(events);
    });
  }


  const buildAddSlots = (events: any) => {
    return new Promise(async (resolve, reject) => {
      if (!calendarApi.currentDataManager) return;

      const { start, end } = calendarApi.currentDataManager.state.dateProfile.currentRange;
      const startMoment = moment(start).tz(timezone);
      const endMoment = moment(end).tz(timezone);
      const weeks = enumerateDaysBetweenDates(startMoment, endMoment);
      const newEvents: Array<EVENT> = [...events];
      const startHour = determineStartHour(timezone);
      for (const date of weeks) {
        const momentDate = moment.tz(`${moment(date).format('YYYY-MM-DD')} ${determineStartHour(timezone)}:00`, 'YYYY-MM-DD HH:mm', 'America/Chicago').clone().tz(timezone);
        momentDate.set('hour', startHour);
        let stop = false;

        let slotInstances: Array<any> = [];
        await dispatch(dataClientRequest({
          ...GET_SLOT_INSTANCES,
          query: {
            filter: {
              occasion_id: occasion,
              date: momentDate.format('YYYY-MM-DD'),
            },
            page: 1,
            per_page: 100,
          }
        }))
          .then((payload: any) => {
            slotInstances = payload.response.data.data;
          });

        // Iterate from 10am - 6pm
        while (!stop) {
          if (momentDate.format('HH') === determineStopHour(timezone)) {
            stop = true;
          }
          const eventExist = events.find((event: any) => momentDate.format('YYYY-MM-DDTHH:mm') === moment(event.start).format('YYYY-MM-DDTHH:mm'));

          const isTodayOrBefore = momentDate.isSameOrBefore(moment());

          if (!eventExist && !isTodayOrBefore) {
            const hasSlotInstance = slotInstances.find((slotInstance) => momentDate.format('YYYY-MM-DDTHH:mm') === moment.tz(`${slotInstance.start_date} ${slotInstance.start_time}`, 'YYYY-MM-DD HH:mm', 'America/Chicago').clone().tz(timezone).format('YYYY-MM-DDTHH:mm'));
            if (hasSlotInstance) {
              const newSlotInstanceEvent: EVENT = {
                title: 'Add Slot',
                // See event render to see how htmlTitle is being used (CUSTOM)
                htmlTitle: '<div class="add-icon"></div> Add Slot',
                start: `${momentDate.format('YYYY-MM-DD')}T${momentDate.format('HH')}:00`,
                end: `${momentDate.format('YYYY-MM-DD')}T${momentDate.format('HH')}:50`,
                className: lockCalendar ? 'add-slot add-slot__locked' : 'add-slot',
                type: 'add-availability',
              };
              newEvents.push(newSlotInstanceEvent)
            }
          }
          momentDate.add('1', 'hour');
        }
      }
      return resolve(newEvents);
    })
  }

  const debouncedFullCalendarEvents = useDebounce(fullCalendarEvents, 10);

  const renderCustomEvent = (arg: any) => {
    switch (arg.event._def.extendedProps.type) {
      case 'add-availability':
        return <AddSlot args={arg} />;
      case 'guide-available':
        return <Availability args={arg} />;
      case 'booked':
        return <Availability args={arg} />;
      case 'slot-booked':
        return <Matched args={arg} />;
    }
  }

  return (
    <div className={calendarClassNames}>
      <FullCalendar
        //@ts-ignore
        allDaySlot={false}
        ref={calendarRef}
        timeZone={timezone}
        initialView="timeGridWeek"
        customButtons={{
          myCustomRight: {
            text: ' ',
            click: function () {
              calendarApi.next();
              buildEvents()
                .then((events: any) => {
                  setFullCalendarEvents(events);
                  setEventsBuilt(true);
                });
            }
          },
          myCustomLeft: {
            text: ' ',
            click: function () {
              calendarApi.prev();
              buildEvents()
                .then((events: any) => {
                  setFullCalendarEvents(events);
                  setEventsBuilt(true);
                });
            }
          }
        }}
        //@ts-ignore
        plugins={[momentTimezonePlugin, timeGridPlugin]}
        // timeGridEventMinHeight={81}
        slotMinTime={minTime}
        slotMaxTime={maxTime}
        slotLabelInterval={'1:00:00'}
        slotDuration={'1:00:00'}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
        }}
        height="auto"
        contentHeight="auto"
        headerToolbar={{
          left: 'myCustomLeft',
          center: 'title',
          right: 'myCustomRight',
        }}
        displayEventEnd={false}
        events={debouncedFullCalendarEvents}
        eventClick={(e) => {
          if (lockCalendar) {
            return;
          }
          if (e.event.extendedProps.type === 'add-availability') {
            setCurrentEvent({
              start: e.event.start,
              end: e.event.end,
              event: e.event,
            });
            dispatch(openModal('createAvailability'));
          } else if (e.event.extendedProps.type === 'guide-available') {
            const currentGuideAvailability = guide_availabilities.find((ga: any) => ga.id === e.event.extendedProps.guideAvailabilityId);
            setCurrentEvent({
              start: e.event.start,
              end: e.event.end,
              guideAvailabilityId: e.event.extendedProps.guideAvailabilityId,
              guideAvailability: currentGuideAvailability,
            });
            dispatch(openModal('editGuideAvailability'));
          } else if (e.event.extendedProps.type === 'booked') {
            const currentGuideAvailability = guide_availabilities.find((ga: any) => ga.id === e.event.extendedProps.guideAvailabilityId);
            setCurrentEvent({
              start: e.event.start,
              end: e.event.end,
              guideAvailabilityId: e.event.extendedProps.guideAvailabilityId,
              guideAvailability: currentGuideAvailability,
            });
            dispatch(openModal('editGuideAvailability'));
          } else if (e.event.extendedProps.type === 'slot-booked') {
            const eventStart = moment(e.event.start);
            // need to find all availability in events, not guide availabilities
            const currentGuideAvailability = guide_availabilities.find((ga: any) => {
              const gaMoment = moment(`${ga.first_available_at}T${ga.start_hour}:${ga.start_minute === 0 ? '00' : ga.start_minute}`)
              if (ga.last_available_at) {
                const gaMomentLastAvailable = moment(`${ga.last_available_at}T${ga.start_hour}:${ga.start_minute === 0 ? '00' : ga.start_minute}`)
                return (gaMoment.format('dddd HH:mm') === eventStart.format('dddd HH:mm')) && eventStart.isBefore(gaMomentLastAvailable);
              } else {
                return gaMoment.format('dddd HH:mm') === eventStart.format('dddd HH:mm');
              }
            })

            if (currentGuideAvailability) {
              setCurrentEvent({
                start: e.event.start,
                end: e.event.end,
                guideAvailabilityId: currentGuideAvailability.id,
                guideAvailability: currentGuideAvailability,
              });
              dispatch(openModal('editParticipationSlot'));
            } else {
              toast.error(`Cannot edit: No availability found for ${eventStart.format('dddd M/DD @ hh:mmA')}`, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
        }}
        eventContent={renderCustomEvent}
      />
      <CalendarModalActions
        currentEvent={currentEvent}
        occasion={occasion}
      />
    </div>
  );
};

export default Calendar;
