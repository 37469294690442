export default function (startDate: any, endDate: any) {
  const dates = [];

  const currDate = startDate.startOf('day');
  const lastDate = endDate;

  while (currDate.add(1, 'days').diff(lastDate) < 1) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};