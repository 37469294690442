import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Input } from "@codeverse/react-helios-ui";
import { RouteComponentProps } from "react-router-dom";
import SatelliteClient from "@codeverse/satellite-client";
import { Button } from "@codeverse/react-helios-ui";
import { toast } from "react-toastify";
import { SetPasswordFunction } from "components/SignIn/SignIn";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/state";
import sessionApi from "store/modules/Session/api";
import userApi from "store/modules/User/api";

import { AppDispatch } from "store";
import { login } from "../../store/modules/Session/actions";

const checkEmail = (myString: string) => {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    myString
  );
};

type IProps = {
  guide: boolean;
  history: any;
  location: any;
  loginFailed: boolean;
  parentEmail: string;
  password: string;
  redirect: string;
  scope: string;
  setLoginFailed: (loginFailed: boolean) => void;
  setPassword: SetPasswordFunction;
  onForgotPasswordClick: () => void;
};

export const PasswordForm: React.FC<IProps> = ({
  guide,
  history,
  location,
  loginFailed,
  parentEmail,
  password,
  redirect,
  setLoginFailed,
  setPassword,
  onForgotPasswordClick,
  scope,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [passwordError, setPasswordError] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [staySignedIn, setStaySignedIn] = useState(true);

  useEffect(() => {
    if (staySignedIn) {
      localStorage.setItem("staySignedIn", "true");
    } else {
      localStorage.setItem("staySignedIn", "false");
    }
  }, [staySignedIn]);

  const handleLogin = (e: any) => {
    e.preventDefault();
    if (guide) {
      localStorage.setItem("guideLogin", "true");
    } else {
      localStorage.setItem("guideLogin", "false");
    }
    if (scope === "child") {
      return sessionApi
        .authenticateUser(parentEmail.trim(), password)
        .then((response: any) => {
          const accessToken = response.data.data.access_token.access_token;
          const userId = response.data.data.user.id;
          userApi
            .getUserMemberships(accessToken, userId)
            .then((membershipsResponse: any) => {
              const organizationId =
                membershipsResponse.data.data[0].organization.id;
              const isMobile = window.innerWidth < 992;
              window.location.assign(
                `${process.env.REACT_APP_ESCAPE_POD_URL}/home/?access_token=${accessToken}&current_user_id=${userId}&portal=true&show_mobile_navbar=${isMobile}&current_organization_id=${organizationId}`
              );
            });
        })
        .catch((response: any) => {
          toast.error(
            "Incorrect password. Try again, ask a parent, or contact support@codeverse.com",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          setPasswordError(true);
        });
    } else {
      return dispatch(
        login({ email: parentEmail.trim(), password, history }, redirect || "/", guide)
      ).catch((error) => {
        console.log("error", error);
        toast.error(
          "Incorrect password. Try again or contact support@codeverse.com",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        setPasswordError(true);
      });
    }
  };

  const showPassword = () => {
    setShowPass(!showPass);
    setInputType(!showPass ? "text" : "password");
  };

  const iconClassnames = classNames("far fa-lg btn-icon", {
    "fa-eye-slash": !showPass,
    "fa-eye": showPass,
  });

  const passwordClassnames = classNames("login-field mt-4", {
    "has-value": password !== "",
    "has-focus": inputFocus,
    "has-danger": passwordError && !inputFocus,
  });

  return (
    <div>
      <h1 className="org-login__information-header header-two-tone pb-4">
        Enter your <span>password</span>
      </h1>
      <div className={passwordClassnames}>
        <label>Password</label>
        <input
          autoFocus
          className="login-field__control"
          onChange={(e: any) => {
            setPasswordError(false);
            setPassword(e.target.value);
          }}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          onKeyDown={(e: any) => {
            if (e.nativeEvent.keyCode === 13) {
              handleLogin(e);
            }
          }}
          type={inputType}
          value={password}
          // showError={passwordError}
        />
        <i onClick={() => showPassword()} className={iconClassnames} />
      </div>
      <div className="d-flex align-items-center mt-3">
        <input
          name="isGoing"
          type="checkbox"
          checked={staySignedIn}
          onChange={(e) => setStaySignedIn(!staySignedIn)}
        />
        <span className="ml-2">Keep me logged in</span>
      </div>
      <div className="org-login__legal-print">
        By clicking Sign In you agree to the Codeverse
        <a
          href="https://www.codeverse.com/terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
          className=""
        >
          {" "}
          Terms&nbsp;of&nbsp;Service
        </a>{" "}
        and
        <a
          href="https://www.codeverse.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          className=""
        >
          {" "}
          Privacy&nbsp;Policy.
        </a>
      </div>
      <div className="mt-4">
        <Button
          onClick={(e: any) => handleLogin(e)}
          className="org-login__button w-100"
          context="primary"
          withoutSpan={
            <>
              <span>Sign In</span>
              <i className="fas fa-chevron-right" />
            </>
          }
        />
      </div>
      <div className="org-login__forgot-password text-center mt-3">
        {scope !== "child" && (
          <a
            onClick={() => onForgotPasswordClick()}
            className="font-poppins-semibold cursor-pointer"
          >
            I forgot my password
          </a>
        )}
      </div>
    </div>
  );
};

export default PasswordForm;
