import React, { useEffect, useState, useMemo } from "react";
import CalendarDateCarousel from "components/Checkout/SelectTime/CalendarDateCarousel";
import { Card } from "@codeverse/react-helios-ui";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/state";
import { useHistory } from "react-router-dom";

import SlotInstance from "components/Checkout/SelectTime/SlotInstance";
import { resetCheckout } from "store/modules/Checkout/actions";

type Props = {
  hashKey: string;
  goToStepHash?: any;
};

const SelectTime: React.FC<Props> = ({ goToStepHash }) => {
  const [slotInstances, setSlotInstances] = useState<any>([]);
  const childUsers = useSelector((state: RootState) => state.user.childUsers);
  const currentChildIndex = useSelector(
    (state: RootState) => state.checkout.currentChildIndex
  );
  const checkoutChildren = useSelector(
    (state: RootState) => state.checkout.children
  );
  const dispatch = useDispatch();
  const [headerDateDisplay, setHeaderDateDisplay] = useState("");
  const history = useHistory();

  // useEffect(() => {
  //   if (
  //     checkoutChildren[currentChildIndex] &&
  //     !checkoutChildren[currentChildIndex].userId
  //   ) {
  //     dispatch(resetCheckout());
  //     history.push("/parent/checkout/#select-child");
  //   }
  // }, []);

  const SlotInstances = useMemo(() => {
    if (slotInstances.length > 0) {
      return slotInstances.map((slotInstance: any) => {
        if (
          moment
            .tz(
              `${slotInstance.start_date}T${slotInstance.start_time}`,
              "America/Chicago"
            )
            .isSameOrAfter(moment.tz("America/Chicago"))
        ) {
          return (
            <SlotInstance
              key={slotInstance.id}
              setCurrentStep={() => {
                // got to review if user created account already
                const accessToken = localStorage.getItem("accessToken");
                // if (accessToken) {
                //   goToStepHash("review");
                // } else {
                goToStepHash("set-password");
                // }
              }}
              slotInstance={slotInstance}
              setCurrentSlotInstance={(slotInstance: any) => {
                dispatch({
                  type: "UPDATE_CHILD_BY_INDEX",
                  index: currentChildIndex,
                  value: { slot_instance: slotInstance },
                });
              }}
            />
          );
        }
      });
    }
  }, [slotInstances]);

  const child = useMemo(() => {
    if (childUsers.length > 0 && checkoutChildren[currentChildIndex]) {
      const child = childUsers.find(
        (childUser) =>
          childUser.id === checkoutChildren[currentChildIndex].user_id
      );
      return child;
    }
    return null;
  }, [childUsers, currentChildIndex, checkoutChildren]);

  if (!child) {
    return null;
  }

  return (
    <div className="m-width-checkout">
      <h3>
        <span className="highlight">Schedule</span> {child.name.split(" ")[0]}'s
        1:1 class
      </h3>

      <CalendarDateCarousel
        setSlotInstances={setSlotInstances}
        setHeaderDateDisplay={setHeaderDateDisplay}
      />

      <Card className="checkout-card">
        <div className="checkout-card__header">
          <div>{headerDateDisplay}</div>
        </div>

        {SlotInstances}
      </Card>
    </div>
  );
};

export default SelectTime;
