import React from 'react';
import { Button } from '@codeverse/react-helios-ui';
import { closeModal, openModal } from 'store/modules/UI/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/state';
import moment from 'moment-timezone';

type Props = {
  showDeleteConfirmation: any;
  currentEvent: any;
  setAcceptingCodeverse: any;
  acceptingCodeverse: any;
  handleUpdateGuideAvailability: any;
  handleOnlyThisDate: any;
  handleDeleteFuture: any;
};

const EditAvailabilityModal: React.FC<Props> = ({
  showDeleteConfirmation,
  currentEvent,
  setAcceptingCodeverse,
  acceptingCodeverse,
  handleUpdateGuideAvailability,
  handleOnlyThisDate,
  handleDeleteFuture,
}) => {
  const dispatch = useDispatch();
  const timezone = useSelector((state: RootState) => state.user.currentUser.time_zone);

  if (showDeleteConfirmation) {
    return (
      <div className="edit-guide-availability-modal__content d-flex flex-column h-100">
        <div className="d-flex justify-content-center">
          <h4>{moment(currentEvent.start).format('dddd MM/DD/YYYY')} <br /> @ {moment(currentEvent.start).tz(timezone).format('h:mm A')}</h4>
        </div>

        <div className="w-100 mt-auto">
          <Button
            onClick={handleOnlyThisDate}
            className="w-100 mb-3"
            context="primary"
          >
            Only on this date
          </Button>
          <Button
            onClick={handleDeleteFuture}
            style={{ backgroundColor: '#fc5f54' }}
            className="w-100"
            context="status-danger"
          >
            This date and all future sessions
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className="edit-guide-availability-modal__content edit-guide-availability-modal__content--edit-availability d-flex flex-column">
      <div className="d-flex justify-content-center">
        <h4><br />{moment(currentEvent.start).format('dddd MM/DD/YYYY')} <br /> <br />from <br /><br />  {moment(currentEvent.start).tz(timezone).format('h:mm A')} - {moment(currentEvent.end).tz(timezone).format('h:mm A')}</h4>


      </div>
      {/* <div className="limit-students" onClick={() => setAcceptingCodeverse(!acceptingCodeverse)}>
        <input
          name=""
          type="checkbox"
          checked={!acceptingCodeverse}
          onChange={() => setAcceptingCodeverse(!acceptingCodeverse)}
        />
        <div className="limit-students__text">
          Limit this slot to my students only
        </div>
      </div>
      <div className="edit-actions w-100">
        {currentEvent.guideAvailability
          && <Button
            onClick={() => handleUpdateGuideAvailability()}
            disabled={!(acceptingCodeverse !== currentEvent.guideAvailability.accept_codeverse_customers)}
            className="w-100"
            context="primary"
          >
            Save Changes
          </Button>
        }
      </div> */}
    </div>
  );
};

export default EditAvailabilityModal;
