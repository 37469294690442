import React, { useEffect, useState } from 'react';
import { Card } from '@codeverse/react-helios-ui';

const PasswordCard: React.FC<any> = ({ color, handleCardClick, object }) => {
  const [cardColor, setCardColor] = useState(null);

  useEffect(() => {
    setCardColor(color);
  }, []);

  const cardStyle = {
    backgroundColor: cardColor,
  };

  return (
    <Card style={cardStyle} className="org-login__password-card" onClick={() => handleCardClick(object, cardColor)}>
      <img className="org-login__image" src={object.imgPath} />
    </Card>
  );
};

export default PasswordCard;
