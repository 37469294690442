import { CheckoutAction } from "./types";

export const addChild = (
  userId: string,
  childUsers: any = [],
  userPayload?: any
): CheckoutAction => {
  return { type: "ADD_CHILD", userId, childUsers, userPayload };
};

export const resetCheckout = (): CheckoutAction => {
  return { type: "RESET_CHECKOUT" };
};

export const updateChildByIndex = (
  index: number,
  value: any
): CheckoutAction => {
  return { type: "UPDATE_CHILD_BY_INDEX", index, value };
};

export const setCurrentChildIndex = (index: number): CheckoutAction => {
  return { type: "SET_CURRENT_CHILD_INDEX", index };
};

export const deleteChildBooking = (childIndex: number): CheckoutAction => {
  return { type: "DELETE_CHILD_BOOKING", childIndex };
};
