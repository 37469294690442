import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Input, Row, Col, Container } from '@codeverse/react-helios-ui';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { CREATE_GUIDE_PROFILE, UPDATE_GUIDE_PROFILE } from 'models/User';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { AppDispatch } from 'store';

type Props = {
  guideProfile: any;
  history?: any;
  location?: any;
  setGuideProfile?: any;
}

export const BookingPageInfoForm: React.FC<Props> = ({ guideProfile, setGuideProfile, history, location }) => {
  const dispatch: AppDispatch = useDispatch();

  const [infoChanged, setInfoChanged] = useState(false);
  const [bio, setBio] = useState(guideProfile && guideProfile.bio || '');
  const [facebookUsername, setFacebookUsername] = useState(guideProfile && guideProfile.facebook || '');
  const [instagramUsername, setInstagramUsername] = useState(guideProfile && guideProfile.instagram || '');
  const [linkedinUsername, setLinkedinUsername] = useState(guideProfile && guideProfile.linkedin || '');
  const [stackoverflowUsername, setStackoverflowUsername] = useState(guideProfile && guideProfile.stackoverflow || '');
  const [githubUsername, setGithubUsername] = useState(guideProfile && guideProfile.github || '');
  const [youtubeUsername, setYoutubeUsername] = useState(guideProfile && guideProfile.youtube || '');
  const [profileVideo, setProfileVideo] = useState(guideProfile && guideProfile.video_uri || '')

  const [bioFocus, setBioFocus] = useState(false);

  const messageClassNames = classNames('h-input', {
    'has-value': bioFocus || bio !== '',
    'has-danger': bio.length > 625,
  });

  const handleSave = () => {
    if (infoChanged) {
      if (Object.keys(guideProfile).length > 0) {
        dispatch(dataClientRequest({
          ...UPDATE_GUIDE_PROFILE,
          data: {
            id: guideProfile.id,
            bio,
            video_uri: null,
            facebook: facebookUsername === '' ? null : facebookUsername,
            instagram: instagramUsername === '' ? null : instagramUsername,
            linkedin: linkedinUsername === '' ? null : linkedinUsername,
            stackoverflow: stackoverflowUsername === '' ? null : stackoverflowUsername,
            github: githubUsername === '' ? null : githubUsername,
            youtube: youtubeUsername === '' ? null : youtubeUsername,
          }
        }))
          .then((payload: any) => {
            setGuideProfile(payload.response.data.data);
            toast.success('Booking page info updated', {
              position: toast.POSITION.TOP_CENTER,
            });
          })
          .catch(() => {
            toast.error('Failed to update booking page info', {
              position: toast.POSITION.TOP_CENTER,
            });
          })
      } else {
        let currentUserId = localStorage.getItem('currentUserId') || '';
        dispatch(dataClientRequest({
          ...CREATE_GUIDE_PROFILE,
          data: {
            bio,
            video_uri: null,
            facebook: facebookUsername === '' ? null : facebookUsername,
            instagram: instagramUsername === '' ? null : instagramUsername,
            linkedin: linkedinUsername === '' ? null : linkedinUsername,
            stackoverflow: stackoverflowUsername === '' ? null : stackoverflowUsername,
            github: githubUsername === '' ? null : githubUsername,
            youtube: youtubeUsername === '' ? null : youtubeUsername,

            relationships: {
              user: { type: 'users', id: currentUserId },
            }
          }
        }))
          .then((payload: any) => {
            setGuideProfile(payload.response.data.data);
            toast.success('Booking page info updated', {
              position: toast.POSITION.TOP_CENTER,
            });
          })
          .catch(() => {
            toast.error('Failed to update booking page info', {
              position: toast.POSITION.TOP_CENTER,
            });
          })
      }
      setInfoChanged(false);
    }
  }

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  return (
    <div className="booking-page-info fadein-short pb-3 pb-md-0 mb-5">
      {
        <Card>
          <CardHeader>
            <h4 className="booking-page-info__subheader">
              About Me
            </h4>

            <div className="mb-2 pb-3 booking-page-info__bio-box">
              <div
                className="input-field-container"
                id="bioContainer"
              >
                <div className={messageClassNames} id="bioInput">
                  <label htmlFor="bio">Bio</label>
                  <textarea
                    name="bio"
                    id="bio"
                    onChange={e => {
                      if (e.target.value.length <= 625) {
                        setBio(e.target.value);
                        setInfoChanged(true);
                      } else {
                        setBio(e.target.value);
                        setInfoChanged(false);
                      }
                    }}
                    onBlur={() => setBioFocus(false)}
                    onFocus={() => setBioFocus(true)}
                    className="h-input__control"
                    value={bio}
                  />
                </div>
              </div>
            </div>

            <h4 className="booking-page-info__subheader">
              Social Links (Optional)
            </h4>

            <Row>
              <Col sm="6">
                <div className="mb-2 pb-1">
                  <Input
                    name="facebookUsername"
                    label="Facebook username"
                    onChange={(e: any) => { setFacebookUsername(e.target.value); setInfoChanged(true); }}
                    value={facebookUsername}
                  />
                </div>
                <div className="mb-2 pb-1">
                  <Input
                    name="instagramUsername"
                    label="Instagram username"
                    onChange={(e: any) => { setInstagramUsername(e.target.value); setInfoChanged(true); }}
                    value={instagramUsername}
                  />
                </div>
                <div className="booking-page-info__linkedin-input">
                  <Input
                    name="linkedinUsername"
                    label="LinkedIn username"
                    onChange={(e: any) => { setLinkedinUsername(e.target.value); setInfoChanged(true); }}
                    value={linkedinUsername}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div className="mb-2 pb-1">
                  <Input
                    name="stackoverflowUsername"
                    label="Stack Overflow username"
                    onChange={(e: any) => { setStackoverflowUsername(e.target.value); setInfoChanged(true); }}
                    value={stackoverflowUsername}
                  />
                </div>
                <div className="mb-2 pb-1">
                  <Input
                    name="githubUsername"
                    label="GitHub username"
                    onChange={(e: any) => { setGithubUsername(e.target.value); setInfoChanged(true); }}
                    value={githubUsername}
                  />
                </div>
                <div>
                  <Input
                    name="youtubeUsername"
                    label="YouTube username"
                    onChange={(e: any) => { setYoutubeUsername(e.target.value); setInfoChanged(true); }}
                    value={youtubeUsername}
                  />
                </div>
              </Col>
            </Row>
            {/* <Row className="video-box">
              <Col sm="6">
                <div className="video-box__thumbnail">
                  <i className="fas fa-video"></i>
                </div>
              </Col>
              <Col sm="6">
                <div className="video-box__text-box">
                  <div className="video-box__header">
                    Booking Page Video
                  </div>
                  <input className="visually-hidden" type="file" id="videoElem" accept="video/*" 
                    onChange={(e) => { 
                      getBase64(e.target.files[0])
                        .then((response) => setProfileVideo(response));
                      // e.target.files && setProfileVideo(window.URL.createObjectURL(e.target.files[0]));
                      setInfoChanged(true);
                    }}
                  />
                  <label className="video-box__link" htmlFor="videoElem">Upload Video</label>
                </div>
              </Col>
            </Row> */}

          </CardHeader>
          <CardBody>
            <Button
              className="parent-profile__save-button"
              context="primary"
              disabled={!infoChanged}
              onClick={() => handleSave()}
              size="sm"
            >
              Save Changes
            </Button>
          </CardBody>
        </Card>
      }
    </div>
  );
}

