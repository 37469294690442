import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BraintreeCCForm from 'components/Shared/BraintreeCCForm'
import { closeModal } from 'store/modules/UI/actions';
import { AppDispatch } from 'store';

type Props = {
  currentSubscription?: any;
};

const EditBillingModal: React.FC<Props> = ({ currentSubscription }) => {
  const dispatch: AppDispatch = useDispatch();

  return (
    <div className="edit-billing-modal__content">
      <BraintreeCCForm
        // accessToken={accessToken}
        closeModal={closeModal}
        dispatch={dispatch}
        currentSubscriptionId={currentSubscription.id}
      // getToken={getToken}
      // userId={userId}
      // createPaymentMethod={createPaymentMethod}
      // setPaymentMethod={setPaymentMethod}
      />
    </div>
  );
}

export default EditBillingModal;