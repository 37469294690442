import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Button } from '@codeverse/react-helios-ui';
import { createLocalAudioTrack, createLocalTracks, createLocalVideoTrack, LocalVideoTrack } from 'twilio-video';
import { pollAudioLevel } from '../pollAudioLevel';

import AudioMeter from './AudioMeter';
import DeviceSetting from './DeviceSetting';
import { RootState } from 'store/state';

const VIDEO_WIDTH = 200
const VIDEO_HEIGHT = 150
const VIDEO_FRAME_RATE = 8;
const SCREEN_CAPTURE_WIDTH = 1000;
const SCREEN_CAPTURE_FRAME_RATE = 4;

type Props = {
  onAudioOutputDeviceChange: any;
  room: any;
  showSettingsPanel: boolean;
  setShowSettingsPanel: any;
};

const LocalAudioVideo: React.FC<Props> = ({ onAudioOutputDeviceChange, room, showSettingsPanel, setShowSettingsPanel }) => {
  const dispatch = useDispatch();
  const video = useRef<any>({});
  const audio = useRef<any>({});
  const audioTrack = useRef<any>({});
  const videoTrack = useRef<any>({});
  const screenTrack = useRef<any>({});
  const [audioLevel, setAudioLevel] = useState(0);
  const [audioInputDeviceId, setAudioInputDeviceId] = useState('');
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState('');
  const [videoDeviceId, setVideoDeviceId] = useState('');
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    if (Object.keys(video.current).length === 0 && !room && Object.keys(audioTrack.current).length === 0) {
      return;
    }
    const constraints = {
      audio: true,
      video: {
        width: VIDEO_WIDTH,
        height: VIDEO_HEIGHT,
        frameRate: VIDEO_FRAME_RATE,
      }
    }

    // twilios wrapper for getting access to the media streams available by the browser
    createLocalTracks(constraints).then((localTracks) => {
      // extract the video track from the response, and determine whether video is currently enabled
      videoTrack.current = localTracks.find(track => track.kind === 'video') || null
      const isVideoEnabled = videoTrack.current !== null
      if (videoTrack.current) {
        videoTrack.current.attach(video.current)
      }

      // extract the audio track from the response, and determine whether audio is currently enabled
      audioTrack.current = localTracks.find(track => track.kind === 'audio') || null
      if (audioTrack.current) {
        startAudioLevelPolling()
      }

      const isAudioEnabled = audioTrack.current !== null

      // update the state with the default tracks
      setVideoEnabled(isVideoEnabled);
      setAudioEnabled(isAudioEnabled);

      // get the list of devices
      getDevices()
    })
  }, [video.current, audio.current, room]);

  const startAudioLevelPolling = () => {
    pollAudioLevel(audioTrack.current, (level: any) => {
      setAudioLevel(level);
    })
  }

  const getVideoDeviceByLabel = (label: string, devicesList: any) => {
    return devicesList.find((device: any) => device.label === label);
  }

  const getVideoDevices = (devicesList: any) => {
    return devicesList.filter((device: any) => device.kind === 'videoinput')
  }

  const getAudioInputDevices = (devicesList: any) => {
    return devicesList.filter((device: any) => device.kind === 'audioinput')
  }

  const getAudioOutputDevices = (devicesList: any) => {
    return devicesList.filter((device: any) => device.kind === 'audiooutput')
  }

  const getAudioInputDeviceByLabel = (label: string, devicesList: any) => {
    return getAudioInputDevices(devicesList).find((device: any) => device.label === label)
  }

  const getAudioOutputDeviceByLabel = (label: string, devicesList: any) => {
    return getAudioOutputDevices(devicesList).find((device: any) => device.label === label)
  }

  const setAudioOutputDevice = (audioOutputDeviceId: string) => {
    if (audioOutputDeviceId === 'null' || audioOutputDeviceId === null) {
      return
    };
    if (onAudioOutputDeviceChange) {
      sessionStorage.setItem('audioOutputDeviceId', audioOutputDeviceId);
      try {
        onAudioOutputDeviceChange(audioOutputDeviceId);
      } catch (e) {
      }
      setAudioOutputDeviceId(audioOutputDeviceId);
    }
  }

  const setAudioInputDevice = (deviceId: any) => {

    // stop the current track
    if (audioTrack.current) {
      audioTrack.current.stop()
    }

    // if we are currently sending audio, then stop it
    if (audioEnabled && room && room.localParticipant) {
      room.localParticipant.unpublishTrack(audioTrack.current)
    }

    // get this device stream, add it to the state and enable it
    const constraints = {
      deviceId
    }
    createLocalAudioTrack(constraints).then(audioTrackLocal => {
      audioTrack.current = audioTrackLocal
      if (audioTrack.current) {
        startAudioLevelPolling()
      }

      // update the state with the new track and device id
      setAudioInputDeviceId(deviceId);

      // if we were sending audio, then publish this new track
      if (audioEnabled && room && room.localParticipant) {
        room.localParticipant.publishTrack(audioTrack.current, { priority: 'high' });
      }
      sessionStorage.setItem('audioInputDeviceId', deviceId);
    });
  }

  const setVideoDevice = (deviceId: string) => {

    // stop the current track
    if (videoTrack.current) {
      videoTrack.current.stop()
    }

    // if we are currently sending video, then stop it
    if (videoEnabled && room && room.localParticipant) {
      room.localParticipant.unpublishTrack(videoTrack.current)
    }

    // get this device stream, add it to the state and enable it
    const constraints = {
      width: VIDEO_WIDTH,
      height: VIDEO_HEIGHT,
      frameRate: VIDEO_FRAME_RATE,
      deviceId
    }
    createLocalVideoTrack(constraints).then((videoTrack: any) => {
      videoTrack.current = videoTrack
      if (videoTrack.current) {
        videoTrack.current.attach(video.current)
      }

      // update the state with the new track and device id
      setVideoDeviceId(deviceId);

      // if we were sending video, then publish this new track
      if (videoEnabled && room && room.localParticipant) {
        room.localParticipant.publishTrack(videoTrack)
      }
      sessionStorage.setItem('videoDeviceId', deviceId);
    });
  }

  const disableVideoTrack = () => {
    if (videoTrack.current) {
      videoTrack.current.disable();
    }
    if (room && room.localParticipant) {
      room.localParticipant.unpublishTrack(videoTrack.current)
    }
    setVideoEnabled(false);
  }

  const enableVideoTrack = () => {
    if (videoTrack.current) {
      videoTrack.current.enable();
    }
    if (room && room.localParticipant) {
      room.localParticipant.publishTrack(videoTrack.current)
    }
    setVideoEnabled(true);
  }

  const handleMicrophoneToggle = () => {
    if (audioTrack.current) {
      if (audioEnabled) {
        audioTrack.current.disable();
        setAudioEnabled(false);
        if (room && room.localParticipant) {
          room.localParticipant.unpublishTrack(audioTrack.current)
        }
      } else {
        audioTrack.current.enable();
        setAudioEnabled(true);
        if (room && room.localParticipant) {
          room.localParticipant.publishTrack(audioTrack.current)
        }
      }
    }
  }

  const videoDevices = useMemo(() => {
    return getVideoDevices(devices);
  }, [devices])
  const audioInputDevices = useMemo(() => {
    return getAudioInputDevices(devices);
  }, [devices])
  const audioOutputDevices = useMemo(() => {
    return getAudioOutputDevices(devices);
  }, [devices])

  const getDevices = () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      // store the device list
      setDevices(devices);

      // set the current video device id
      const videoDevice = getVideoDeviceByLabel(videoTrack.current.mediaStreamTrack.label, devices);
      const _videoDeviceId = videoDevice && videoDevice.deviceId

      // set the current audio device id
      const audioInputDevice = getAudioInputDeviceByLabel(audioTrack.current.mediaStreamTrack.label, devices)
      const _audioInputDeviceId = audioInputDevice && audioInputDevice.deviceId

      // set the current audio output device id
      const audioOutputDevice = getAudioOutputDeviceByLabel(audioTrack.current.mediaStreamTrack.label, devices);
      const _audioOutputDeviceId = audioOutputDevice && audioOutputDevice.deviceId;

      // set the local video and audio streams
      const savedVideoDevice = sessionStorage.getItem('videoDeviceId');
      const savedAudioInputDeviceId = sessionStorage.getItem('audioInputDeviceId');
      const savedAudioOutputDeviceId = sessionStorage.getItem('audioOutputDeviceId');
      if ((savedAudioOutputDeviceId !== 'undefined' || typeof _audioOutputDeviceId === 'string')) {
        setAudioOutputDevice(savedAudioOutputDeviceId === 'undefined' ? _audioOutputDeviceId : savedAudioOutputDeviceId);
      }
      setVideoDevice(savedVideoDevice || _videoDeviceId)
      setAudioInputDevice(savedAudioInputDeviceId || _audioInputDeviceId);
    });
  }

  return (
    <div className="audio-video-settings-panel" style={{ display: showSettingsPanel ? 'block' : 'none' }}>
      <Card>
        <div className="card-header">
          <div className="close-btn" onClick={() => setShowSettingsPanel(false)} />
          Audio & Video Settings
        </div>

        <CardBody>
          <div className="audio-settings">

            <div className="audio-settings-header">
              Audio Settings
              <AudioMeter audioLevel={audioLevel} audioEnabled={audioEnabled} />
            </div>
            <DeviceSetting label={'Microphone'} devices={audioInputDevices} currentDeviceId={audioInputDeviceId} showToggleActions disabled={!audioEnabled} toggleAction={handleMicrophoneToggle} onChange={(option: any) => setAudioInputDevice(option.value)} />
            <DeviceSetting label={'Speakers'} devices={audioOutputDevices} currentDeviceId={audioOutputDeviceId} showToggleActions disabled={!audioEnabled} toggleAction={handleMicrophoneToggle} onChange={(option: any) => setAudioOutputDevice(option.value)} />
          </div>
          <div className="video-settings">
            <div className="video-settings-header">
              Video Settings
            </div>
            <DeviceSetting label={'Camera'} devices={videoDevices} currentDeviceId={videoDeviceId} disabled={videoEnabled} toggleAction={() => { }} onChange={(option: any) => setVideoDevice(option.value)} />
            <Button className="w-100 mt-3" context="secondary" size="sm" onClick={() => getDevices()}>Refresh Devices</Button>
            <div className="local-video">
              <video
                autoPlay={true}
                muted={true}
                ref={video}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default LocalAudioVideo;
