import React, { useEffect, useState, useMemo, useRef } from "react";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@codeverse/react-helios-ui";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";

import { RootState } from "store/state";
import { openModal } from "store/modules/UI/actions";
import { AppDispatch } from "store";

import { dataClientRequest } from "@codeverse/redux-data-client";
import {
  GET_USER_GUIDE_PREFERENCES,
  GET_OTHER_USER_MODEL_CONFIG,
} from "models/User";
import { VIRTUAL_TRIAL } from "data/occasions";
import determineAvatarImage from "utils/determineAvatarImage";

const activeStatuses = ["active", "new", "trial", "pending"];

type Props = {
  history?: any;
  location?: any;
  match?: any;
  booking: any;
  bookingInstance?: any;
  setCurrentBooking: any;
  setCurrentBookingInstance?: any;
  subscriptions: any;
  subscriptionAddOns: any;
};

const capitalizeFirstLetter = (string: any) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const SessionCard: React.FC<Props> = ({
  booking,
  bookingInstance,
  setCurrentBooking,
  setCurrentBookingInstance,
  subscriptions,
  subscriptionAddOns,
}) => {
  const [profileImageStyle, setProfileImageStyle] = useState(null);
  const dispatch: AppDispatch = useDispatch();
  const timezone = useSelector(
    (state: RootState) => state.user.currentUser.time_zone
  );
  const priorityMembership = useSelector(
    (state: RootState) => state.user.priorityMembership
  );
  const weekdayName = capitalizeFirstLetter(booking.weekday_name);
  const bookingDate = moment
    .tz(
      `${bookingInstance.start_date} ${bookingInstance.start_time}`,
      "YYYY-MM-DD HH:mm",
      "America/Chicago"
    )
    .tz(timezone)
    .format("M/DD");

  const timeZone = moment.tz(timezone).format("z");

  const userName = booking.name.split(" - ")[0];
  const sessionName = booking.name.split(" - ")[1].split("(")[0];

  const startTime = moment
    .tz(bookingInstance.start_time, "HH:mm", "America/Chicago")
    .tz(timezone)
    .format("h:mm A");
  const endTime = moment
    .tz(bookingInstance.end_time, "HH:mm", "America/Chicago")
    .tz(timezone)
    .format("h:mm A");
  const [isSameDay, setIsSameDay] = useState(
    moment().isSame(moment(bookingInstance.start_date), "day")
  );

  let guidePhotoStyle = {};

  if (
    (bookingInstance.meta.completed_meetings_with_guide_count === 0 ||
      !bookingInstance.guide) &&
    !(bookingInstance.occasion_id === VIRTUAL_TRIAL)
  ) {
    guidePhotoStyle = {
      backgroundImage: "url(/icons/new-guide-icon.png)",
    };
  } else if (
    bookingInstance.meta.guide_photo_2x === "/photos/default_2x/missing.png"
  ) {
    guidePhotoStyle = {
      backgroundImage: "url(/icons/new-guide-icon.png)",
    };
  } else if (
    bookingInstance.occasion_id === VIRTUAL_TRIAL &&
    bookingInstance.meta.guide_photo_2x
  ) {
    guidePhotoStyle = {
      backgroundImage: `url(${bookingInstance.meta.guide_photo_2x})`,
    };
  } else if (bookingInstance.meta.guide_photo_2x) {
    guidePhotoStyle = {
      backgroundImage: `url(${bookingInstance.meta.guide_photo_2x})`,
    };
  } else {
    guidePhotoStyle = {
      backgroundImage: "url(/icons/new-guide-icon.png)",
    };
  }

  useEffect(() => {
    if (booking) {
      if (booking.user) {
        determineAvatarImage(booking.user, setProfileImageStyle);
      }
    }
  }, [booking]);
  const [width, setWidth] = useState(window.innerWidth);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, []);

  useEffect(() => {
    if (width) {
      if (width < 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [width]);

  const kidSignIn = () => {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (!isChrome && !isSafari && !iOS) {
      toast.error("Please use Chrome version 83 or higher", {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: "A",
      });
    } else if (!isMobile) {
      const accessToken = localStorage.getItem("accessToken");
      const currentOrganizationId = localStorage.getItem(
        "currentOrganizationId"
      );
      const activeSubscription = subscriptions.find(
        (sub: any) =>
          sub.start_at &&
          moment(sub.start_at).isSameOrBefore(moment()) &&
          !sub.cancel_at &&
          activeStatuses.includes(sub.status)
      );
      const start_at = activeSubscription.start_at;
      const add_on = subscriptionAddOns[0]
        ? subscriptionAddOns[0].product
        : null;
      window.location.assign(
        `${process.env.REACT_APP_ESCAPE_POD_URL}/home?access_token=${accessToken}&current_user_id=${booking.user.id}&current_organization_id=${currentOrganizationId}`
      );
    }
  };

  return (
    <div className="card session-card parent-session-card">
      <div className="session-card__guide-info-box">
        <div style={profileImageStyle} className="session-card__avatar" />
        <div className="session-card__info-container">
          <div className="session-card__session-info">
            {weekdayName}, {bookingDate} <br />
            {startTime}&nbsp;-&nbsp;{endTime}&nbsp;{timeZone}
          </div>
          <div className="session-card__session-name">
            {userName}'s&nbsp;{sessionName}
          </div>
        </div>
      </div>
      <div className="session-card__preferred-guide-container">
        <div className="session-card__preferred-guide">
          <div
            style={guidePhotoStyle}
            className="session-card__preferred-guide--picture"
          />
          <div className="session-card__preferred-guide--info-container">
            <div className="session-card__preferred-guide--title">GUIDE</div>
            {(bookingInstance.meta.completed_meetings_with_guide_count === 0 ||
              !bookingInstance.guide) &&
              !(bookingInstance.occasion_id === VIRTUAL_TRIAL) && (
                <div className="session-card__preferred-guide--name">
                  New Guide
                </div>
              )}
            {bookingInstance.guide &&
              (bookingInstance.meta.completed_meetings_with_guide_count > 0 ||
                bookingInstance.occasion_id === VIRTUAL_TRIAL) && (
                <div className="session-card__preferred-guide--name">
                  {bookingInstance.guide.split(" ")[0]}&nbsp;
                  {bookingInstance.guide
                    .split(" ")
                    [bookingInstance.guide.split(" ").length - 1].charAt(0)}
                  .
                </div>
              )}
            {moment
              .tz(bookingInstance.start_date, "America/Chicago")
              .isBefore(moment.tz("America/Chicago").add(14, "days")) && (
              <div className="session-card__preferred-guide--status">
                Confirmed
              </div>
            )}
          </div>
          <div className="session-card__button">
            {(!isSameDay || booking.occasion.id === VIRTUAL_TRIAL) && (
              <Button
                size="sm"
                context="secondary"
                onClick={() => {
                  dispatch(openModal("rescheduleModal"));
                  setCurrentBooking(booking);
                  setCurrentBookingInstance(bookingInstance);
                }}
              >
                Reschedule
              </Button>
            )}
            {!isSameDay && booking.occasion.id !== VIRTUAL_TRIAL && (
              <>
                <div
                  className="session-card__skip-btn"
                  onClick={() => {
                    dispatch(openModal("cancelModal"));
                    setCurrentBooking(booking);
                    setCurrentBookingInstance(bookingInstance);
                  }}
                >
                  Skip
                </div>
              </>
            )}
            {isSameDay && (
              <>
                <Button
                  size="sm"
                  className="white"
                  context="secondary"
                  style={{ color: "white !important" }}
                  onClick={() => kidSignIn()}
                >
                  Sign In
                </Button>
                {booking.occasion.id !== VIRTUAL_TRIAL && (
                  <>
                    <div
                      className="session-card__skip-btn"
                      onClick={() => {
                        dispatch(openModal("cancelModal"));
                        setCurrentBooking(booking);
                        setCurrentBookingInstance(bookingInstance);
                      }}
                    >
                      Cancel
                      <div
                        data-tip
                        data-for="infoIcon"
                        className="info-icon"
                        data-event="mouseenter"
                        style={{ marginLeft: "4px" }}
                      >
                        i
                      </div>
                      <ReactTooltip
                        id="infoIcon"
                        aria-haspopup="true"
                        effect="solid"
                        globalEventOff="click"
                        clickable
                      >
                        This session is outside of the rescheduling window,{" "}
                        <br />
                        but you can still cancel the session to let us <br />
                        (and your guide) know you that can't make it. <br />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.codeverse.com/faq"
                        >
                          Learn more.
                        </a>
                      </ReactTooltip>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionCard;
