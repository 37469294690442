import React, { Component } from 'react';
import { Braintree, HostedField } from 'react-braintree-fields';
import classNames from 'classnames';

import { closeModal } from 'store/modules/UI/actions';
import { dataClientRequest } from 'store/data-client';
import { getPaymentMethodSuccess } from 'store/modules/Organizations/actions';
import { AppDispatch } from 'store';


import { GET_BRAINTREE_TOKEN_CONFIG, CREATE_PAYMENT_METHOD_CONFIG, UPDATE_SUBSCRIPTION_WITH_PAYMENT_METHOD } from 'models/User';

type braintreeFormProps = {

}

type braintreeFormState = {

}

export default class BraintreeCCForm extends Component<any, any> {
  private getToken: any;
  constructor(props: any) {
    super(props);
    this.state = {
      authorization: '',
      isBraintreeReady: false,
      creditCardFocus: false,
      postalCodeFocus: false,
      cvvFocus: false,
      expirationDateFocus: false,
      creditCardValue: false,
      postalCodeValue: false,
      cvvValue: false,
      expirationDateValue: false,
      isError: false,
    };
    this.getToken = React.createRef();
  }

  componentDidMount() {
    this.props.dispatch(dataClientRequest({
      ...GET_BRAINTREE_TOKEN_CONFIG,
      headers: {
        Accept: 'application/json',
      },
    }))
      .then((data: any) => {
        this.setState({
          authorization: data.response.data,
          isBraintreeReady: true,
        });
      })
  }

  handleError = () => {

  };

  handleSubmit = () => {
    this.getToken()
      .then((response: any) => {
        this.props.dispatch(dataClientRequest({
          ...CREATE_PAYMENT_METHOD_CONFIG,
          data: {
            payment_method_nonce: response.nonce,
          },
        }))
          .then((response2: any) => {
            this.props.dispatch(getPaymentMethodSuccess(response2.response.data.data));
            this.props.dispatch(dataClientRequest({
              ...UPDATE_SUBSCRIPTION_WITH_PAYMENT_METHOD,
              data: {
                id: this.props.currentSubscriptionId,
                relationships: {
                  payment_method: { type: 'payment_methods', id: response2.response.data.data.id },
                },
              },
            }))
              .then(() => {
                this.props.dispatch(closeModal('editBilling'));
              })
              .catch(() => {
                this.setState({
                  isError: true,
                });
              })
          })
          .catch(() => {
            this.setState({
              isError: true,
            });
          })
      })
      .catch(() => {
        this.setState({
          isError: true,
        });
      });
    // this.getToken()
    //   .then((payload1) => {
    //     createPaymentMethod(userId, payload1.nonce, accessToken)
    //       .then((payload2) => {
    //         setPaymentMethod(userId, payload2.payload.data, currentSubscription.id, accessToken)
    //           .then(() => {
    //             window.location.reload();
    //           }).catch((error: any) => {
    //             this.setState({
    //               isError: true,
    //             });
    //           });
    //       }).catch((error: any) => {
    //         this.setState({
    //           isError: true,
    //         });
    //       });
    //   }).catch((error: any) => {
    //     this.setState({
    //       isError: true,
    //     });
    //   });
  }

  handleClose = (e: any) => {
    e.preventDefault();
    const { closeModal } = this.props;
    this.props.dispatch(closeModal('editBilling'));
  };

  handleFocus = (e: any, fieldName: string) => {
    if (e.isEmpty) {
      this.setState({
        [`${fieldName}Value`]: false,
        [`${fieldName}Focus`]: true,
      });
    } else {
      this.setState({
        [`${fieldName}Value`]: true,
        [`${fieldName}Focus`]: true,
      });
    }
  }

  handleBlur = (e: any, fieldName: string) => {
    if (e.isEmpty) {
      this.setState({
        [`${fieldName}Value`]: false,
        [`${fieldName}Focus`]: false,
      });
    } else {
      this.setState({
        [`${fieldName}Value`]: true,
        [`${fieldName}Focus`]: false,
      });
    }
  }

  render() {
    const creditCardClassnames = classNames('hosted-field',
      {
        'has-value': this.state.creditCardValue,
        'has-focus': this.state.creditCardFocus,

      },
    );
    const expirationDateClassnames = classNames('hosted-field',
      {
        'has-value': this.state.expirationDateValue,
        'has-focus': this.state.expirationDateFocus,
      },
    );
    const cvvClassnames = classNames('hosted-field',
      {
        'has-value': this.state.cvvValue,
        'has-focus': this.state.cvvFocus,
      },
    );
    const postalCodeClassnames = classNames('hosted-field',
      {
        'has-value': this.state.postalCodeValue,
        'has-focus': this.state.postalCodeFocus,
      },
    );

    const componentThis = this;

    return (
      <Braintree
        className={this.state.isBraintreeReady ? '' : 'disabled'}
        authorization={this.state.authorization}
        // onAuthorizationSuccess={this.onAuthorizationSuccess}
        // onDataCollectorInstanceReady={this.onDataCollectorInstanceReady}
        onError={this.handleError}
        // onCardTypeChange={this.onCardTypeChange}
        getTokenRef={(ref: any) => (this.getToken = ref)}
        styles={{
          input: {
            'background-color': '#eef6fd',
            height: '56px',
            'font-size': '18px',
            'line-height': '1',
            padding: '15px 24px 0 24px',
            color: '#474c72',
            transition: 'all 200ms linear',
            display: 'block',
            width: '100%',
            border: '0',
            'border-radius': '16px',
            'z-index': '1',
          },
          'input.number': {
            'font-family': 'monospace',
          },
          '.valid': {
            color: '#00d397',
          },
          '.invalid': {
            color: '#fe5353',
          },
        }}
      >
        <div className="fields form-group mb-0">
          <div className="row pb-3 mx-0">
            <div className="col-sm-12 px-0 responsive-form-no-pb">
              <div className={creditCardClassnames}>
                <label>Credit Card Number</label>
                <HostedField
                  className="hosted-field__control"
                  type="number"
                  onFocus={(e: any) => componentThis.handleFocus(e, 'creditCard')}
                  onBlur={(e: any) => componentThis.handleBlur(e, 'creditCard')}
                />
              </div>
            </div>
          </div>

          <div className="row pb-4 mx-0">

            <div className="col-sm-4 pl-0 responsive-form">
              <div className={expirationDateClassnames}>
                <label>Exp Date</label>
                <HostedField
                  className="hosted-field__control"
                  type="expirationDate"
                  onFocus={(e: any) => componentThis.handleFocus(e, 'expirationDate')}
                  onBlur={(e: any) => componentThis.handleBlur(e, 'expirationDate')}
                />
              </div>
            </div>
            <div className="col-sm-4 px-1 responsive-form">
              <div className={cvvClassnames}>
                <label>CVV</label>
                <HostedField
                  className="hosted-field__control"
                  type="cvv"
                  onFocus={(e: any) => componentThis.handleFocus(e, 'cvv')}
                  onBlur={(e: any) => componentThis.handleBlur(e, 'cvv')}
                />
              </div>
            </div>
            <div className="col-sm-4 pr-0 responsive-form-no-pb">
              <div className={postalCodeClassnames}>
                <label>Zip Code</label>
                <HostedField
                  className="hosted-field__control"
                  type="postalCode"
                  onFocus={(e: any) => componentThis.handleFocus(e, 'postalCode')}
                  onBlur={(e: any) => componentThis.handleBlur(e, 'postalCode')}
                />
              </div>
            </div>
          </div>

          <div className="row flex-column-reverse flex-sm-row mx-0">
            <div className="col-sm-6 pl-0 pr-2 responsive-form">
              <button className="btn btn-secondary w-100" onClick={this.handleClose}><span>Cancel</span></button>
            </div>
            <div className="col-sm-6 pr-0 pl-2 responsive-form">
              <button className="btn btn-primary w-100" onClick={this.handleSubmit}><span>Update</span></button>
            </div>
          </div>

          {
            this.state.isError
              ? <div>
                <small style={{ color: '#e83e8c' }} role="alert">Failed to update your payment method. Try a new method or contact us at support@codeverse.com</small>
              </div>
              : null
          }

        </div>
      </Braintree>
    );
  }
}

