import React, { useEffect, useState, useMemo, useRef } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col } from "@codeverse/react-helios-ui";
import Modal from "components/Shared/NewModal";
import { closeModal, openModal } from "store/modules/UI/actions";
import transformHourInteger from "utils/transformHourInteger";
import { dataClientRequest } from "@codeverse/redux-data-client";
import { AppDispatch } from "store";
import { RootState } from "store/state";
import determineAvatarImage from "utils/determineAvatarImage";

type Props = {
  history?: any;
  location?: any;
  match?: any;
  setCurrentStep: any;
  handleSetChild: any;
};

type ChildUserProps = {
  user: any;
  handleSetChild?: any;
  showJoin?: boolean;
};

export const ChildUser: React.FC<ChildUserProps> = ({
  user,
  handleSetChild,
  showJoin,
}) => {
  const [profileImageStyle, setProfileImageStyle] = useState(null);

  useEffect(() => {
    determineAvatarImage(user, setProfileImageStyle);
  }, [user]);

  return (
    <div
      className="child-user"
      onClick={() => {
        if (typeof handleSetChild === "function") {
          handleSetChild(user.id);
        }
      }}
    >
      <div style={profileImageStyle} className="child-user__avatar" />
      <div className="child-user__name">
        {user.name && user.name.split(" ")[0]}
      </div>

      {showJoin && (
        <Button
          className="join-button"
          size="sm"
          context="tertiary"
          onClick={() => {
            if (typeof handleSetChild === "function") {
              handleSetChild(user.id);
            }
          }}
        >
          Join
        </Button>
      )}
    </div>
  );
};

const Step1: React.FC<Props> = ({ handleSetChild, setCurrentStep }) => {
  const timezone = useSelector(
    (state: RootState) => state.user.currentUser.time_zone
  );
  const dispatch: AppDispatch = useDispatch();
  const memberships = useSelector(
    (state: RootState) => state.organizations.memberships
  );
  const [childUsers, setChildUsers] = useState([]);
  const users = useSelector((state: RootState) => state.organizations.users);

  useEffect(() => {
    const tempChildUsers: any = [];
    memberships.map((membership: any) => {
      if (membership.role === "studentship") {
        const currentUser = users.find((user: any) => {
          return user.id === membership.user.id;
        });
        if (
          currentUser &&
          currentUser.scope === "child" &&
          !currentUser.meta.deleted_at
        ) {
          tempChildUsers.push(currentUser);
        }
      }
    });
    setChildUsers(tempChildUsers);
    if (tempChildUsers.length === 1) {
      handleSetChild(tempChildUsers[0].id);
      setCurrentStep(2);
    }
  }, [memberships]);

  return (
    <>
      <div className="child-select">
        {childUsers.map((childUser) => {
          return <ChildUser handleSetChild={handleSetChild} user={childUser} />;
        })}
      </div>
    </>
  );
};

export default Step1;
