import { combineReducers } from 'redux';


import CheckoutReducer from './modules/Checkout/reducer';
import { SessionReducer } from './modules/Session/reducer';
import { UserReducer } from './modules/User/reducer';
import { OrganizationsReducer } from './modules/Organizations/reducer';
import { UIReducer } from './modules/UI/reducer';
import { GuideReducer } from './modules/Guide/reducer';

import { RootState } from './state';

export type ReducerKey = 'session' | 'user' | 'organizations';

export default combineReducers<RootState>({
  checkout: CheckoutReducer,
  session: SessionReducer,
  user: UserReducer,
  organizations: OrganizationsReducer,
  guide: GuideReducer,
  ui: UIReducer,
});
