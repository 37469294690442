import React from 'react';
import Decorations from './Decorations';

type Props = {
  children: React.ReactNode;
};

export const SignIn: React.FC<Props> = ({ children }) => {

  return (
    <div className="org-login h-100 w-100">
      <div className="org-login__container">
        <div className="org-login__panel-left">
          {children}
        </div>
        <Decorations />
      </div>
    </div>
  );
};

export default SignIn;
