import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col } from '@codeverse/react-helios-ui';
import { toast } from 'react-toastify';

import { dataClientRequest } from '@codeverse/redux-data-client';
import moment from 'moment-timezone';
import { UPDATE_MEETING } from 'models/Meeting';

import { AppDispatch } from 'store';
import { RootState } from 'store/state';
import { Meeting } from 'store/modules/User/types';

type Props = {
  history: any;
  meeting: Meeting;
  past?: any;
  userParticipation?: any;
};

const SessionCard: React.FC<Props> = ({
  history,
  meeting,
  past,
  userParticipation,
}) => {
  const timezone = useSelector((state: RootState) => state.user.currentUser.time_zone);
  const dispatch: AppDispatch = useDispatch();

  const [profileImageStyle, setProfileImageStyle] = useState({ backgroundImage: 'url(/img/dog.jpg' });

  const weekdayName = moment.tz(meeting.start_at, 'America/Chicago').tz(timezone).format('dddd');
  const bookingDate = moment.tz(meeting.start_at, 'America/Chicago').tz(timezone).format('M/DD');

  const timeZone = moment.tz(timezone).format('z');

  const startTime = moment.tz(meeting.start_at, 'America/Chicago').tz(timezone).format('h:mm A');
  const endTime = moment.tz(meeting.end_at, 'America/Chicago').tz(timezone).format('h:mm A');

  const meetingEndedWithin12Hours = (past || false) && moment.tz(timezone).isBefore(moment.tz(meeting.end_at, 'America/Chicago').add(12, 'hours'));

  useEffect(() => {
    if (userParticipation) {
      switch (userParticipation.avatar) {
        case 'a7a62b5d-f2a3-4c76-ae6e-7b8bd316b50e':
          setProfileImageStyle({ backgroundImage: 'url(/img/alien.jpg)' });
          break;
        case '430d3a38-b1ee-44d1-b11c-107a11860671':
          setProfileImageStyle({ backgroundImage: 'url(/img/cat.jpg)' });
          break;
        case '20edd67a-2ad2-4d23-b0de-e114fe39d8d8':
          setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
          break;
        case 'f9517d14-b581-4fab-b3a1-a2885f8088c7':
          setProfileImageStyle({ backgroundImage: 'url(/img/player.jpg)' });
          break;
        case '15143073-1633-4d98-b8b8-39f0f2f1d888':
          setProfileImageStyle({ backgroundImage: 'url(/img/rabbit.jpg)' });
          break;
        case '1fbff479-dec5-4f7c-9aba-60e7d70f6990':
          setProfileImageStyle({ backgroundImage: 'url(/img/spaceman.jpg)' });
          break;
        case '676e15c7-28c6-41a3-abcb-c70b21c14e3f':
          setProfileImageStyle({ backgroundImage: 'url(/img/octopus.jpg)' });
          break;
        case 'da3c7d84-8cbc-483e-8074-6784f3f7e416':
          setProfileImageStyle({ backgroundImage: 'url(/img/turtle.jpg)' });
          break;
        default:
          setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
          break;
      }
    } else if (meeting) {
      if (meeting.meta.child_participations.length > 0) {
        const userParticipation = meeting.meta.child_participations.filter((child_participation: any) => !child_participation.abandoned_at)[0];
        if (userParticipation) {
          switch (userParticipation.avatar) {
            case 'a7a62b5d-f2a3-4c76-ae6e-7b8bd316b50e':
              setProfileImageStyle({ backgroundImage: 'url(/img/alien.jpg)' });
              break;
            case '430d3a38-b1ee-44d1-b11c-107a11860671':
              setProfileImageStyle({ backgroundImage: 'url(/img/cat.jpg)' });
              break;
            case '20edd67a-2ad2-4d23-b0de-e114fe39d8d8':
              setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
              break;
            case 'f9517d14-b581-4fab-b3a1-a2885f8088c7':
              setProfileImageStyle({ backgroundImage: 'url(/img/player.jpg)' });
              break;
            case '15143073-1633-4d98-b8b8-39f0f2f1d888':
              setProfileImageStyle({ backgroundImage: 'url(/img/rabbit.jpg)' });
              break;
            case '1fbff479-dec5-4f7c-9aba-60e7d70f6990':
              setProfileImageStyle({ backgroundImage: 'url(/img/spaceman.jpg)' });
              break;
            case '676e15c7-28c6-41a3-abcb-c70b21c14e3f':
              setProfileImageStyle({ backgroundImage: 'url(/img/octopus.jpg)' });
              break;
            case 'da3c7d84-8cbc-483e-8074-6784f3f7e416':
              setProfileImageStyle({ backgroundImage: 'url(/img/turtle.jpg)' });
              break;
            default:
              setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
              break;
          }
        } else {
          setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
        }
      } else {
        setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
      }
    } else {
      setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
    }
  }, [meeting]);

  const surveyRedirect = () => {
    localStorage.setItem('currentMeetingId', meeting.id);
    history.push('/guide/survey');
  };

  const profileRedirect = (user: any) => {
    window.open(`https://houston.codeverse.com/#/organizations/${user.organization_id}/show`, '_blank');
  };

  const handleJoin = () => {
    if (meeting && meeting.status === 'new') {
      localStorage.setItem('currentMeetingId', meeting.id);
      dispatch(dataClientRequest({
        ...UPDATE_MEETING,
        data: {
          id: meeting.id,
          status: 'opened',
        },
      }))
        .then(() => {
          history.push(`/guide/meeting/${meeting.id}`);
        })
        .catch((error: any) => {
          // toast.error(error, {
          //   position: toast.POSITION.BOTTOM_CENTER,
          // });
        });
    } else {
      localStorage.setItem('currentMeetingId', meeting.id);
      history.push(`/guide/meeting/${meeting.id}`);
    }
  };

  return (
    <>
      {
        meeting.meta.child_participations.map((user: any) => {
          return (
            <Col key={user.id} xs={12} lg={12}>
              <Card className="session-card">
                <div className="session-card__guide-info-box">
                  <div style={profileImageStyle} className="session-card__avatar" />
                  <div className="session-card__info-container">
                    <div className="session-card__session-info">
                      {weekdayName}, {bookingDate} <br />
                      {startTime}&nbsp;-&nbsp;{endTime}&nbsp;{timeZone}
                    </div>
                    <div className="session-card__session-name">
                      {user.abandoned_at ? <s>{user.user_name}'s&nbsp;Virtual Class</s> : <>{user.user_name}'s&nbsp;Virtual Class</>}
                    </div>
                  </div>
                </div>
                <div className="session-card__two-buttons">
                  {
                    !past &&
                    <>
                      {!user.abandoned_at && <>
                        {(!((meeting.starting_in_seconds < 60 * 10 && meeting.starting_in_seconds > 60 * -15) || meeting.status === 'started' || meeting.status === 'opened') && meeting.status !== 'ended')
                          ? <div className="pr-2"><Button size="sm" onClick={handleJoin} disabled context="primary">Join</Button></div>
                          : <div className="pr-2"><Button size="sm" onClick={handleJoin} disabled={user.abandoned_at} context={user.abandoned_at ? 'primary' : 'tertiary'}>Join</Button></div>}
                      </>
                      }
                      <Button size="sm" context="secondary" onClick={() => profileRedirect(user)}>
                        Profile
                      </Button>
                    </>
                  }
                  {
                    past &&
                    <>
                      <div className="pr-2">
                        {
                          meeting.sentiment &&
                          <Button
                            className="session-card__titan-button"
                            size="sm"
                            type="button"
                            context="status-success"
                            withoutSpan={
                              <>
                                <span>Survey</span>
                                <i className="fas fa-check-circle pl-2" />
                              </>
                            }
                          />
                        }
                        {
                          !meeting.sentiment &&
                          <Button
                            className="session-card__primary-button"
                            size="sm"
                            type="button"
                            context="tertiary"
                            disabled={!meetingEndedWithin12Hours}
                            onClick={() => {
                              if (meetingEndedWithin12Hours) {
                                surveyRedirect();
                              } else {

                              }
                            }}
                            withoutSpan={
                              <>
                                <span>Survey</span>
                                <i className="fas fa-times-circle pl-2" />
                              </>
                            }
                          />
                        }
                      </div>
                      <Button size="sm" context="secondary" onClick={() => profileRedirect(user)}>
                        Profile
                      </Button>
                    </>
                  }
                </div>
              </Card>
            </Col>
          );
        })
      }
    </>
  );
};

export default SessionCard;
