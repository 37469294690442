import { UserAction } from './types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { TIMEZONE } from 'store/modules/User/types';
import { AnyAction } from 'redux';
import api from './api';

export const resetUserBookingInstances = (): UserAction => {
  return { type: 'RESET_USER_BOOKING_INSTANCES' }
}

export const setCurrentSubscriptionId = (id: string): UserAction => {
  return { type: 'SET_CURRENT_SUBSCRIPTION_ID', id }
}

export const setPriorityMembership = (membership: any): UserAction => {
  return { type: 'SET_PRIORITY_MEMBERSHIP', membership }
}

export const getUserAddressesRequest = (): UserAction => {
  return { type: 'GET_USER_ADDRESSES_REQUEST' }
}
export const getUserAddressesSuccess = (payload: UserAction): UserAction => {
  return { type: 'GET_USER_ADDRESSES_SUCCESS', payload }
}
export const getUserAddressesFailure = (error: string): UserAction => {
  return { type: 'GET_USER_ADDRESSES_FAILURE', error }
}

export const getUserMembershipsRequest = (): UserAction => {
  return { type: 'GET_USER_MEMBERSHIPS_REQUEST' }
}

export const getUserMembershipsSuccess = (payload: UserAction): UserAction => {
  return { type: 'GET_USER_MEMBERSHIPS_SUCCESS', payload }
}

export const getUserMembershipsFailure = (error: string): UserAction => {
  return { type: 'GET_USER_MEMBERSHIPS_FAILURE', error }
}

export const getUserPhoneNumbersRequest = (): UserAction => {
  return { type: 'GET_USER_PHONE_NUMBERS_REQUEST' }
}

export const getUserPhoneNumbersSuccess = (payload: UserAction): UserAction => {
  return { type: 'GET_USER_PHONE_NUMBERS_SUCCESS', payload }
}

export const getUserPhoneNumbersFailure = (error: string): UserAction => {
  return { type: 'GET_USER_PHONE_NUMBERS_FAILURE', error }
}
export const setTimezone = (timezone: TIMEZONE): UserAction => {
  return { type: 'SET_TIMEZONE', timezone }
}

export const addChild = (child: any) => {
  return { type: 'ADD_CHILD_TO_CHILD_USERS', child }
}

export const getParentData = (accessToken: string, currentUserId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(getUserMembershipsRequest());
    api.getUserMemberships(accessToken, currentUserId)
      .then((response: any) => {
        dispatch(getUserMembershipsSuccess(response.data.data));
      })
      .catch((e: string) => {
        dispatch(getUserMembershipsFailure(e));
      });
    dispatch(getUserAddressesRequest());
    api.getUserAddresses(accessToken, currentUserId)
      .then((response: any) => {
        dispatch(getUserAddressesSuccess(response.data.data));
      })
      .catch((e: string) => {
        dispatch(getUserAddressesFailure(e));
      })
    dispatch(getUserPhoneNumbersRequest());
    api.getUserPhoneNumbers(accessToken, currentUserId)
      .then((response: any) => {
        dispatch(getUserPhoneNumbersSuccess(response.data.data));
      })
      .catch((e: string) => {
        dispatch(getUserPhoneNumbersFailure(e));
      })
  }
}

export const getGuideData = (accessToken: string, currentUserId: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(getUserMembershipsRequest());
    api.getUserMemberships(accessToken, currentUserId)
      .then((response: any) => {
        dispatch(getUserMembershipsSuccess(response.data.data));
      })
      .catch((e: string) => {
        dispatch(getUserMembershipsFailure(e));
      });
    dispatch(getUserAddressesRequest());
    api.getUserAddresses(accessToken, currentUserId)
      .then((response: any) => {
        dispatch(getUserAddressesSuccess(response.data.data));
      })
      .catch((e: string) => {
        dispatch(getUserAddressesFailure(e));
      })
    dispatch(getUserPhoneNumbersRequest());
    api.getUserPhoneNumbers(accessToken, currentUserId)
      .then((response: any) => {
        dispatch(getUserPhoneNumbersSuccess(response.data.data));
      })
      .catch((e: string) => {
        dispatch(getUserPhoneNumbersFailure(e));
      })
  }
}