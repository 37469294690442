import React, { useEffect, useState, useRef, useMemo } from 'react';
import ClassNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Row, Col, Select, Button } from '@codeverse/react-helios-ui';
import moment from 'moment-timezone';

import {
  VIRTUAL_TRIAL,
  VIRTUAL_CLASS,
  VIRTUAL_CAMP,
  INFLUENCER_CLASS,
} from 'data/occasions';

import {
  GET_GUIDE_AVAILABILITY,
  GET_USERS_PARTICIPATIONS,
  GET_GUIDE_SCHEDULABLES,
} from 'models/User';
import { GET_GUIDE_AVAILABILITY_INSTANCES } from 'models/GuideAvailability';
import { dataClientRequest } from '@codeverse/redux-data-client';

import { AppDispatch } from 'store';
import { RootState } from 'store/state';

import Calendar from 'components/Guide/Schedule/Calendar';

const options = [
  { value: VIRTUAL_CLASS, label: 'Virtual Class' },
  { value: VIRTUAL_TRIAL, label: 'Virtual Trial' },
  { value: VIRTUAL_CAMP, label: 'Virtual Camp' },
  { value: INFLUENCER_CLASS, label: 'Influencer Class' },
];

type Props = {
  history?: any;
  location?: any;
};

export const GuideSchedule: React.FC<Props> = ({ history, location }) => {
  const dispatch: AppDispatch = useDispatch();

  const guideSchedulables = useSelector(
    (state: RootState) => state.user.guide_schedulables,
  );
  const [dataFetched, setDataFetched] = useState(false);
  const [eventsBuilt, setEventsBuilt] = useState(false);
  const [occasion, setOccasion] = useState(null);
  const [lockCalendar, setLockCalendar] = useState(true);

  useEffect(() => {
    if (!dataFetched) {
      Promise.all([
        dispatch(
          dataClientRequest({
            ...GET_GUIDE_AVAILABILITY,
            data: {
              id: localStorage.getItem('currentUserId'),
            },
          }),
        ),
        dispatch(
          dataClientRequest({
            ...GET_USERS_PARTICIPATIONS,
            data: {
              id: localStorage.getItem('currentUserId'),
            },
          }),
        ),
        dispatch(
          dataClientRequest({
            ...GET_GUIDE_SCHEDULABLES,
            data: {
              id: localStorage.getItem('currentUserId'),
            },
          }),
        ),
        dispatch(
          dataClientRequest({
            ...GET_GUIDE_AVAILABILITY_INSTANCES,
            query: {
              filter: {
                user_id: localStorage.getItem('currentUserId'),
                after: moment().subtract(1, 'days').format('YYYY-MM-DD'),
              },
              page: 1,
              per_page: 1000,
            },
          }),
        ),
      ]).then(() => {
        setDataFetched(true);
      });
    }
  }, []);

  useEffect(() => {
    if (guideSchedulables.length > 0) {
      guideSchedulables.map((guideSchedulable: any) => {
        const currentOption = options.find(
          (option: any) => option.value === guideSchedulable.occasion.id,
        );
        if (currentOption) {
          setOccasion(guideSchedulable.occasion.id);
        }
      });
    }
  }, [guideSchedulables]);

  const alertClassNames = ClassNames('', {
    'd-none': guideSchedulables.length > 0,
  });

  return (
    <>
      <Alert className="schedule-alert d-block d-md-none" context="danger">
        Hop on a desktop to schedule your availability.
      </Alert>
      <div className="schedule mt-5 d-none d-md-block">
        <Row className="schedule-header">
          <Col lg={6} className="schedule-header__title">
            <h2>My Schedule</h2>
          </Col>
          <Col lg={6} className="occasion-dropdown">
            <div className="d-flex flex-row">
              {lockCalendar ? (
                <Button
                  onClick={() => setLockCalendar(false)}
                  className="ml-auto mr-4"
                  context="tertiary"
                >
                  Unlock Calendar
                </Button>
              ) : (
                <Button
                  onClick={() => setLockCalendar(true)}
                  className="ml-auto mr-4"
                  context="primary "
                >
                  Save Calendar
                </Button>
              )}
              <Select
                className="ml-auto"
                placeholder=""
                label="Program Type"
                options={options.filter((option: any) => {
                  let currentOption = null;
                  guideSchedulables.map((guideSchedulable: any) => {
                    if (guideSchedulable.occasion.id === option.value) {
                      currentOption = option;
                    }
                  });
                  return currentOption;
                })}
                onChange={(option: any) => {
                  setOccasion(option.value);
                }}
                value={options.find(option => option.value === occasion)}
              />
            </div>
          </Col>
        </Row>
        <Calendar
          dataFetched={dataFetched}
          occasion={occasion}
          lockCalendar={lockCalendar}
          setEventsBuilt={setEventsBuilt}
          guideSchedulables={guideSchedulables}
        />
        <Alert className={alertClassNames} context="danger">
          You must have a program certification to add to your schedule. Please
          contact a Codeverse admin for help.
        </Alert>
      </div>
    </>
  );
};
