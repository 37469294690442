// Move to helios once `Invalid hooks` bug is fixed

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import classNames from 'classnames';

type Props = {
  className?: string;
  closeRedirect?: any;
  handleClose?: any;
  history?: any;
  icon?: any;
  isOpaque?: boolean;
  isOpen?: any;
  location?: any;
  modalName: string;
  showHeader?: boolean;
  subtext?: any;
  title?: any;
};


const Modal: React.FC<Props> = ({ children, className, closeRedirect, handleClose, history, icon, isOpaque, isOpen, showHeader, subtext, title }) => {
  const shadeClassnames = classNames('', {
    'modal-element__shade': !isOpaque,
    'modal-element__opaque-shade': isOpaque,
  })
  return (
    <div className="modal-element">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={shadeClassnames}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-element__close-button d-flex justify-content-center align-items-center"
            onClick={() => {
              if (isOpaque && closeRedirect) {
                history.push(closeRedirect);
              } else {
                handleClose()
              }
            }}
          >
            <i className="fas fa-times" />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0, y: 50 }}
            className="modal-element__content d-flex justify-content-center align-items-center"
            onClick={() => {if (!isOpaque)  {handleClose() }}}
          >
            <div className={`modal-element__contents ${className}`} onClick={e => e.stopPropagation()}>
              {showHeader &&
                <div className="modal-element__header">
                  <div className="modal-element__title">
                    {title}
                  </div>
                  {typeof icon === 'function' && icon()}
                </div>
              }
              <div className="modal-element__children">
                {children}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Modal;
