import React, { useEffect, useState } from 'react';

const PointsMeter = ({
  addedPoints,
  startPoints,
}) => {
  const [currentPoints, setCurrentPoints] = useState(startPoints);
  useEffect(() => {
    setCurrentPoints(startPoints);
  }, [startPoints]);

  useEffect(() => {
    if (addedPoints && addedPoints !== 0) {
      setTimeout(() => {
        setCurrentPoints(currentPoints + addedPoints);
      }, 1000);
    }
  }, []);

  return (
    <div className="experience-meter fadein-short" >
      <div className="experience-meter__filler">
        <div className="experience-meter__text-1">
          {currentPoints}&nbsp;Pts
        </div>
      </div>
    </div>
  );
};

export default PointsMeter;
