import React, { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, LocalDataTrack, LocalVideoTrack } from "twilio-video";
import { toast } from "react-toastify";

import { dataClientRequest } from "@codeverse/redux-data-client";
import { GET_MEETING, GET_MEETING_PARTICIPATIONS } from "models/Meeting";
import { GET_PARTICIPATION } from "models/Participation";

import { RootState } from "store/state";
import Header from "components/Guide/Meeting/Header";
import GameView from "components/Guide/Meeting/GameView";
import MainScreen from "components/Guide/Meeting/MainScreen";
import VideoSidePanel from "components/Guide/Meeting/VideoSidePanel";
import LocalAudioVideo from "components/Guide/Meeting/Local/LocalAudioVideo";
import { AppDispatch } from "store";
import Participant from "components/Guide/Meeting/Participant";

import { printNetworkQualityStats } from "utils/printNetworkQualityStats";

type Props = {
  history?: any;
  location?: any;
  match?: any;
};

type ParticipantTwilio = {
  identity: string;
};

export type CURRENT_VIEW = "editor" | "screen";

const SCREEN_CAPTURE_WIDTH = 1000;
const SCREEN_CAPTURE_FRAME_RATE = 4;

const log = (message: string, object: any) => {
  // console.log(message, object)
};

const Meeting: React.FC<Props> = ({ match, history }) => {
  const dispatch: AppDispatch = useDispatch();
  const meetingId = match.params.id;
  const currentMeeting = useSelector(
    (state: RootState) => state.user.currentMeeting
  );
  const currentUserId = useSelector(
    (state: RootState) => state.user.currentUser.id
  );
  const participations = useSelector(
    (state: RootState) => state.user.participations
  );
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const screenTrack = useRef<any>({});
  const dataTrack = useRef<any>({});
  const dataTrackPublished = useRef<any>({});

  const [currentView, setCurrentView] = useState<CURRENT_VIEW>("screen");

  const [room, setRoom] = useState<any>({});
  const [joining, setJoining] = useState(false);
  const [showSettingsPanel, setShowSettingsPanel] = useState(false);
  const [meetingAccessToken, setMeetingAccessToken] = useState("");
  const [screenEnabled, setScreenEnabled] = useState(false);
  const [connected, setConnected] = useState(false);
  const [participants, setParticipants] = useState<any>({});
  const [participantVideoTracks, setParticipantVideoTracks] = useState<any>({});
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState(null);
  const [name, setName] = useState("");
  const [networkMeter, setNetworkMeter] = useState("");
  const [networkQualityLevel, setNetworkLevel] = useState(0);
  const [madeMeetingRequest, setMadeMeetingRequest] = useState(false);
  const [followingCursor, setFollowingCursor] = useState(true);

  const meetingParticipations = useMemo(
    () => participations.filter((p: any) => p.meeting.id === currentMeeting.id),
    [currentMeeting, participations]
  );

  useEffect(() => {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    if (isFirefox) {
      toast.error("Please use Chrome version 83 or higher", {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: "A",
      });
    }
  }, []);

  // Build Data Track
  useEffect(() => {
    // https://www.twilio.com/docs/video/using-datatrack-api
    dataTrack.current = new LocalDataTrack();
    dataTrackPublished.current = {};
    dataTrackPublished.current.promise = new Promise((resolve, reject) => {
      dataTrackPublished.current.resolve = resolve;
      dataTrackPublished.current.reject = reject;
    });
  }, []);

  // Make Initial Data Request
  useEffect(() => {
    dispatch(
      dataClientRequest({
        ...GET_MEETING,
        data: {
          id: meetingId,
        },
      })
    );
    dispatch(
      dataClientRequest({
        ...GET_MEETING_PARTICIPATIONS,
        data: {
          id: meetingId,
        },
      })
    );
  }, [meetingId]);

  // Get Meeting Access Token
  useEffect(() => {
    console.log("setting interval");
    const interval = setInterval(() => {
      setMadeMeetingRequest(false);
      if (!meetingAccessToken) {
        console.log("getting access");
        const localParticipant = meetingParticipations.find(
          (participant) => participant.user.id === currentUserId
        );
        if (localParticipant) {
          dispatch(
            dataClientRequest({
              ...GET_PARTICIPATION,
              data: {
                id: localParticipant.id,
              },
            })
          ).then((payload: any) => {
            setMeetingAccessToken(payload.response.data.data.meta.access_token);
          });
        }
        setMadeMeetingRequest(true);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [meetingParticipations, meetingAccessToken]);

  // Connect to room
  useEffect(() => {
    const hasCurrentMeeting = Object.keys(currentMeeting).length > 0;
    const hasDataTrack = Object.keys(dataTrack.current).length > 0;
    const hasMeetingAccessToken = meetingAccessToken.length > 0;
    if (
      hasDataTrack &&
      !joining &&
      madeMeetingRequest &&
      hasMeetingAccessToken &&
      hasCurrentMeeting
    ) {
      setJoining(true);
      connect(meetingAccessToken, {
        name: currentMeeting.id,
        tracks: [dataTrack.current],
        networkQuality: {
          local: 3, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 3, // RemoteParticipants' Network Quality verbosity [0 - 3]
        },
      })
        .then((room) => {
          console.log("Connected to room");
          toast.success("Connected!", {
            position: toast.POSITION.BOTTOM_CENTER,
            toastId: "A",
          });
          setConnected(true);
          room.participants.forEach(participantConnected);
          room.on("participantDisconnected", participantDisconnected);
          room.on("participantConnected", (participant) =>
            participantConnected(participant)
          );
          room.on("disconnected", roomLeft);
          setRoom(room);

          room.localParticipant.on("trackPublished", (publication: any) => {
            if (publication.track === dataTrack.current) {
              dataTrackPublished.current.resolve();
            }
          });

          room.localParticipant.on(
            "trackPublicationFailed",
            (error: any, track) => {
              if (track === dataTrack.current) {
                dataTrackPublished.current.reject(error);
              }
            }
          );

          room.localParticipant.on(
            "networkQualityLevelChanged",
            (networkQualityLevel: any, networkQualityStats: any) => {
              const meter = printNetworkQualityStats(
                networkQualityLevel,
                networkQualityStats
              );
              setNetworkMeter(meter);
              setNetworkLevel(networkQualityLevel);
            }
          );
        })
        .catch((error: any) => {
          console.error(`Unable to connect to Room: ${error.message}`, error);
          console.log("currentMeeting", currentMeeting);
          setJoining(false);
          if (meetingAccessToken && currentMeeting.status === "ended") {
            toast.error("Meeting has ended", {
              position: toast.POSITION.BOTTOM_CENTER,
              toastId: "A",
            });
          }
        });
    }
  }, [
    currentMeeting,
    dataTrack.current,
    meetingAccessToken,
    madeMeetingRequest,
  ]);

  const sendData = (type: any, event: any, data = {}, to: any = null) => {
    if (room) {
      const payload = {
        event,
        type,
        data,
        to,
      };
      dataTrackPublished.current.promise.then(() =>
        dataTrack.current.send(JSON.stringify(payload))
      );
    }
  };

  const addVideoTrack = (key: string, track: any) => {
    setParticipantVideoTracks((prevState: any) => ({
      [key]: track,
      ...prevState,
    }));
  };
  const removeVideoTrack = (key: string, track: any) => {
    const newParticipationTracks = Object.assign(
      {},
      { ...participantVideoTracks }
    );
    delete newParticipationTracks[key];
    setParticipantVideoTracks(newParticipationTracks);
  };

  const participantConnected = (participant: ParticipantTwilio) => {
    console.log(`Participant "${participant.identity}" connected`);
    setParticipants((prevState: any) => ({
      [participant.identity]: participant,
      ...prevState,
    }));
  };

  const participantDisconnected = (participant: ParticipantTwilio) => {
    console.log(`Participant disconnected: ${participant.identity}`);
    const newParticipants: any = Object.assign({}, participants);
    const newParticipantVideoTracks: any = Object.assign(
      {},
      participantVideoTracks
    );
    delete newParticipants[participant.identity];
    delete newParticipantVideoTracks[participant.identity];
    setParticipants(newParticipants);
    setParticipantVideoTracks(newParticipantVideoTracks);
  };

  const onAudioOutputDeviceChange = (audioOutputDeviceId: any) => {
    setAudioOutputDeviceId(audioOutputDeviceId);
  };

  const roomLeft = (error: any) => {
    if (error) {
      console.log(error);
    }
    if (room.participants && room.participants.length > 0) {
      room.participants.forEach(participantDisconnected);
    }
  };

  const shareScreen = () => {
    const constraints = {
      video: {
        frameRate: SCREEN_CAPTURE_FRAME_RATE,
        width: SCREEN_CAPTURE_WIDTH,
      },
      audio: false,
    };
    // @ts-ignore
    navigator.mediaDevices
      // @ts-ignore
      .getDisplayMedia(constraints)
      .then((stream: any) => {
        // @ts-ignore
        screenTrack.current = new LocalVideoTrack(stream.getTracks()[0], {
          name: "guide-screen",
        });

        if (room && room.localParticipant) {
          room.localParticipant.publishTrack(screenTrack.current);
        }
        setScreenEnabled(true);
      })
      .catch((err: any) => {
        console.log(err);
        setScreenEnabled(false);
      });
  };

  const stopSharingScreen = () => {
    if (Object.keys(screenTrack.current).length > 0) {
      screenTrack.current.stop();
    }
    if (
      room &&
      room.localParticipant &&
      Object.keys(screenTrack.current).length > 0
    ) {
      room.localParticipant.unpublishTrack(screenTrack.current);
    }
    screenTrack.current = null;
    setScreenEnabled(false);
  };

  const handleLeaveRoom = () => {
    if (room && typeof room.disconnect === "function") {
      room.disconnect();
    }
    setRoom({});
  };

  return (
    <div className="meeting">
      <MainScreen>
        <Header
          followingCursor={followingCursor}
          setFollowingCursor={setFollowingCursor}
          currentFocusedParticipant={{ name }}
          currentView={currentView}
          setCurrentView={setCurrentView}
          history={history}
          handleLeaveRoom={handleLeaveRoom}
          connected={connected}
          networkMeter={networkMeter}
          networkQualityLevel={networkQualityLevel}
        />
        <div className="ParticipantViewContainer">
          {Object.keys(participants).map((key) => {
            return (
              <Participant
                followingCursor={followingCursor}
                setFollowingCursor={setFollowingCursor}
                audioOutputDeviceId={audioOutputDeviceId}
                room={room}
                key={key}
                keyName={key}
                participant={participants[key]}
                addVideoTrack={addVideoTrack}
                removeVideoTrack={removeVideoTrack}
                sendData={sendData}
                currentView={currentView}
                setName={setName}
                setParticipantVideoTracks={setParticipantVideoTracks}
                currentUser={currentUser}
              />
            );
          })}
        </div>
      </MainScreen>
      <VideoSidePanel
        participants={participants}
        participantVideoTracks={participantVideoTracks}
        showSettingsPanel={showSettingsPanel}
        setShowSettingsPanel={setShowSettingsPanel}
        shareScreen={shareScreen}
        stopSharingScreen={stopSharingScreen}
        screenEnabled={screenEnabled}
      />
      <LocalAudioVideo
        room={room}
        onAudioOutputDeviceChange={onAudioOutputDeviceChange}
        showSettingsPanel={showSettingsPanel}
        setShowSettingsPanel={setShowSettingsPanel}
      />
    </div>
  );
};

export default Meeting;
