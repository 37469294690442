import React from 'react';
import { toast } from 'react-toastify';
import { Button } from '@codeverse/react-helios-ui';
import kidSignIn from 'utils/kidSignIn';

type Props = {
  childUser?: any;
  isMobile?: any;
  userHasAccessToPlatform?: any;
  activeSubscription: any;
  subscriptionAddOns: any;
  priorityMembership?: any;
}

const ChildSignInButton: React.FC<Props> = ({
  childUser,
  isMobile,
  userHasAccessToPlatform,
  activeSubscription,
  subscriptionAddOns,
  priorityMembership,
}) => {

  const access_token = localStorage.getItem('accessToken');

  const handleKidSignIn = () => {
    const kidSignInLink = kidSignIn(
      toast,
      userHasAccessToPlatform,
      activeSubscription,
      subscriptionAddOns,
      access_token,
      childUser,
      priorityMembership.organization.id,
      isMobile,
      priorityMembership,
    );
    window.open(kidSignInLink, '_blank');
  };

  const iconStyle = {
    right: '16px',
    top: '9px',
    color: '#FFFFFF',
  };
  const textStyle = {
    right: '10px',
    color: '#FFFFFF',
    fontSize: '14px',
  };
  const btnStyle = {
    width: '106px',
  };

  return (
    <Button
      className="child-card__sign-in-button text-lg"
      context="secondary"
      withoutSpan={
        <>
          <span style={textStyle}>Sign In</span>
          <i
            style={iconStyle}
            className="fas fa-external-link-square-alt position-absolute"
          />
        </>
      }
      onClick={() => handleKidSignIn()}
      type="button"
      size="sm"
      style={btnStyle}
    />
  );
};

export default ChildSignInButton;
