import React from 'react';
import _ from 'lodash';

type Props = {
  bigMoney?: any;
  numSteps?: any;
  currentStep?: any;
  showBill?: any;
  history?: any;
  location?: any;
};

const StepCounter: React.FC<Props> = ({ bigMoney, numSteps, currentStep, showBill }) => {
  const currentStepInt = parseInt(currentStep);
  const emptySteps = numSteps - currentStepInt;
  const progressWidth = currentStepInt * 24 + ((currentStepInt * 6) - 6);
  let currentProgressStyle = null;

  if (bigMoney) {
    currentProgressStyle = {
      width: `${progressWidth}px`,
      background: '#17e898',
      margin: '0px',
    }
  } else if (currentStepInt === numSteps) {
    currentProgressStyle = {
      width: `${progressWidth}px`,
      margin: '0px',
    }
  } else {
    currentProgressStyle = {
      width: `${progressWidth}px`,
    }
  }

  return (
    <div  className="step-counter">
      {
        <div style={currentProgressStyle} className="step-counter__current-progress" />
      }
      {
        _.times(emptySteps, (i) => {
          if (i === emptySteps - 1) {
            return (
              <div key={`step${i}a`} className="step-counter__empty-unit step-counter__empty-unit--no-margin" />
            );
          } else {
            return (
              <div key={`step${i}b`} className="step-counter__empty-unit" />
            );
          }
        })
      }
      {
        showBill && !bigMoney &&
        <div className="money-icon money-bill-alt"></div>
      }
      {
        showBill && bigMoney &&
        <div className="money-icon big-money-bill"></div>
      }
    </div>
  );
}

export default StepCounter;