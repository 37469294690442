import React, { useState } from 'react';
import { Button } from '@codeverse/react-helios-ui';
import classNames from 'classnames';

type Props = {
  guideName?: any;
  guidePhoto?: any;
  history?: any;
  location?: any;
  childFirstName?: any;
  makeGuideMostPreferred?: any;
  setCurrentStep?: any;
};

const Step1b: React.FC<Props> = ({ guideName, guidePhoto, makeGuideMostPreferred, setCurrentStep }) => {
  const guidePhotoStyle = {
    backgroundImage: guidePhoto && `url(${guidePhoto})`,
  }

  return (
    <div className="step1">
      <div style={guidePhotoStyle} className="step1__guide-picture">
        {
          !guidePhoto &&
          <i className="fas fa-user-astronaut"></i>
        }
        <i style={{backgroundColor: '#00CE94'}} className="fas fa-check step1__guide-picture--icon"></i>
      </div>
      {
        !makeGuideMostPreferred &&
        <div className="question">Great! {guideName} has been added to your list of preferred Codeverse Guides.</div>
      }
      {
        makeGuideMostPreferred &&
        <div className="question">Great! {guideName} is now your top preferred Codeverse Guide.</div>
      }
      <Button
        className="w-100 mt-4"
        context="primary"
        onClick={() => setCurrentStep('2')}
      >
        Continue
      </Button>
    </div>
  );
}

export default Step1b;