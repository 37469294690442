import React, { useState } from 'react';
import SatelliteClient from '@codeverse/satellite-client';
import { Button, Input } from '@codeverse/react-helios-ui';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import SchoolDropdown from 'components/Shared/SchoolDropdown';

const Step1: React.FC<any> = ({
  currentOrganizationId,
  currentStep,
  fullName,
  schools,
  setCurrentStep,
  setUserName,
  setFullName,
  setSchools,
  setCurrentOrganizationId,
  userName,
  history,
}) => {
  const dispatch = useDispatch();
  const handleInputChange = (newValue: any) => {
    if (newValue) {
      setCurrentOrganizationId(newValue.value);
      return newValue;
    }
    return '';
  };
  const [userNameError, setUserNameError] = useState(false);
  const handleButtonSubmit = (e: any) => {
    if (!currentOrganizationId) {
      toast.error('Please select a school', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (!userName) {
      toast.error('Please enter a username', {
        position: toast.POSITION.TOP_CENTER,
      });
      setUserNameError(true);
    }
    if (currentOrganizationId && userName) {
      SatelliteClient.platform.get(`/organizations/${currentOrganizationId}/memberships/exists`, {
        headers: Object.assign({},
          { Accept: 'application/vnd.api+json' },
          { 'content-type': 'application/vnd.api+json' },
        ),
        query: {
          badge: userName,
        },
      })
        .then(() => {
          toast.error('Username exists, please choose another', {
            position: toast.POSITION.TOP_CENTER,
          });
          setUserNameError(true);
        })
        .catch(() => {
          setCurrentStep(currentStep + 1);
          history.push('/schools/register?step=2');
        });
    }
  };
  return (
    <div>
      <h1 className="org-login__information-header">Create Account</h1>
      <p className="org-login__information-subtext">Create an account to access Codeverse at your school.</p>
      <div className="mt-2">
        <SchoolDropdown
          currentOrganizationId={currentOrganizationId}
          handleInputChange={handleInputChange}
          setSchools={setSchools}
          schools={schools}
        />
      </div>
      <div className="mt-2">
        <Input
          className="org-login__name-input"
          name="firstName"
          label="First Name"
          onChange={(e: any) => setFullName(e.target.value)}
          value={fullName}
        />
      </div>
      <div className="mt-2">
        <Input
          className="org-login__name-input"
          name="userName"
          label="Username"
          onChange={(e: any) => setUserName(e.target.value)}
          value={userName}
          showError={userNameError}
        />
      </div>
      <div className="mt-3">
        <Button
          onClick={(e: any) => handleButtonSubmit(e)}
          className="org-login__button w-100"
          context="primary"
          withoutSpan={
            <>
              <span>
                Create Account
              </span>
              <i className="fas fa-chevron-right" />
            </>
          }
        />
      </div>
    </div>
  );
};

export default Step1;
