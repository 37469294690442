import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch } from 'react-redux';

import SchoolsLayout from 'components/Shared/LoginLayout';
import Step1 from 'components/schools/SignIn/Step1';
import Step2 from 'components/schools/SignIn/Step2';

const SignIn: React.FC<any> = ({
  currentStep,
  currentOrganizationId,
  currentOrganizationName,
  setCurrentOrganizationName,
  fullName,
  lastStep,
  passcode,
  schools,
  setUserName,
  setFullName,
  setSchools,
  setCurrentOrganizationId,
  userName,
  setCurrentStep,
  setLastStep,
  setPasscode,
  history,
}) => {
  const dispatch = useDispatch();

  return (
    <SchoolsLayout>
      <AnimatePresence>
        {currentStep === 1 && (
          <motion.div
            className="org-login__information-form-container"
            key={1}
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -1000, opacity: 0, position: 'absolute' }}
            transition={{ duration: 1 }}
          >
            <Step1
              currentStep={currentStep}
              currentOrganizationId={currentOrganizationId}
              currentOrganizationName={currentOrganizationName}
              setCurrentOrganizationName={setCurrentOrganizationName}
              fullName={fullName}
              schools={schools}
              setUserName={setUserName}
              setFullName={setFullName}
              setSchools={setSchools}
              setCurrentOrganizationId={setCurrentOrganizationId}
              userName={userName}
              setCurrentStep={setCurrentStep}
              setLastStep={setLastStep}
              history={history}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {currentStep === 2 && (
          <motion.div
            className="org-login__password-form"
            key={2}
            initial={{ x: 1000, opacity: 0 }}
            animate={{ x: 0, opacity: 1, position: 'absolute' }}
            exit={{ x: lastStep < currentStep ? 1000 : -1000, opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <Step2
              currentOrganizationId={currentOrganizationId}
              fullName={fullName}
              schools={schools}
              setUserName={setUserName}
              setFullName={setFullName}
              setSchools={setSchools}
              setCurrentOrganizationId={setCurrentOrganizationId}
              userName={userName}
              setPasscode={setPasscode}
              history={history}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {currentStep === 1 && <div className="org-login__footer d-flex justify-content-center">
        Need a school account?&nbsp;
        <span onClick={() => history.push('/schools/register')} className="org-login__footer--link">
          Create Account
        </span>
      </div>}
    </SchoolsLayout>
  );
};

export default SignIn;
