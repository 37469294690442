import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { Card } from '@codeverse/react-helios-ui';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';
import { GET_SLOT_INSTANCES } from 'models/SlotInstances';
import { VIRTUAL_TRIAL } from 'data/occasions';

type Props = {
  setSlotInstances: any;
  setHeaderDateDisplay: any;
};


const CalendarDateCarousel: React.FC<Props> = ({ setSlotInstances, setHeaderDateDisplay }) => {
  const dispatch: AppDispatch = useDispatch();
  const timezone = useSelector((state: RootState) => state.user.currentUser.time_zone);

  const [currentDateIndex, setCurrentDateIndex] = useState(1);
  const [currentDateMoment, setCurrentDateMoment] = useState<any>(null);

  const arrowNextClassnames = classNames('arrow arrow__next', { disabled: currentDateIndex === 4 });
  const arrowPrevClassnames = classNames('arrow arrow__prev', { disabled: currentDateIndex === 1 });

  useEffect(() => {
    if (currentDateMoment) {
      setHeaderDateDisplay(currentDateMoment.format('dddd, MMM D'));
    }
  }, [currentDateMoment]);

  const Dates = useMemo(() => {
    let count = 0;
    const dates = [];
    while (count < 6) {
      if (count === currentDateIndex) {
        setCurrentDateMoment(moment().add(count, 'days'));
      }

      if (count === 0) {
        dates.push({
          index: count,
          moment: moment().add(count, 'days'),
        });
      } else {
        dates.push({
          index: count,
          moment: moment().add(count, 'days'),
        });
      }
      count += 1;
    }
    let currentDates;
    if (currentDateIndex > 3) {
      currentDates = dates.slice(3, currentDateIndex + 3);
    } else {
      currentDates = dates.slice(currentDateIndex, currentDateIndex + 3);
    }
    return currentDates.map((date: any, index: any) => {
      const calendarDateClassnames = classNames('calendar-date', { active: date.index === currentDateIndex, disabled: date.index !== currentDateIndex });
      return (
        <Card className={calendarDateClassnames} onClick={() => setCurrentDateIndex(date.index)}>
          <div className="calendar-date__day-name">{date.moment.format('ddd')}</div>
          <div className="calendar-date__day">{date.moment.format('D')}</div>
          <div className="calendar-date__month">{date.moment.format('MMM')}</div>
        </Card>
      );
    });
  }, [currentDateIndex]);

  useEffect(() => {
    if (currentDateMoment) {
      dispatch(dataClientRequest({
        ...GET_SLOT_INSTANCES,
        query: {
          // current_user_id: curentChildId,
          filter: {
            occasion_id: VIRTUAL_TRIAL,
            date: currentDateMoment.format('YYYY-MM-DD'),
          },
          page: 1,
          per_page: 100,
        },
      }))
        .then((payload: any) => setSlotInstances(payload.response.data.data));
    }
  }, [currentDateMoment]);

  return (
    <div className="calendar-date-carousel">
      <div
        className={arrowPrevClassnames}
        onClick={() => {
          if (currentDateIndex !== 1) { setCurrentDateIndex(currentDateIndex - 1); }
        }}
      >
        <i className="fas fa-chevron-left" />
      </div>
      <div className="calendar-dates">
        {Dates}
      </div>
      <div
        className={arrowNextClassnames}
        onClick={() => {
          if (currentDateIndex !== 4) { setCurrentDateIndex(currentDateIndex + 1); }
        }}
      >
        <i className="fas fa-chevron-right" />
      </div>
    </div>
  );
};

export default CalendarDateCarousel;
