import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import DatePicker from 'react-datepicker'
import { Button, Badge, Input, Select } from "@codeverse/react-helios-ui";
import { useHistory } from "react-router-dom";
import SelectTime from "components/NewCheckout/AddChild/SelectTime";
import { dataClientRequest } from "@codeverse/redux-data-client";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { RootState } from "store/state";
import capitalizeFirstLetter from "utils/captializeFirstLetter";
import {
  addChild,
  addChild as addChildToChildUsers,
} from "store/modules/User/actions";
import { CREATE_USER } from "models/User";
import {
  resetCheckout,
  updateChildByIndex,
  setCurrentChildIndex,
  addChild as addCheckoutChild,
} from "store/modules/Checkout/actions";

import { AppDispatch } from "store";
import ClassIncludes from "../ParentInformation/ClassIncludes";

import "react-datepicker/dist/react-datepicker.css";


const calculateAge = (birthday: Date) => {
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const options = [
  { value: "beginner", label: "Beginner" },
  { value: "intermediate", label: "Intermediate" },
  { value: "advanced", label: "Advanced" },
  { value: "expert", label: "Expert" },
  { value: "pro", label: "Pro" },
];

// flows
// create acc -> add child
// add child -> refresh -> load first child / cached user

function isValidDatee(d: any) {
  return !isNaN(d) && d instanceof Date;
}


export function isValidDate(dateString: string) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split("/");
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

export function formatDate(date: any) {
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let year = date.getFullYear();
  return `${month}/${day}/${year}`;
}


const Booked: React.FC<any> = ({
  goToStepHash,
  setButtonText,
  childEditMode,
  setChildEditMode,
  selectedSlotInstance,
  setSelectedSlotInstance,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [childName, setChildName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const children = useSelector((state: RootState) => state.checkout.children);
  const currentChildIndex = useSelector(
    (state: RootState) => state.checkout.currentChildIndex
  );

  const hasEmptyValues = (children: any) => {
    let tempHasEmptyValues = false;

    children.map((child: any) => {
      Object.keys(child).map((key, value) => {
        if (typeof value === "string") {
          if (child[key] === "") {
            tempHasEmptyValues = true;
          }
        } else if (typeof value === "object") {
          if (Object.keys(value).length === 0) {
            tempHasEmptyValues = true;
          }
        }
      });
    });
    return tempHasEmptyValues;
  };

  const timezone = useMemo(() => {
    let userTimezone = "";
    const zonenameAbbr = moment.tz(moment.tz.guess()).zoneName();

    switch (zonenameAbbr) {
      case "CST":
        userTimezone = "America/Chicago";
        break;
      case "MST":
        userTimezone = "America/Denver";
        break;
      case "PST":
        userTimezone = "America/Los_Angeles";
        break;
      case "EST":
        userTimezone = "America/New_York";
        break;
      case "CDT":
        userTimezone = "America/Chicago";
        break;
      case "MDT":
        userTimezone = "America/Denver";
        break;
      case "PDT":
        userTimezone = "America/Los_Angeles";
        break;
      case "EDT":
        userTimezone = "America/New_York";
        break;
      default:
        userTimezone = "America/Chicago";
        break;
    }
    return userTimezone;
  }, []);

  useEffect(() => {
    if (children.length > 1) {
      // find first empty child on mount and make it current edit
      if (hasEmptyValues(children)) {
        setChildEditMode(true);
      }
    } else if (
      children[0].name === "" ||
      children[0].date_of_birth === "" ||
      Object.keys(children[0].slot_instance).length === 0
    ) {
      setChildEditMode(true);
    }
  }, []);

  const history = useHistory();

  const circleIconClassnames = classNames("circle-icon", {
    // disabled: true,
  });

  const buttonTextClassnames = classNames("button-text", {
    // disabled: true,
  });

  useEffect(() => {
    if (
      children[currentChildIndex].date_of_birth &&
      typeof children[currentChildIndex].date_of_birth === 'string' &&
      children[currentChildIndex].date_of_birth.replace(/[_\/]/g, "").length ===
        8 &&
      !isValidDatee(children[currentChildIndex].date_of_birth)
    ) {
      setDateOfBirthError(true);
    } else {
      setDateOfBirthError(false);
    }
  }, [children, currentChildIndex]);

  return (
    <div className="m-width-checkout">
      <h1 className="header">Who's coding?</h1>

      {!childEditMode &&
        children.map((child, index) => {
          // if (editMode && index === currentChildIndex) return null;
          const slotInstanceMomentStartObj = moment
            .tz(
              `${child.slot_instance.start_date}T${child.slot_instance.start_time}`,
              "America/Chicago"
            )
            .tz(timezone);
          return (
            <div className="card checkout-card mb-3">
              <div className="checkout-card__header pb-5">
                {child.name}
                <div
                  className="edit-icn"
                  onClick={() => {
                    dispatch({
                      type: "SET_CURRENT_CHILD_INDEX",
                      index,
                    });
                    setChildEditMode(true);
                    setButtonText("Save");
                  }}
                />
              </div>

              <div className="p-3">
                <div className="checkout-card__child-session-information d-block">
                  <span className="d-inline">Trial:</span>{" "}
                  <p className="d-inline">
                    {`${slotInstanceMomentStartObj.format("dddd, MMM D")}`} @{" "}
                    {`${slotInstanceMomentStartObj.format("h:mma z")}`}
                  </p>
                </div>
                <div className="checkout-card__child-session-information d-block">
                  <span className="d-inline">Birthday:</span>{" "}
                  <p className="d-inline">{formatDate(new Date(children[0].date_of_birth))}</p>
                </div>
                <div className="checkout-card__child-session-information d-block">
                  <span className="d-inline">Experience:</span>{" "}
                  <p className="d-inline">
                    {capitalizeFirstLetter(child.difficulty)} Coder
                  </p>
                </div>
              </div>
            </div>
          );
        })}

      {childEditMode && (
        <div>
          <div className="input-container">
            <div className="input-spacer">
              <Input
                className="w-100"
                name="childName"
                label="Child Full Name"
                onChange={(e: any) => {
                  setChildName(e.target.value);
                  dispatch({
                    type: "UPDATE_CHILD_BY_INDEX",
                    index: currentChildIndex,
                    value: { name: e.target.value },
                  });
                }}
                value={children[currentChildIndex].name}
              />
            </div>
            <div className="input-spacer">
              <DatePicker
                className="w-100"
                selected={startDate}
                onChange={(date: any) => {
                  dispatch({
                    type: "UPDATE_CHILD_BY_INDEX",
                    index: currentChildIndex,
                    value: { date_of_birth: date },
                  });
                  setStartDate(date);
                }}
                customInput={<Input
                  className="w-100"
                  name="childName"
                  label="Birthday (MM/DD/YYYY)"
                  showError={dateOfBirthError}
                />}
              />
              {/* <Input
                className="w-100"
                name="childName"
                label="Birthday (MM/DD/YYYY)"
                onChange={(e: any) => {
                  dispatch({
                    type: "UPDATE_CHILD_BY_INDEX",
                    index: currentChildIndex,
                    value: { date_of_birth: e.target.value },
                  });
                }}
                value={children[currentChildIndex].date_of_birth}
                showError={dateOfBirthError}
                mask="99/99/9999"
                inputMode="numeric"
                pattern="[0-9]*"
              /> */}
            </div>
            <Select
              label="Coding Experience"
              placeholder="Beginner"
              options={options}
              onChange={(option: any) => {
                setDifficultyLevel(option.value);
                dispatch({
                  type: "UPDATE_CHILD_BY_INDEX",
                  index: currentChildIndex,
                  value: { difficulty: option.value },
                });
              }}
              value={options.find(
                (option) =>
                  option.value === children[currentChildIndex].difficulty
              )}
            />
          </div>
          {isValidDatee(children[currentChildIndex].date_of_birth) && (
            <div className="pt-4">
              <div className="subheader">Pick a time</div>
              <div className="subtext">
                Pick a time for your free, 50 minute 1-on-1 class where &nbsp;
                {children[currentChildIndex].name} will be matched with one of
                our Guides, learn the basics of coding, and build their first
                game.
              </div>
              <div className="pt-3">
                <SelectTime
                  setButtonText={setButtonText}
                  goToStepHash={goToStepHash}
                  setChildEditMode={setChildEditMode}
                  child={children[currentChildIndex]}
                  selectedSlotInstance={selectedSlotInstance}
                  setSelectedSlotInstance={setSelectedSlotInstance}
                  timezone={timezone}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {!childEditMode && (
        <div
          className="add-child-button"
          onClick={() => {
            dispatch({ type: "ADD_CHILD" });
            dispatch({
              type: "SET_CURRENT_CHILD_INDEX",
              index: children.length,
            });
            setChildEditMode(true);
          }}
        >
          <div className={circleIconClassnames}>
            <i className="fas fa-plus" />
          </div>
          <div className={buttonTextClassnames}>Add Another Child</div>
        </div>
      )}

      <hr style={{ color: '#EEF6FD', marginTop: '32px', marginBottom: '32px' }} />

      {!childEditMode && (
        <ClassIncludes defaultState="close" className="" style={{ marginTop: '24px' }} />
      )}

      {/* <div style={{ backgroundImage: 'url(', backgroundSize: 'cover', height: '48px', width: '142px' }} /> */}
      <div style={{ marginTop: '32px', display: 'flex', justifyContent: 'center' }}>
        <img alt="kidsafe" src={'https://s3.amazonaws.com/account.codeverse.com/production/img/kidsafe.png'} height={48} width={142} />

      </div>
    </div>
  );
};

export default Booked;
