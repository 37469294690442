import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SatelliteClient from "@codeverse/satellite-client";
import { RootState } from "store/state";
import { Button, Card, Input } from "@codeverse/react-helios-ui";

import { getOrganizationSubscriptionsData } from "store/modules/Organizations/actions";

import moment from "moment-timezone";
import classNames from "classnames";
import { toast } from "react-toastify";
import Payment from "./Payment";

const EXPLORER_MERCHANT_ID = "9887d05b-3cd1-4a6d-a261-0100f90faed5";

type Props = {
  hashKey: string;
  currentStep?: number;
  goToStepHash?: any;
  getParentDataFunc?: any;
  submitCheckoutRef: React.MutableRefObject<VoidFunction>;
  setLoading: (boolean: boolean) => void;
  setChildEditMode: any;
  isLoading: any;
  parentInformation?: any;
  history: any;
  currentSelectedChildren: any;
};

const capitalizeFirstLetter = (string: any) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// const trialTerms = 'By clicking "Finish", you will activate a 7-day trial and agree to the Terms & Conditions. Upon completion of trial, you will be auto-enrolled as a Member for a monthly fee charged to the payment provided. Cancel at any time during the trial period by emailing support@codeverse.com.';

export type PAYMENT_METHOD = "credit card" | "paypal";

declare const window: any;

const Review: React.FC<Props> = ({
  currentSelectedChildren,
  history,
  currentStep,
  goToStepHash,
  submitCheckoutRef,
  setLoading,
  setChildEditMode,
  isLoading,
  parentInformation,
}) => {
  const dispatch = useDispatch();
  const children = useSelector((state: RootState) => state.checkout.children);
  const timezone = useSelector(
    (state: RootState) => state.user.currentUser.time_zone
  );
  const subscriptionType = useSelector(
    (state: RootState) => state.checkout.subscriptionType
  );
  const trialEndAt = useSelector(
    (state: RootState) => state.organizations.organization.trial_period_end_at
  );
  const [authorizationToken, setAuthorizationToken] = useState("");
  const [braintreeReady, setBraintreeReady] = useState(false);
  const [braintreeClientInstance, setBraintreeClientInstance] = useState({});
  const [braintreePaypalInstance, setBraintreePaypalInstance] = useState({});
  const [paypalPaymentDetails, setPaypalPaymentDetails] = useState({});
  const [paypalPaymentNonce, setPaypalPaymentNonce] = useState("");
  const [tokenRef, setTokenRef] = useState<any>({});

  const [showCouponField, setShowCouponField] = useState(false);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponValid, setCouponValid] = useState(false);
  const [attemptedCoupon, setAttemptedCoupon] = useState(false);

  useEffect(() => {
    if (!trialEndAt) {
      history.push("/");
    }
  }, []);

  const [selectedPaymentMethod, updateSelectedPaymentMethod] =
    useState<PAYMENT_METHOD>("credit card");

  const paymentRequired = true;

  const creditCardTabClassnames = classNames("btn btn-secondary card-tab", {
    active: selectedPaymentMethod === "credit card",
  });
  const paypalTabClassnames = classNames("btn btn-secondary card-tab", {
    active: selectedPaymentMethod === "paypal",
  });

  const firstMemberCost = useMemo(() => {
    console.log('couponCode', couponCode)
    switch (subscriptionType) {
      case "supernova_starter":
        return couponValid && couponCode === 'BTS-2023' ? 99 : 99;
      case "supernova_lite":
       return couponValid && couponCode === 'BTS-2023' ? 129 : 159;
      case "supernova":
        return couponValid && couponCode === 'BTS-2023' ? 199 : 269;
      case "supernova_pro":
        return couponValid && couponCode === 'BTS-2023' ? 349 : 489;
      default:
        return 1;
    }
  }, [subscriptionType, couponCode, couponValid]);

  const additionalCost = useMemo(() => {
    switch (subscriptionType) {
      case "supernova_starter":
        return couponValid && couponCode === 'BTS-2023' ? 89 :89;
      case "supernova_lite":
        return couponValid && couponCode === 'BTS-2023' ? 116 : 139;
      case "supernova":
        return couponValid && couponCode === 'BTS-2023' ? 179 : 239;
      case "supernova_pro":
        return couponValid && couponCode === 'BTS-2023' ? 314 : 439;
      default:
        return 1;
    }
  }, [subscriptionType]);

  const planPrice = useMemo(() => {
    const selectedChildren = Object.values(currentSelectedChildren).filter(
      (bool) => bool
    ).length;
    const realAdditionalCost =
      selectedChildren > 1 ? (selectedChildren - 1) * additionalCost : 0;
    switch (subscriptionType) {
      case "supernova_lite":
        return firstMemberCost + realAdditionalCost;
      case "supernova":
        return firstMemberCost + realAdditionalCost;
      case "supernova_pro":
        return firstMemberCost + realAdditionalCost;
    }
  }, [subscriptionType, additionalCost, currentSelectedChildren, firstMemberCost]);

  const PlanOverview = useMemo(() => {
    switch (subscriptionType) {
      case "supernova_starter":
        return "1 classes per month";
      case "supernova_lite":
        return "2 classes per month";
      case "supernova":
        return "4 classes per month";
      case "supernova_pro":
        return "8 classes per month";
      default:
        return "";
    }
  }, [subscriptionType]);

  const validateCoupon = (couponCode: any) => {
    if (!couponCode) {
      return;
    }
    SatelliteClient.platform
      .get("/occasions/d2e9f65b-c86f-40c8-b9ce-4d3afc6dc8e8/coupons/validate", {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
        query: {
          coupon_code: couponCode,
        },
      })
      .then(() => {
        const splitCoupon = couponCode.split("-");
        const couponPrice = splitCoupon[1];
        setCouponDiscount(couponPrice);
        setCouponValid(true);
        setAttemptedCoupon(true);
      })
      .catch(() => {
        setCouponValid(false);
        setCouponDiscount(0);
        setAttemptedCoupon(true);
      });
  };

  const handleSubmit = () => {
    setLoading(true);

    if (paymentRequired) {
      const data = {
        cadence: "monthly",
        first_add_on: subscriptionType,
        trial_period: false,
        start_at: moment(trialEndAt.split("T")[0])
          .add(1, "day")
          .format("YYYY-MM-DD"),
        initial_member_count: Object.values(currentSelectedChildren).filter(
          (bool) => bool
        ).length,
      };
      if (selectedPaymentMethod === "credit card") {
        tokenRef
          .ref()
          .then((response: any) => {
            const dataWithPayment = Object.assign(
              {},
              {
                ...data,
              },
              couponCode && couponValid && { coupon_code: couponCode }
            );
            submitCheckout(dataWithPayment, response.nonce);
          })
          .catch((error: any) => {
            setLoading(false);
            toast.error("Please check your payment information", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          });
      } else if (selectedPaymentMethod === "paypal") {
        if (!paypalPaymentNonce) {
          setLoading(false);
          toast.error("Please check your payment information", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          return;
        }
        const dataWithPayment = Object.assign(
          {},
          {
            ...data,
          },
          couponCode && couponValid && { coupon_code: couponCode }
        );
        submitCheckout(dataWithPayment, paypalPaymentNonce);
      }
    } else {
      setLoading(false);
    }
  };

  const submitCheckout = (data: any, paypalPaymentNonce: string) => {
    return SatelliteClient.platform
      .post("/payment_methods", {
        headers: Object.assign(
          {},
          { Accept: "application/vnd.api+json" },
          { "content-type": "application/vnd.api+json" }
        ),
        data: {
          data: {
            type: "payment_methods",
            attributes: {
              payment_method_nonce: paypalPaymentNonce,
            },
          },
        },
      })
      .then((response: any) => {
        return SatelliteClient.platform
          .post("/subscriptions", {
            headers: Object.assign(
              {},
              { Accept: "application/vnd.api+json" },
              { "content-type": "application/vnd.api+json" }
            ),
            data: {
              data: {
                type: "subscriptions",
                attributes: {
                  ...data,
                },
                relationships: {
                  organization: {
                    data: {
                      type: "organizations",
                      id: localStorage.getItem("currentOrganizationId"),
                    },
                  },
                  payment_method: {
                    data: {
                      type: "payment_methods",
                      id: response.data.data.id,
                    },
                  },
                  merchant_account: {
                    data: {
                      type: "merchant_accounts",
                      id: EXPLORER_MERCHANT_ID,
                    },
                  },
                },
              },
            },
          })
          .then(() => {
            setLoading(false);
            const access_token = localStorage.getItem("accessToken");
            const current_user_id = localStorage.getItem("currentUserId");
            const current_organization_id = localStorage.getItem(
              "currentOrganizationId"
            );
            dispatch(
              getOrganizationSubscriptionsData(
                access_token,
                current_user_id,
                current_organization_id
              )
            );
            history.push("/parent/dashboard");
            toast.success("Successfully added membership", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          })
          .catch((err: any) => {
            toast.error("Please try again or email support@codeverse.com", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            setLoading(false);
          });
      })
      .catch((err: any) => {
        toast.error("Please try again or email support@codeverse.com", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setLoading(false);
      });
  };

  // // bind handleSubmit to ref in parent component
  // useEffect(() => {
  //   //@ts-ignore
  //   submitCheckoutRef.current = handleSubmit;
  // }, [handleSubmit]);

  return (
    <div className="m-width-checkout mt-5 review">
      <h1 className="header">Add Payment Information</h1>

      <Card className="checkout-card">
        <div className="checkout-card__plan-information p-3">
          <div className="bold-header">Plan after trial</div>
          <p className="mb-0">
            {PlanOverview}: 1 x ${firstMemberCost}/mo
          </p>
          {Object.values(currentSelectedChildren).filter((bool) => bool)
            .length > 0 ? (
            <p className="mb-0">
              Additional Members:{" "}
              {Object.values(currentSelectedChildren).filter((bool) => bool)
                .length - 1}{" "}
              x ${additionalCost}/mo
            </p>
          ) : null}

          <div
            className="edit-icn plan-information-edit-icn"
            onClick={() => goToStepHash("choose-plan")}
          />
        </div>

        <div className="checkout-card__due-today subscription-field">
          {trialEndAt && (
            <div className="due-today-text">
              Due on{" "}
              {moment(trialEndAt)
                .tz(timezone)
                .add(1, "day")
                .format("dddd, MMM D")}
            </div>
          )}
          <div className="due-today-price">${planPrice}</div>
        </div>

        <div className="payment-method">
          <div className="card-body payment-method-information">
            <div className="btn-group btn-group-sm selector-tabs">
              <div
                onClick={() => updateSelectedPaymentMethod("credit card")}
                className={creditCardTabClassnames}
              >
                <span>Card</span>
              </div>
              <div
                onClick={() => updateSelectedPaymentMethod("paypal")}
                className={paypalTabClassnames}
              >
                <span>PayPal</span>
              </div>
            </div>
          </div>

          <Payment
            selectedPaymentMethod={selectedPaymentMethod}
            authorizationToken={authorizationToken}
            setAuthorizationToken={setAuthorizationToken}
            braintreeReady={braintreeReady}
            setBraintreeReady={setBraintreeReady}
            braintreeClientInstance={braintreeClientInstance}
            setBraintreeClientInstance={setBraintreeClientInstance}
            braintreePaypalInstance={braintreePaypalInstance}
            setBraintreePaypalInstance={setBraintreePaypalInstance}
            paypalPaymentDetails={paypalPaymentDetails}
            setPaypalPaymentDetails={setPaypalPaymentDetails}
            paypalPaymentNonce={paypalPaymentNonce}
            setPaypalPaymentNonce={setPaypalPaymentNonce}
            tokenRef={tokenRef}
            setTokenRef={setTokenRef}
          />
        </div>

        {/* <div className="d-flex flex-column">
          <div className="ssl-secure">
            <span>SSL</span>
            <span>Secure</span>
          </div>
          <div className="ssl-secure__shield" />
        </div> */}
        <div className="coupon-code">
          <div
            className="coupon-code-title"
            onClick={() => {
              setShowCouponField(!showCouponField);
            }}
          >
            Got a Code?
            <i
              className={`ml-2 fas ${
                showCouponField ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            />
          </div>

          {showCouponField && (
            <div className="coupon-code-field mt-3">
              <Input
                showError={attemptedCoupon && !couponValid}
                showSuccess={attemptedCoupon && couponValid}
                name="couponCode"
                label="Code"
                onChange={(e: any) =>
                  setCouponCode(e.target.value.toUpperCase())
                }
                value={couponCode}
                onBlur={() => validateCoupon(couponCode)}
                onKeyPress={(event: any) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    validateCoupon(couponCode);
                  }
                }}
              />
              {couponCode && attemptedCoupon && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setCouponCode("");
                    setCouponDiscount(0);
                    setAttemptedCoupon(false);
                    setCouponValid(false);
                  }}
                  href="#"
                  className="reset-coupon"
                >
                  Reset
                </a>
              )}
              {couponCode && !attemptedCoupon && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    validateCoupon(couponCode);
                  }}
                  href="#"
                  className="reset-coupon"
                >
                  Check
                </a>
              )}
            </div>
          )}
        </div>
      </Card>

      <Button
        disabled={isLoading}
        onClick={() => handleSubmit()}
        className="w-100 mt-4 checkout-complete-btn"
        context="primary"
      >
        Finish
      </Button>

      {/* <div className="terms-and-conditions">
        By clicking "Finish", you will activate a 7-day trial and agree to the Terms & Conditions. Upon completion of trial, you will be auto-enrolled as a Member for a monthly fee charged to the payment provided. Cancel at any time during the trial period by emailing <a href="mailto:support@codeverse.com">support@codeverse.com</a>.
      </div> */}
    </div>
  );
};

export default Review;
