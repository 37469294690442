import React, { useEffect, useState, useMemo } from 'react';
import { Card, Button, CardHeader, CardBody } from '@codeverse/react-helios-ui';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { dataClientRequest } from '@codeverse/redux-data-client';


import { openModal } from 'store/modules/UI/actions';
import { AppDispatch } from 'store';
import { GET_SUBSCRIPTION_ADD_ONS } from 'models/Subscription';
import { RootState } from 'store/state';

type Props = {
  history: any;
  currentPaymentMethod: any;
  currentSubscriptions: any;
};

const MembershipCardInfo: React.FC<Props> = ({ currentPaymentMethod, currentSubscriptions }) => {
  const dispatch: AppDispatch = useDispatch();

  const [retrievedAddOns, setRetrievedAddOns] = useState(false);
  const [subscriptionAddOn, setSubscriptionAddOn] = useState(null);

  useEffect(() => {
    if (currentSubscriptions && currentSubscriptions.length > 0) {
      const newCurrentSubscriptions = currentSubscriptions.filter((sub: any) => sub.status !== 'canceled');
      setRetrievedAddOns(true);
      dispatch(dataClientRequest({
        ...GET_SUBSCRIPTION_ADD_ONS,
        data: {
          id: currentSubscriptions[0].id,
        },
      }))
        .then((response: any) => {
          setRetrievedAddOns(true);
          setSubscriptionAddOn(response.response.data.data[0]);
        });
    }
  }, [currentSubscriptions]);

  const determineNumCredits = (productName: string) => {
    switch (productName) {
      case 'nova':
        return '0';
      case 'explorer':
        return '0';
      case 'studio':
        return '0';
      case 'supernova_starter':
        return '1';
      case 'supernova_lite':
        return '2';
      case 'supernova':
        return '4';
      case 'supernova_pro':
        return '8';
      default:
        return '0';
    }
  };

  const roundTo = (n: any, digits: any) => {
    let negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n *= -1;
    }
    const multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(digits);
    if (negative) {
      n = (n * -1).toFixed(digits);
    }
    return n;
  };

  return (
    <Card className="account-info">
      <CardHeader>
        <div className="account-info__header">
          Membership
        </div>
      </CardHeader>
      <CardBody>
        {
          subscriptionAddOn &&
          <div className="d-flex flex-row align-items-center green-pill-container">
            <div className="green-pill mr-3">{determineNumCredits(subscriptionAddOn.product)} Session Credits</div>per month
          </div>
        }
      </CardBody>
      <div className="account-info__info-row">
        <div className="account-info__info-row--label">
          Next Bill Amount
        </div>
        <div className="account-info__info-row--content">
          {currentSubscriptions && currentSubscriptions.length > 0 && '$'}
          {currentSubscriptions && currentSubscriptions.length > 0 && roundTo(currentSubscriptions[0].braintree_next_billing_period_amount, 2)}
        </div>
      </div>
      <div className="account-info__info-row white">
        <div className="account-info__info-row--label">Next Bill Date</div>
        <div className="account-info__info-row--content">
          {currentSubscriptions && currentSubscriptions.length > 0 && moment(currentSubscriptions[0].braintree_next_billing_date).format('LL')}
        </div>
      </div>
      <div className="account-info__mid-header">
        <div className="account-info__header">
          Billing Information
        </div>
        <i onClick={() => dispatch(openModal('editBilling'))} className="fas fa-pen account-info__edit-button" />
      </div>
      <div className="account-info__info-row white">
        <div className="account-info__info-row--label">Payment Type</div>
        <div className="account-info__info-row--content">
          {currentPaymentMethod && currentPaymentMethod.last_four ? `Card ending in ${currentPaymentMethod.last_four}` : 'Paypal'}
        </div>
      </div>
      {/* <div className="account-info__info-row">
        <div className="account-info__info-row--label">Expiration Date</div>
        <div className="account-info__info-row--content">
          03/25
        </div>
      </div>
      <div className="account-info__info-row white">
        <div className="account-info__info-row--label">Billing Zip Code</div>
        <div className="account-info__info-row--content">
          60110
        </div>
      </div> */}
      {/* <div className="neptune-dark">
        View Payment History
      </div> */}
      <div className="account-info__info-row">
        <div className="account-info__info-row--label">
          <b>Need to modify to your plan?</b>
        </div>
        <Button
          onClick={() => window.open('https://forms.gle/E7JUtPnZAcPixyndA')}
          context="primary"
          size="sm"
        >Submit Request
        </Button>
      </div>
    </Card>
  );
};

export default MembershipCardInfo;
