import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Row, Col } from '@codeverse/react-helios-ui';
import moment from 'moment';
import { ReferralBox } from 'components/Shared/ReferralBox';
import SessionCard from 'components/Parent/Schedule/SessionCard';
import RescheduleModal from 'components/Parent/Schedule/RescheduleModal/RescheduleModal';
import CancelModal from 'components/Parent/Schedule/CancelModal';
import { resetUserBookingInstances } from 'store/modules/User/actions';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { GET_SUBSCRIPTION_ADD_ONS } from 'models/Subscription';
import {
  GET_ORGANIZATION_BOOKINGS,
  GET_ORGANIZATION_CREDITS,
} from 'models/Organization';
import { GET_USER_BOOKING_INSTANCES } from 'models/User';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';
import BookingSessionModal from 'components/Parent/Schedule/BookSessionModal/BookSessionModal';

import Credits from 'components/Parent/Schedule/Credits';
import {
  INTERNAL,
  VIRTUAL_TRIAL,
  VIRTUAL_CLASS,
  VIDEO_CONFERENCE_TEST,
  INFLUENCER_CLASS,
} from 'data/occasions';

import PastSessions from 'components/Parent/PastSessions';

const activeSubscriptionStatuses = ['active', 'new', 'trial', 'pending'];
const activeBookingStatuses = ['active', 'pending'];
const visibleOccasions = [
  INFLUENCER_CLASS,
  INTERNAL,
  VIDEO_CONFERENCE_TEST,
  VIRTUAL_CLASS,
  VIRTUAL_TRIAL,
];

const Schedule: React.FC<any> = ({ history, location }) => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const bookings = useSelector((state: RootState) => state.user.bookings);
  const accessToken =
    useSelector(
      (state: RootState) => state.session.access_token.access_token,
    ) || '';
  const userBookingInstances = useSelector(
    (state: RootState) => state.user.userBookingInstances,
  );
  const subscriptions = useSelector(
    (state: RootState) => state.organizations.subscriptions,
  );
  const subscriptionAddOns = useSelector(
    (state: RootState) => state.organizations.subscriptionAddOns,
  );
  const credits = useSelector((state: RootState) => state.user.credits);
  const [currentBookings, setCurrentBookings] = useState(null);
  const [currentBookingInstances, setCurrentBookingInstances] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [currentBooking, setCurrentBooking] = useState({});
  const [currentBookingInstance, setCurrentBookingInstance] = useState({});
  const [bookingsUpdated, setBookingsUpdated] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState(null);

  const getOrganizationBookings = (page = 1) => {
    dispatch(
      dataClientRequest({
        ...GET_ORGANIZATION_BOOKINGS,
        data: {
          id: localStorage.getItem('currentOrganizationId'),
        },
        query: {
          per_page: 500,
          page,
        },
      }),
    );
  };

  useEffect(() => {
    if (bookings.length === 0) {
      Promise.all([getOrganizationBookings()]).then(() => {});
    }
  }, []);

  useEffect(() => {
    if (bookingsUpdated) {
      setDataFetched(false);
      Promise.all([getOrganizationBookings()]).then(() => {
        setBookingsUpdated(false);
      });
    }
  }, [bookingsUpdated]);

  useEffect(() => {
    if (bookings && bookings.length > 0) {
      let tempBookings = [];
      tempBookings = bookings.filter(
        booking =>
          (visibleOccasions.includes(booking.occasion.id) && activeBookingStatuses.includes(booking.status)),
      );
      setCurrentBookings(tempBookings);
    }
  }, [bookings]);

  useEffect(() => {
    if (currentBookings && currentBookings.length > 0) {
      let tempUserList: any = [];
      currentBookings.forEach((booking: any) => {
        tempUserList.push(booking.user.id);
      });
      tempUserList = tempUserList.filter(
        (x: any, i: any, a: any) => a.indexOf(x) === i,
      );
      setOrganizationUsers(tempUserList);
    }
  }, [currentBookings]);

  useEffect(() => {
    if (organizationUsers && organizationUsers.length > 0) {
      dispatch(resetUserBookingInstances());
      for (let index = 0; index < organizationUsers.length; index++) {
        dispatch(
          dataClientRequest({
            ...GET_USER_BOOKING_INSTANCES,
            data: {
              id: organizationUsers[index],
            },
          }),
        )
          .then(() => {
            setDataFetched(true);
          })
          .catch(() => {
            setDataFetched(true);
          });
      }
    }
  }, [organizationUsers]);

  useEffect(() => {
    if (userBookingInstances && currentBookings && currentBookings.length > 0) {
      const tempCurrentBookingInstances: any = [];
      userBookingInstances.forEach((userBookingInstance: any) => {
        const currentBooking = currentBookings.find((booking: any) => {
          return booking.id === userBookingInstance.booking_id;
        });

        if (
          currentBooking &&
          moment(
            `${userBookingInstance.start_date}T${userBookingInstance.start_time}`,
            'YYYY-MM-DDTHH:mm',
          ).isSameOrAfter(moment()) &&
          moment(
            `${userBookingInstance.start_date}T${userBookingInstance.start_time}`,
            'YYYY-MM-DDTHH:mm',
          ).isSameOrBefore(moment().add(30, 'd'))
        ) {
          tempCurrentBookingInstances.push(userBookingInstance);
        }
      });
      const sortedBookingInstances = tempCurrentBookingInstances.sort(
        (a: any, b: any) => {
          return moment(a.start_date, 'YYYY-MM-DD').diff(
            moment(b.start_date, 'YYYY-MM-DD'),
          );
        },
      );
      setCurrentBookingInstances(sortedBookingInstances);
    }
  }, [userBookingInstances]);

  // Get Credits
  useEffect(() => {
    dispatch(
      dataClientRequest({
        ...GET_ORGANIZATION_CREDITS,
        data: {
          id: localStorage.getItem('currentOrganizationId'),
        },
      }),
    );
  }, []);

  const appliedCredits = useMemo(() => {
    const unusedCredits = credits.filter(credit => !credit.used_at).length;
    return unusedCredits;
  }, [credits, currentBookingInstances, subscriptions]);

  const unappliedCredits = useMemo(() => {
    const activeSubscription = subscriptions.find(
      (sub: any) => sub.status === 'trial' || 'pending' || 'active' || 'canceling',
    );
    if (activeSubscription) {
      const currentBookingsWithinBillingCycle = currentBookingInstances.filter(
        (currentBookingInstance: any) => {
          return moment(
            currentBookingInstance.start_date,
            'YYYY-MM-DD',
          ).isBefore(
            moment(activeSubscription.braintree_next_billing_date, 'YYYY-MM-DD'),
          );
        },
      );
      const unusedCredits = credits.filter(credit => !credit.used_at).length;
      if (unusedCredits - currentBookingsWithinBillingCycle.length > 0) {
        return unusedCredits - currentBookingsWithinBillingCycle.length;
      } else {
        return 0;
      }
    }
  }, [credits, currentBookingInstances, subscriptions]);

  useEffect(() => {
    const activeSubscription = subscriptions.find(
      (sub: any) => sub.start_at && moment(sub.start_at).isSameOrBefore(moment()) && !sub.cancel_at && activeSubscriptionStatuses.includes(sub.status),
    );

    if (activeSubscription) {
      dispatch(dataClientRequest({
        ...GET_SUBSCRIPTION_ADD_ONS,
        data: {
          id: activeSubscription.id,
        },
      }));
    }
  }, [subscriptions]);

  return (
    <>
      <Row className="mt-5">
        <Col lg={8}>
          <div>
            <h2 className="mt-4 mb-4 pb-2">Upcoming Sessions</h2>
          </div>
          {!bookingsUpdated &&
            dataFetched &&
            currentBookings &&
            currentBookings.length > 0 &&
            currentBookingInstances &&
            currentBookingInstances.length > 0 && (
            <div className="upcoming-sessions">
              {currentBookingInstances.map((bookingInstance: any) => {
                const currentBooking = currentBookings.find((booking: any) => {
                  return booking.id === bookingInstance.booking_id;
                });
                return (
                  <SessionCard
                    key={bookingInstance.id}
                    setCurrentBooking={setCurrentBooking}
                    setCurrentBookingInstance={setCurrentBookingInstance}
                    booking={currentBooking}
                    bookingInstance={bookingInstance}
                    subscriptions={subscriptions}
                    subscriptionAddOns={subscriptionAddOns}
                  />
                );
              })}
            </div>
          )}

          {currentBookingInstances.length === 0 && (
            <h6>No Upcoming Sessions</h6>
          )}

          <PastSessions />

          {/* {
            (dataFetched && currentBookingInstances && currentBookingInstances.length === 0) || (currentBookings && currentBookings.length === 0) &&
            <Alert className="parent-schedule-warning" context="warning">Don't miss out on coding!<br />It looks like you are short of the 1 session per week that comes with your membership. Please <u><b><a href="mailto: support@codeverse.com">email</a></b></u>, chat or phone <b><a href="tel: 18446442633">844-644-CODE</a></b> to book more sessions.</Alert>
          } */}
        </Col>
        <Col lg={4} className="mb-3">
          <Credits
            history={history}
            location={location}
            appliedCredits={appliedCredits}
            unappliedCredits={unappliedCredits}
          />
          <ReferralBox referralCode={currentUser.referral_code} addSpacing />
        </Col>
        {currentBooking && currentBookingInstance && (
          <RescheduleModal
            currentBooking={currentBooking}
            bookings={currentBookings}
            currentBookingInstance={currentBookingInstance}
            setBookingsUpdated={setBookingsUpdated}
          />
        )}
        {currentBooking && currentBookingInstance && (
          <CancelModal
            currentBooking={currentBooking}
            currentBookingInstance={currentBookingInstance}
            setBookingsUpdated={setBookingsUpdated}
          />
        )}
        <BookingSessionModal />
      </Row>
    </>
  );
};

export default Schedule;
