import React from 'react';
import { Button } from '@codeverse/react-helios-ui';
import { closeModal, openModal } from 'store/modules/UI/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

type Props = {
  currentEvent: any;
  handleRecurring: any;
  handleThisDayOnly: any;
};

const CreateAvailabilityModal: React.FC<Props> = ({
  currentEvent,
  handleRecurring,
  handleThisDayOnly,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="edit-guide-availability-modal__content d-flex flex-column h-100">
      <div className="d-flex justify-content-center">
        <h4>{moment(currentEvent.start).format('dddd MM/DD/YYYY')} <br /> @ {moment(currentEvent.start).format('h:mm A')}</h4>
      </div>

      <div className="w-100 mt-auto">
        <Button
          onClick={handleRecurring}
          className="w-100 mb-3"
          context="primary"
        >
          Every week at this day and time
    </Button>
        <Button
          onClick={handleThisDayOnly}
          style={{ backgroundColor: '#fc5f54' }}
          className="w-100"
          context="status-danger"
        >
          This date only
    </Button>
      </div>
    </div>
  );
};

export default CreateAvailabilityModal;
