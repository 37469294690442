import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@codeverse/react-helios-ui';
import Editor from '@codeverse/editor';
import { RootState } from 'store/state';

type Props = {
  label: 'Microphone' | 'Speakers' | 'Camera';
  devices: any;
  currentDeviceId: string;
  showToggleActions?: boolean;
  disabled: boolean;
  toggleAction: () => void;
  onChange: any;
};

const DeviceSetting: React.FC<Props> = ({ label, devices, currentDeviceId, showToggleActions, disabled, toggleAction, onChange }) => {
  const [deviceOptions, setDeviceOptions] = useState([])
  const [firstDeviceId, setFirstDeviceId] = useState('');
  useEffect(() => {
    const newDeviceOptions: Array<any> = [];

    devices.filter((d: any) => d.kind).map((device: any) => {
      newDeviceOptions.push({ value: device.deviceId, label: device.label });
    })

    setDeviceOptions(newDeviceOptions);
  }, [devices]);
  return (
    <div className="device-setting">
      <div className="device-dropdown">
        <Select
          label={label}
          options={deviceOptions}
          value={deviceOptions.find((deviceOption: any) => deviceOption.value === currentDeviceId) || deviceOptions[0] || {}}
          onChange={onChange}
        />
      </div>
      {showToggleActions && <div onClick={toggleAction} className={`toggle-action ${label.toLowerCase()}-action ${disabled ? `${label.toLowerCase()}-disabled` : ''}`}></div>}
    </div>
  );
};

export default DeviceSetting;
