import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import Modal from "components/Shared/NewModal";
import { RootState } from "store/state";
import { Button, Input } from "@codeverse/react-helios-ui";
import { dataClientRequest } from "@codeverse/redux-data-client";
import moment from "moment-timezone";
import { closeModal, openModal } from "store/modules/UI/actions";
import { CREATE_USER } from "models/User";
import { AppDispatch } from "store";
import { addChild as addChildToChildUsers } from "store/modules/User/actions";
import {
  resetCheckout,
  updateChildByIndex,
  setCurrentChildIndex,
  addChild as addCheckoutChild,
} from "store/modules/Checkout/actions";
import { ChildUser } from "components/Parent/Schedule/BookSessionModal/Step1";

const calculateAge = (birthday: Date) => {
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const calculateDifficulty = (age: number, hasExperience: boolean) => {
  if (age < 8) {
    return "easy";
  } else if (age < 11) {
    if (hasExperience) {
      return "medium";
    } else {
      return "easy";
    }
  } else if (hasExperience) {
    return "hard";
  } else {
    return "medium";
  }
};

const AddChildCheckoutModal: React.FC<any> = ({ isOpen, handleClose }) => {
  const addChildCheckout = useSelector(
    (state: RootState) => state.ui.addChildCheckout
  );
  const children = useSelector((state: RootState) => state.checkout.children);
  const currentChildIndex = useSelector(
    (state: RootState) => state.checkout.currentChildIndex
  );
  const childUsers = useSelector((state: RootState) => state.user.childUsers);
  const dispatch: AppDispatch = useDispatch();
  const [childName, setChildName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [showCreateUser, setShowCreateUser] = useState(false);
  const history = useHistory();

  const createUser = () => {
    if (!childName || !birthday) {
      return toast.error("Please complete all the fields", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    const firstName = childName.split(" ")[0];

    if (childUsers.length > 1) {
      let foundDuplicateChild = false;
      const lowercaseChildName = childName.toLowerCase();
      childUsers.map((child) => {
        if (lowercaseChildName === child.name) {
          foundDuplicateChild = true;
        }
      });
      if (foundDuplicateChild) {
        toast.error(`${firstName} already has an account!`, {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
    }

    let DOB = birthday.replace(/ /g, "");
    const [month, day, year] = DOB.split("/");
    DOB = `${year}-${month}-${day}`;
    const age = calculateAge(new Date(DOB));
    const difficulty = calculateDifficulty(age, false);
    const dateOfBirth = moment(birthday, "MM/DD/YYYY").format("YYYY-MM-DD");

    dispatch(
      dataClientRequest({
        ...CREATE_USER,
        data: {
          name: childName,
          date_of_birth: dateOfBirth,
          // difficulty_level: difficulty,
          scope: "child",
        },
      })
    ).then((payload: any) => {
      if (history.location.pathname === "/parent/profile") {
        // first time
        handleFirstCheckoutUser(payload.response.data.data.id);
      } else {
        dispatch(setCurrentChildIndex(currentChildIndex + 1));
        dispatch(
          addCheckoutChild(
            payload.response.data.data.id,
            childUsers,
            payload.response.data.data
          )
        );
        dispatch(closeModal("addChildCheckout"));
        history.push("/parent/checkout/#select-time");
      }

      dispatch(closeModal("addChildCheckout"));
      dispatch(addChildToChildUsers(payload.response.data.data));
      toast.success(`${firstName} has been added`);
    });
  };

  const handleFirstCheckoutUser = (userId: string) => {
    dispatch(resetCheckout());
    dispatch(closeModal("addChildCheckout"));
    dispatch(updateChildByIndex(0, { user_id: userId }));
    dispatch(setCurrentChildIndex(0));
    history.push("/parent/checkout/#select-time");
  };

  const handleNewCheckout = (userId: string) => {
    // check if user exist
    let checkoutChild = null;
    let checkoutChildIndex = null;
    children.map((child, index) => {
      if (child.user_id === userId) {
        checkoutChildIndex = index;
        checkoutChild = child;
      }
    });
    if (checkoutChild) {
      dispatch(setCurrentChildIndex(checkoutChildIndex));
    } else {
      dispatch(setCurrentChildIndex(currentChildIndex + 1));
      dispatch(addCheckoutChild(userId, childUsers));
      dispatch(closeModal("addChildCheckout"));
      history.push("/parent/checkout/#select-time");
    }
  };

  const handleSetChild = (userId: string) => {
    if (
      history.location.pathname === "/parent/checkout/" ||
      history.location.pathname === "/parent/checkout"
    ) {
      handleNewCheckout(userId);
    } else {
      handleFirstCheckoutUser(userId);
    }
  };

  const filteredChildUsers = useMemo(() => {
    const existingCheckoutUsers: Array<any> = [];
    if (history.location.pathname === "/parent/profile") {
      return childUsers;
    } else if (history.location.pathname === "/parent/dashboard") {
      return childUsers;
    }
    children.map((child) => existingCheckoutUsers.push(child.user_id));
    return childUsers.filter(
      (child: any) => !existingCheckoutUsers.includes(child.id)
    );
  }, [children, childUsers, history.location.pathname]);

  return (
    <>
      <Modal
        isOpen={addChildCheckout}
        title={showCreateUser ? "Add Child" : "Select who this session is for"}
        modalName="editGuideAvailability"
        handleClose={() => {
          setShowCreateUser(false);
          dispatch(closeModal("addChildCheckout"));
        }}
        className="add-child-modal reschedule-modal"
        showHeader
      >
        {showCreateUser ? (
          <div className="add-child-modal-contents w-100">
            <div className="row">
              <div className="col-12">
                <Input
                  className="w-100"
                  name="childName"
                  label="Full Name"
                  onChange={(e: any) => {
                    setChildName(e.target.value);
                  }}
                  value={childName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Input
                  className="w-100"
                  name="childName"
                  label="Birthday (MM/DD/YYYY)"
                  onChange={(e: any) => {
                    setBirthday(e.target.value);
                  }}
                  value={birthday}
                  mask="99/99/9999"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Button
                  onClick={createUser}
                  className="w-100 mt-5"
                  size="lg"
                  context="primary"
                >
                  Add Child
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="reschedule-modal__content">
            <div className="child-select">
              {filteredChildUsers.map((childUser) => {
                return (
                  <ChildUser handleSetChild={handleSetChild} user={childUser} />
                );
              })}
              <div
                className="child-user"
                onClick={() => setShowCreateUser(true)}
              >
                <div className="child-user__avatar child-user__avatar-add">
                  <i className="fas fa-plus" />
                </div>
                <div className="child-user__name">Add User</div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AddChildCheckoutModal;
