import React from 'react';
import { Button } from '@codeverse/react-helios-ui';

import TextAreaInput from 'components/Shared/TextAreaInput';

type Props = {
  createNote?: any;
  history?: any;
  location?: any;
  setCurrentStep?: any;
  setSubtext?: any;
  setShowHeader?: any;
  input2Text?: any;
  setInput2Text?: any;
  updateParticipation?: any;
};

const Step4: React.FC<Props> = ({ createNote, input2Text, setCurrentStep, setSubtext, setInput2Text, setShowHeader, updateParticipation }) => {
  const handleSubmit = () => {
    // createNote('feedback');
    updateParticipation();
    setShowHeader(false);
    setSubtext('');
    setCurrentStep('4b');
  }
  return (
    <div className="step4">
      <div className="question">What would make your child's experience better? </div>
      <div className="my-4">
        <TextAreaInput inputText={input2Text} setInputText={setInput2Text} labelText={'Write your feedback here (optional)'} />
      </div>
      <Button
        className="w-100"
        context="primary"
        onClick={() => {
          handleSubmit()
        }}
      >
        Continue
      </Button>
    </div>
  );
}

export default Step4;