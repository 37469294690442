import React, { useEffect, useState, useRef, useMemo } from "react";
import { Button } from "@codeverse/react-helios-ui";
import SatelliteClient from "@codeverse/satellite-client";
import { toast } from "react-toastify";
import queryString from "query-string";
import LogRocket from "logrocket";
import Cookies from "js-cookie";
import moment from "moment-timezone";

import { formatDate, isValidDate } from "components/NewCheckout/AddChild/AddChild";
import { closeModal, openModal } from "store/modules/UI/actions";
import Modal from "components/Shared/NewModal";
import StepWizard from "components/Shared/StepWizard";
import AddChild from "components/NewCheckout/AddChild/AddChild";
import ChoosePlan from "components/NewCheckout/ChoosePlan/ChoosePlan";
import Review from "components/NewCheckout/Review/Review";
import { useDispatch, useSelector } from "react-redux";
import ParentInformation from "components/NewCheckout/ParentInformation/ParentInformation";
import ProgressIcons from "components/NewCheckout/ProgressIcons/ProgressIcons";
import SetPassword from "components/NewCheckout/SetPassword/SetPassword";

import { RootState } from "store/state";
import { setTimezone } from "store/modules/User/actions";
import Overview from "components/NewCheckout/Overview/Overview";
import { dataClientRequest } from "store/data-client";
import { CREATE_USER, UPDATE_USER_MODEL_CONFIG_NO_STORE } from "models/User";

type Props = {
  history?: any;
  location?: any;
  getParentDataFunc?: any;
};

const hasNumber = (string: string) => {
  return /\d/.test(string);
};

const hasLetter = (string: string) => {
  return /[a-z]/i.test(string);
};

export const PASSWORD_ERROR =
  "Passwords must be minimum of 8 characters and include at least one letter and one number.";

declare const window: any;

const Checkout: React.FC<Props> = ({
  location,
  history,
  getParentDataFunc,
}) => {
  const dispatch = useDispatch();
  const children = useSelector((state: RootState) => state.checkout.children);
  const parentInformation = useSelector(
    (state: RootState) => state.checkout.parent_information
  );
  const checkoutHelp = useSelector((state: RootState) => state.ui.checkoutHelp);
  const childUsers = useSelector((state: RootState) => state.user.childUsers);
  const currentChildIndex = useSelector(
    (state: RootState) => state.checkout.currentChildIndex
  );
  const checkoutChildren = useSelector(
    (state: RootState) => state.checkout.children
  );
  const checkout = useSelector((state: RootState) => state.checkout);
  const timezone = useSelector(
    (state: RootState) => state.user.currentUser.time_zone
  );

  const [fieldErrors, setFieldErrors] = useState({
    parentNameHasError: false,
    parentEmailHasError: false,
    parentPhoneNumberHasError: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [scrollContainer, setScrollContainer] = useState(true);
  const [nextLocation, setNextLocation] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedSlotInstance, setSelectedSlotInstance] = useState(null);
  const [buttonText, setButtonText] = useState("Next");
  const [password, setPassword] = useState("");
  const [childEditMode, setChildEditMode] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const { name, email, phone_number } = parentInformation || {};
  const query: any = queryString.parse(location.search);

  useEffect(() => {
    if (query.parent_name) {
      dispatch({
        type: "UPDATE_PARENT_INFORMATION",
        key: "name",
        value: query.parent_name,
      });
    }
    if (query.email_address) {
      dispatch({
        type: "UPDATE_PARENT_INFORMATION",
        key: "email",
        value: query.email_address,
      });
    }
    if (query.phone_number) {
      dispatch({
        type: "UPDATE_PARENT_INFORMATION",
        key: "phone_number",
        value: query.phone_number,
      });
    }
  }, []);

  useEffect(() => {
    setSelectedSlotInstance(checkoutChildren[currentChildIndex].slot_instance);
  }, [currentChildIndex]);

  useEffect(() => {
    localStorage.setItem("checkoutState", JSON.stringify(checkout));
  }, [checkout]);

  useEffect(() => {
    switch (location.hash) {
      case "#parent-information":
        localStorage.removeItem("currentUserId");
        localStorage.removeItem("currentOrganizationId");
        localStorage.removeItem("accessToken");
        setNextLocation("add-child");
        setCurrentStep(1);
        break;
      case "#add-child":
        localStorage.removeItem("currentUserId");
        localStorage.removeItem("currentOrganizationId");
        localStorage.removeItem("accessToken");
        setButtonText('Next')
        setNextLocation("overview");
        setCurrentStep(2);
        break;
      case "#overview":
        localStorage.removeItem("currentUserId");
        localStorage.removeItem("currentOrganizationId");
        localStorage.removeItem("accessToken");
        setButtonText('Continue')
        setNextLocation("set-password");
        setCurrentStep(3);
        break;
      case "#set-password":
        setButtonText('Finish')
        setNextLocation("review");
        setCurrentStep(4);
        break;
      case "#review":
        setCurrentStep(4);
        break;
      default:
        setNextLocation("parent-information");
    }
    // if (location.hash === '#choose-plan') {
    //   setScrollContainer(false)
    // } else {
    setScrollContainer(true);
    // }
  }, [location.hash]);

  useEffect(() => {
    localStorage.setItem("checkoutState", JSON.stringify(checkout));
  }, [checkout]);

  useEffect(() => {
    newCheckoutContainerRef.current.scrollTo(0, 0);
  }, [window.location.hash]);

  const setFieldErrorsHelper = (key: string, value: boolean) => {
    setFieldErrors(Object.assign({}, fieldErrors, { [key]: value }));
  };

  const verifyParentInformation = () => {
    const EMAIL_REGEX = /[^@\s]+@([^@\s]+\.)+[^@\W]+/g;
    if (!name || !email) {
      if (!name) {
        setFieldErrorsHelper("parentNameHasError", true);
      }
      if (!email) {
        setFieldErrorsHelper("parentEmailHasError", true);
      }
      toast.error("Please complete the fields", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return false;
    } else if (!EMAIL_REGEX.test(email)) {
      setFieldErrorsHelper("parentEmailHasError", true);
      toast.error("Please enter a valid email", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return false;
    } else {
      return true;
    }
  };

  async function makeSHA1(message: string) {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join(""); // convert bytes to hex string
    return hashHex;
  }

  const handleAccountCreation = (data: any) => {
    return SatelliteClient.platform
      .post("/homepage/registration", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data,
      })
      .then((response: any) => {
        const { access_token, organization, user } = response.data;
        localStorage.setItem("accessToken", access_token.access_token);
        localStorage.setItem("currentOrganizationId", organization.id);
        localStorage.setItem("currentUserId", user.id);
        setCurrentUserId(user.id);
        // @ts-ignore
        // const digestBuffer = makeSHA1(email);
        // digestBuffer.then((response) => {
        //   window.ire(
        //     "trackConversion",
        //     23945,
        //     {
        //       orderId: user.id,
        //       customerId: user.id,
        //       customerEmail: response,
        //     },
        //     {
        //       verifySiteDefinitionMatch: true,
        //     }
        //   );
        // });
        dispatch(setTimezone(timezone));
        const childrenUpdates: any = []
    
        const defaultQuery = {
          current_user_id: user.id,
          current_organization_id: organization.id,
          access_token: access_token.access_token,
        }

        checkoutChildren.forEach((child: any, index: number) => {
          if (child.user_id === "") {
            childrenUpdates.push(new Promise(async (resolve, reject) => {
              await SatelliteClient.platform.post(`/users`, Object.assign({},
                { query: defaultQuery },
                 {
                   data: Object.assign({}, {
                     name: child.name,
                     date_of_birth: formatDate(new Date(child.date_of_birth)),
                     difficulty_level: child.difficulty,
                     scope: "child",
                     id: child.user_id,
                   }, {
                     relationships: Object.assign({},
                       {user: { type: 'users', id: user.id }},
                       {organization: { type: 'organizations', id: organization.id }},
                     )
                   })
                 },
                 { serializer: 'users' },
                 {
                   headers: {
                     Accept: 'application/vnd.api+json',
                     'content-type': 'application/vnd.api+json',
                     bearer: access_token,
                   },
                 }
              ))
                .then((childResponse: any) => {
                  dispatch({
                    type: "UPDATE_CHILD_BY_INDEX",
                    index,
                    value: { user_id: childResponse.data.data.id },
                  });
                  resolve(childResponse.data.data)
                })
              })
              // const childResponse: any = await dispatch(
              //   dataClientRequest({
              //     ...CREATE_USER,
              //     data: {
              //       name: child.name,
              //       date_of_birth: formatDate(new Date(child.date_of_birth)),
              //       difficulty_level: child.difficulty,
              //       scope: "child",
              //     },
              //   })
              // );
              // dispatch({
              //   type: "UPDATE_CHILD_BY_INDEX",
              //   index,
              //   value: { user_id: childResponse.response.data.data.id },
              // });
            )
          } else {
            childrenUpdates.push(new Promise(async (resolve, reject) => {
              await SatelliteClient.platform.post(`/users`, Object.assign({},
                { query: defaultQuery },
                 {
                   data: Object.assign({}, {
                     name: child.name,
                     date_of_birth: formatDate(new Date(child.date_of_birth)),
                     difficulty_level: child.difficulty,
                     scope: "child",
                     id: child.user_id,
                   }, {
                     relationships: Object.assign({},
                       {user: { type: 'users', id: user.id }},
                       {organization: { type: 'organizations', id: organization.id }},
                     )
                   })
                 },
                 { serializer: 'users' },
                 {
                   headers: {
                     Accept: 'application/vnd.api+json',
                     'content-type': 'application/vnd.api+json',
                     bearer: access_token,
                   },
                 }
              )).then((childResponse: any) => {
                resolve(childResponse.data.data)
              })
            //   childrenUpdates.push(new Promise(async (resolve, reject) => {
            //   dispatch(
            //     dataClientRequest({
            //       ...UPDATE_USER_MODEL_CONFIG_NO_STORE,
            //       data: {
            //         name: child.name,
            //         date_of_birth: formatDate(new Date(child.date_of_birth)),
            //         difficulty_level: child.difficulty,
            //         scope: "child",
            //         id: child.user_id,
            //       },
            //     })
            //   );
            //     resolve(null)
            }))
            }
        })
        
        Promise.all(childrenUpdates).then((response) => {
          handleCheckout(response, { access_token, organization, user })
        })
          .catch((err) => {
          console.log('err', err)
        })
        // window.location.hash = "review";
      })
      .catch((err: any) => {
        console.log("err", err);
        setLoading(false);
        if (
          err.response.data.errors[0] &&
          err.response.data.errors[0].code === "record_invalid" &&
          err.response.data.errors[0].source.pointer ===
            "/data/attributes/phone_number"
        ) {
          createAccount(true);
        } else {
          if (LogRocket) {
            LogRocket.captureMessage(err.response.data.errors[0].detail);
          }
          toast.error(err.response.data.errors[0].detail, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      });
  };

  const handleCheckout = (newChildren: any, { access_token, organization, user }: { access_token: any, organization: any, user: any, }) => {
    const bookings = children.map((child) => {
      const foundChild = newChildren.find((newChild: any) => newChild.name === child.name)
      const startHour = child.slot_instance.start_time.split(":")[0];
      const startMinute = child.slot_instance.start_time.split(":")[1];
      const endHour = child.slot_instance.end_time.split(":")[0];
      const endMinute = child.slot_instance.end_time.split(":")[1];
      return {
        user_id: foundChild.id,
        start_hour: startHour,
        start_minute: startMinute,
        end_hour: endHour,
        end_minute: endMinute,
        first_visit_at: child.slot_instance.start_date,
        last_visit_at: child.slot_instance.end_date,
        occasion_id: child.slot_instance.occasion_id,
      };
    });
    const data = {
      bookings,
    };

    return SatelliteClient.platform
    .post("/homepage/checkout", {
      headers: Object.assign(
        {},
        { Accept: "application/json" },
        { "content-type": "application/json" }
      ),
      data,
      query: {
        current_user_id: user.id,
        current_organization_id: organization.id,
        access_token: access_token.access_token,
      }
    })
      .then((response: any) => {
      setLoading(false);
        //@ts-ignore
      window.dataLayer.push({
        event: "checkout-complete",
      });
      // getParentDataFunc();
      // const access_token = localStorage.getItem('accessToken');
      // const current_user_id = localStorage.getItem('currentUserId');
      // const current_organization_id = localStorage.getItem('currentOrganizationId');
        // // dispatch(getOrganizationSubscriptionsData(access_token, current_user_id, current_organization_id))
      history.push(`/parent/dashboard?checkout_confirmation=true&start_date=${children[0].slot_instance.start_date}&start_time=${children[0].slot_instance.start_time}&end_time=${children[0].slot_instance.end_time}`);
    })
    .catch((err: any) => {
      if (err && err.response) {
        if (err.response.data) {
          if (err.response.data.errors) {
            console.log(
              err.response.data.errors[0].detail
                .split(" ")
                .slice(0, 7)
                .join(" ")
            );
            if (
              err.response.data.errors[0].detail
                .split(" ")
                .slice(0, 7)
                .join(" ") === "cannot create booking because session is full"
            ) {
              toast.error("No space available. Please choose another slot.", {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            } else {
              toast.error("Please try again or email support@codeverse.com", {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            }
          } else {
            toast.error("Please try again or email support@codeverse.com", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        } else {
          toast.error("Please try again or email support@codeverse.com", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      } else {
        toast.error("Please try again or email support@codeverse.com", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
      setLoading(false);
    });
  }


  const createAccount = (removePhoneNumber?: boolean) => {
    setLoading(true);
    const phoneNum = phone_number.replace(/[- )(]/g, "").replace("+1", "");

    if (password.length < 8 || !hasNumber(password) || !hasLetter(password)) {
      setLoading(false);
      toast.error(PASSWORD_ERROR, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    let timezone = "";
    const zonenameAbbr = moment.tz(moment.tz.guess()).zoneAbbr();
    switch (zonenameAbbr) {
      case "CST":
        timezone = "America/Chicago";
        break;
      case "MST":
        timezone = "America/Denver";
        break;
      case "PST":
        timezone = "America/Los_Angeles";
        break;
      case "EST":
        timezone = "America/New_York";
        break;
      case "CDT":
        timezone = "America/Chicago";
        break;
      case "MDT":
        timezone = "America/Denver";
        break;
      case "PDT":
        timezone = "America/Los_Angeles";
        break;
      case "EDT":
        timezone = "America/New_York";
        break;
      default:
        timezone = "America/Chicago";
        break;
    }

    const data = Object.assign(
      {
        name: name.trim(),
        email: email.trim(),
        password,
        public_session_id: localStorage.getItem("sessionToken"),
        time_zone: timezone,
      },
      phoneNum && !removePhoneNumber && { phone_number: phoneNum }
    );

    handleAccountCreation(data);
    //@ts-ignore
    if (window.dataLayer) {
      //@ts-ignore
      window.dataLayer.push({
        event: "Account_Created",
      });
    }
  };

  const newCheckoutContainerRef: any = useRef();

  const submitCheckoutRef: any = useRef();

  useEffect(() => {
    if (childEditMode) {
      setButtonText("Save");
    }
  }, [childEditMode]);

  const Icon = useMemo(() => {
    if (buttonText === "Next") {
      return <i className="ml-2 fas fa-chevron-right" />;
    } else {
      return null;
    }
  }, [buttonText]);

  return (
    <>
      <div className="new-checkout-container" ref={newCheckoutContainerRef}>
        <div
          className={scrollContainer ? "checkout-container-scroll" : "center"}
        >
          <div className="nav">
            <div
              onClick={() => (window.location = "https://www.codeverse.com")}
              className="nova"
            />
            <div
              onClick={() => (window.location = "https://www.codeverse.com")}
              className="codeverse"
            />

            <div
              onClick={() => dispatch(openModal("checkoutHelp"))}
              className="help-button"
            >
              <span className="help-button__text">Help</span>
              <div className="question-mark" />
            </div>
          </div>

          {location.hash !== "#choose-plan" && (
            <ProgressIcons currentStep={currentStep} />
          )}

          <StepWizard
            initialStep={1}
            isHashEnabled
            className={`checkout-step-container${
              !scrollContainer ? " center" : ""
            }`}
          >
            <ChoosePlan hashKey={"choose-plan"} />
            <ParentInformation
              hashKey={"parent-information"}
              fieldErrors={fieldErrors}
            />
            <AddChild
              hashKey={"add-child"}
              setButtonText={setButtonText}
              childEditMode={childEditMode}
              setChildEditMode={setChildEditMode}
              selectedSlotInstance={selectedSlotInstance}
              setSelectedSlotInstance={setSelectedSlotInstance}
            />
            <Overview
              hashKey={"overview"}
            />
            <SetPassword
              hashKey={"set-password"}
              setPassword={setPassword}
              password={password}
              fieldErrors={fieldErrors}
            />

            {/* <SelectTime hashKey={'select-time'} /> */}
            {/* <Review hashKey={'review'} getParentDataFunc={getParentDataFunc} /> */}
            {/* <Booked hashKey={'booked'} /> */}
            {/* <Review
              setChildEditMode={setChildEditMode}
              hashKey={"review"}
              setLoading={setLoading}
              submitCheckoutRef={submitCheckoutRef}
              isLoading={isLoading}
              parentInformation={{ email: email, id: currentUserId }}
            /> */}
          </StepWizard>
        </div>
        {location.hash !== "#choose-plan" && (
          <div className="bottom-button-container">
            <Button
              disabled={
                isLoading ||
                (currentStep === 2 &&
                  (!selectedSlotInstance ||
                    Object.keys(selectedSlotInstance).length === 0))
              }
              className="w-100 bottom-button"
              context="primary"
              onClick={() => {
                const accessToken = localStorage.getItem("accessToken");
                if (currentStep === 1) {
                  if (verifyParentInformation()) {
                    window.ire &&
                      window.ire("identify", {
                        customerEmail: email,
                        customerId: "",
                      });
                    // @ts-ignore
                    if (window.dataLayer) {
                      window.dataLayer.push({
                        event: "lead-submit",
                        conversionValue: 0,
                      });
                    }
                    window.location.hash = nextLocation;
                  }
                } else if (buttonText === "Save" && currentStep === 2) {
                  setChildEditMode(false);
                  setButtonText("Next");
                } else if (accessToken && currentStep === 2) {
                  // window.location.hash = "review";
                } else if (currentStep === 4) {
                  const phoneNum = phone_number.replace(/[- )(]/g, "").replace(/[_ )(]/g, "").replace("+1", "");
                  console.log('phoneNum', phoneNum)
                  if (phoneNum.length < 10) {
                    setFieldErrorsHelper("parentPhoneNumberHasError", true);
                    toast.error("Please enter a valid phone number", {
                      position: toast.POSITION.BOTTOM_CENTER,
                    });
                  } else {
                    setFieldErrorsHelper("parentPhoneNumberHasError", false);
                    createAccount();
                  }
                } else if (currentStep === 5) {
                  if (typeof submitCheckoutRef.current === "function") {
                    submitCheckoutRef.current();
                  }
                } else {
                  window.location.hash = nextLocation;
                }
              }}
            >
              {buttonText}
              {Icon}
            </Button>
          </div>
        )}
      </div>
      <Modal
        isOpen={checkoutHelp}
        modalName="checkoutHelp"
        handleClose={() => {
          dispatch(closeModal("checkoutHelp"));
        }}
        className="checkout-help"
      >
        <div className="d-flex flex-direction-column">
          <div className="header">
            We would love to help!
            <div className="subtext">
              Give us a call or text at{" "}
              <a href="tel:8446442633" className="underlined">
                844&#8209;644&#8209;2633
              </a>{" "}
              or email us at{" "}
              <a href="mailto:support@codeverse.com" className="underlined">
                support@codeverse.com
              </a>
              .
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Checkout;
