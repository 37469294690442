import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/Shared/NewModal';
import Step1 from 'components/Parent/Survey/Step1';
import Step1b from 'components/Parent/Survey/Step1b';
import Step1c from 'components/Parent/Survey/Step1c';
import Step2 from 'components/Parent/Survey/Step2';
import Step2b from 'components/Parent/Survey/Step2b';
import Step2c from 'components/Parent/Survey/Step2c';
import Step3 from 'components/Parent/Survey/Step3';
import Step4 from 'components/Parent/Survey/Step4';
import Step4b from 'components/Parent/Survey/Step4b';
import StepCounter from  'components/Shared/StepCounter';

import { determineSentimentSymbol } from 'utils/determineSentimentSymbol';
import { GET_PARTICIPATION, UPDATE_PARTICIPATION } from 'models/Participation';
import { GET_OTHER_USER_MODEL_CONFIG } from 'models/User';
import { CREATE_GUIDE_BLOCK, DELETE_GUIDE_PREFERENCE, GET_USER_GUIDE_PREFERENCES, UPDATE_GUIDE_PREFERENCE } from 'models/GuidePreferences';
import { CREATE_NOTE } from 'models/Notes';
import { dataClientRequest } from 'store/data-client';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';

type Props = {
  history?: any;
  location?: any;
  match?: any;
};

const ParentSurvey: React.FC<Props> = ({ history, location, match }) => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const [childUser, setChildUser] = useState(null);
  const [currentGuidePreference, setCurrentGuidePreference] = useState(null);

  const [surveyCompleted, setSurveyCompleted] = useState(null);
  const [currentStep, setCurrentStep] = useState('1');
  const [subtext, setSubtext] = useState(null);
  const [participation, setParticipation] = useState(null);

  const [keepGuide, setKeepGuide] = useState(null);
  const [makeGuideMostPreferred, setMakeGuideMostPreferred] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  // const [kidConcerns, setKidConcerns] = useState(null);
  // const [technologyIssues, setTechnologyIssues] = useState(null);
  // const [parentConcerns, setParentConcerns] = useState(null);
  // const [otherConcernsExist, setOtherConcernsExist] = useState(null);
  // const [otherConcerns, setOtherConcerns] = useState('');

  // const [technologyLoggingInIssues, setTechnologyLoggingInIssues] = useState(null);
  // const [technologyAudioVideoIssues, setTechnologyAudioVideoIssues] = useState(null);
  // const [technologyCodeIdeIssues, setTechnologyCodeIdeIssues] = useState(null);
  // const [otherIssuesExist, setOtherIssuesExist] = useState(null);
  // const [otherIssues, setOtherIssues] = useState('');

  const [input1Text, setInput1Text] = useState('');
  const [input2Text, setInput2Text] = useState('');
  const [showHeader, setShowHeader] = useState(true);

  const participationId = match.params.id;

  useEffect(() => {
    dispatch(dataClientRequest({
      ...GET_PARTICIPATION,
      data: {
        id: participationId,
      }
    }))
    .then((response: any) => {
      setParticipation(response.response.data.data);
    })
  }, [])

  useEffect(() => {
    if (participation) {
      if (participation.parent_sentiment) {
        setSurveyCompleted(true);
      } else {
        setSurveyCompleted(false);
      }
    }
  }, [participation])

  useEffect(()  => {
    if (participation) {
      if (participation.user) {
        dispatch(dataClientRequest({
          ...GET_OTHER_USER_MODEL_CONFIG,
          data: {
            id: participation.user.id,
          }
        }))
        .then((response: any) => {
          setChildUser(response.response.data.data);
        })
      }
    }
  }, [participation])

  useEffect(() => {
    if (childUser) {
      dispatch(dataClientRequest({
        ...GET_USER_GUIDE_PREFERENCES,
        data: {
          id: childUser.id,
        }
      }))
      .then((response: any) => {
        const currentPreference = response.response.data.data.find((guidePreference: any) =>  {
          return guidePreference.guide.id === participation.meta.meeting_user_id;
        });
        setCurrentGuidePreference(currentPreference);
      })
    }
  }, [childUser])

  const updateParticipation = () => {
    dispatch(dataClientRequest({
      ...UPDATE_PARTICIPATION,
      data: {
        id: participationId,
        parent_sentiment: determineSentimentSymbol(selectedIcon),
        parent_keep_guide: keepGuide,
        parent_make_guide_most_preferred: makeGuideMostPreferred,
        // parent_learning_concerns: kidConcerns,
        // parent_technology_issues: technologyIssues,
        // parent_guide_concerns: parentConcerns,
        // parent_other_concerns: otherConcerns !== '' ? otherConcerns : null,
        // parent_technology_logging_in_issues: technologyLoggingInIssues,
        // parent_technology_audio_video_issues: technologyAudioVideoIssues,
        // parent_technology_code_ide_issues: technologyCodeIdeIssues,
        // parent_other_issues: otherIssues !== '' ? otherIssues : null,
        parent_next_session_comments: input1Text !== '' ? input1Text : null,
        parent_experience_comments: input2Text !== '' ? input2Text : null,
      }
    }))
  }

  const deleteGuidePreference = () => {
    if (currentGuidePreference) {
      dispatch(dataClientRequest({
        ...DELETE_GUIDE_PREFERENCE,
        data: {
          id: currentGuidePreference.id,
        }
      }))
    }
  }

  const updateGuidePreferencetoTop = () => {
    if (currentGuidePreference) {
      dispatch(dataClientRequest({
        ...UPDATE_GUIDE_PREFERENCE,
        data: {
          id: currentGuidePreference.id,
          position: 1,
        }
      }))
    }
  }

  const createGuideBlock = () => {
    if (currentGuidePreference) {
      dispatch(dataClientRequest({
        ...CREATE_GUIDE_BLOCK,
        data: {
          relationships: {
            user: { type: 'users', id: currentGuidePreference.guide.id },
          }
        }
      }))
    }
  }

  const createNote = (topic_name: any) => {
    if (childUser) {
      dispatch(dataClientRequest({
        ...CREATE_NOTE,
        data: {
          topic: topic_name,
          heading: `Note for ${childUser.name.split(' ')[0]}'s next session`,
          detail: input1Text,
          relationships: {
            participation: { type: 'participations', id: participationId },
          }
        }
      }))
    }
  }

  const handleGuidePreferenceUpdate = () => {
    if (keepGuide) {
      if (makeGuideMostPreferred) {
        updateGuidePreferencetoTop();
      } else {
        return;
      }
    } else {
      deleteGuidePreference();
      createGuideBlock();
    }
  }

  if (participation && childUser && surveyCompleted === false) {
    return (
      <>
        <Modal 
          className="survey-modal d-flex flex-column"
          closeRedirect="/parent/dashboard"
          history={history}
          isOpaque
          isOpen={true}
          modalName="parentSurvey"
          showHeader={showHeader}
          subtext
          title={'End of Session Survey'}
        >
          <div className="survey-modal__contents">
            {currentStep === '1' && <Step1
              guideName={participation.meta.meeting_guide.split(' ')[0]}
              guidePhoto={participation.meta.guide_photo_2x}
              handleGuidePreferenceUpdate={handleGuidePreferenceUpdate}
              setCurrentStep={setCurrentStep}
              keepGuide={keepGuide}
              setKeepGuide={setKeepGuide}
              makeGuideMostPreferred={makeGuideMostPreferred}
              setMakeGuideMostPreferred={setMakeGuideMostPreferred}
            />}
            {currentStep === '1b' && <Step1b
              guideName={participation.meta.meeting_guide.split(' ')[0]}
              guidePhoto={participation.meta.guide_photo_2x}
              makeGuideMostPreferred={makeGuideMostPreferred}
              setCurrentStep={setCurrentStep}
            />}
            {currentStep === '1c' && <Step1c
              guideName={participation.meta.meeting_guide.split(' ')[0]}
              guidePhoto={participation.meta.guide_photo_2x}
              setCurrentStep={setCurrentStep}
            />}
            {currentStep === '2' && <Step2 setCurrentStep={setCurrentStep} setSelectedIcon={setSelectedIcon} setSubtext={setSubtext} selectedIcon={selectedIcon} />}
            {/* {currentStep === '2b' && <Step2b 
              setCurrentStep={setCurrentStep} 
              setSubtext={setSubtext}
              kidConcerns={kidConcerns}
              setKidConcerns={setKidConcerns}
              technologyIssues={technologyIssues}
              setTechnologyIssues={setTechnologyIssues}
              parentConcerns={parentConcerns}
              setParentConcerns={setParentConcerns}
              otherConcernsExist={otherConcernsExist}
              setOtherConcernsExist={setOtherConcernsExist}
              otherConcerns={otherConcerns}
              setOtherConcerns={setOtherConcerns}
            />}
            {currentStep === '2c' && <Step2c
              technologyLoggingInIssues={technologyLoggingInIssues}
              setTechnologyLoggingInIssues={setTechnologyLoggingInIssues}
              technologyAudioVideoIssues={technologyAudioVideoIssues}
              setTechnologyAudioVideoIssues={setTechnologyAudioVideoIssues}
              technologyCodeIdeIssues={technologyCodeIdeIssues}
              setTechnologyCodeIdeIssues={setTechnologyCodeIdeIssues}
              otherIssuesExist={otherIssuesExist}
              setOtherIssuesExist={setOtherIssuesExist}
              otherIssues={otherIssues}
              setOtherIssues={setOtherIssues}
              setCurrentStep={setCurrentStep}
              setSubtext={setSubtext}
            />} */}
            {currentStep === '3' && <Step3 input1Text={input1Text} setInput1Text={setInput1Text} setCurrentStep={setCurrentStep} setSubtext={setSubtext} />}
            {currentStep === '4' && <Step4
              createNote={createNote}
              input2Text={input2Text}
              setInput2Text={setInput2Text}
              setCurrentStep={setCurrentStep}
              setSubtext={setSubtext}
              setShowHeader={setShowHeader}
              updateParticipation={updateParticipation} 
            />}
            {currentStep === '4b' && <Step4b selectedIcon={selectedIcon} referralCode={currentUser.referral_code} />}
            {
              subtext &&
              <div className="modal-element__subtext">
                {subtext}
              </div>
            }
          </div>
        </Modal>
        <StepCounter numSteps={4} currentStep={currentStep} />
      </>
    );
  } else if (participation && surveyCompleted === true) {
    history.push('/parent/dashboard?fromSurvey=true');
    return null;
  } else {
    return (
      <div>
        <div className="spacer" />
        <div className="spacer" />
        <div className="spacer" />
        <div className="nova-portal m-auto loader" />
      </div>
    );
  }
};

export default ParentSurvey;
