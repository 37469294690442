import { GetClientConfig } from 'store/data-client';

export const GET_PARTICIPATION: GetClientConfig = {
  method: 'get',
  model: 'participations',
  reducerKey: 'user',
}

export const UPDATE_PARTICIPATION: GetClientConfig = {
  method: 'put',
  model: 'participations',
}
