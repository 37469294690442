import React, { useState } from 'react';
import { Button } from '@codeverse/react-helios-ui';

type Props = {
  guideName?: any;
  guidePhoto?: any;
  history?: any;
  location?: any;
  setCurrentStep?: any;
};

const Step1b: React.FC<Props> = ({ guideName, guidePhoto, setCurrentStep }) => {
  const guidePhotoStyle = {
    backgroundImage: guidePhoto && `url(${guidePhoto})`,
  }

  return (
    <div className="step1">
      <div style={guidePhotoStyle} className="step1__guide-picture">
        {
          !guidePhoto &&
          <i className="fas fa-user-astronaut"></i>
        }
        <i style={{backgroundColor: '#FE5353'}} className="fas fa-times step1__guide-picture--icon"></i>
      </div>
      <div className="question">Thanks for your feedback</div>
      <p className="text-center">We want your child to have the best experience possible. Your child will be paired with a new guide on their next mission!</p>
        <Button
          className="w-100 mt-4"
          context="primary"
          onClick={() => setCurrentStep('2')}
        >
          Continue
        </Button>
    </div>
  );
}

export default Step1b;