import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

type Props = {
  history?: any;
  location?: any;
  onChangeFunction?: any;
  onFocusFunction?: any;
  onRenderFunction?: any;
  inputText?: any;
  labelText?: any;
  setInputText?: any;
  small?: any;
}

const TextAreaInput: React.FC<Props> = ({ inputText, onChangeFunction, onFocusFunction, onRenderFunction, setInputText, labelText, small }) => {
  const [textFocus, setTextFocus] = useState(false);

  const messageClassNames = classNames('h-input', {
    'has-value': textFocus || inputText !== '',
    // 'has-danger': inputText.length > 625,
  });

  useEffect(() => {
    if (onRenderFunction) {
      onRenderFunction();
    }
  }, []);

  const inputFieldContainerStyle = {
    height: small ? '72px' : '120px',
  };

  const textareaStyle = {
    height: small ? '72px' : '120px',
  };

  return (
    <div className="textarea-input">
      <div
        className="input-field-container"
        id="inputContainer"
        style={inputFieldContainerStyle}
      >
        <div className={messageClassNames} id="textareaInput">
          <label htmlFor="textarea">{labelText}</label>
          <textarea
            name="textarea"
            id="textarea"
            style={textareaStyle}
            onChange={e => 
            { 
              if (e.target.value.length <= 625) {
                setInputText(e.target.value);
              } else {
                setInputText(e.target.value);
              }
              if (onChangeFunction) {
                onChangeFunction();
              }
            }}
            onBlur={() => setTextFocus(false)}
            onFocus={() => {
              setTextFocus(true);
              if (onFocusFunction) {
                onFocusFunction();
              }
            }}
            className="h-input__control"
            value={inputText}
          />
        </div>
      </div>
    </div>
  );
}

export default TextAreaInput;