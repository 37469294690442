import React, { useState } from 'react';
import classNames from 'classnames';

type Props = {
  history?: any;
  location?: any;
  setSelectedIcon?: any;
  selectedIcon?: any;
};

const FiveSmiley: React.FC<Props> = ({ setSelectedIcon, selectedIcon }) => {
  const iconOverlay1Classnames = classNames('', {
    'five-smiley__icon-overlay': selectedIcon === null,
    'five-smiley__icon-overlay-not-selected': selectedIcon && selectedIcon !== 1,
  });
  const iconOverlay2Classnames = classNames('', {
    'five-smiley__icon-overlay': selectedIcon === null,
    'five-smiley__icon-overlay-not-selected': selectedIcon && selectedIcon !== 2,
  })
  const iconOverlay3Classnames = classNames('', {
    'five-smiley__icon-overlay': selectedIcon === null,
    'five-smiley__icon-overlay-not-selected': selectedIcon && selectedIcon !== 3,
  })
  const iconOverlay4Classnames = classNames('', {
    'five-smiley__icon-overlay': selectedIcon === null,
    'five-smiley__icon-overlay-not-selected': selectedIcon && selectedIcon !== 4,
  })
  const iconOverlay5Classnames = classNames('', {
    'five-smiley__icon-overlay': selectedIcon === null,
    'five-smiley__icon-overlay-not-selected': selectedIcon && selectedIcon !== 5,
  })

  const icon1Classnames = classNames('five-smiley__icon', {
    'five-smiley__icon-selected': selectedIcon === 1,
  });
  const icon2Classnames = classNames('five-smiley__icon', {
    'five-smiley__icon-selected': selectedIcon === 2,
  });
  const icon3Classnames = classNames('five-smiley__icon', {
    'five-smiley__icon-selected': selectedIcon === 3,
  });
  const icon4Classnames = classNames('five-smiley__icon', {
    'five-smiley__icon-selected': selectedIcon === 4,
  });
  const icon5Classnames = classNames('five-smiley__icon', {
    'five-smiley__icon-selected': selectedIcon === 5,
  });

  return (
    <div className="five-smiley">
      <div className="five-smiley__icons-box">
        <div className="five-smiley__icon-container">
          <div className={iconOverlay1Classnames}>
            <img className={icon1Classnames} onClick={() => setSelectedIcon(1)} src="/icons/tired-duotone.svg" alt=""/>
          </div>
        </div>
        <div className="five-smiley__icon-container">
          <div className={iconOverlay2Classnames}>
            <img className={icon2Classnames} onClick={() => setSelectedIcon(2)} src="/icons/frown-duotone.svg" alt=""/>
          </div>
        </div>
        <div className="five-smiley__icon-container">
          <div className={iconOverlay3Classnames}>
            <img className={icon3Classnames} onClick={() => setSelectedIcon(3)} src="/icons/meh-duotone.svg" alt=""/>
          </div>
        </div>
        <div className="five-smiley__icon-container">
          <div className={iconOverlay4Classnames}>
            <img className={icon4Classnames} onClick={() => setSelectedIcon(4)} src="/icons/smile-beam-duotone.svg" alt=""/>
          </div>
        </div>
        <div className="five-smiley__icon-container">
          <div className={iconOverlay5Classnames}>
            <img className={icon5Classnames} onClick={() => setSelectedIcon(5)} src="/icons/laugh-beam-duotone.svg" alt=""/>
          </div>
        </div>
      </div>
      <div className="five-smiley__descriptions-box">
        <div className="five-smiley__description">Difficult</div>
        <div className="five-smiley__description">Just OK</div>
        <div className="five-smiley__description">Amazing!</div>
      </div>
    </div>
  );
}

export default FiveSmiley;