import React, { useState } from 'react';
import { Braintree, HostedField } from 'react-braintree-fields';
import classNames from 'classnames';

const BraintreePayment: React.FC<any> = ({ selectedPaymentMethod, authorizationToken, setTokenRef }) => {
  const [state, setState] = useState({
    creditCardFocus: false,
    postalCodeFocus: false,
    cvvFocus: false,
    expirationDateFocus: false,
    creditCardValue: false,
    postalCodeValue: false,
    cvvValue: false,
    expirationDateValue: false,
  });
  let getToken;
  const creditCardInputClassnames = classNames('hosted-field',
    {
      'has-value': state.creditCardValue,
      'has-focus': state.creditCardFocus,

    },
  );
  const expirationDateClassnames = classNames('hosted-field',
    {
      'has-value': state.expirationDateValue,
      'has-focus': state.expirationDateFocus,
    },
  );
  const cvvClassnames = classNames('hosted-field',
    {
      'has-value': state.cvvValue,
      'has-focus': state.cvvFocus,
    },
  );
  const postalCodeClassnames = classNames('hosted-field',
    {
      'has-value': state.postalCodeValue,
      'has-focus': state.postalCodeFocus,
    },
  );

  const handleFocus = (e: any, fieldName: any) => {
    if (e.isEmpty) {
      setState((prevState) => {
        return {
          ...prevState,
          [`${fieldName}` + 'Value']: false,
          [`${fieldName}` + 'Focus']: true,
        };
      });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          [`${fieldName}` + 'Value']: true,
          [`${fieldName}` + 'Focus']: true,
        };
      });
    }
  };
  const handleBlur = (e: any, fieldName: any) => {
    if (e.isEmpty) {
      setState((prevState) => {
        return {
          ...prevState,
          [`${fieldName}` + 'Value']: false,
          [`${fieldName}` + 'Focus']: false,
        };
      });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          [`${fieldName}` + 'Value']: true,
          [`${fieldName}` + 'Focus']: false,
        };
      });
    }
  };

  return (
    <Braintree
      // className={this.state.isBraintreeReady ? '' : 'disabled'}
      authorization={authorizationToken}
      getTokenRef={(ref: any) => setTokenRef({ ref })}
      onError={(err: any) => console.log('err', err)}
      styles={{
        input: {
          'background-color': '#eef6fd',
          height: '56px',
          'font-size': '18px',
          'line-height': '1',
          padding: '15px 16px 0 16px',
          color: '#474c72',
          transition: 'all 200ms linear',
          display: 'block',
          width: '100%',
          border: '0',
          'border-radius': '16px',
          'z-index': '1',
        },
        'input.number': {
          'font-family': 'monospace',
        },
        '.valid': {
          color: '#00d397',
        },
        '.invalid': {
          color: '#fe5353',
        },
      }}
    >
      <div className="fields form-group">
        <div className="row pb-3 mt-4 px-1">
          <div className="col-md-12 px-0">
            <div className={creditCardInputClassnames}>
              <label>Card Number</label>
              <HostedField
                className="hosted-field__control"
                type="number"
                onFocus={(e: any) => handleFocus(e, 'creditCard')}
                onBlur={(e: any) => handleBlur(e, 'creditCard')}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 pb-3 px-1">
            <div className={expirationDateClassnames}>
              <label>Expires</label>
              <HostedField
                className="hosted-field__control"
                type="expirationDate"
                onFocus={(e: any) => handleFocus(e, 'expirationDate')}
                onBlur={(e: any) => handleBlur(e, 'expirationDate')}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
          <div className="col-3 pb-3 px-1">
            <div className={cvvClassnames}>
              <label>CVV</label>
              <HostedField
                className="hosted-field__control"
                type="cvv"
                onFocus={(e: any) => handleFocus(e, 'cvv')}
                onBlur={(e: any) => handleBlur(e, 'cvv')}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
          <div className="col-5 pb-3 px-1">
            <div className={postalCodeClassnames}>
              <label>Zip Code</label>
              <HostedField
                className="hosted-field__control"
                type="postalCode"
                onFocus={(e: any) => handleFocus(e, 'postalCode')}
                onBlur={(e: any) => handleBlur(e, 'postalCode')}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
        </div>
      </div>
    </Braintree>
  );

};

export default BraintreePayment;
