export const determineSentimentSymbol = (sentiment: Number) => {
  switch (sentiment) {
    case 1:
      return 'very_sad';
    case 2:
      return 'sad';
    case 3:
      return 'neutral';
    case 4:
      return 'happy';
    case 5:
      return 'very_happy';
    default:
      return null;
  }
};