import React, { useEffect, useState} from 'react';
import { Row } from '@codeverse/react-helios-ui';
import { useDispatch, useSelector } from 'react-redux';

import TrialCard from 'components/Parent/Trial/TrialCard';
import ParentAccountInfo from 'components/Parent/my-account/ParentAccountInfo';
import MembershipInfoCard from 'components/Parent/my-account/MembershipInfoCard';
import EditAccountModal from 'components/Parent/my-account/EditAccountModal';
import EditBillingModal from 'components/Parent/my-account/EditBillingModal';
import EditPasswordModal from 'components/Parent/my-account/EditPasswordModal';
import EditReferralCodeModal from 'components/Parent/my-account/EditReferralCodeModal';
import Modal from 'components/Shared/NewModal';

import { getPaymentMethod } from 'store/modules/Organizations/actions';
import { closeModal, openModal } from 'store/modules/UI/actions';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';

const ParentProfile: React.FC<any> = ({ history, location }) => {
  const dispatch: AppDispatch = useDispatch();

  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const organization = useSelector((state: RootState) => state.organizations.organization);
  const editAccount = useSelector((state: RootState) => state.ui.editAccount);
  const editPassword = useSelector((state: RootState) => state.ui.editPassword);
  const editReferralCode = useSelector((state: RootState) => state.ui.editReferralCode);
  const editBilling = useSelector((state: RootState) => state.ui.editBilling);

  const priorityMembership = useSelector((state: RootState) => state.user.priorityMembership);
  const subscriptions = useSelector((state: RootState) => state.organizations.subscriptions);
  const paymentMethods = useSelector((state: RootState) => state.organizations.paymentMethods);
  const accessToken = useSelector((state: RootState) => state.session.access_token.access_token) || '';

  const [hasRetrievedPaymentMethods, setHasRetrievedPaymentMethods] = useState(false);

  const [currentSubscriptions, setCurrentSubscriptions] = useState([]);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);

  useEffect(() => {
    if (priorityMembership && subscriptions.length > 0) {
      const tempCurrentSubscriptions = subscriptions.filter((subscription: any) => {
        return subscription.organization.id === priorityMembership.organization.id;
      });
      if (tempCurrentSubscriptions.length > 0) {
        setCurrentSubscriptions(tempCurrentSubscriptions);
      } else {
      }
    }
  }, [subscriptions, priorityMembership])

  useEffect(() => {
    if (currentSubscriptions && currentSubscriptions.length > 0 && currentSubscriptions[0].payment_method) {
      if (currentSubscriptions[0].payment_method)  {
        if (paymentMethods.length > 0) {
          let tempCurrentPaymentMethod = paymentMethods.find((paymentMethod: any) => {
            return paymentMethod.id === currentSubscriptions[0].payment_method.id;
          })
          if (tempCurrentPaymentMethod) {
            setCurrentPaymentMethod(tempCurrentPaymentMethod);
          } else if (!hasRetrievedPaymentMethods) {
            Promise.all([
              dispatch(getPaymentMethod(accessToken, currentUser.id, currentSubscriptions[0].payment_method.id))
            ]).then(()  => {
              setHasRetrievedPaymentMethods(true);
            })
          }
        }
    }
  }
  }, [currentSubscriptions, paymentMethods])

  useEffect(() => {
    if (currentSubscriptions && currentSubscriptions.length > 0 && currentSubscriptions[0].payment_method) {
      dispatch(getPaymentMethod(accessToken, currentUser.id, currentSubscriptions[0].payment_method.id));
    }
  }, [currentSubscriptions])

  return (
    <>
      <h2 className="mt-5">My Account</h2>
      <Row className="mt-5">
        <div className="col-md-6">
          <ParentAccountInfo currentUser={currentUser} />
        </div>
        <div className="col-md-6 mb-3">
          {
            organization.status === 'freemium' && <TrialCard history={history} />
          }
          {
            (organization.status === 'trial' || organization.status === 'pending') && <TrialCard history={history} trial />
          }
          {
            organization.status === 'active' && 
            <MembershipInfoCard
              history={history}
              currentPaymentMethod={currentPaymentMethod}
              currentSubscriptions={currentSubscriptions}
            />
          }
        </div>
      </Row>
      <Modal
        isOpen={editAccount}
        title={'Edit Person'}
        modalName="editAccount"
        handleClose={() => {
          dispatch(closeModal('editAccount'));
        }}
        className="edit-account-modal"
        showHeader
      >
        <EditAccountModal
          handleClose={() => {
            dispatch(closeModal('editAccount'));
          }}
          currentUser={currentUser}
        />
      </Modal>
      <Modal
        isOpen={editPassword}
        title={'Change Password'}
        modalName="changePassword"
        handleClose={() => {
          dispatch(closeModal('editPassword'))
        }}
        className="edit-password-modal"
        showHeader
      >
        <EditPasswordModal currentUser={currentUser} dispatch={dispatch} />
      </Modal>
      {/* <Modal
        isOpen={editReferralCode}
        title={'Edit Referral Code'}
        modalName="editReferralCode"
        handleClose={() => {
          dispatch(closeModal('editReferralCode'))
        }}
        className="edit-referral-code-modal"
        showHeader
      >
        <EditReferralCodeModal />
      </Modal> */}
      <Modal
        isOpen={editBilling}
        title={'Edit Billing Information'}
        modalName="editBillingInformation"
        handleClose={() => {
          dispatch(closeModal('editBilling'))
        }}
        className="edit-billing-modal"
        showHeader
      >
        <EditBillingModal currentSubscription={currentSubscriptions[0]} />
      </Modal>
    </>
  );
};

export default ParentProfile;
