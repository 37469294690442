import React from 'react';
import { Button, Input } from '@codeverse/react-helios-ui';

import PicturePasswordForm from 'components/schools/PicturePasswordForm';
import { openModal, setCurrentCardColor, setCurrentPasswordObject } from 'store/modules/UI/actions';
import { useDispatch } from 'react-redux';

const Step2: React.FC<any> = ({
  setPasscode,
}) => {
  const dispatch = useDispatch();

  const handleCardClick = (object: any, color: any) => {
    setPasscode(object.pin);
    dispatch(setCurrentPasswordObject(object));
    dispatch(setCurrentCardColor(color));
    dispatch(openModal('passwordConfirmation'));
  };
  return (
    <PicturePasswordForm
      handleCardClick={handleCardClick}
      header="Pick a Password Character for Your&nbsp;Account"
      subtext=""
    />
  );
};

export default Step2;
