import { handleActions } from "redux-actions";
import { createReducer } from "typesafe-actions";
import { Omit } from "lodash";

import { UserState, UserAction } from "./types";
import dataClientDefaultReducer from "utils/dataClientDefaultReducer";

// initalState
const initialState: UserState = {
  childUsers: [],
  credits: [],
  currentUser: {},
  currentMeeting: {},
  currentSubscriptionId: "",
  priorityMembership: null,
  userAddresses: [],
  userMemberships: [],
  userPhoneNumbers: [],
  guide_availabilities: [],
  guide_schedulables: [],
  participations: [],
  meetings: [],
  guide_availability_instances: [],
  bookings: [],
  userBookingInstances: [],
  //@ts-ignore
  timezone: localStorage.getItem("timezone"),
};

export function UserReducer(
  state = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case "ADD_CHILD_TO_CHILD_USERS": {
      let existingChildIndex = null;
      let existingChild = null;
      state.childUsers.map((child, index) => {
        if (child.id === action.child.id) {
          existingChildIndex = index;
          existingChild = child;
        }
      });
      if (existingChild) {
        const updatedChildUsers = [...state.childUsers];
        // remove old child
        updatedChildUsers.splice(existingChildIndex, 1, action.child);
        return Object.assign({}, { ...state, childUsers: updatedChildUsers });
      }
      return { ...state, childUsers: [...state.childUsers, action.child] };
    }
    case "SET_CHILD_USERS":
      return { ...state, childUsers: action.childUsers };
    case "SET_TIMEZONE":
      return {
        ...state,
        currentUser: Object.assign(
          {},
          { ...state.currentUser },
          { time_zone: action.timezone }
        ),
      };
    case "REHYDRATE_SESSION_FROM_COOKIES":
    return { ...state, currentUser: action.cookie.user };
    case "LOGIN_SUCCESS":
      return { ...state, currentUser: action.payload.user };
    case "GET_USER_ADDRESSES_SUCCESS":
      return { ...state, userAddresses: action.payload };
    case "GET_USER_MEMBERSHIPS_SUCCESS":
      let allowedMemberships: Array<any> = [];
      action.payload.forEach((membership: any) => {
        if (
          membership.role === "ownership" ||
          membership.role === "instructorship"
        ) {
          allowedMemberships.push(membership);
        }
      });
      return { ...state, userMemberships: allowedMemberships };
    case "GET_USER_PHONE_NUMBERS_SUCCESS":
      return { ...state, userPhoneNumbers: action.payload };
    case "SET_CURRENT_SUBSCRIPTION_ID":
      return { ...state, currentSubscriptionId: action.id };
    case "SET_PRIORITY_MEMBERSHIP":
      return { ...state, priorityMembership: action.membership };
    case "@@data-client/GET_USERS_GUIDE_SCHEDULABLES_SUCCESS":
      let tempGuideSchedulables = action.response.data.data.filter(
        (guideSchedulable: any) => {
          return !guideSchedulable.meta.deleted_at;
        }
      );
      return { ...state, guide_schedulables: tempGuideSchedulables };
    case "RESET_USER_BOOKING_INSTANCES":
      return { ...state, userBookingInstances: [] };
    default:
      return dataClientDefaultReducer(state, action, "user");
  }
}
