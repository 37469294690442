import React from 'react';
import { Button } from '@codeverse/react-helios-ui';

import TextAreaInput from 'components/Shared/TextAreaInput';

type Props = {
  history?: any;
  location?: any;
  setCurrentStep?: any;
  input1Text?: any;
  setInput1Text?: any;
  setSubtext?: any;
};

const Step3: React.FC<Props> = ({ input1Text, setCurrentStep, setInput1Text, setSubtext }) => {
  return (
    <div className="step3">
      <div className="question">Do you have any requests for your child's next session?</div>
      <div className="my-4">
        <TextAreaInput inputText={input1Text} setInputText={setInput1Text} labelText={'Write your feedback here (optional)'} />
      </div>
      <Button
        className="w-100"
        context="primary"
        onClick={() => 
          {
            setCurrentStep('4');
            setSubtext("This information will be used by the Codeverse team to improve your experience.");
          }}
      >
        Continue
      </Button>
    </div>
  );
}

export default Step3;