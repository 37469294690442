import React, { useState, useEffect } from 'react';
import { Button, PasswordInput } from '@codeverse/react-helios-ui';
import { PasswordValidation } from '@codeverse/helios-solarwind';
import { toast } from 'react-toastify';

import { closeModal } from 'store/modules/UI/actions';
import { dataClientRequest } from 'store/data-client';
import { UPDATE_USER_PASSWORD_MODEL_CONFIG } from 'models/User';

type Props = {
  dispatch: any;
  currentUser: any;
  childUser: any;
  passwordSet: boolean;
  setPasswordSet: any;
};

const hasNumber = (myString: string) => {
  return /\d/.test(myString);
};

const hasLetter = (myString: string) => {
  return /[a-z]/i.test(myString);
};

const ChildPasswordModal: React.FC<Props> = ({ 
  childUser,
  dispatch,
  passwordSet,
  setPasswordSet,
}) => {
  const [password, setPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);

  const handleClose = () => {
    dispatch(closeModal('editChildPassword'));
  };

  const handleSave = () => {
    if (password.length < 8 || !hasNumber(password) || !hasLetter(password)) {
      toast.error('Your new password does not meet character requirements', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      dispatch(dataClientRequest({
        ...UPDATE_USER_PASSWORD_MODEL_CONFIG,
        data: {
          id: childUser.id,
          password,
        },
      }))
        .then(() => {
          toast.success('Password change successful.', {
            position: toast.POSITION.TOP_CENTER,
          });
          handleClose();
          setPasswordSet(true);
        })
        .catch(() => {
          toast.error('Failed to change password.', {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <div className="edit-password-modal__content">
      {!passwordSet && <p className="text-center">Set a password so that your child can login on their own.</p>}
      {passwordSet && <p className="text-center">Edit or reset your child's password.</p>}
      {/* {passwordSet &&
        <>
          <p>Create a new password for your child.</p>
          <PasswordInput
            label="Current Password"
            password={currentPassword}
            setPassword={setCurrentPassword}
            passwordError={passwordError}
            setPasswordError={setPasswordError}
            className="password-input"
          />
        </>
      } */}
      <PasswordInput
        label={passwordSet ? 'New Password' : 'Child\'s Password'}
        password={password}
        setPassword={setPassword}
        passwordError={passwordError}
        setPasswordError={setPasswordError}
        className="password-input"
      />
      <div className="password-validator">
        <PasswordValidation
          minLength="8"
          minNumber="1"
          passwordValid={passwordValid}
          setPasswordValid={setPasswordValid}
          value={password}
        />
      </div>
      <div className="d-flex flex-row justify-content-between">
        <Button
          context="secondary"
          onClick={() => {
            handleClose();
          }}
          className="w-50 mr-1 mt-4"
        >
          Cancel
        </Button>
        <Button
          context="primary"
          onClick={() => {
            handleSave();
          }}
          className="w-50 ml-1 mt-4"
          disabled={!passwordValid}
        >
          Save Password
        </Button>
      </div>
    </div>
  );
};

export default ChildPasswordModal;
