import React from 'react';
import PasswordCard from 'components/Shared/PasswordCard';

const characterObjects = [
  {
    imgPath: '/img/passcode/alien.svg',
    name: 'Alien',
    pin: '1292',
    color: '#745BFF',
  },
  {
    imgPath: '/img/passcode/astronaut.svg',
    name: 'Astronaut',
    pin: '4448',
    color: '#FFA34D',
  },
  {
    imgPath: '/img/passcode/bat.svg',
    name: 'Bat',
    pin: '6891',
    color: '#3D7CF9',
  },
  {
    imgPath: '/img/passcode/cat.svg',
    name: 'Cat',
    pin: '7647',
    color: '#484C72',
  },
  {
    imgPath: '/img/passcode/cow.svg',
    name: 'Cow',
    pin: '9055',
    color: '#06CE94',
  },
  {
    imgPath: '/img/passcode/dog.svg',
    name: 'Dog',
    pin: '0654',
    color: '#F8BED5',
  },
  {
    imgPath: '/img/passcode/elephant.svg',
    name: 'Elephant',
    pin: '8143',
    color: '#FFC745',
  },
  {
    imgPath: '/img/passcode/fish.svg',
    name: 'Fish',
    pin: '1622',
    color: '#8AE3FA',
  },
  {
    imgPath: '/img/passcode/ghost.svg',
    name: 'Ghost',
    pin: '4922',
    color: '#484C72',
  },
  {
    imgPath: '/img/passcode/gingerbread-man.svg',
    name: 'Gingerbread Man',
    pin: '7829',
    color: '#745BFF',
  },
  {
    imgPath: '/img/passcode/octopus.svg',
    name: 'Octopus',
    pin: '9763',
    color: '#F8BED5',
  },
  {
    imgPath: '/img/passcode/platypus.svg',
    name: 'Platypus',
    pin: '8557',
    color: '#3D7CF9',
  },
];

const PicturePasswordForm: React.FC<any> = ({ handleCardClick, header, subtext }) => {

  return (
    <>
      <div className="org-login__password-header-container">
        <h2 className="org-login__password-header">{header}</h2>
      </div>
      { subtext !== '' &&
        <p className="org-login__subtext">
          {subtext}
        </p>
      }
      <div className="org-login__password-container">
        {
          characterObjects.map((object) => {
            return (<PasswordCard
              handleCardClick={handleCardClick}
              object={object}
              key={object.imgPath}
              color={object.color}
            />);
          })
        }
      </div>
    </>
  );
};
export default PicturePasswordForm;
