import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Row, Select } from '@codeverse/react-helios-ui';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/state';
import { UPDATE_USER_MODEL_CONFIG, CREATE_USER_ADDRESS_MODEL_CONFIG, UPDATE_USER_ADDRESS_MODEL_CONFIG, CREATE_USER_PHONE_NUMBER_MODEL_CONFIG, UPDATE_USER_PHONE_NUMBER_MODEL_CONFIG } from 'models/User';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { AppDispatch } from 'store';
import { getCookie, saveCookie } from 'store/auth';

const options = [
  { value: 'xs', label: 'XS' },
  { value: 's', label: 'S' },
  { value: 'm', label: 'M' },
  { value: 'l', label: 'L' },
  { value: 'xl', label: 'XL' },
  { value: 'xxl', label: 'XXL' },
];

type Props = {
  currentUser?: any;
  history?: any;
  location?: any;
  accessToken?: any;
  createPhoneNumber?: any;
  createUserAddress?: any;
  guide?: any;
  loadData?: any;
  orgId?: any;
  phoneNumbers?: any;
  primaryUser?: any;
  updatePhoneNumber?: any;
  updateUser?: any;
  updateUserAddress?: any;
  userAddresses?: any;
};

export const UserProfile: React.FC<Props> = ({
  currentUser,
  guide,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const accessToken = useSelector((state: RootState) => state.session.access_token.access_token);
  const userPhoneNumbers = useSelector((state: RootState) => state.user.userPhoneNumbers);
  const userAddresses = useSelector((state: RootState) => state.user.userAddresses);
  const [infoChanged, setInfoChanged] = useState(false);
  const [profilePicture, setProfilePicture] = useState(currentUser.photo_2x);
  const [tempProfilePicture, setTempProfilePicture] = useState(null);
  const [emailAddress, setEmailAddress] = useState(currentUser.email || '');
  const [fullName, setFullName] = useState(currentUser.name || '');
  const [tShirtSize, setTShirtSize] = useState(currentUser.t_shirt_size || '');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');

  const [userUpdated, setUserUpdated] = useState(false);
  const [phoneUpdated, setPhoneUpdated] = useState(false);
  const [addressUpdated, setAddressUpdated] = useState(false);

  const mainAddress = userAddresses[0];
  const mainPhoneNumber = userPhoneNumbers[0];

  useEffect(() => {
    if (userPhoneNumbers.length > 0) {
      setPhoneNumber(userPhoneNumbers[0].phone_number);
    }
  }, [userPhoneNumbers])

  useEffect(() => {
    if (userAddresses.length > 0) {
      setStreetAddress(userAddresses[0].address1);
      setStreetAddress2(userAddresses[0].address2);
      setCity(userAddresses[0].city);
      setState(userAddresses[0].state);
      setZipcode(userAddresses[0].zip_code);
    }
  }, [userAddresses])

  const handleSave = (e: any) => {
    const currentUserId = localStorage.getItem('currentUserId') || '';

    if (userUpdated) {
      dispatch(dataClientRequest({
        ...UPDATE_USER_MODEL_CONFIG,
        data: {
          name: fullName,
          email: emailAddress,
          id: currentUserId,
          t_shirt_size: tShirtSize,
          photo: tempProfilePicture,
        },
      }))
        .then((payload: any) => {
          if (payload.response) {
            const user = payload.response.data.data;
            const cookie = getCookie();
            if (cookie && cookie.access_token && cookie.user.id) {
              if (cookie.access_token.access_token) {
                cookie.user = user;
                saveCookie(cookie);
              }
            }
            toast.success('Profile successfully updated', {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch(() => {
          toast.error('Failed to update profile', {
            position: toast.POSITION.TOP_CENTER,
          });
        })
    }

    if (!mainPhoneNumber && phoneUpdated) {
      dispatch(dataClientRequest({
        ...CREATE_USER_PHONE_NUMBER_MODEL_CONFIG,
        data: {
          phone_number: phoneNumber,
          id: mainPhoneNumber.id
        },
      }))
        .then(() => {
          toast.success('Phone number successfully updated', {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch(() => {
          toast.error('Failed to update phone number', {
            position: toast.POSITION.TOP_CENTER,
          });
        })
    } else if (phoneUpdated) {
      dispatch(dataClientRequest({
        ...UPDATE_USER_PHONE_NUMBER_MODEL_CONFIG,
        data: {
          phone_number: phoneNumber,
          id: mainPhoneNumber.id
        },
      }))
        .then(() => {
          toast.success('Phone number successfully updated', {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch(() => {
          toast.error('Failed to update phone number', {
            position: toast.POSITION.TOP_CENTER,
          });
        })
    }

    if (!mainAddress && addressUpdated) {
      dispatch(dataClientRequest({
        ...CREATE_USER_ADDRESS_MODEL_CONFIG,
        data: {
          name: fullName,
          address1: streetAddress,
          address2: streetAddress2,
          city: city,
          state: state,
          zip_code: zipcode,
          country_code: 'US',
        },
      }))
        .then(() => {
          toast.success('Address successfully updated', {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch(() => {
          toast.error('Failed to update address', {
            position: toast.POSITION.TOP_CENTER,
          });
        })
    } else if (addressUpdated) {
      dispatch(dataClientRequest({
        ...UPDATE_USER_ADDRESS_MODEL_CONFIG,
        data: {
          id: mainAddress.id,
          name: fullName,
          address1: streetAddress,
          address2: streetAddress2,
          city: city,
          state: state,
          zip_code: zipcode,
          country_code: 'US',
        },
      }))
        .then(() => {
          toast.success('Address successfully updated', {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch(() => {
          toast.error('Failed to update address', {
            position: toast.POSITION.TOP_CENTER,
          });
        })
    }

    setUserUpdated(false);
    setPhoneUpdated(false);
    setAddressUpdated(false);
    setInfoChanged(false);
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const reformatAndSetProfilePicture = (base64Img: any) => {
    var img = new Image;
    img.src = base64Img;
    document.getElementById("fileElem").appendChild(img);
    const extra_canvas = document.createElement('canvas');
    extra_canvas.width = 800;
    extra_canvas.height = 800;
    const ctx = extra_canvas.getContext('2d');
    img.onload = () => {
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, 800, 800);
      const dataURL = extra_canvas.toDataURL();
      setTempProfilePicture(dataURL);
    }
  }

  let profilePhotoStyle = {
    backgroundImage: profilePicture && `url(${profilePicture})`,
  }

  return (
    <div className="parent-profile fadein-short pb-3 pb-md-0 mb-5">
      {!guide &&
        <h2 className="mt-4 mb-4 pb-2">Parent Profile</h2>
      }
      {guide &&
        <h2 className="mt-3 mb-4 pb-2">Guide Profile</h2>
      }
      <Card>
        <CardHeader>
          <Container>
            {
              guide &&
              <div className="photo-box">
                <div style={profilePhotoStyle} className="photo-box__photo">
                  {
                    !profilePicture &&
                    <i className="fas fa-user-astronaut"></i>
                  }
                </div>
                <div className="photo-box__text-box">
                  <div className="photo-box__header">
                    Profile Photo
                  </div>
                  <input className="visually-hidden" type="file" id="fileElem" accept=".jpeg,.gif,.png"
                    onChange={(e) => {
                      getBase64(e.target.files[0])
                        .then((response) => reformatAndSetProfilePicture(response));
                      e.target.files && setProfilePicture(window.URL.createObjectURL(e.target.files[0]));
                      setInfoChanged(true);
                      setUserUpdated(true);
                    }}
                  />
                  <label className="photo-box__link" htmlFor="fileElem">Upload Photo</label>
                </div>
              </div>
            }
            <Row className="parent-profile__container">
              <Col sm="12">
                <h4 className="parent-profile__subheader">
                  General Info
                </h4>
                <div className="mb-2 pb-1">
                  <Input
                    name="fullName"
                    label="Full Name"
                    onChange={(e: any) => { setFullName(e.target.value); setInfoChanged(true); setUserUpdated(true); }}
                    value={fullName}
                  // showError={userNameError}
                  />
                </div>
                <div className="mb-2 pb-1 parent-profile__email-input">
                  <Input
                    name="emailAddress"
                    label="Email Address"
                    // onChange={(e: any) => { setEmailAddress(e.target.value); setInfoChanged(true); setUserUpdated(true); }}
                    value={emailAddress}
                    disabled
                  // showError={userNameError}
                  />
                </div>
                <div className="mb-2 pb-1">
                  <Input
                    name="phoneNumber"
                    label="Phone Number"
                    onChange={(e: any) => { setPhoneNumber(e.target.value); setInfoChanged(true); setPhoneUpdated(true); }}
                    value={phoneNumber}
                  // showError={userNameError}
                  />
                </div>
                {
                  guide &&
                  <div className="mb-2 pb-1">
                    <Select
                      label="T-Shirt Size (Unisex)"
                      placeholder="Select a T-Shirt Size..."
                      options={options}
                      onChange={(option: any) => { setTShirtSize(option.value); setInfoChanged(true); setUserUpdated(true); }}
                      value={options.find(option => option.value === tShirtSize)}
                    // showError={userNameError}
                    />
                  </div>
                }
              </Col>

              <Col sm="12">
                <h4 className="parent-profile__subheader mt-4">
                  Mailing Address
                </h4>
                <div className="mb-2 pb-1">
                  <Input
                    name="streetAddress"
                    label="Street Address"
                    onChange={(e: any) => { setStreetAddress(e.target.value); setInfoChanged(true); setAddressUpdated(true); }}
                    value={streetAddress}
                  // showError={userNameError}
                  />
                </div>
                <div className="mb-2 pb-1">
                  <Input
                    name="streetAddress2"
                    label="Street Address 2"
                    onChange={(e: any) => { setStreetAddress2(e.target.value); setInfoChanged(true); setAddressUpdated(true); }}
                    value={streetAddress2}
                  // showError={userNameError}
                  />
                </div>
                <Row>
                  <Col sm="8">
                    <div className="mb-2 pb-1">
                      <Input
                        name="city"
                        label="City"
                        onChange={(e: any) => { setCity(e.target.value); setInfoChanged(true); setAddressUpdated(true); }}
                        value={city}
                      // showError={userNameError}
                      />
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="mb-2 pb-1">
                      <Input
                        name="state"
                        label="State"
                        onChange={(e: any) => { setState(e.target.value); setInfoChanged(true); setAddressUpdated(true); }}
                        value={state}
                      // showError={userNameError}
                      />
                    </div>
                  </Col>
                </Row>
                <Input
                  name="zipcode"
                  label="Zipcode"
                  onChange={(e: any) => { setZipcode(e.target.value); setInfoChanged(true); setAddressUpdated(true); }}
                  value={zipcode}
                // showError={userNameError}
                />
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody>
          <Button
            className="parent-profile__save-button"
            context="primary"
            disabled={!infoChanged}
            onClick={(e: any) => handleSave(e)}
            size="sm"
          >
            Save Changes
          </Button>
        </CardBody>
      </Card>
    </div >
  );
};
