import React from 'react';

type Props = {
  history?: any;
  location?: any;
  setCurrentStep?: any;
  text?: any;
  checked?: any;
  setChecked?: any;
};

const FormElement: React.FC<Props> = ({ checked, setChecked, text }) => {
  return (
    <div className="form-element">
      <input type="checkbox" onChange={() => setChecked(!checked)} checked={checked} />
      <div className="form-element__text">
        {text}
      </div>
    </div>
  );
}

export default FormElement;