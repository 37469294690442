import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import classNames from "classnames";
import { Button, Card } from "@codeverse/react-helios-ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/state";
import { AppDispatch } from "store";

type Props = {
  index?: number;
  sessionCount?: number;
  price?: number;
  goToStepHash: any;
  hash?: string;
};

const Feature: React.FC<any> = ({ children }) => {
  return (
    <div className="trial-feature">
      <div className="trial-feature__check">
        <i className="fas fa-check" />
      </div>
      <div className="trial-feature__text">{children}</div>
    </div>
  );
};

const determineSessionCount = (sessionCount: number) => {
  switch (sessionCount) {
    case 1:
      return "One";
    case 2:
      return "Two";
    case 4:
      return "Four";
    case 8:
      return "Eight";
  }
};

const PlanCard: React.FC<Props> = ({
  sessionCount,
  price,
  goToStepHash,
  hash,
}) => {
  const dispatch: AppDispatch = useDispatch();
  return (
    <Card className="plan-card">
      <div className="plan-card-header">
        <div>{sessionCount} classes</div>
        <h4>${price}/month</h4>
      </div>

      <div className={`trial-signup-large trial${sessionCount}`} />

      <div className="trial-features">
        <Feature>
          {determineSessionCount(sessionCount)} private 50-minute coding classes
          led by a dedicated Codeverse Guide
        </Feature>
        <Feature>Unlimited access to the Codeverse Studio</Feature>
        <Feature>
          Personalized notes from your child’s Guide after each session
        </Feature>
        <Feature>
          Track your child’s progress, book new classes, and reschedule classes
          any time.
        </Feature>
      </div>

      <div className="sub-button d-flex justify-content-center">
        <Button
          className="plan-card__btn w-100 my-3"
          context="primary"
          onClick={() => {
            goToStepHash(hash);
            switch (sessionCount) {
              case 1:
                return dispatch({
                  type: "UPDATE_SUBSCRIPTION_PLAN",
                  plan: "supernova_starter",
                });
              case 2:
                return dispatch({
                  type: "UPDATE_SUBSCRIPTION_PLAN",
                  plan: "supernova_lite",
                });
              case 4:
                return dispatch({
                  type: "UPDATE_SUBSCRIPTION_PLAN",
                  plan: "supernova",
                });
              case 8:
                return dispatch({
                  type: "UPDATE_SUBSCRIPTION_PLAN",
                  plan: "supernova_pro",
                });
            }
          }}
        >
          Select
        </Button>
      </div>
    </Card>
  );
};

export default PlanCard;
