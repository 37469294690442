import SatelliteClient from '@codeverse/satellite-client';

export default {
  getUserAddresses: (access_token: string, current_user_id: string) => {
    return SatelliteClient.platform.get(`/users/${current_user_id}/user_addresses`, {
      headers: Object.assign({},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' },
      ),
      query: {
        access_token,
        current_user_id,
      },
    });
  },
  getUserMemberships: (access_token: string, current_user_id: string) => {
    return SatelliteClient.platform.get(`/users/${current_user_id}/memberships`, {
      headers: Object.assign({},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' },
        { authorization: `Bearer ${access_token}` },
      ),
      query: {
        access_token,
        current_user_id,
      },
    });
  },
  getUserPhoneNumbers: (access_token: string, current_user_id: string) => {
    return SatelliteClient.platform.get(`/users/${current_user_id}/phone_numbers`, {
      headers: Object.assign({},
        { Accept: 'application/vnd.api+json' },
        { 'content-type': 'application/vnd.api+json' },
      ),
      query: {
        access_token,
        current_user_id,
      },
    });
  },
};
