import React, { useEffect, useState, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';

type Props = {
  history?: any;
  location?: any;
  match?: any;
  notRecurring?: any;
  nextWeekSlotInstance?: any;
  slotInstance: any;
  setCurrentStep?: any;
  setCurrentSlotInstance?: any;
};

const SAFE_NUMBER = 8;

const SlotInstance: React.FC<Props> = ({ notRecurring, setCurrentStep, nextWeekSlotInstance, slotInstance, setCurrentSlotInstance }) => {
  const [profileImageStyle, setProfileImageStyle] = useState(null);
  const dispatch: AppDispatch = useDispatch();
  const timezone = useSelector((state: RootState) => state.user.currentUser.time_zone);

  const slotInstancePreferredGuideObj = slotInstance.meta.preferred_guides.reduce(function(prev: any, current: any) {
    return (prev.position <= current.position) ? prev : current;
  }, SAFE_NUMBER);

  const slotInstanceMomentStartObj = useMemo(() => {
    return moment.tz(`${slotInstance.start_date}T${slotInstance.start_time}`, 'America/Chicago').tz(timezone)
  }, [slotInstance]);

  const slotInstanceMomentEndObj = useMemo(() => {
    return moment.tz(`${slotInstance.end_date}T${slotInstance.end_time}`, 'America/Chicago').tz(timezone)
  }, [slotInstance]);

  if (slotInstance.has_capacity && (notRecurring || !nextWeekSlotInstance || (nextWeekSlotInstance && nextWeekSlotInstance.has_capacity))) {
    return (
      <div onClick={() => {if (slotInstance.has_capacity) {setCurrentStep(3); setCurrentSlotInstance(slotInstance)}}} className="slot-instance">
        <div>{slotInstanceMomentStartObj.format('h:mm')}-{slotInstanceMomentEndObj.format('h:mm')} {slotInstanceMomentEndObj.format('A')}</div>
  
        <div className="slot-instance__action-container">
          {
            (slotInstancePreferredGuideObj && slotInstancePreferredGuideObj !== SAFE_NUMBER && slotInstancePreferredGuideObj.bookable) &&
            <div className="slot-instance__guide-name">
              {slotInstancePreferredGuideObj.name.split(' ')[0]}&nbsp;{(slotInstancePreferredGuideObj.name.split(' ')[slotInstancePreferredGuideObj.name.split(' ').length - 1]).charAt(0)}
            </div>
          }
          <div className="slot-instance__action">
            <i className="far fa-chevron-right" />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default SlotInstance;