import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/state';
import { Button } from '@codeverse/react-helios-ui'
interface InfoStepProps {
  setCurrentStep: (currentStep: number) => void,
  subscriptionAddOn: any
}

const InfoStep: React.FC<InfoStepProps> = ({ subscriptionAddOn, setCurrentStep }) => {
  const childUsers = useSelector((state: RootState) => state.user.childUsers);
  const determineText = () => {
    if (subscriptionAddOn.product !== 'supernova_lite') {
      return <>On the next step, you will select a time of the week that works best to hold as a re-occurring time for your weekly class. Don't worry though, you will be able to reschedule your classes easily in the Schedule tab.</>
    } else {
      return <>On the next step you will select a time for your first class. After your first class, you will be able to book your future classes from the Schedule tab!</>
    }
  }

  const childNames = useMemo(() => {
    if (childUsers.length === 1) {
      return `${childUsers[0].name.split(' ')[0]}'s`
    } else if (childUsers.length === 2) {
      return `${childUsers[1].name.split(' ')[0]} & ${childUsers[1].name.split(' ')[0]}'s`
    } else {
      return 'your kids`'
    }
  }, [childUsers])

  return (
    <div className="info-step">
      <h3 style={{ color: '#0e1540' }}>Let's schedule  {childNames} first 1:1 coding class</h3>
      <p className="mt-3">{determineText()}</p>

      <Button onClick={() => setCurrentStep(2)} className="mt-5 w-100">Next</Button>
    </div>
  );
};

export default InfoStep;