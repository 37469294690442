import React, { useEffect, useState, useMemo, useRef } from "react";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store";
import { RootState } from "store/state";

type Props = {
  key?: any;
  history?: any;
  location?: any;
  match?: any;
  slotInstance: any;
  nextStep?: any;
  setCurrentSlotInstance?: any;
  selectedSlotInstance?: any;
  setSelectedSlotInstance?: any;
  timezone: string;
};

const SlotInstance: React.FC<Props> = ({
  slotInstance,
  selectedSlotInstance,
  setSelectedSlotInstance,
  setCurrentSlotInstance,
  nextStep,
  timezone,
}) => {
  // const children = useSelector((state: RootState) => state.checkout.children);
  // const currentChildIndex = useSelector((state: RootState) => state.checkout.currentChildIndex);

  const slotInstanceMomentStartObj = useMemo(() => {
    return moment
      .tz(
        `${slotInstance.start_date}T${slotInstance.start_time}`,
        "America/Chicago"
      )
      .tz(timezone);
  }, [slotInstance]);

  const slotInstanceMomentEndObj = useMemo(() => {
    return moment
      .tz(
        `${slotInstance.end_date}T${slotInstance.end_time}`,
        "America/Chicago"
      )
      .tz(timezone);
  }, [slotInstance]);

  // const slotInstanceSelectedInCheckout = useMemo(() => {
  //   let foundSlotInstance = false;
  //   const foundSlotInstanceIndexes: any = [];
  //   children.map((child, index) => {
  //     if (child.slot_instance && child.slot_instance.id === slotInstance.id) {
  //       foundSlotInstance = true;
  //       foundSlotInstanceIndexes.push(index);
  //     }
  //   });
  //   return { foundSlotInstance, foundSlotInstanceIndexes };
  // }, [children]);

  const handleClick = (slot: any) => {
    if (slot.has_capacity) {
      setSelectedSlotInstance(slot);
      setCurrentSlotInstance(slot);
      nextStep();
    }
  };

  if (slotInstance.has_capacity) {
    return (
      <div
        onClick={() => handleClick(slotInstance)}
        className="slot-instance-selection"
      >
        <div className="slot-instance-selection__time">
          {slotInstanceMomentStartObj.format("h:mm")}-
          {slotInstanceMomentEndObj.format("h:mm")}{" "}
          {slotInstanceMomentEndObj.format("A")}{" "}
          {slotInstanceMomentEndObj.format("z")}
        </div>
        {selectedSlotInstance && selectedSlotInstance.id === slotInstance.id ? (
          <div className="slot-instance-selection__selected">
            Selected
            <i className="fas fa-chevron-right pl-2" />
          </div>
        ) : (
          <div className="slot-instance-selection__indicator">
            Select
            <i className="fas fa-chevron-right pl-2" />
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default SlotInstance;
