import cookie from "js-cookie";

export const cookieName = () => {
  return process.env.NODE_ENV === "production"
    ? "accessToken"
    : `accessToken${process.env.NODE_ENV}`;
};

export const cookieOptions = () => {
  let expires = null;
  const staySignedIn = localStorage.getItem("staySignedIn");
  if (staySignedIn === "true") {
    expires = 365;
  }
  return process.env.NODE_ENV !== "development"
    ? { path: "/", secure: true, domain: ".codeverse.com", expires }
    : { path: "/", expires };
};

export const getCookie = () => {
  const cookieData = cookie.get(cookieName());
  if (!cookieData) return {};
  const data = JSON.parse(cookieData);
  console.log("data", data);
  return data;
};

export const saveCookie = (payload: any) => {
  removeCookie();
  console.log("saving cookie", payload);
  return cookie.set(cookieName(), JSON.stringify(payload), cookieOptions());
};

export const removeCookie = () => {
  console.log("removing cookie");
  return cookie.remove(cookieName(), cookieOptions());
};
