import React, { useEffect, useState, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@codeverse/react-helios-ui';
import ReactTooltip from 'react-tooltip';

import { RootState } from 'store/state';
import { openModal } from 'store/modules/UI/actions';
import { AppDispatch } from 'store';

import { dataClientRequest } from '@codeverse/redux-data-client';
import { GET_USER_GUIDE_PREFERENCES, GET_OTHER_USER_MODEL_CONFIG } from 'models/User';
import { INFLUENCER_CLASS, INTERNAL, VIRTUAL_CLASS, VIRTUAL_TRIAL } from 'data/occasions';
import determineAvatarImage from 'utils/determineAvatarImage';

type Props = {
  history?: any;
  location?: any;
  match?: any;
  participation: any;
};

const capitalizeFirstLetter = (string: any) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const determineSessionName = (occasion: any) => {
  switch (occasion) {
    case VIRTUAL_TRIAL:
      return 'Virtual Trial';
    case VIRTUAL_CLASS:
      return 'Virtual Class';
    case INTERNAL:
      return 'Internal Session';
    case INFLUENCER_CLASS:
      return 'Influencer Class';
    default:
      return '';
  }
};

const determineAttendanceStatus = (status: string) => {
  switch (status) {
    case 'ended':
      return 'Attended';
    case 'children_no_show':
      return 'Missed';
  }
};

const determineIcon = (status: string) => {
  switch (status) {
    case 'ended':
      return <div className="attendance-badge__icon attendance-badge__icon--attended"><i className="fas fa-check" /></div>;
    case 'children_no_show':
      return <div className="attendance-badge__icon attendance-badge__icon--missed"><i className="fas fa-times" /></div>;
  }
};

const PastSessionCard: React.FC<Props> = ({ participation }) => {
  const [profileImageStyle, setProfileImageStyle] = useState(null);
  const dispatch: AppDispatch = useDispatch();
  const timezone = useSelector((state: RootState) => state.user.currentUser.time_zone);
  const participations = useSelector((state: RootState) => state.user.participations);
  const users = useSelector((state: RootState) => state.organizations.users);

  const weekdayName = useMemo(() => {
    return moment.tz(participation.meeting_start_at, 'America/Chicago').tz(timezone).format('dddd');
  }, [participation, timezone]);

  // const weekdayName = capitalizeFirstLetter(booking.weekday_name);
  const bookingDate = useMemo(() => {
    return moment.tz(participation.meeting_start_at, 'America/Chicago').tz(timezone).format('M/DD');
  }, [participation, timezone]);


  const timeZone = moment.tz(timezone).format('z');

  const sessionName = useMemo(() => {
    const user = users.find((user: any) => user.id === participation.user.id);
    const sessionName = `${user.name}'s ${determineSessionName(participation.occasion.id)}`;
    return sessionName;
  }, [users, participation]);

  const startTime = moment.tz(participation.meeting_start_at, 'America/Chicago').tz(timezone).format('h:mm A');
  const endTime = moment.tz(participation.meeting_end_at, 'America/Chicago').tz(timezone).tz(timezone).format('h:mm A');

  useEffect(() => {
    if (participation) {
      if (participation.user) {
        determineAvatarImage(participation.user, setProfileImageStyle);
      }
    }
  }, [participation]);

  return (
    <div className="card session-card parent-session-card">
      <div className="session-card__guide-info-box">
        <div style={profileImageStyle} className="session-card__avatar" />
        <div className="session-card__info-container">
          <div className="session-card__session-info">
            {weekdayName}, {bookingDate} <br />
            {startTime}&nbsp;-&nbsp;{endTime}&nbsp;{timeZone}
          </div>
          <div className="session-card__session-name">
            {sessionName}
          </div>
        </div>
      </div>
      <div className="session-card__preferred-guide-container">
        <div className="session-card__preferred-guide">
          <div style={{ backgroundImage: `url(${participation.meta.guide_photo})` }} className="session-card__preferred-guide--picture" />
          <div className="session-card__preferred-guide--info-container">
            <div className="session-card__preferred-guide--title">GUIDE</div>
            <div className="session-card__preferred-guide--name">{participation.meta.meeting_guide.split(' ')[0]}&nbsp;{(participation.meta.meeting_guide.split(' ')[participation.meta.meeting_guide.split(' ').length - 1]).charAt(0)}.</div>

          </div>
          <div className="session-card__button">
            <div className="attendance-badge">
              <div className="attendance-badge__icon-container">
                {determineIcon(participation.meeting_status)}
              </div>
              <div className="attendance-badge__status">
                {determineAttendanceStatus(participation.meeting_status)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastSessionCard;
