import React, { useEffect } from 'react';
import classNames from 'classnames';
import { PAYMENT_METHOD } from './Review';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/state';
import { AppDispatch } from 'store';
import axios from 'axios';
import braintree from 'braintree-web';
import BraintreeCCPayment from './BraintreeCCPayment';

type Props = {
  selectedPaymentMethod: PAYMENT_METHOD;
  authorizationToken: any;
  setAuthorizationToken: any;
  braintreeReady: any;
  setBraintreeReady: any;
  braintreeClientInstance: any;
  setBraintreeClientInstance: any;
  braintreePaypalInstance: any;
  setBraintreePaypalInstance: any;
  paypalPaymentDetails: any;
  setPaypalPaymentDetails: any;
  paypalPaymentNonce: any;
  setPaypalPaymentNonce: any;
  tokenRef: any;
  setTokenRef: any;
}

const Payment: React.FC<Props> = ({
  selectedPaymentMethod,
  authorizationToken,
  setAuthorizationToken,
  braintreeReady,
  setBraintreeReady,
  braintreeClientInstance,
  setBraintreeClientInstance,
  braintreePaypalInstance,
  setBraintreePaypalInstance,
  paypalPaymentDetails,
  setPaypalPaymentDetails,
  paypalPaymentNonce,
  setPaypalPaymentNonce,
  tokenRef,
  setTokenRef,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const creditCardClassnames = classNames({
    'd-none': selectedPaymentMethod !== 'credit card',
  });
  const paypalClassnames = classNames('paypal-information', {
    'd-none': selectedPaymentMethod !== 'paypal',
  });

  const tokenizePaypal = () => {
    braintreePaypalInstance.tokenize({
      flow: 'vault',
    }, (error: any, payload: any) => {
      if (error) {
      } else {
        console.log('paypal nonce: ', payload.nonce);
        setPaypalPaymentDetails(payload.details);
        setPaypalPaymentNonce(payload.nonce);
      }
    });
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_PLATFORM_URL}/homepage/braintree_token`,
      { headers: { Accept: 'application/json' } },
    )
      .then((response) => {
        setAuthorizationToken(response.data);
        braintree.client.create({ authorization: response.data }, (err: any, braintreeInstance: any) => {
          setBraintreeClientInstance(braintreeInstance);
          braintree.paypal.create({
            client: braintreeInstance,
          }, (error: any, paypalInstance: any) => {
            if (error) {
            } else {
              setBraintreePaypalInstance(paypalInstance);
              setBraintreeReady(true);
            }
          });
        });
      })
      .catch((error) => {
        console.log('Error fetching Braintree token', error);
      });
  }, []);

  return (
    <div className="card-body payment-information">
      <div className={creditCardClassnames}>
        <BraintreeCCPayment
          authorizationToken={authorizationToken}
          selectedPaymentMethod={selectedPaymentMethod}
          setTokenRef={setTokenRef}
        />
      </div>
      <div className={paypalClassnames}>
        {Object.keys(paypalPaymentDetails).length === 0
          ? <div className="paypal-button-container"><img onClick={tokenizePaypal} className="paypalButton" src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png" alt="Check out with PayPal" /></div>
          : <div className="paypal-buyer-information">
            <span className="paypal-icon"><i className="fab fa-cc-paypal fa-2x" /></span>
            <span className="paypal-email">{paypalPaymentDetails.email}</span>
            <span className="paypal-check"><i className="fas fa-check" /></span>
          </div>
        }
      </div>
    </div>
  )
}

export default Payment;