import React, { useEffect, useState, useMemo, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button, Row, Col } from '@codeverse/react-helios-ui';
import Modal from 'components/Shared/NewModal';
import { closeModal, openModal } from 'store/modules/UI/actions';
import transformHourInteger from 'utils/transformHourInteger';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';
import { GET_SLOT_INSTANCES } from 'models/SlotInstances';
import { VIRTUAL_CLASS } from 'data/occasions';
import SlotInstance from 'components/Parent/Schedule/RescheduleModal/SlotInstance';

type Props = {
  history?: any;
  location?: any;
  match?: any;
  setCurrentStep: any;
  setCurrentSlotInstance: any;
  currentChildId: string;
  subscriptionAddOn: any;
  currentBookings: any;
  setCurrentBookings: any;
};

const Step1: React.FC<Props> = ({ subscriptionAddOn, setCurrentStep, currentChildId, setCurrentSlotInstance, currentBookings, setCurrentBookings }) => {
  const dispatch: AppDispatch = useDispatch();
  const [currentDay, setCurrentDay] = useState(1);
  const subscriptions = useSelector((state: RootState) => state.organizations.subscriptions);
  const [dataFetched, setDataFetched] = useState(false);

  const currentDate = useMemo(() => {
    setDataFetched(false);
    return moment().add(currentDay, 'days');
  }, [currentDay]);

  const [slotInstances, setSlotInstances] = useState([]);
  const [nextWeekSlotInstances, setNextWeekSlotInstances] = useState(null);

  useEffect(() => {
    dispatch(dataClientRequest({
      ...GET_SLOT_INSTANCES,
      query: {
        current_user_id: currentChildId,
        filter: {
          occasion_id: VIRTUAL_CLASS,
          date: currentDate.format('YYYY-MM-DD'),
          include_my_guides: true,
        },
        page: 1,
        per_page: 100,
      }
    }))
      .then((payload: any) => {
        dispatch(dataClientRequest({
          ...GET_SLOT_INSTANCES,
          query: {
            filter: {
              occasion_id: VIRTUAL_CLASS,
              date: moment(currentDate).add(1, 'w').format('YYYY-MM-DD'),
            },
            page: 1,
            per_page: 100,
          }
        }))
          .then((payload2: any) => {
            setNextWeekSlotInstances(payload2.response.data.data);
            setDataFetched(true);
          })
        const slotInstancesResponse = payload.response.data.data;
        const filteredSlotInstances = slotInstances.filter((slotInstance: any) => moment.tz(`${slotInstance.start_date}T${slotInstance.start_time}`, 'America/Chicago').isSameOrAfter(moment.tz('America/Chicago')));
        setSlotInstances(slotInstancesResponse);
      });
  }, [currentDate]);

  const slotInstancesFiltered = useMemo(() => {
    const filteredSlotInstances = slotInstances.filter((slotInstance: any) => moment.tz(`${slotInstance.start_date}T${slotInstance.start_time}`, 'America/Chicago').isSameOrAfter(moment.tz('America/Chicago')));
    return filteredSlotInstances;
  }, [slotInstances]);

  const previousClassnames = classNames('prev', {
    disabled: currentDay === 1,
  });
  const nextClassnames = useMemo(() => {
    const activeSubscription = subscriptions.find((sub: any) => sub.status === 'active');
    let nextDayIsEndOfBillingCycle = false;
    if (activeSubscription) {
      const momentNextDay = moment().add(currentDay + 1, 'days');
      if (momentNextDay.format('YYYY-MM-DD') === activeSubscription.braintree_next_billing_date) {
        nextDayIsEndOfBillingCycle = true;
      }
    }
    return classNames('next', {
      disabled: nextDayIsEndOfBillingCycle,
    });
  }, [currentDay, subscriptions]);

  const handleSetCurrentSlotInstance = (slotInstance: any) => {
    setCurrentSlotInstance(slotInstance);
    setCurrentBookings(Object.assign({}, { ...currentBookings }, { [currentChildId]: slotInstance }))
    if (subscriptionAddOn.member_count === 1) {
      setCurrentStep(4);
    } else if (subscriptionAddOn.member_count !== (Object.keys(currentBookings).length) + 1) {
      setCurrentStep(2);
    } else {
      setCurrentStep(4);
    }
  }

  return (
    <div>
      <div className="slot-tabs">
        <div
          className={previousClassnames} onClick={() => setCurrentDay(currentDay - 1)}
        >
          <span className="slot-text left">Prev</span>
          <i className="far fa-chevron-left" />
        </div>
        <div className="current-month">
          <div className="current-month-title">
            {currentDate.format('ddd, MMMM D')}
          </div>
        </div>

        <div className={nextClassnames} onClick={() => setCurrentDay(currentDay + 1)}>
          <span className="slot-text right">Next</span>
          <i className="far fa-chevron-right" />
        </div>
      </div>
      {slotInstances &&
        <div className="slot-instances">
          {(slotInstances.length === 0) && <div className="w-100 d-flex justify-content-center">No Times Available</div>}
          {dataFetched && slotInstances && nextWeekSlotInstances && slotInstancesFiltered.map((slotInstance: any, index: any) => {
            return <SlotInstance key={slotInstance.id} notRecurring={false} setCurrentStep={setCurrentStep} slotInstance={slotInstance} nextWeekSlotInstance={nextWeekSlotInstances[index]} setCurrentSlotInstance={handleSetCurrentSlotInstance} />
          })}
        </div>
      }
    </div>
  );
}

export default Step1;

