import React from 'react';
import { Button } from '@codeverse/react-helios-ui';

type Props = {
  history?: any;
  location?: any;
};

const Step3b: React.FC<Props> = ({ history }) => {
  return (
    <div className="step3">
      <div className="header-icon-container">
        <div className="money-icon-white header-icon" />
      </div>
      <div className="statement">
        Thank you for completing this survey! Your <span className="highlight">$1 Lift</span> has been applied to your session payout.
      </div>
      <Button
        context="primary"
        className="w-100"
        onClick={() => {
          history.push('/guide/upcoming-sessions');
        }}
      >
        Done
      </Button>
    </div>
  );
}

export default Step3b;