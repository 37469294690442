import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Badge, Input, Select } from "@codeverse/react-helios-ui";
import { useHistory } from "react-router-dom";
import { dataClientRequest } from "@codeverse/redux-data-client";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { RootState } from "store/state";

import { addChild as addChildToChildUsers } from "store/modules/User/actions";
import { CREATE_USER } from "models/User";
import {
  resetCheckout,
  updateChildByIndex,
  setCurrentChildIndex,
  addChild as addCheckoutChild,
  deleteChildBooking,
} from "store/modules/Checkout/actions";

import { AppDispatch } from "store";

const calculateAge = (birthday: Date) => {
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

// flows
// create acc -> add child
// add child -> refresh -> load first child / cached user

const Booked: React.FC<any> = ({ goToStepHash }) => {
  const [childName, setChildName] = useState("");
  const [birthday, setBirthday] = useState("");
  const dispatch: AppDispatch = useDispatch();

  const children = useSelector((state: RootState) => state.checkout.children);
  const currentChildIndex = useSelector(
    (state: RootState) => state.checkout.currentChildIndex
  );
  const childUsers = useSelector((state: RootState) => state.user.childUsers);

  // useEffect(() => {
  //   dispatch(resetCheckout());
  // }, []);

  const createUser = () => {
    if (!childName || !birthday) {
      return toast.error("Please complete all the fields", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    const firstName = childName.split(" ")[0];

    if (childUsers.length > 1) {
      let foundDuplicateChild = false;
      const lowercaseChildName = childName.toLowerCase();
      childUsers.map((child) => {
        if (lowercaseChildName === child.name) {
          foundDuplicateChild = true;
        }
      });
      if (foundDuplicateChild) {
        toast.error(`${firstName} already has an account!`, {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
    }

    let DOB = birthday.replace(/ /g, "");
    const [month, day, year] = DOB.split("/");
    DOB = `${year}-${month}-${day}`;
    const age = calculateAge(new Date(DOB));
    const dateOfBirth = moment(birthday, "MM/DD/YYYY").format("YYYY-MM-DD");

    dispatch(
      dataClientRequest({
        ...CREATE_USER,
        data: {
          name: childName,
          date_of_birth: dateOfBirth,
          // difficulty_level: difficulty,
          scope: "child",
        },
      })
    ).then((payload: any) => {
      dispatch(setCurrentChildIndex(0));
      dispatch(
        updateChildByIndex(0, { user_id: payload.response.data.data.id })
      );
      dispatch(addChildToChildUsers(payload.response.data.data));
      goToStepHash("select-time");
    });
  };

  const history = useHistory();

  return (
    <div className="m-width-checkout booked">
      <h2 className="step-title">
        <span style={{ color: "#FDBA17" }}>Add Your</span> <br />
        <span style={{ color: "white" }}>Child</span>
      </h2>
      <div className="child-information-fields">
        <Input
          className="w-100"
          name="childName"
          label="Full Name"
          onChange={(e: any) => {
            setChildName(e.target.value);
          }}
          value={childName}
        />
        <Input
          className="w-100"
          name="childName"
          label="Birthday (MM/DD/YYYY)"
          onChange={(e: any) => {
            setBirthday(e.target.value);
          }}
          value={birthday}
          mask="99/99/9999"
        />
      </div>

      <div className="child-information-details">
        <div className="child-information-details__main">
          Want to add another child?
        </div>
        <div className="child-information-details__sub">
          You'll be able to do this in a later step.
        </div>
      </div>

      <div className="action mt-2">
        <Button
          className="w-100"
          context="tertiary"
          onClick={() => createUser()}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Booked;
