import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import CalendarDateCarousel from "components/Checkout/SelectTime/CalendarDateCarousel";
import { Card } from "@codeverse/react-helios-ui";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/state";

import SlotInstance from "components/NewCheckout/AddChild/SlotInstance";

type Props = {
  goToStepHash?: any;
  setChildEditMode: any;
  setButtonText: any;
  child: any;
  setSelectedSlotInstance?: any;
  selectedSlotInstance?: any;
  timezone: string;
};

const SelectTime: React.FC<Props> = ({
  goToStepHash,
  setChildEditMode,
  setButtonText,
  child,
  setSelectedSlotInstance,
  selectedSlotInstance,
  timezone,
}) => {
  const [slotInstances, setSlotInstances] = useState<any>([]);
  const currentChildIndex = useSelector(
    (state: RootState) => state.checkout.currentChildIndex
  );
  const checkoutChildren = useSelector(
    (state: RootState) => state.checkout.children
  );
  const dispatch = useDispatch();
  const [headerDateDisplay, setHeaderDateDisplay] = useState("");
  const SlotInstances = useMemo(() => {
    if (slotInstances.length > 0) {
      return slotInstances.map((slotInstance: any) => {
        if (
          moment
            .tz(
              `${slotInstance.start_date}T${slotInstance.start_time}`,
              "America/Chicago"
            )
            .isSameOrAfter(moment.tz("America/Chicago"))
        ) {
          return (
            <SlotInstance
              key={slotInstance.id}
              nextStep={() => {
                const accessToken = localStorage.getItem("accessToken");
                if (child.name.length === 0) {
                  toast.error("Please enter a name for your child", {
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                  return;
                }
                // if (accessToken) {
                  // goToStepHash("review");
                // }
                setChildEditMode(false);
                setButtonText("Next");
              }}
              slotInstance={slotInstance}
              setCurrentSlotInstance={(slotInstance: any) => {
                if (child.name.length === 0) {
                  return;
                }
                dispatch({
                  type: "UPDATE_CHILD_BY_INDEX",
                  index: currentChildIndex,
                  value: { slot_instance: slotInstance },
                });
              }}
              setSelectedSlotInstance={setSelectedSlotInstance}
              selectedSlotInstance={selectedSlotInstance}
              timezone={timezone}
            />
          );
        }
      });
    }
  }, [slotInstances, checkoutChildren, selectedSlotInstance]);

  return (
    <div className="m-width-checkout">
      <CalendarDateCarousel
        setSlotInstances={setSlotInstances}
        setHeaderDateDisplay={setHeaderDateDisplay}
      />

      <Card className="checkout-card">
        <div className="checkout-card__header">
          <div>{headerDateDisplay}</div>
        </div>

        {SlotInstances}
      </Card>
    </div>
  );
};

export default SelectTime;
