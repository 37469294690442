import React, { useEffect, useState, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from '@codeverse/react-helios-ui';
import classNames from 'classnames';

import Modal from 'components/Shared/NewModal';
import { closeModal, openModal } from 'store/modules/UI/actions';
import transformHourInteger from 'utils/transformHourInteger';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { GET_SLOT_INSTANCES } from 'models/SlotInstances';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';

import SlotInstance from 'components/Parent/Schedule/RescheduleModal/SlotInstance';
import { VIRTUAL_TRIAL } from 'data/occasions';

type Props = {
  history?: any;
  location?: any;
  match?: any;
  notRecurring?: any;
  onlyOneDay?: any;
  currentBooking: any;
  currentBookingInstance?: any;
  setCurrentStep: any;
  setCurrentSlotInstance?: any;
};

const Step2: React.FC<Props> = ({ notRecurring, onlyOneDay, setCurrentStep, currentBooking, currentBookingInstance, setCurrentSlotInstance }) => {
  const dispatch: AppDispatch = useDispatch();
  const [currentDay, setCurrentDay] = useState(1);
  const [dataFetched, setDataFetched] = useState(false);

  const currentDate = useMemo(() => {
    setDataFetched(false);
    if (!notRecurring) {
      if (!onlyOneDay) {
        return moment(currentBookingInstance.start_date, 'YYYY-MM-DD').add(currentDay, 'days');
      } else {
        return moment().add(currentDay, 'days');
      }
    } else {
      return moment().add(currentDay, 'days');
    }
  }, [currentDay]);

  const [slotInstances, setSlotInstances] = useState(null);
  const [nextWeekSlotInstances, setNextWeekSlotInstances] = useState(null);

  useEffect(() => {
    dispatch(dataClientRequest({
      ...GET_SLOT_INSTANCES,
      query: {
        current_user_id: currentBooking.user.id,
        filter: {
          occasion_id: currentBooking.occasion.id,
          date: currentDate.format('YYYY-MM-DD'),
          include_my_guides: true,
        },
        page: 1,
        per_page: 100,
      }
    }))
      .then((payload: any) => {
        dispatch(dataClientRequest({
          ...GET_SLOT_INSTANCES,
          query: {
            filter: {
              occasion_id: currentBooking.occasion.id,
              date: moment(currentDate).add(1, 'w').format('YYYY-MM-DD'),
            },
            page: 1,
            per_page: 100,
          }
        }))
          .then((payload2: any) => {
            setNextWeekSlotInstances(payload2.response.data.data);
            setDataFetched(true);
          })
        const slotInstances = payload.response.data.data;
        setSlotInstances(slotInstances);
      });
  }, [currentDate, currentBooking]);

  const previousClassnames = classNames('prev', {
    disabled: currentDay === 1,
  });
  const nextClassnames = classNames('next', {
    disabled: currentDay === 5 && currentBooking.occasion.id === VIRTUAL_TRIAL,
  });

  return (
    <div>
      <div className="slot-tabs">
        <div
          className={previousClassnames} onClick={() => setCurrentDay(currentDay - 1)}
        >
          <span className="slot-text left">Prev</span>
          <i className="far fa-chevron-left" />
        </div>
        <div className="current-month">
          <div className="current-month-title">
            {currentDate.format('ddd, MMMM D')}
          </div>
        </div>

        <div className={nextClassnames} onClick={() => setCurrentDay(currentDay + 1)}>
          <span className="slot-text right">Next</span>
          <i className="far fa-chevron-right" />
        </div>
      </div>
      {slotInstances &&
        <div className="slot-instances">
          {(slotInstances.length === 0) && <div className="w-100 d-flex justify-content-center">No Times Available</div>}
          {dataFetched && slotInstances && nextWeekSlotInstances && slotInstances.map((slotInstance: any, index: any) => {
            if (moment.tz(`${slotInstance.start_date}T${slotInstance.start_time}`, 'America/Chicago').isSameOrAfter(moment.tz('America/Chicago'))) {
              return <SlotInstance key={slotInstance.id} notRecurring={notRecurring} setCurrentStep={setCurrentStep} slotInstance={slotInstance} nextWeekSlotInstance={nextWeekSlotInstances[index]} setCurrentSlotInstance={setCurrentSlotInstance} />
            }
          })}
        </div>
      }
    </div>
  );
}

export default Step2;

