import React, { useState } from 'react';
import { Button, ButtonGroup } from '@codeverse/react-helios-ui';

type Props = {
  guideName?: any;
  guidePhoto?: any;
  handleGuidePreferenceUpdate?: any;
  history?: any;
  location?: any;
  childFirstName?: any;
  keepGuide?: any;
  makeGuideMostPreferred?: any;
  setCurrentStep?: any;
  setKeepGuide?: any;
  setMakeGuideMostPreferred?: any;
};

const Step1: React.FC<Props> = ({ guideName, guidePhoto, handleGuidePreferenceUpdate, childFirstName, keepGuide, makeGuideMostPreferred, setCurrentStep, setMakeGuideMostPreferred, setKeepGuide }) => {
  const guidePhotoStyle = {
    backgroundImage: guidePhoto && `url(${guidePhoto})`,
  }

  return (
    <div className="step1">
      <div style={guidePhotoStyle} className="step1__guide-picture">
        {
          !guidePhoto &&
          <i className="fas fa-user-astronaut"></i>
        }
      </div>
      <div className="question">Would your child like to work with {guideName} again?</div>
      <div className="step1__response-buttons">
        <ButtonGroup>
          <Button active={keepGuide} onClick={() => setKeepGuide(true)} context="secondary">Yes</Button>
          <Button active={keepGuide === false} onClick={() => setKeepGuide(false)} context="secondary">No</Button>
        </ButtonGroup>
      </div>
        {
          keepGuide === true &&
          <div className="step1__response-elaboration">
            <b>Yes - </b>We will try to pair {childFirstName} with {guideName} again in the future!
          </div>
        }
        {
          keepGuide === true &&
          <div className="step1__response-elaboration">
            <input type="checkbox" onChange={() => setMakeGuideMostPreferred(!makeGuideMostPreferred)} checked={makeGuideMostPreferred} ></input>
            Always pair with {guideName} if available
          </div>
        }
        {
          keepGuide === false &&
          <div className="step1__response-elaboration">
            <b>No - </b>Thanks for your feedback. We will not pair {childFirstName} with {guideName} in the future.
          </div>
        }
        <Button
          className="w-100 mt-4"
          context="primary"
          disabled={keepGuide === null}
          onClick={() => {
            if (keepGuide === true) {
              handleGuidePreferenceUpdate();
              setCurrentStep('1b')
            } else if (keepGuide === false) {
              handleGuidePreferenceUpdate();
              setCurrentStep('1c')
            }
          }}
        >
          Continue
        </Button>
    </div>
  );
}

export default Step1;