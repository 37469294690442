// @ts-nocheck
import SatelliteClient from "@codeverse/satellite-client";
import axios from "axios";

const EMAIL_REGEX = /[^@\s]+@([^@\s]+\.)+[^@\W]+/g;
import LogRocket from "logrocket";
import Cookies from "js-cookie";

const captureLead: any = ({
  name,
  email,
  phone,
  address1,
  address2,
  city,
  region,
  zip_code,
  first_journey,
  lead_journey_id,
  lead_existing_organization_journey_id,
  location_id,
  comments,
}) => {
  name = name && name.length < 2 ? undefined : name;
  phone = phone && phone.length < 2 ? undefined : phone;
  address1 = address1 && address1.length < 2 ? undefined : address1;
  address2 = address2 && address2.length < 2 ? undefined : address2;
  city = city && city.length < 2 ? undefined : city;
  region = region && region.length < 2 ? undefined : region;
  zip_code = zip_code && zip_code.length < 2 ? undefined : zip_code;
  email = email && email.length < 2 ? undefined : email;
  first_journey = "Opportunities::VirtualClassesLead";
  comments = comments && comments.length < 2 ? undefined : comments;
  // check if we have a referral code
  const referral_code = undefined;

  // always set to "other"
  // TODO: make this dynamic?
  const source_code = "other";
  // only submit if a valid email
  if (!email || email.length < 2 || !EMAIL_REGEX.test(email)) {
    return;
  }

  // create lead object
  const lead = {
    name,
    phone,
    email,
    address1,
    address2,
    city,
    region,
    source_code,
    referral_code,
    zip_code,
    first_journey,
    comments,
  };

  const sessionToken = Cookies.get("sessionToken");

  // IntercomAPI('update', lead);

  if (process.env.NODE_ENV !== "development") {
    if (LogRocket) {
      LogRocket.identify(sessionToken, lead);
    }
  }

  const relationships = Object.assign(
    {},
    {
      public_session: {
        data: {
          type: "public_sessions",
          id: sessionToken,
        },
      },
    }
  );

  if (process.env.NODE_ENV !== "development") {
    axios
      .post(
        `${process.env.REACT_APP_PLATFORM_URL}/leads`,
        {
          data: {
            type: "leads",
            attributes: lead,
            relationships,
          },
        },
        {
          headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
          },
        }
      )
      .catch((err: any) => {
        if (LogRocket) {
          LogRocket.captureMessage(err.response.data.errors[0].detail);
        }
      });
  } else {
    console.log("SessionTracker would have tracked lead", {
      headers: { Accept: "application/json" },
      data: {
        type: "leads",
        attributes: lead,
        relationships,
      },
    });
  }
};

export default captureLead;
