import { OrganizationsState, OrganizationsAction } from './types';
import { assign } from 'lodash';
import dataClientDefaultReducer from 'utils/dataClientDefaultReducer';

const initialState: OrganizationsState = {
  subscriptions: [],
  subscriptionAddOns: [],
  paymentMethods: [],
  memberships: [],
  users: [],
  organization: {},
  fetchedUsers: false,
};

export function OrganizationsReducer(state = initialState, action: OrganizationsAction): OrganizationsState {
  switch (action.type) {
    case 'GET_PAYMENT_METHOD_SUCCESS':
      return { ...state, paymentMethods: [...state.paymentMethods, action.payload] }
    case 'GET_ORGANIZATION_MEMBERSHIPS_SUCCESS':
      const existingMemberships = state.memberships.filter((membership: any) => {
        return membership.organization.id === action.organizationId;
      });
      if (existingMemberships.length === 0) {
        return { ...state, memberships: [...state.memberships, ...action.payload] }
      } else {
        return { ...state }
      }
    case 'GET_ORGANIZATION_SUBSCRIPTIONS_SUCCESS':
      const existingSubscriptions = state.subscriptions.filter((subscription: any) => {
        return subscription.organization.id === action.organizationId;
      });
      if (existingSubscriptions.length === 0) {
        return { ...state, subscriptions: [...state.subscriptions, ...action.payload] }
      } else {
        return { ...state }
      }
    case '@@data-client/PUT_SUBSCRIPTIONS_SUCCESS':
      let existingSubscriptionIndex = 0;
      state.subscriptions.map((subscription: any, index: any) => {
        if (subscription.id === action.response.data.data.id) {
          existingSubscriptionIndex = index;
        }
      })
      let newSubscriptions = [...state.subscriptions];
      newSubscriptions.splice(existingSubscriptionIndex, 1, action.response.data.data);
      return Object.assign({}, state, { subscriptions: newSubscriptions });
    case 'GET_USER_SUCCESS':
      return { ...state, users: [...state.users, action.payload] }
    case 'GET_USER_DATA_REQUEST':
      return { ...state, fetchedUsers: false }
    case 'GET_USER_DATA_SUCCESS':
      return { ...state, fetchedUsers: true }
    case 'SET_ORGANIZATION':
      return { ...state, organization: action.organization }
    default:
      return dataClientDefaultReducer(state, action, 'organizations');
  }
}