import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import SatelliteClient from "@codeverse/satellite-client";
import { useDispatch } from "react-redux";

import SchoolsLayout from "components/Shared/LoginLayout";
import Step1 from "components/schools/Create/Step1";
import Step2 from "components/schools/Create/Step2";
import PasswordConfirmation from "components/schools/Create/PasswordConfirmation";

declare var heap: any;

const Create: React.FC<any> = ({
  currentStep,
  currentOrganizationId,
  fullName,
  lastStep,
  passcode,
  schools,
  setUserName,
  setFullName,
  setSchools,
  setCurrentOrganizationId,
  userName,
  setCurrentStep,
  setLastStep,
  setPasscode,
  history,
}) => {
  const dispatch = useDispatch();
  const handleSubmit = (
    organizationId: any,
    username: any,
    passcodeValue: any,
    fullname: any
  ) => {
    return SatelliteClient.platform
      .post("/sessions", {
        headers: Object.assign(
          {},
          { Accept: "application/vnd.api+json" },
          { "content-type": "application/vnd.api+json" }
        ),
        data: {
          badge: username.toLowerCase(),
          pin: passcodeValue,
          name: fullname,
          relationships: {
            organization: { type: "organizations", id: organizationId },
          },
        },
        serializer: "sessions",
      })
      .then((response: any) => {
        const { access_token, user } = response.data.data;
        const userProperties = Object.assign({
          Name: user.name,
          User_Scope: user.scope,
        });
        // @ts-ignore
        if (window.heap) {
          // @ts-ignore
          window.heap.resetIdentity();
          // @ts-ignore
          window.heap.identify(user.referral_code);
          // @ts-ignore
          window.heap.addUserProperties(userProperties);
        }
        window.location.replace(
          `${process.env.REACT_APP_ESCAPE_POD_URL}/home?access_token=${access_token.access_token}&current_user_id=${user.id}&current_organization_id=${currentOrganizationId}&school=true&organization_classification=school&organization_status=freemium`
        );
      });
  };

  useEffect(() => {
    localStorage.removeItem("currentOrganizationId");
    localStorage.removeItem("currentUserId");
    localStorage.removeItem("accessToken");
  }, []);
  return (
    <SchoolsLayout>
      <div className="org-login__form-container">
        <AnimatePresence>
          {currentStep === 1 && (
            <motion.div
              className="org-login__information-form-container"
              key={1}
              initial={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -1000, opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <Step1
                currentStep={currentStep}
                currentOrganizationId={currentOrganizationId}
                fullName={fullName}
                schools={schools}
                setUserName={setUserName}
                setFullName={setFullName}
                setSchools={setSchools}
                setCurrentOrganizationId={setCurrentOrganizationId}
                userName={userName}
                setCurrentStep={setCurrentStep}
                setLastStep={setLastStep}
                history={history}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {currentStep === 2 && (
            <motion.div
              className="org-login__password-form"
              key={2}
              initial={{ x: 1000, opacity: 0 }}
              animate={{ x: 0, opacity: 1, position: "absolute" }}
              exit={{ x: lastStep < currentStep ? 1000 : -1000, opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <Step2
                currentOrganizationId={currentOrganizationId}
                fullName={fullName}
                schools={schools}
                setUserName={setUserName}
                setFullName={setFullName}
                setSchools={setSchools}
                setCurrentOrganizationId={setCurrentOrganizationId}
                userName={userName}
                setPasscode={setPasscode}
                history={history}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <PasswordConfirmation
        onSignIn={() =>
          handleSubmit(currentOrganizationId, userName, passcode, fullName)
        }
      />
      {currentStep === 1 && (
        <div className="org-login__footer d-flex justify-content-center">
          Have a school account?&nbsp;
          <span
            onClick={() => history.push("/schools/sign-in")}
            className="org-login__footer--link"
          >
            Sign In
          </span>
        </div>
      )}
    </SchoolsLayout>
  );
};

export default Create;
