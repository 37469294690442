import React, { useState, useEffect } from 'react';
import { Button, Input } from '@codeverse/react-helios-ui';
import { Select } from '@codeverse/helios-solarwind';
import { toast } from 'react-toastify';

import { closeModal } from 'store/modules/UI/actions';
import { dataClientRequest } from 'store/data-client';
import { UPDATE_USER_MODEL_CONFIG } from 'models/User';

import generateArrayOfYears from 'utils/generateArrayOfYears';

const yearOptions = generateArrayOfYears(99, 5)

const defaultFormData = {
  birthMonthSelect: {
    options: [
      { value: null, label: 'MM' },
      { value: 1, label: 'Jan' },
      { value: 2, label: 'Feb' },
      { value: 3, label: 'Mar' },
      { value: 4, label: 'Apr' },
      { value: 5, label: 'May' },
      { value: 6, label: 'Jun' },
      { value: 7, label: 'Jul' },
      { value: 8, label: 'Aug' },
      { value: 9, label: 'Sep' },
      { value: 10, label: 'Oct' },
      { value: 11, label: 'Nov' },
      { value: 12, label: 'Dec' },
    ],
    label: 'Month',
  },
  birthDateSelect: {
    options: [
      { value: null, label: 'DD' },
      { value: 1, label: '1st' },
      { value: 2, label: '2nd' },
      { value: 3, label: '3rd' },
      { value: 4, label: '4th' },
      { value: 5, label: '5th' },
      { value: 6, label: '6th' },
      { value: 7, label: '7th' },
      { value: 8, label: '8th' },
      { value: 9, label: '9th' },
      { value: 10, label: '10th' },
      { value: 11, label: '11th' },
      { value: 12, label: '12th' },
      { value: 13, label: '13th' },
      { value: 14, label: '14th' },
      { value: 15, label: '15th' },
      { value: 16, label: '16th' },
      { value: 17, label: '17th' },
      { value: 18, label: '18th' },
      { value: 19, label: '19th' },
      { value: 20, label: '20th' },
      { value: 21, label: '21th' },
      { value: 22, label: '22th' },
      { value: 23, label: '23th' },
      { value: 24, label: '24th' },
      { value: 25, label: '25th' },
      { value: 26, label: '26th' },
      { value: 27, label: '27th' },
      { value: 28, label: '28th' },
      { value: 29, label: '29th' },
      { value: 30, label: '30th' },
      { value: 31, label: '31st' },
    ],
    label: 'Day',
  },
  birthYearSelect: {
    options: yearOptions,
    label: 'Year',
  },
};

type Props = {
  dispatch: any;
  childUser: any;
  childFullName: string;
  setChildFullName: any;
  childBirthDate: string;
  setChildBirthDate: any;
};

const ChildInfoModal: React.FC<Props> = ({ 
  childUser,
  dispatch,
  childFullName,
  setChildFullName,
  childBirthDate,
  setChildBirthDate,
}) => {
  const [fullNameChanged, setFullNameChanged] = useState(false);
  const [newFullName, setNewFullname] = useState(childFullName);
  // const [userNameChanged, setUserNameChanged] = useState(false);
  const [birthDateChanged, setBirthDateChanged] = useState(false);
  const [newBirthDate, setNewBirthDate] = useState(childBirthDate);
  const [dobValid, setDobValid] = useState(false);
  const splitDOB = childBirthDate ? childBirthDate.split('/') : null;
  const [birthMonth, setBirthMonth] = useState(splitDOB ? splitDOB[0] : null);
  const [birthDate, setBirthDate] = useState(splitDOB ? splitDOB[1] : null);
  const [birthYear, setBirthYear] = useState(splitDOB ? splitDOB[2] : null);
  const [selectedBirthYear, setSelectedBirthYear] = useState({ value: birthYear, label: birthYear });
  const birthMonthSelect = defaultFormData.birthMonthSelect;
  const birthDateSelect = defaultFormData.birthDateSelect;
  const birthYearSelect = defaultFormData.birthYearSelect;

  useEffect(() => {
    if (dobValid) {
      setNewBirthDate(`${birthYear}-${birthMonth}-${birthDate}`);
    }
  }, [birthDate, birthMonth, birthYear, dobValid]);

  useEffect(() => {
    const birthDateValid = birthDate != null && birthDate !== 'DD';
    const birthMonthValid = birthMonth != null && birthMonth !== 'MM';
    const birthYearValid = birthYear != null && birthYear !== 'YYYY';
    setDobValid(birthDateValid && birthMonthValid && birthYearValid);
  }, [birthDate, birthMonth, birthYear]);

  const handleClose = () => {
    dispatch(closeModal('editChildInfo'));
  };

  const handleSave = () => {
    if (childFullName.length < 3) {
      toast.error('Full name must be at least 3 characters', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (birthDateChanged && !dobValid) {
      toast.error('Choose a valid birth date', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if ((fullNameChanged && childFullName.length >= 3) || (birthDateChanged && dobValid)) {
      const data = {
        id: childUser.id,
        name: newFullName,
        date_of_birth: newBirthDate,
      };
      if (!fullNameChanged) { delete data.name; }
      if (!birthDateChanged) { delete data.date_of_birth; }

      dispatch(dataClientRequest({
        ...UPDATE_USER_MODEL_CONFIG,
        data,
      }))
        .then((response: any) => {
          toast.success('Child info change successful.', {
            position: toast.POSITION.TOP_CENTER,
          });
          if (fullNameChanged) {
            setChildFullName(newFullName);
          }
          if (birthDateChanged) {
            setChildBirthDate(`${birthMonth}/${birthDate}/${birthYear}`);
          }
          handleClose();
        })
        .catch((error: any) => {
          toast.error('Failed to change child info.', {
            position: toast.POSITION.TOP_CENTER,
          });
          console.error(error);
        });
    }
  };

  return (
    <div className="edit-password-modal__content">
      <div className="mb-2 pb-1">
        <Input
          name="fullName"
          label="Full Name"
          onChange={(e: any) => { setNewFullname(e.target.value); setFullNameChanged(true); }}
          value={newFullName}
        />
      </div>
      {/* <div className="mb-2 pb-1 disabled">
        <Input
          name="username"
          label="Username"
          className="disabled"
          onChange={(e: any) => { setUserName(e.target.value); setUserNameChanged(true); }}
          value={userName}
          disabled
          readonly
          style={{ color: '#8d98c6', background: '#cbd6eb' }}
        />
      </div> */}

      <div className="gap-8 grid grid-cols-3 z-100">
        <Select
          label={birthMonthSelect.label}
          options={birthMonthSelect.options}
          defaultVal={birthMonth ? birthMonthSelect.options[parseInt(birthMonth, 10)] : birthMonthSelect.options[0]}
          dropdownClassname="bottom-0 overflow-scroll rounded-t-8"
          onChange={(selected: any) => {
            if (selected.value !== null && parseInt(selected.value, 10) !== parseInt(birthMonth, 10)) {
              setBirthMonth(selected.value);
              setBirthDateChanged(true);
            }
          }}
          value={birthMonth}
        />
        <Select
          label={birthDateSelect.label}
          options={birthDateSelect.options}
          defaultVal={birthDate ? birthDateSelect.options[parseInt(birthDate, 10)] : birthDateSelect.options[0]}
          dropdownClassname="bottom-0 overflow-scroll rounded-t-8"
          onChange={(selected: any) => {
            if (selected.value !== null && parseInt(selected.value, 10) !== parseInt(birthDate, 10)) {
              setBirthDate(selected.value);
              setBirthDateChanged(true);
            }
          }}
          value={birthDate}
        />
        <Select
          label={birthYearSelect.label}
          options={birthYearSelect.options}
          defaultVal={birthYear ? selectedBirthYear : birthYearSelect.options[0]}
          dropdownClassname="bottom-0 overflow-scroll rounded-t-8"
          onChange={(selected: any) => {
            if (selected.value !== null && parseInt(selected.value, 10) !== parseInt(birthYear, 10)) {
              setBirthYear(selected.value);
              setBirthDateChanged(true);
            }
          }}
          value={birthYear}
        />
      </div>

      <div className="d-flex flex-row justify-content-between">
        <Button
          context="secondary"
          onClick={() => {
            handleClose();
          }}
          className="w-50 mr-1 mt-4"
        >
          Cancel
        </Button>
        <Button
          context="primary"
          onClick={() => {
            handleSave();
          }}
          disabled={!fullNameChanged && !birthDateChanged}
          className="w-50 ml-1 mt-4"
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default ChildInfoModal;
