import React, { useCallback, useState } from 'react';
import { faCode, faGamepad, faUserAstronaut } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

import ClassHighlight from './ClassHighlight';

interface ClassIncludesProps {
  className?: string
  style?: any
  defaultState?: 'open' | 'close'
}

const ClassIncludes: React.FC<ClassIncludesProps> = ({
  className,
  style,
  defaultState = 'open',
}) => {
  const [containerHeight, setContainerHeight] = useState(defaultState);

  const ref= React.useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    if (containerHeight === 'open') {
      setContainerHeight('close');
    } else {
      setContainerHeight('open');
      console.log('ref', ref.current)
      ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  },[ref, containerHeight])

  return (
    <div className={className} style={{ width: '100%', ...style }}>
      <div onClick={handleClick} style={{ width: '100%', display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
        <div style={{ userSelect: 'none' }}>Your child’s free class includes:</div>
        <div className="ml-auto">
          {containerHeight === 'open' ? <FontAwesomeIcon
            style={{ cursor: 'pointer' }} icon={faChevronDown} color="#0e1540"
          /> : <FontAwesomeIcon
            onClick={() => {
            }}
            style={{ cursor: 'pointer' }} icon={faChevronUp} color="#0e1540"
          />}
        </div>
      </div>

      <div style={{height: containerHeight !== 'open' ? '0' : 'auto', display: 'block', overflow: 'hidden', transition: 'height 250ms linear 0s', }}>
        <ClassHighlight
          icon={<FontAwesomeIcon
            style={{
              //@ts-ignore
              '--fa-primary-color': '#FFA34E',
              '--fa-secondary-color': '#FFA34E',
              '--fa-secondary-opacity': '.5',
              fontSize: '32px',
            }}
            size="xs"
            icon={faCode}
          />}
          header="Intro to coding"
          description="50 minutes of 1-on-1 fun, hosted directly in our award-winning Codeverse Studio Platform"
        />
        <ClassHighlight
          icon={<FontAwesomeIcon
            style={{
              //@ts-ignore
              '--fa-primary-color': '#3e7cf9',
              '--fa-secondary-color': '#3e7cf9',
              '--fa-secondary-opacity': '.5',
              fontSize: '32px',
            }}
            size="xs"
            icon={faUserAstronaut}
          />}
          header="Kid-focused experience"
          description="An engaging & inspiring coding Guide that your kid will love"
        />
        <ClassHighlight
          icon={<FontAwesomeIcon
            style={{
              //@ts-ignore
              '--fa-primary-color': '#02be89',
              '--fa-secondary-color': '#02be89',
              '--fa-secondary-opacity': '.5',
              fontSize: '32px',
            }}
            size="xs"
            icon={faGamepad}
          />}
          header="Share-worthy creations"
          description="Help building your first video game with real coding tools designed just for kids"
        />
        <div ref={ref} style={{ height: '2px', width: '100%' }} />
      </div>
    </div>
  );
};

export default ClassIncludes;