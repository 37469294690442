import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from '@codeverse/react-helios-ui';
import Editor from '@codeverse/editor';
import { RootState } from 'store/state';

type Props = {
  audioLevel: number;
  audioEnabled: boolean;
};

const AudioMeter: React.FC<Props> = ({ audioLevel, audioEnabled }) => {
  if (!audioEnabled) {
    return (
      <div className="audio-meter">
        <div className={`audio-level disabled`} />
        <div className={`audio-level disabled`} />
        <div className={`audio-level disabled`} />
        <div className={`audio-level disabled`} />
        <div className={`audio-level disabled`} />
      </div>
    )
  }

  return (
    <div className="audio-meter">
      <div className={`audio-level ${audioLevel > 1 ? '' : 'disabled'}`} />
      <div className={`audio-level ${audioLevel > 4 ? '' : 'disabled'}`} />
      <div className={`audio-level ${audioLevel > 6 ? '' : 'disabled'}`} />
      <div className={`audio-level ${audioLevel > 8 ? '' : 'disabled'}`} />
      <div className={`audio-level ${audioLevel === 10 ? '' : 'disabled'}`} />
    </div>
  );
};

export default AudioMeter;
