import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from '@codeverse/react-helios-ui';
import { toast } from 'react-toastify';

import { closeModal, openModal } from 'store/modules/UI/actions';
import Modal from 'components/Shared/NewModal';
import EditAvailabilityModal from 'components/Guide/Schedule/EditAvailabilityModal';
import {
  VIRTUAL_TRIAL,
  VIRTUAL_CLASS,
  VIRTUAL_CAMP,
} from 'data/occasions';

import { CREATE_GUIDE_AVAILABILITY, GET_GUIDE_AVAILABILITY, UPDATE_GUIDE_AVAILABILITY, GET_USERS_PARTICIPATIONS, GET_OTHER_USER_MODEL_CONFIG, GET_GUIDE_SCHEDULABLES } from 'models/User';
import { GET_GUIDE_AVAILABILITY_INSTANCES } from 'models/GuideAvailability';
import { dataClientRequest } from '@codeverse/redux-data-client';

import { AppDispatch } from 'store';
import { RootState } from 'store/state';

import { MeetingCustomRecord } from 'containers/Guide/UpcomingSessions';
import Calendar, { EVENT } from 'components/Guide/Schedule/Calendar';
import dayIntegerToString from 'utils/dayIntegerToString';
import CreateAvailabilityModal from './CreateAvailabilityModal';

const options = [
  { value: VIRTUAL_CLASS, label: 'Virtual Class' },
  { value: VIRTUAL_TRIAL, label: 'Virtual Trial' },
  { value: VIRTUAL_CAMP, label: 'Virtual Camp' },
];


type DELETE_WARNING_LEVEL = 0 | 1;
type DELETE_TYPES = 'onlyThisDate' | 'future';

type Props = {
  history?: any;
  location?: any;
  occasion: any;
  currentEvent: any;
};

const CalendarModalActions: React.FC<Props> = ({ currentEvent, occasion }) => {
  const dispatch: AppDispatch = useDispatch();

  const guide_availabilities = useSelector((state: RootState) => state.user.guide_availabilities);
  const editGuideAvailability = useSelector((state: RootState) => state.ui.editGuideAvailability);
  const createAvailability = useSelector((state: RootState) => state.ui.createAvailability);
  const editParticipationSlot = useSelector((state: RootState) => state.ui.editParticipationSlot);
  const showDeleteWarning = useSelector((state: RootState) => state.ui.showDeleteWarning);
  const timezone = useSelector((state: RootState) => state.user.currentUser.time_zone);

  const [acceptingCodeverse, setAcceptingCodeverse] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [deleteWarningLevel, setDeleteWarningLevel] = useState<DELETE_WARNING_LEVEL>(0);
  const [deleteType, setDeleteType] = useState<DELETE_TYPES>('onlyThisDate');

  useEffect(() => {
    if (currentEvent.guideAvailability) {
      // Populate checkfield box
      setAcceptingCodeverse(currentEvent.guideAvailability.accept_codeverse_customers);
    }
  }, [currentEvent]);

  const getGuideAvailabilityInstances = () => setTimeout(() => dispatch(dataClientRequest({
    ...GET_GUIDE_AVAILABILITY_INSTANCES,
    query: {
      filter: {
        user_id: localStorage.getItem('currentUserId'),
        after: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      },
    },
  })), 1000);

  const handleOnlyThisDate = () => {
    const currentGuideAvailability = guide_availabilities.find((ga: any) => ga.id === currentEvent.guideAvailabilityId);
    if (!currentGuideAvailability) return;
    return dispatch(dataClientRequest({
      ...UPDATE_GUIDE_AVAILABILITY,
      data: {
        id: currentEvent.guideAvailabilityId,
        excluded_dates: [...currentGuideAvailability.excluded_dates, moment(currentEvent.start).tz(timezone).format('YYYY-MM-DD')],
        relationships: {
          occasion: { type: 'occasions', id: occasion },
        },
      },
    }))
      .then(() => {
        getGuideAvailabilityInstances();
        dispatch(closeModal('editGuideAvailability'));
        dispatch(closeModal('editParticipationSlot'));
      })
      .catch(() => {
        toast.error('Error deleting slot', {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const handleDeleteFuture = () => {
    const currentGuideAvailability = guide_availabilities.find((ga: any) => ga.id === currentEvent.guideAvailabilityId);
    if (!currentGuideAvailability) return;
    return dispatch(dataClientRequest({
      ...UPDATE_GUIDE_AVAILABILITY,
      data: {
        id: currentEvent.guideAvailabilityId,
        excluded_dates: [...currentGuideAvailability.excluded_dates, moment(currentEvent.start).tz(timezone).format('YYYY-MM-DD')],
        last_available_at: moment(currentEvent.start).format('YYYY-MM-DD'),
        relationships: {
          occasion: { type: 'occasions', id: occasion },
        },
      },
    }))
      .then(() => {
        getGuideAvailabilityInstances();
        dispatch(closeModal('editGuideAvailability'));
        dispatch(closeModal('editParticipationSlot'));
        dispatch(closeModal('showDeleteWarning'));
      })
      .catch(() => {
        toast.error('Error deleting slot', {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleUpdateGuideAvailability = () => {
    return dispatch(dataClientRequest({
      ...UPDATE_GUIDE_AVAILABILITY,
      data: {
        id: currentEvent.guideAvailabilityId,
        accept_codeverse_customers: acceptingCodeverse,
        relationships: {
          occasion: { type: 'occasions', id: occasion },
        },
      },
    }))
      .then(() => {
        getGuideAvailabilityInstances();
        dispatch(closeModal('editGuideAvailability'));
        dispatch(closeModal('showDeleteWarning'));
      })
      .catch(() => {
        toast.error('Error editing availability', {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const addAvailability = (eventData: any, recurring: boolean = false) => {
    const startMoment = moment(eventData.start).tz('America/Chicago');
    const startMomentFormatted = startMoment.format('YYYY-MM-DD');
    const endMoment = moment(eventData.end).tz('America/Chicago');
    const foundExcludedDate = guide_availabilities.find((availability: any) => {
      return availability.excluded_dates.includes(startMomentFormatted) && `${availability.start_hour}` === startMoment.format('HH');
    });

    // if we found an excluded
    if (foundExcludedDate) {
      const dateExcludedPosition = foundExcludedDate.excluded_dates.findIndex((excludedDate: string) => excludedDate === startMomentFormatted);
      const excludedDates = foundExcludedDate.excluded_dates.splice(1, dateExcludedPosition);
      const today = moment();

      dispatch(dataClientRequest({
        ...UPDATE_GUIDE_AVAILABILITY,
        data: {
          id: foundExcludedDate.id,
          excluded_dates: excludedDates,
          last_available_at: recurring ? null : startMomentFormatted,
          relationships: {
            occasion: { type: 'occasions', id: occasion },
          },
        },
      }))
        .then(() => {
          dispatch(closeModal('createAvailability'));
          getGuideAvailabilityInstances();
        })
        .catch((object: any) => {
          const error = object.response.data.errors[0].detail;
          toast.error(object.response.data.errors[0].detail.includes('overlapping') ? 'Cannot add slot due to existing availability for this day and time. Please check future weeks and delete before adding' : error, {
            id: 'CREATE_GUIDE_AVAILABILITY_ERROR',
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    } else {
      dispatch(dataClientRequest({
        ...CREATE_GUIDE_AVAILABILITY,
        data: {
          first_available_at: moment(eventData.start).format('YYYY-MM-DD'),
          last_available_at: recurring ? null : moment(eventData.start).format('YYYY-MM-DD'),
          accept_codeverse_customers: true,
          disabled: false,
          end_hour: endMoment.format('HH'),
          end_minute: endMoment.format('mm'),
          start_hour: startMoment.format('HH'),
          start_minute: startMoment.format('mm'),
          time_zone: 'America/Chicago',
          relationships: {
            occasion: { type: 'occasions', id: occasion },
          },
        },
      }))
        .then(() => {
          dispatch(closeModal('createAvailability'));
          getGuideAvailabilityInstances();
        })
        .catch((object: any) => {
          const error = object.response.data.errors[0].detail;

          toast.error(object.response.data.errors[0].detail.includes('overlapping') ? 'Cannot add slot due to existing availability for this day and time. Please check future weeks and delete before adding' : error, {
            id: 'CREATE_GUIDE_AVAILABILITY_ERROR',
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    }
  };

  return (
    <>
      <Modal
        isOpen={editGuideAvailability}
        title={!showDeleteConfirmation ? 'Edit Availability' : 'Delete This Availability'}
        modalName="editGuideAvailability"
        handleClose={() => {
          dispatch(closeModal('editGuideAvailability'));
          setShowDeleteConfirmation(false);
        }}
        icon={() =>
          (!showDeleteConfirmation
            ? <div onClick={() => setShowDeleteConfirmation(true)} style={{ top: '10px', right: '10px', zIndex: 10000 }} className="position-absolute" >
              <span onClick={() => setShowDeleteConfirmation(true)} style={{ top: '9px', right: '8px', zIndex: 10000 }} className="delete-availability position-absolute">Delete</span>
            </div>
            : <div onClick={() => setShowDeleteConfirmation(false)} style={{ top: '10px', left: '10px', zIndex: 10000 }} className="position-absolute btn-icon" >
              <i onClick={() => setShowDeleteConfirmation(false)} style={{ top: '9px', left: '8px', zIndex: 10000 }} className="fas fa-lg fa-arrow-left position-absolute" />
            </div>)
        }
        className="edit-guide-availability-modal"
        showHeader
      >
        <EditAvailabilityModal
          showDeleteConfirmation={showDeleteConfirmation}
          currentEvent={currentEvent}
          setAcceptingCodeverse={setAcceptingCodeverse}
          acceptingCodeverse={acceptingCodeverse}
          handleUpdateGuideAvailability={handleUpdateGuideAvailability}
          handleOnlyThisDate={handleOnlyThisDate}
          handleDeleteFuture={handleDeleteFuture}
        />
      </Modal>
      <Modal
        isOpen={editParticipationSlot}
        title={!showDeleteConfirmation ? 'Edit Availability' : 'Delete This Slot'}
        modalName="editParticipationSlot"
        handleClose={() => {
          dispatch(closeModal('editParticipationSlot'));
          setShowDeleteConfirmation(false);
        }}
        icon={() =>
          (!showDeleteConfirmation
            ? <div onClick={() => setShowDeleteConfirmation(true)} style={{ top: '10px', right: '10px', zIndex: 10000 }} className="position-absolute" >
              <span onClick={() => setShowDeleteConfirmation(true)} style={{ top: '9px', right: '8px', zIndex: 10000 }} className="delete-availability position-absolute">Delete</span>
            </div>
            : <div onClick={() => setShowDeleteConfirmation(false)} style={{ top: '10px', left: '10px', zIndex: 10000 }} className="position-absolute btn-icon" >
              <i onClick={() => setShowDeleteConfirmation(false)} style={{ top: '9px', left: '8px', zIndex: 10000 }} className="fas fa-lg fa-arrow-left position-absolute" />
            </div>)
        }
        className="edit-guide-availability-modal"
        showHeader
      >
        <EditAvailabilityModal
          showDeleteConfirmation={showDeleteConfirmation}
          currentEvent={currentEvent}
          setAcceptingCodeverse={setAcceptingCodeverse}
          acceptingCodeverse={acceptingCodeverse}
          handleUpdateGuideAvailability={handleUpdateGuideAvailability}
          handleOnlyThisDate={() => {
            const daysAway = moment(currentEvent.start).diff(moment(), 'days');
            if (daysAway > 7) {
              setDeleteWarningLevel(0);
            } else {
              setDeleteWarningLevel(1);
            }
            setDeleteType('onlyThisDate');
            dispatch(closeModal('editParticipationSlot'));
            dispatch(openModal('showDeleteWarning'));
          }}
          handleDeleteFuture={() => {
            const daysAway = moment(currentEvent.start).diff(moment(), 'days');
            if (daysAway > 7) {
              setDeleteWarningLevel(0);
            } else {
              setDeleteWarningLevel(1);
            }
            setDeleteType('future');
            dispatch(closeModal('editParticipationSlot'));
            dispatch(openModal('showDeleteWarning'));
          }}
        />
      </Modal>
      <Modal
        isOpen={showDeleteWarning}
        modalName="showDeleteWarning"
        handleClose={() => {
          dispatch(closeModal('showDeleteWarning'));
        }}
        className="slot-delete-warning-modal"
      >
        {deleteWarningLevel === 0
          ? <div className="slot-delete-warning">
            <h2>Are you sure?</h2>
            <p>You have sessions scheduled with kids during this time. Removing this slot will remove these sessions and rematch the kids with a different guide.</p>

            <div className="d-flex flex-row slot-delete-warning__actions">
              <Button
                onClick={() => {
                  dispatch(closeModal('showDeleteWarning'));
                  setDeleteWarningLevel(0);
                }}
                className="w-100 mb-3"
                context="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {

                }}
                style={{ backgroundColor: '#fc5f54' }}
                className="w-100 ml-3"
                context="status-danger"
              >
                Delete
              </Button>
            </div>
          </div>
          : <div className="slot-delete-warning slot-delete-warning__high-level">
            <h2>You have sessions with kids within 7 days</h2>
            <p>We track these cancellations closely. Removing this booking should only be done if you have health or tech issues that prevent you from doing this session.</p>

            <div className="d-flex flex-row slot-delete-warning__actions">
              <Button
                onClick={() => {
                  dispatch(closeModal('showDeleteWarning'));
                  setDeleteWarningLevel(0);
                }}
                className="w-100 mb-3"
                context="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (deleteType === 'onlyThisDate') {
                    handleOnlyThisDate();
                  } else {
                    handleDeleteFuture();
                  }
                }}
                style={{ backgroundColor: '#fc5f54' }}
                className="w-100 ml-3"
                context="status-danger"
              >
                Delete
              </Button>
            </div>
          </div>
        }
      </Modal>
      <Modal
        isOpen={createAvailability}
        title={'Create Availability'}
        modalName="createAvailability"
        handleClose={() => {
          dispatch(closeModal('createAvailability'));
        }}
        className="edit-guide-availability-modal"
        showHeader
      >
        <CreateAvailabilityModal
          currentEvent={currentEvent}
          handleRecurring={() => addAvailability(currentEvent.event, true)}
          handleThisDayOnly={() => addAvailability(currentEvent.event)}
        />
      </Modal>
    </>
  );
};

export default CalendarModalActions;
