const determineAvatarImage = (childUser: any, setProfileImageStyle: any) => {
  if (childUser) {
    if (childUser.avatar) {
      switch (childUser.avatar.id) {
        case 'a7a62b5d-f2a3-4c76-ae6e-7b8bd316b50e':
          setProfileImageStyle({ backgroundImage: 'url(/img/alien.jpg)' });
          break;
        case '430d3a38-b1ee-44d1-b11c-107a11860671':
          setProfileImageStyle({ backgroundImage: 'url(/img/cat.jpg)' });
          break;
        case '20edd67a-2ad2-4d23-b0de-e114fe39d8d8':
          setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
          break;
        case 'f9517d14-b581-4fab-b3a1-a2885f8088c7':
          setProfileImageStyle({ backgroundImage: 'url(/img/player.jpg)' });
          break;
        case '15143073-1633-4d98-b8b8-39f0f2f1d888':
          setProfileImageStyle({ backgroundImage: 'url(/img/rabbit.jpg)' });
          break;
        case '1fbff479-dec5-4f7c-9aba-60e7d70f6990':
          setProfileImageStyle({ backgroundImage: 'url(/img/spaceman.jpg)' });
          break;
        case '676e15c7-28c6-41a3-abcb-c70b21c14e3f':
          setProfileImageStyle({ backgroundImage: 'url(/img/octopus.jpg)' });
          break;
        case 'da3c7d84-8cbc-483e-8074-6784f3f7e416':
          setProfileImageStyle({ backgroundImage: 'url(/img/turtle.jpg)' });
          break;
        default:
          setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
          break;
      }
    } else {
      setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
    }
  }
}

export default determineAvatarImage;
