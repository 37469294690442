import React, { useEffect, useState, useMemo, useRef } from "react";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store";
import { RootState } from "store/state";

type Props = {
  key?: any;
  history?: any;
  location?: any;
  match?: any;
  slotInstance: any;
  setCurrentStep?: any;
  setCurrentSlotInstance?: any;
};

const SlotInstance: React.FC<Props> = ({
  setCurrentStep,
  slotInstance,
  setCurrentSlotInstance,
}) => {
  const children = useSelector((state: RootState) => state.checkout.children);
  const currentChildIndex = useSelector(
    (state: RootState) => state.checkout.currentChildIndex
  );

  const timezone = useMemo(() => {
    let userTimezone = "";
    const zonenameAbbr = moment.tz(moment.tz.guess()).zoneAbbr();
    switch (zonenameAbbr) {
      case "CST":
        userTimezone = "America/Chicago";
        break;
      case "MST":
        userTimezone = "America/Denver";
        break;
      case "PST":
        userTimezone = "America/Los_Angeles";
        break;
      case "EST":
        userTimezone = "America/New_York";
        break;
      case "CDT":
        userTimezone = "America/Chicago";
        break;
      case "MDT":
        userTimezone = "America/Denver";
        break;
      case "PDT":
        userTimezone = "America/Los_Angeles";
        break;
      case "EDT":
        userTimezone = "America/New_York";
        break;
      default:
        userTimezone = "America/Chicago";
        break;
    }
    return userTimezone;
  }, []);

  const slotInstanceMomentStartObj = useMemo(() => {
    return moment
      .tz(
        `${slotInstance.start_date}T${slotInstance.start_time}`,
        "America/Chicago"
      )
      .tz(timezone);
  }, [slotInstance, timezone]);

  const slotInstanceMomentEndObj = useMemo(() => {
    return moment
      .tz(
        `${slotInstance.end_date}T${slotInstance.end_time}`,
        "America/Chicago"
      )
      .tz(timezone);
  }, [slotInstance, timezone]);

  const slotInstanceSelectedInCheckout = useMemo(() => {
    let foundSlotInstance = false;
    let foundSlotInstanceIndexes: any = [];
    children.map((child, index) => {
      if (child.slot_instance && child.slot_instance.id === slotInstance.id) {
        foundSlotInstance = true;
        foundSlotInstanceIndexes.push(index);
      }
    });
    return { foundSlotInstance, foundSlotInstanceIndexes };
  }, [children]);

  if (slotInstance.has_capacity) {
    return (
      <div
        onClick={() => {
          if (slotInstance.has_capacity) {
            setCurrentStep(3);
            setCurrentSlotInstance(slotInstance);
          }
        }}
        className="slot-instance-selection"
      >
        <div className="slot-instance-selection__time">
          {slotInstanceMomentStartObj.format("h:mm")}-
          {slotInstanceMomentEndObj.format("h:mm")}{" "}
          {slotInstanceMomentEndObj.format("A")}{" "}
          {slotInstanceMomentEndObj.format("z")}
        </div>
        <div className="slot-instance-selection__indicator">
          <i className="fas fa-chevron-right" />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default SlotInstance;
