import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, Container, Col, Row } from '@codeverse/react-helios-ui';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';

import { GET_GUIDE_SCHEDULABLES } from 'models/User';
import { dataClientRequest } from '@codeverse/redux-data-client';

type Props = {
  currentUser?: any;
  history?: any;
  location?: any;
  organizationId?: any;
}

export const ProgramCertification: React.FC<Props> = ({ currentUser, history, location }) => {
  const dispatch: AppDispatch = useDispatch();
  const [infoChanged, setInfoChanged] = useState(false);
  const guideSchedulables = useSelector((state: RootState) => state.user.guide_schedulables);

  useEffect(() => {
    dispatch(dataClientRequest({
      ...GET_GUIDE_SCHEDULABLES,
      data: {
        id: currentUser.id,
      },
    }));
  }, []);

  const determineOccasionTitle = (occasionId: any) => {
    switch (occasionId) {
      case 'e4a8a29d-1930-40b9-ba9a-98f72976b5a4':
        return 'Scholarship Class';
        break;
      case '0a00172a-3238-463e-8bc5-3e3edab8c6a4':
        return 'Weeklong Studio Camp - Y1';
        break;
      case '621c7eee-04a1-45bd-898c-c8c695e4981d':
        return 'Weeklong Studio Camp - Y2';
        break;
      case '7951297a-828a-4dfa-b493-4c4b6b2900c1':
        return 'Weeklong Studio Camp';
        break;
      case 'e5c16d28-0343-49ab-9894-6ad244da952c':
        return 'Hackathon';
        break;
      case 'fb6d0ece-a4a9-44ae-bc59-648705c34d7f':
        return 'Virtual Weeklong Camp';
        break;
      case 'ced57450-5521-420f-baaf-658272ca7800':
        return 'Day Camp (Free)';
        break;
      case '718fd00c-10c1-4e57-8ddf-95abf17e102a':
        return 'Codeverse Junior';
        break;
      case '267076f0-8182-4ee6-a320-a304808faf2a':
        return 'Membership';
        break;
      case '6921c96d-9791-40a7-b2b6-4597f783493f':
        return 'Field Trip';
        break;
      case '7ec0004b-2766-4ccf-9a84-e90c67f6ab64':
        return 'Trial Class';
        break;
      case '62306236-41e1-450f-8c18-5e9ea4435ea2':
        return 'Event';
        break;
      case '1c956bef-13c5-46d9-87e2-5f7d79b30d31':
        return 'Demo';
        break;
      case 'a72ebc0d-c4f3-4f37-8bd9-bba0f5fd7097':
        return 'Free Class';
        break;
      case 'a0b61fba-bdbe-4f83-a1f8-c1bc7254a4f2':
        return 'Weeklong Popup Camp';
        break;
      case '55342b31-74db-4389-9495-2d20db879008':
        return 'Day Camp';
        break;
      case '551ebacc-ffcd-4e51-9317-dcd0e79d0761':
        return 'BISC Lincoln Park Club';
        break;
      case '3a82e14c-393d-49cb-b126-c5dee12b8629':
        return 'Birthday Party';
        break;
      case '8e1991c2-3f00-4682-a43a-50e7883508c4':
        return 'Kids Night Out';
        break;
      case '4e09273f-19a5-4775-9b1e-8b9e7a756a0f':
        return 'Kids Night Out (Free)';
        break;
      case '86833acb-3bcd-465d-9e5b-2399d7252992':
        return 'Virtual Class';
        break;
      case 'd2e9f65b-c86f-40c8-b9ce-4d3afc6dc8e8':
        return 'Virtual Trial';
        break;
      case '2a1d37c5-8d0e-415d-8d90-1fd082cada02':
        return 'Virtual Weeklong Camp (Free)';
        break;
      case 'f64f458b-2864-43ad-a70c-62a11004137b':
        return 'Troubleshooting Call';
        break;
      case '2ec2ad34-9b1a-4b4f-9c15-7f6cc0b6becd':
        return 'Checkout Call';
        break;
      case 'fc197f1b-b9eb-4666-b8c5-5b44c1b20a3c':
        return 'Internal Test';
        break;
      case '83f958bc-c25d-4ba2-8c0c-e035c47160b7':
        return 'Influencer Class';
        break;
    }
  };

  return (
    <div className="program-certification fadein-short pb-3 pb-md-0 mb-5">
      <Card>
        <CardBody>
          <h4 className="program-certification__subheader">
            Program Certifications
          </h4>
          <Row>
            {
              guideSchedulables && guideSchedulables.map((guideSchedulable: any) => {
                return (
                  <Col key={guideSchedulable.occasion.id} sm="6">
                    <div className="program-certification__checkbox-container mb-2">
                      <input type="checkbox" checked readOnly />
                      <label className="ml-3">{determineOccasionTitle(guideSchedulable.occasion.id)}</label><br />
                    </div>
                  </Col>
                );
              })
            }
            {
              guideSchedulables.length === 0 &&
              <div className="ml-3">No Program Certifications yet!</div>
            }
          </Row>
        </CardBody>
      </Card>
    </div >
  );
};
