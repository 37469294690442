import { GetClientConfig } from 'store/data-client';

export const GET_USER_GUIDE_PREFERENCES: GetClientConfig = {
  method: 'get',
  model: 'users',
  submodel: 'guide_preferences',
}

export const DELETE_GUIDE_PREFERENCE: GetClientConfig = {
  method: 'delete',
  model: 'guide_preferences',
}

export const UPDATE_GUIDE_PREFERENCE: GetClientConfig = {
  method: 'put',
  model: 'guide_preferences',
}

export const CREATE_GUIDE_BLOCK: GetClientConfig = {
  method: 'post',
  model: 'guide_block',
}