import { GetClientConfig } from 'store/data-client';

export const CREATE_BOOKING: GetClientConfig = {
  method: 'post',
  model: 'bookings',
}

export const UPDATE_BOOKING: GetClientConfig = {
  method: 'put',
  model: 'bookings',
}