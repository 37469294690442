import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Modal from 'components/Shared/NewModal';
import Step1 from 'components/Guide/Survey/Step1';
import Step1b from 'components/Guide/Survey/Step1b';
import Step1c from 'components/Guide/Survey/Step1c';
import Step2 from 'components/Guide/Survey/Step2';
import Step3 from 'components/Guide/Survey/Step3';
import Step3b from 'components/Guide/Survey/Step3b';
import Step3c from 'components/Guide/Survey/Step3c';

import StepCounter from 'components/Shared/StepCounter';

import { RootState } from 'store/state';
import { AppDispatch } from 'store'

import { dataClientRequest } from '@codeverse/redux-data-client';
import { GET_MEETING, UPDATE_MEETING } from 'models/Meeting';
import { CREATE_NOTE } from 'models/Notes';
import { determineSentimentSymbol } from 'utils/determineSentimentSymbol';

type Props = {
  history?: any;
  location?: any;
};

const GuideSurvey: React.FC<Props> = ({ history }) => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const [currentParticipation, setCurrentParticipation] = useState(null);
  const [meetingInfo, setMeetingInfo] = useState(null);

  const [currentStep, setCurrentStep] = useState('1a');
  const [childName, setChildName] = useState('');
  const [parentNoteHeader, setParentNoteHeader] = useState(`Hey [Parent Name], this is ${currentUser.name.split(' ')[0]} from Codeverse.`);
  const [title, setTitle] = useState('End of Session Survey');
  const [subtext, setSubtext] = useState('This session rating will be saved for internal reference. It will not be shown to the parents and will not impact your Guide rating.');
  const [showHeader, setShowHeader] = useState(true);
  const [bigMoney, setBigMoney] = useState(false);

  const [selectedIcon, setSelectedIcon] = useState(null);
  const [kidConcerns, setKidConcerns] = useState(null);
  const [technologyIssues, setTechnologyIssues] = useState(null);
  const [parentConcerns, setParentConcerns] = useState(null);
  const [otherConcernsExist, setOtherConcernsExist] = useState(null);
  const [otherConcerns, setOtherConcerns] = useState('');

  const [technologyLoggingInIssues, setTechnologyLoggingInIssues] = useState(null);
  const [technologyAudioVideoIssues, setTechnologyAudioVideoIssues] = useState(null);
  const [technologyCodeIdeIssues, setTechnologyCodeIdeIssues] = useState(null);
  const [otherIssuesExist, setOtherIssuesExist] = useState(null);
  const [otherIssues, setOtherIssues] = useState('');
  const [input1Text, setInput1Text] = useState('');
  const [parentNote, setParentNote] = useState('');

  useEffect(() => {
    toast.success('Complete this survey for a $1 Lift Credit', {
      position: toast.POSITION.TOP_CENTER,
    });
  }, []);

  useEffect(() => {
    const currentMeetingId = localStorage.getItem('currentMeetingId');
    if (currentMeetingId) {
      dispatch(dataClientRequest({
        ...GET_MEETING,
        data: {
          id: currentMeetingId,
        },
      }))
        .then(async (meetingPayload: any) => {
          setMeetingInfo(meetingPayload.response.data.data);
          const userParticipation = meetingPayload.response.data.data.meta.child_participations.filter((child_participation: any) => !child_participation.abandoned_at)[0];
          if (userParticipation) {
            setCurrentParticipation(userParticipation.id);
            setChildName(userParticipation.user_name);
          }
        })
    }
  }, [])

  const updateMeeting = () => {
    dispatch(dataClientRequest({
      ...UPDATE_MEETING,
      data: {
        id: localStorage.getItem('currentMeetingId'),
        sentiment: determineSentimentSymbol(selectedIcon),
        kid_concerns: kidConcerns,
        technology_issues: technologyIssues,
        parent_concerns: parentConcerns,
        other_concerns: otherConcerns !== '' ? otherConcerns : null,
        technology_logging_in_issues: technologyLoggingInIssues,
        technology_audio_video_issues: technologyAudioVideoIssues,
        technology_code_ide_issues: technologyCodeIdeIssues,
        other_issues: otherIssues !== '' ? otherIssues : null,
      }
    }))
  }

  const createNote = (topic_name: any, note: any) => {
    dispatch(dataClientRequest({
      ...CREATE_NOTE,
      data: {
        topic: topic_name,
        heading: `Note from Codeverse Guide ${meetingInfo.date}`,
        detail: note,
        relationships: {
          participation: { type: 'participations', id: currentParticipation },
        }
      }
    }))
  }

  return (
    <>
      {
        meetingInfo &&
        <>
          <Modal
            className="survey-modal d-flex flex-column"
            closeRedirect="/guide/upcoming-sessions"
            history={history}
            isOpaque
            isOpen={true}
            modalName="guideSurvey"
            showHeader={showHeader}
            subtext
            title={title}
          >
            <div className="survey-modal__contents">
              {currentStep === '1a' && <Step1 setCurrentStep={setCurrentStep} selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} childName={childName} />}
              {currentStep === '1b' &&
                <Step1b setCurrentStep={setCurrentStep}
                  setKidConcerns={setKidConcerns}
                  kidConcerns={kidConcerns}
                  technologyIssues={technologyIssues}
                  setTechnologyIssues={setTechnologyIssues}
                  parentConcerns={parentConcerns}
                  setParentConcerns={setParentConcerns}
                  otherConcernsExist={otherConcernsExist}
                  setOtherConcernsExist={setOtherConcernsExist}
                  otherConcerns={otherConcerns}
                  setOtherConcerns={setOtherConcerns}
                />
              }
              {currentStep === '1c' &&
                <Step1c setCurrentStep={setCurrentStep}
                  technologyLoggingInIssues={technologyLoggingInIssues}
                  setTechnologyLoggingInIssues={setTechnologyLoggingInIssues}
                  technologyAudioVideoIssues={technologyAudioVideoIssues}
                  setTechnologyAudioVideoIssues={setTechnologyAudioVideoIssues}
                  technologyCodeIdeIssues={technologyCodeIdeIssues}
                  setTechnologyCodeIdeIssues={setTechnologyCodeIdeIssues}
                  otherIssuesExist={otherIssuesExist}
                  setOtherIssuesExist={setOtherIssuesExist}
                  otherIssues={otherIssues}
                  setOtherIssues={setOtherIssues}
                />
              }
              {currentStep === '2' && <Step2 childName={childName} createNote={createNote} meeting={meetingInfo} setCurrentStep={setCurrentStep} input1Text={input1Text} setInput1Text={setInput1Text} setShowHeader={setShowHeader} setBigMoney={setBigMoney} setSubtext={setSubtext} updateMeeting={updateMeeting} />}
              {currentStep === '3a' && <Step3 childName={childName} setCurrentStep={setCurrentStep} parentNote={parentNote} parentNoteHeader={parentNoteHeader} setParentNote={setParentNote} setSubtext={setSubtext} setTitle={setTitle} />}
              {currentStep === '3b' && <Step3b createNote={createNote} input1Text={input1Text} parentNote={parentNote} parentNoteHeader={parentNoteHeader} setBigMoney={setBigMoney} setCurrentStep={setCurrentStep} setShowHeader={setShowHeader} setTitle={setTitle} updateMeeting={updateMeeting} />}
              {currentStep === '3c' && <Step3c history={history} />}
              {
                subtext &&
                <div className="modal-element__subtext">
                  {subtext}
                </div>
              }
            </div>
          </Modal>
          <StepCounter numSteps={3} currentStep={currentStep} showBill bigMoney={bigMoney} />
        </>
      }
    </>
  );
};

export default GuideSurvey;
