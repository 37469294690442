import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Badge, Input, Select } from "@codeverse/react-helios-ui";
import { useHistory } from "react-router-dom";
import { RootState } from "store/state";
import captureLead from "utils/captureLead";

import { AppDispatch } from "store";
import ClassIncludes from "./ClassIncludes";

const calculateAge = (birthday: Date) => {
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const ParentInformation: React.FC<any> = ({ goToStepHash, fieldErrors }) => {
  const dispatch: AppDispatch = useDispatch();
  const parentInformation = useSelector(
    (state: RootState) => state.checkout.parent_information
  );
  const { name, email, phone_number } = parentInformation;
  const history = useHistory();

  const handleBlur = () => {
    const strippedPhone = phone_number.replace(/[- )(]/g, "").replace("+1", "");
    captureLead({
      name,
      email,
      phone: strippedPhone,
    });
    //@ts-ignore
    if (window.dataLayer) {
      //@ts-ignore
      window.dataLayer.push({
        event: "lead-submit",
      });
    }
  };

  return (
    <div className="m-width-checkout">
      <h1 className="header">Parent information</h1>
      <div className="input-container">
        <div className="input-spacer">
          <Input
            className="w-100"
            name="parentName"
            label="Parent Full Name"
            onChange={(e: any) =>
              dispatch({
                type: "UPDATE_PARENT_INFORMATION",
                key: "name",
                value: e.target.value,
              })
            }
            value={name}
            showError={fieldErrors.parentNameHasError}
            onBlur={() => handleBlur()}
          />
        </div>
        <div className="input-spacer">
          <Input
            className="w-100"
            name="emailAddress"
            label="Email Address"
            onChange={(e: any) =>
              dispatch({
                type: "UPDATE_PARENT_INFORMATION",
                key: "email",
                value: e.target.value,
              })
            }
            value={email}
            showError={fieldErrors.parentEmailHasError}
            onBlur={() => handleBlur()}
          />
        </div>
        <Input
          className="w-100"
          name="phoneNumber"
          mask="+1 999-999-9999"
          label="Phone Number"
          inputMode="numeric"
          pattern="[0-9]*"
          onChange={(e: any) =>
            dispatch({
              type: "UPDATE_PARENT_INFORMATION",
              key: "phone_number",
              value: e.target.value,
            })
          }
          value={phone_number}
          showError={fieldErrors.parentPhoneNumberHasError}
          onBlur={() => handleBlur()}
        />
      </div>
      <span className="small-print">
        See{" "}
        <span
          onClick={() =>
            window.open("https://www.codeverse.com/terms-of-service/", "_blank")
          }
          className="underlined"
        >
          Terms & Conditions
        </span>{" "}
        and{" "}
        <span
          onClick={() =>
            window.open("https://www.codeverse.com/privacy-policy/", "_blank")
          }
          className="underlined"
        >
          Privacy Policy.
        </span>
      </span>

      <hr style={{ color: '#EEF6FD' }} />

      <ClassIncludes />
    </div>
  );
};

export default ParentInformation;
