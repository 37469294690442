import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button } from '@codeverse/react-helios-ui';
import ReactTooltip from 'react-tooltip';
import { AppDispatch } from 'store';
import { closeModal, openModal } from 'store/modules/UI/actions';
import classNames from 'classnames';

const Credits: React.FC<any> = ({ history, location, appliedCredits, unappliedCredits, removeSpacing }) => {
  const dispatch: AppDispatch = useDispatch();

  const creditsClassname = classNames('credits', {
    'remove-margin-top': removeSpacing,
  });

  return (
    <Card
      className={creditsClassname}
    >
      <div className="credit-section credit-section__header">
        <div>Your Session Credits</div>

        <div className="credit-tooltip">
          <i data-tip data-for='infoI' data-event='click mouseenter' className="fas fa-info-circle"></i>
          <ReactTooltip id='infoI' aria-haspopup='true' effect='solid' type="light" globalEventOff='click' clickable={true} className="info-tooltip">
            Credits can be used for 1:1 sessions <br />
            with a Codeverse Guide. The number of <br />
            credits applied to your account each <br />
            month is based on your membership <br />
            plan. Credits can be used at anytime but <br />
            are non-refundable. <br />
          </ReactTooltip>
        </div>
      </div>
      <div className="credit-section">
        <span>Credits</span>

        <div className="credit-section__available-credits">
          {appliedCredits}
        </div>
      </div>
      {unappliedCredits > 0 && <div className="credit-section">
        <span>Unapplied Credits</span>

        <div className="credit-section__unapplied-credits">
          {unappliedCredits}
        </div>
      </div>}

      {unappliedCredits > 0 && <div className="credit-book-btn">
        <Button onClick={() => {
          if (history.location.pathname === '/parent/dashboard') {
            history.push('/parent/schedule')
          }
          dispatch(openModal('bookNewSession'));
        }} context="primary" size="lg">Book your session</Button>
      </div>}
    </Card>
  )
};

export default Credits;
