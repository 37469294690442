import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from '@codeverse/react-helios-ui';
import { printNetworkQualityStats } from 'utils/printNetworkQualityStats';

import { RootState } from 'store/state';

type Props = {
  history?: any;
  location?: any;
  participantVideoTrack: any;
  participants: any;
  participantId: string;
};

const ParticipantVideo: React.FC<Props> = ({ history, location, participantVideoTrack, participants, participantId }) => {
  const dispatch = useDispatch();
  const videoRef = useRef<any>({});

  const [networkMeter, setNetworkMeter] = useState('');
  const [networkLevel, setNetworkLevel] = useState(0);

  useEffect(() => {
    const participant = participants[participantId];
    if (participant) {
      participant.on('networkQualityLevelChanged', (networkQualityLevel: any, networkQualityStats: any) => {
        const meter = printNetworkQualityStats(networkQualityLevel, networkQualityStats);
        setNetworkMeter(meter);
        setNetworkLevel(networkQualityLevel);
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(videoRef.current).length > 0 && participantVideoTrack) {
      setTimeout(() => {
        participantVideoTrack.attach(videoRef.current);
      }, 500)
    }
  }, [videoRef.current, participantVideoTrack.track])

  return (
    <div className="participant-video-stream">
      <div style={{
        width: '246px',
        height: '22px',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        right: '-155px',
        top: '72px',
        color: networkLevel < 3 ? '#c00037' : '#17e898',
        fontSize: '9px'
      }}>
        {networkMeter}
      </div>
      <video
        autoPlay={true}
        muted={true}
        ref={videoRef}
      />
    </div >
  );
};

export default ParticipantVideo;
