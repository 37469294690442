import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ReducerKey } from 'store/reducers';
import { action } from 'typesafe-actions';

type Method = 'get' | 'post' | 'put' | 'delete';

export type GetClientConfig = {
  nullCurrentOrganizationId?: boolean;
  method: Method;
  model: string,
  submodel?: string,
  query?: any
  reducerKey?: ReducerKey
  stateKey?: string,
  data?: any
  id?: string;
  headers?: any;
  resetData?: any
}

type DispatchObject = {
  type: string
  query: any
  then?: any;
}

export const dataClientRequest = ({
  nullCurrentOrganizationId,
  method,
  model,
  submodel,
  query,
  reducerKey,
  stateKey,
  data,
  id,
  headers,
  resetData = false,
}: GetClientConfig): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    let actionType = '';
    if (submodel) {
      actionType = `@@data-client/${method.toUpperCase()}_${model.toUpperCase()}_${submodel.toUpperCase()}`
    } else {
      actionType = `@@data-client/${method.toUpperCase()}_${model.toUpperCase()}`
    }
    const dispatchObject: DispatchObject = Object.assign({},
      { type: actionType },
      { model: model },
      nullCurrentOrganizationId && { nullCurrentOrganizationId },
      query && { query },
      data && { data },
      reducerKey && { reducerKey },
      stateKey && { stateKey },
      id && { id },
      headers && { headers },
      submodel && { submodel },
      resetData && { resetData }
    );
    return dispatch(dispatchObject)
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err)
        return Promise.reject(err);
      });
  }
}