import React, { useEffect, useState } from 'react';
import { Button } from '@codeverse/react-helios-ui';

import FormElement from 'components/Shared/FormElement';
import TextAreaInput from 'components/Shared/TextAreaInput';

type Props = {
  history?: any;
  location?: any;
  setCurrentStep?: any;
  technologyLoggingInIssues?: any;
  setTechnologyLoggingInIssues?: any;
  technologyAudioVideoIssues?: any;
  setTechnologyAudioVideoIssues?: any;
  technologyCodeIdeIssues?: any;
  setTechnologyCodeIdeIssues?: any;
  otherIssuesExist?: any;
  setOtherIssuesExist?: any;
  otherIssues?: any;
  setOtherIssues?: any;
};

const Step1c: React.FC<Props> = ({ 
  setCurrentStep,
  technologyLoggingInIssues,
  setTechnologyLoggingInIssues,
  technologyAudioVideoIssues,
  setTechnologyAudioVideoIssues,
  technologyCodeIdeIssues,
  setTechnologyCodeIdeIssues,
  otherIssuesExist,
  setOtherIssuesExist,
  otherIssues,
  setOtherIssues,
 }) => {
  const [labelText, setLabelText] = useState('Type feedback here...');

  useEffect(() => {
    if (otherIssues.length !== 0) {
      setLabelText('');
    } else {
      setLabelText('Type feedback here...');
    }
  }, [otherIssues]);

  return (
    <div className="step1">
      <div className="question">
        What type of technology issues did you encounter?
      </div>
      <div className="my-4">
        <div className="mb-2">
          <FormElement checked={technologyLoggingInIssues} setChecked={setTechnologyLoggingInIssues} text={'Logging In'}/>
        </div>
        <div className="mb-2">
          <FormElement checked={technologyAudioVideoIssues} setChecked={setTechnologyAudioVideoIssues} text={'Audio/Video'}/>
        </div>
        <div className="mb-2">
          <FormElement checked={technologyCodeIdeIssues} setChecked={setTechnologyCodeIdeIssues} text={'Code Bugs'}/>
        </div>
        <div className="mb-2">
          <FormElement checked={otherIssuesExist} setChecked={setOtherIssuesExist} text={'Other'}/>
        </div>
        {
          otherIssuesExist &&
          <TextAreaInput small setInputText={setOtherIssues} inputText={otherIssues} labelText={labelText}/>
        }
      </div>
      <Button
        context="primary"
        className="w-100"
        onClick={() => {
          setCurrentStep('2');
        }}
      >
        Continue
      </Button>
    </div>
  );
}

export default Step1c;