import React, { useState } from 'react';
import { Button, PasswordInput } from '@codeverse/react-helios-ui';
import { toast } from 'react-toastify';

import { closeModal } from 'store/modules/UI/actions';
import { dataClientRequest } from 'store/data-client';
import { UPDATE_USER_PASSWORD_MODEL_CONFIG } from 'models/User';

type Props = {
  dispatch: any;
  currentUser: any;
};

const hasNumber = (myString: string) => {
  return /\d/.test(myString);
};

const hasLetter = (myString: string) => {
  return /[a-z]/i.test(myString);
};

const EditPasswordModal: React.FC<Props> = ({ currentUser, dispatch }) => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleClose = () => {
    dispatch(closeModal('editPassword'));
  }

  const handleSave = () => {
    if (password.length < 8 || !hasNumber(password) || !hasLetter(password)) {
      toast.error('Your new password does not meet character requirements', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      dispatch(dataClientRequest({
        ...UPDATE_USER_PASSWORD_MODEL_CONFIG,
        data: {
          id: currentUser.id,
          password: password,
        },
      }))
        .then((response: any) => {
          toast.success('Password change successful.', {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch((error: any) => {
          toast.error('Failed to change your password.', {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  }

  return (
    <div className="edit-password-modal__content">
      <PasswordInput
        label="New Password"
        password={password}
        setPassword={setPassword}
        passwordError={passwordError}
        setPasswordError={setPasswordError}
        className="password-input"
      />
      <div className="d-flex flex-row justify-content-between">
      <Button
          context="secondary"
          onClick={() => {
            handleClose();
          }}
          className="w-50 mr-1 mt-4"
        >
          Cancel
        </Button>
        <Button
          context="primary"
          onClick={() => {
            handleSave();
          }}
          className="w-50 ml-1 mt-4"
        >
          Save Password
        </Button>
      </div>
    </div>
  )
}

export default EditPasswordModal;