import React from "react";
import { Button, Card } from "@codeverse/react-helios-ui";
import { useDispatch } from "react-redux";
import { openModal } from "store/modules/UI/actions";

interface TrialSignupCardProps {}

const TrialFeature: React.FC<any> = ({ children }) => {
  return (
    <div className="trial-feature">
      <div className="trial-feature__check">
        <i className="fas fa-check" />
      </div>
      <div className="trial-feature__text">{children}</div>
    </div>
  );
};

const TrialSignupCard: React.FC<TrialSignupCardProps> = () => {
  const dispatch = useDispatch();
  return (
    <div className="mt-5 trial-signup-card">
      <div className="trial-signup-card__text">Start your trial</div>

      <div className="inner-card">
        <div
          style={{
            backgroundImage: "url(/img/MembershipBanner-min.png)",
            backgroundRepeat: "no-repeat",
            height: "129px",
            width: "236px",
          }}
        />

        <Button
          withoutSpan={
            <>
              <span style={{}}>Book a free class</span>
              <i style={{}} className="fas fa-chevron-right pl-4" />
            </>
          }
          onClick={() => {
            dispatch(openModal("addChildCheckout"));
          }}
          className="trial-signup-book-class"
          context="success"
        />
        <div className="trial-features">
          <h6
            style={{
              marginTop: "24px",
              textAlign: "center",
            }}
          >
            INCLUDED IN YOUR FREE CLASS:
          </h6>

          <TrialFeature>
            A private 50-minute session led by a Codeverse Guide.
          </TrialFeature>
          <TrialFeature>
            Personalized note from your Codeverse Guide after your session.
          </TrialFeature>
          <TrialFeature>
            Track your child’s progress & reschedule at any time
          </TrialFeature>
        </div>
      </div>

      <div className="cc-copy">
        A credit card is required to sign up for a trial, but you won’t be
        charged until 7 days after your your period starts
      </div>
    </div>
  );
};

export default TrialSignupCard;
