import React, { useEffect, useState, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'components/Shared/NewModal';
import { closeModal, openModal } from 'store/modules/UI/actions';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';


type Props = {
  history?: any;
  location?: any;
  match?: any;
  setBookingsUpdated?: any;
  bookings?: any;
  currentBooking: any;
  currentBookingInstance?: any;
};

const determineModalTitle = (currentStep: number) => {
  switch (currentStep) {
    case 1:
      return 'Reschedule Upcoming Session';
    case 2:
      return 'Available Session Times';
    case 3:
      return 'Confirm new time';
    default:
      return '';
  }
}

const RescheduleModal: React.FC<Props> = ({ bookings, currentBooking, currentBookingInstance, setBookingsUpdated }) => {
  const rescheduleModal = useSelector((state: RootState) => state.ui.rescheduleModal);
  const userBookingInstances = useSelector((state: RootState) => state.user.userBookingInstances);
  const dispatch: AppDispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [currentSlotInstance, setCurrentSlotInstance] = useState({});
  const [onlyOneDay, setOnlyOneDay] = useState(false);
  const [notRecurring, setNotRecurring] = useState(false);

  if (!currentBooking || !currentBookingInstance) {
    return null;
  }

  useEffect(() => {
    if (currentBooking) {
      if (currentBooking.last_visit_at) {
        if (moment(currentBooking.first_visit_at, 'YYYY-MM-DD').isSame(moment(currentBooking.last_visit_at))) {
          setCurrentStep(2);
          setNotRecurring(true);
        } else if (moment(currentBooking.last_visit_at, 'YYYY-MM-DD').isAfter(moment(currentBooking.first_visit_at)) && moment(currentBooking.first_visit_at).add(7, 'd').isAfter(moment(currentBooking.last_visit_at))) {
          setCurrentStep(2);
          setNotRecurring(true);
        } else {
          setCurrentStep(1);
          setNotRecurring(false);
        }
      } else {
        setCurrentStep(1);
        setNotRecurring(false);
      }
    }
  }, [rescheduleModal])
  
  const iconClassNames = classNames('position-absolute btn-icon', {
    'd-none': currentStep === 1 || (currentStep === 2 && notRecurring),
  })

  return (
    <Modal
      isOpen={rescheduleModal}
      title={determineModalTitle(currentStep)}
      modalName="rescheduleModal"
      handleClose={() => {
        dispatch(closeModal('rescheduleModal'));
        setCurrentStep(1);
      }}
      className="reschedule-modal d-flex flex-column"
      icon={() => 
        <div onClick={() => { setCurrentStep(currentStep - 1) }} style={{ top: '10px', left: '10px', zIndex: 10000 }} className={iconClassNames} >
          <i onClick={() => { }} style={{ top: '9px', left: '8px', zIndex: 10000 }} className="fas fa-lg fa-arrow-left position-absolute" />
        </div>
      }
      showHeader
    >
      <div className="reschedule-modal__content">
        {(currentStep === 1) && <Step1 setCurrentStep={setCurrentStep} currentBooking={currentBooking} currentBookingInstance={currentBookingInstance} setOnlyOneDay={setOnlyOneDay} />}
        {(currentStep === 2) && <Step2 notRecurring={notRecurring} setCurrentStep={setCurrentStep} currentBooking={currentBooking} currentBookingInstance={currentBookingInstance} onlyOneDay={onlyOneDay} setCurrentSlotInstance={setCurrentSlotInstance} />}
        {(currentStep === 3) &&
          <Step3 
            setBookingsUpdated={setBookingsUpdated}
            bookings={bookings}
            currentBooking={currentBooking}
            currentBookingInstance={currentBookingInstance}
            currentSlotInstance={currentSlotInstance}
            onlyOneDay={onlyOneDay}
            notRecurring={notRecurring}
            userBookingInstances={userBookingInstances} 
          />
        }
      </div>
    </Modal>
  );
}

export default RescheduleModal;

