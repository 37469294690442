import React from 'react';
import { Button } from '@codeverse/react-helios-ui';

import FiveSmiley from 'components/Shared/FiveSmiley';

type Props = {
  childName?: any;
  history?: any;
  location?: any;
  setCurrentStep?: any;
  selectedIcon?: any;
  setSelectedIcon?: any;
};

const Step1: React.FC<Props> = ({ childName, setCurrentStep, selectedIcon, setSelectedIcon }) => {
  return (
    <div className="step1">
      <div className="question">
        How was {childName}'s Session?
      </div>
      <div className="my-5">
        <FiveSmiley selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} />
      </div>
      <Button
        context="primary"
        className="w-100"
        onClick={() => setCurrentStep('1b')}
        disabled={!selectedIcon}
      >
        Submit
      </Button>

    </div>
  );
}

export default Step1;