import { GetClientConfig } from 'store/data-client';

export const GET_ORGANIZATION_BOOKINGS: GetClientConfig = {
  method: 'get',
  model: 'organizations',
  submodel: 'bookings',
  reducerKey: 'user',
  stateKey: 'bookings',
}

export const GET_ORGANIZATION_CREDITS: GetClientConfig = {
  method: 'get',
  model: 'organizations',
  submodel: 'credits',
  reducerKey: 'user',
  stateKey: 'credits',
}

