import React from 'react';
import { Button } from '@codeverse/react-helios-ui';

type Props = {
  header: string;
  subtext: string;
  emailType: string;
  emailLink: string;

};

const ConfirmationForm: React.FC<Props> = ({ header, subtext, emailType, emailLink }) => {
  const handleButtonSubmit = (e: any) => {
    e.preventDefault();
    window.open(emailLink, '_blank');
  };

  return (
    <div className="org-login__confirmation-form">
      <img className="org-login__confirmation-image" src="/img/astronaut.svg" />
      <h1 className="org-login__confirmation-header">{header}</h1>
      <p className="org-login__confirmation-subtext">{subtext}</p>
      <div className="mt-3">
        {emailType && <Button
          onClick={(e: any) => handleButtonSubmit(e)}
          className="org-login__button w-100"
          context="primary"
          withoutSpan={
            <>
              <span>
                Open {emailType}&nbsp;
              </span>
              <i className="fas fa-chevron-right" />
            </>
          }
        />}
      </div>
    </div>
  );
};

export default ConfirmationForm;
