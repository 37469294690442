import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SignIn from "components/SignIn/SignIn";
import queryString from "query-string";
import { RootState } from "store/state";

type Props = {
  guide?: any;
  history?: any;
  location?: any;
};

type QueryState = {
  step: string;
  next: string;
};

const Login: React.FC<Props> = ({ guide, history, location }) => {
  const [parentEmail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailLink, setEmailLink] = useState("");
  const [emailType, setEmailType] = useState("");
  const [lastStep, setLastStep] = useState(1);
  const [currentStep, setCurrentStep] = useState(2);
  const [sessionChecked, setSessionChecked] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const isAuthenticated = useSelector(
    (state: RootState) => state.session.isAuthenticated
  );

  const setLoginFailed = () => {};
  const loginFailed = false;

  const dispatch = useDispatch();

  const query = queryString.parse(location.search) as QueryState;

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
    setSessionChecked(true);
  }, []);

  useEffect(() => {
    if (sessionChecked) {
      if (!query.step) {
        history.push(`${location.pathname}?step=1`);
      }
      if (
        (query.step === "2" || query.step === "3" || query.step === "4") &&
        !parentEmail
      ) {
        history.push(`${location.pathname}?step=1`);
      }
      if (query.next) {
        setRedirect(query.next);
      }
    }
  }, [sessionChecked]);

  useEffect(() => {
    if (sessionChecked) {
      const stepNumber = parseInt(query.step, 10);
      if (!query.step) {
        history.push(`${location.pathname}?step=1`);
      }
      if (
        (query.step === "2" || query.step === "3" || query.step === "4") &&
        !parentEmail
      ) {
        history.push(`${location.pathname}?step=1`);
      }
      setCurrentStep(stepNumber);
    }
  }, [query.step, sessionChecked]);

  useEffect(() => {
    if (sessionChecked) {
      // localStorage.removeItem('currentOrganizationId');
      // localStorage.removeItem('currentUserId');
      // localStorage.removeItem('accessToken');
    }
  }, [sessionChecked]);

  const props = {
    currentStep,
    emailLink,
    emailType,
    guide,
    lastStep,
    location,
    loginFailed,
    parentEmail,
    password,
    redirect,
    setCurrentStep,
    setEmail,
    setEmailLink,
    setEmailType,
    setLastStep,
    setPassword,
    history,
    setLoginFailed,
  };

  if (!query.step) {
    return <></>;
  }

  return <SignIn {...props} />;
};

export default Login;
