import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PointsMeter from 'components/Guide/StudentProfile/PointsMeter';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { GET_OTHER_USER_MODEL_CONFIG, GET_RANKS, GET_USER_CURRICULUM_SCORES } from 'models/User';
import { AppDispatch } from 'store';

export const StudentProfile = (props: any) => {
  const dispatch: AppDispatch = useDispatch();
  const [studentUser, setStudentUser] = useState(null);
  const [profileImageStyle, setProfileImageStyle] = useState(null);
  const [rank, setRank] = useState(null);

  useEffect(() => {
    dispatch(dataClientRequest({
      ...GET_OTHER_USER_MODEL_CONFIG,
      data: {
        id: props.match.params.id,
      },
    }))
      .then((payload: any) => {
        setStudentUser(payload.response.data.data);
      })
    dispatch(dataClientRequest({
      ...GET_USER_CURRICULUM_SCORES,
      data: {
        id: localStorage.getItem('currentUserId'),
      }
    }))
  }, []);

  useEffect(() => {
    if (studentUser) {
      if (studentUser.avatar) {
        switch (studentUser.avatar.id) {
          case 'a7a62b5d-f2a3-4c76-ae6e-7b8bd316b50e':
            setProfileImageStyle({ backgroundImage: 'url(/img/alien.jpg)' });
            break;
          case '430d3a38-b1ee-44d1-b11c-107a11860671':
            setProfileImageStyle({ backgroundImage: 'url(/img/cat.jpg)' });
            break;
          case '20edd67a-2ad2-4d23-b0de-e114fe39d8d8':
            setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
            break;
          case 'f9517d14-b581-4fab-b3a1-a2885f8088c7':
            setProfileImageStyle({ backgroundImage: 'url(/img/player.jpg)' });
            break;
          case '15143073-1633-4d98-b8b8-39f0f2f1d888':
            setProfileImageStyle({ backgroundImage: 'url(/img/rabbit.jpg)' });
            break;
          case '1fbff479-dec5-4f7c-9aba-60e7d70f6990':
            setProfileImageStyle({ backgroundImage: 'url(/img/spaceman.jpg)' });
            break;
          case '676e15c7-28c6-41a3-abcb-c70b21c14e3f':
            setProfileImageStyle({ backgroundImage: 'url(/img/octopus.jpg)' });
            break;
          case 'da3c7d84-8cbc-483e-8074-6784f3f7e416':
            setProfileImageStyle({ backgroundImage: 'url(/img/turtle.jpg)' });
            break;
          default:
            setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
            break;
        }
      } else {
        setProfileImageStyle({ backgroundImage: 'url(/img/dog.jpg)' });
      }
    }
  }, [studentUser])

  useEffect(() => {
    if (studentUser) {
      if (studentUser.rank) {
        dispatch(dataClientRequest({
          ...GET_RANKS,
        })).then((response: any) => {
          let currentRank = response.response.data.data.find((rank: any) => {
            return rank.id === studentUser.rank.id;
          })
          if (currentRank) {
            setRank({ name: currentRank.name.split(/[()]+/)[1], level: (currentRank.position + 1) });
          } else {
            setRank({ name: 'Codeling', level: 1 });
          }
        })
      } else {
        setRank({ name: 'Codeling', level: 1 });
      }
    }
  }, [studentUser])

  return (
    <div className="student-profile mt-4">
      {studentUser && rank
        ?
        <div className="student-profile__header-box">
          <div className="student-profile__identity-box">
            <div style={profileImageStyle} className="student-profile__avatar" />
            <div className="student-profile__name-box">
              <div className="student-profile__full-name">
                {studentUser.name}
              </div>
              <div className="student-profile__rank">
                Level {rank.level} - {rank.name}
              </div>
            </div>
          </div>
          <div className="student-profile__points-box">
            <PointsMeter startPoints={50} addedPoints={0} />
          </div>
        </div>
        :
        <div>
          <div className="nova-portal m-auto loader fadeIn" />
        </div>
      }
    </div>
  );
}