import { omit } from 'lodash';

// initalState
const initialState: any = {
};


export function UIReducer(state = initialState, action: any): any {
  const formatType = (type: string) => {
    return type
      .replace('GET_', '')
      .replace('_REQUEST', '')
      .replace('_FAILURE', '')
      .replace('_SUCCESS', '')
      .toLowerCase();
  };
  if (action.type === 'OPEN_MODAL') {
    return {
      ...state,
      [action.name]: true,
    };
  } else if (action.type === 'CLOSE_MODAL') {
    return {
      ...state,
      [action.name]: false,
    };
  }

  if (action.type === 'SET_CURRENT_CARD_COLOR') {
    return {
      ...state,
      currentCardColor: action.color,
    };
  }
  if (action.type === 'SET_CURRENT_PASSWORD_OBJECT') {
    return {
      ...state,
      currentPasswordObject: action.object,
    };
  }

  if (action.type === 'SHOW_VIDEO_SIDE_PANEL') {
    return { ...state, showVideoSidePanel: true };
  }
  if (action.type === 'HIDE_VIDEO_SIDE_PANEL') {
    return { ...state, showVideoSidePanel: false };
  }
  if (action.isFetching) {
    const actionType = formatType(action.type);
    return { ...state, [actionType]: true };
  }

  if (!action.isFetching) {
    const actionType = formatType(action.type);
    return { ...state, [actionType]: false };
  }


  return state;
}