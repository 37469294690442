import React, { useState } from "react";
import SatelliteClient from "@codeverse/satellite-client";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import PicturePasswordForm from "components/schools/PicturePasswordForm";

declare var heap: any;

export const timeDifference = (current: any, previous: any) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = previous - current;
  if (elapsed < 0) {
    return elapsed;
  }
  if (elapsed < msPerMinute) {
    return "less than 1 minute";
  } else if (elapsed < msPerHour) {
    return `${Math.round(elapsed / msPerMinute)} minutes`;
  } else if (elapsed < msPerDay) {
    return `${Math.round(elapsed / msPerHour)} hours`;
  } else if (elapsed < msPerMonth) {
    return `approximately ${Math.round(elapsed / msPerDay)} days`;
  } else {
    return elapsed;
  }
};

const Step2: React.FC<any> = ({ currentOrganizationId, userName, history }) => {
  const dispatch = useDispatch();
  const [pinError, setPinError] = useState("");
  const handleCardClick = (object: any) => {
    setPinError("");
    const tries = parseInt(localStorage.getItem("tries"), 10);
    if (tries === 0) {
      const triesTimestamp = localStorage.getItem("triesTimestamp");
      const triesDifference = timeDifference(
        new Date(),
        new Date(triesTimestamp)
      );
      if (triesDifference < 0) {
        console.log("Unlocked");
      } else {
        history.push("/schools/sign-in?step=1");
        setTimeout(() => {
          toast.error(
            `You are currently locked from attempts for ${triesDifference}.`,
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }, 300);
        return;
      }
    }
    SatelliteClient.platform
      .post("/sessions", {
        headers: Object.assign(
          {},
          { Accept: "application/vnd.api+json" },
          { "content-type": "application/vnd.api+json" }
        ),
        data: {
          badge: userName.toLowerCase(),
          pin: object.pin,
          relationships: {
            organization: { type: "organizations", id: currentOrganizationId },
          },
        },
        serializer: "sessions",
      })
      .then((response: any) => {
        const { access_token, user } = response.data.data;
        const userProperties = Object.assign({
          Name: user.name,
          User_Scope: user.scope,
        });
        // @ts-ignore
        if (window.heap) {
          // @ts-ignore
          window.heap.resetIdentity();
          // @ts-ignore
          window.heap.identify(user.referral_code);
          // @ts-ignore
          window.heap.addUserProperties(userProperties);
        }
        window.location.replace(
          `${process.env.REACT_APP_ESCAPE_POD_URL}/home?access_token=${access_token.access_token}&current_user_id=${user.id}&current_organization_id=${currentOrganizationId}&school=true&organization_classification=school&organization_status=freemium`
        );
      })
      .catch(() => {
        if (!tries) {
          const errorMessage =
            "Wrong character password. You have 2 tries left.";
          // @ts-ignore
          localStorage.setItem("tries", 2);
          toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER });
        } else if (tries === 1) {
          const timeObject = new Date();
          timeObject.setTime(timeObject.getTime() + 1000 * 60);
          const triesDifference = timeDifference(new Date(), timeObject);
          // @ts-ignore
          localStorage.setItem("tries", 0);
          // @ts-ignore
          localStorage.setItem("triesTimestamp", timeObject);
          history.push("/schools/sign-in?step=1");
          setTimeout(() => {
            toast.error(
              `You are currently locked from attempts for ${triesDifference}.`,
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          }, 300);
        } else {
          const newTries = tries - 1;
          let errorMessage = "";
          // @ts-ignore
          localStorage.setItem("tries", tries - 1);
          if (newTries === 1) {
            errorMessage = `Wrong character password. You have ${newTries} try left.`;
          } else {
            errorMessage = `Wrong character password. You have ${newTries} tries left.`;
          }
          toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER });
        }
      });
    // toast.error(`You are currently blocked for ${triesDifference}`, {
    //   position: toast.POSITION.TOP_CENTER,
    // });
    // add code here for signin
  };
  return (
    <PicturePasswordForm
      handleCardClick={handleCardClick}
      pinError={pinError}
      header="What's&nbsp;your Password&nbsp;Character?"
      subtext=""
      undertext="I don't remember my character"
    />
  );
};

export default Step2;
