import React from 'react';
import classNames from 'classnames';

const ProgressIcons: React.FC<any> = ({ currentStep }) => {

  const circle2Classnames = classNames('progress-icons__circle', {
    disabled: currentStep === 1,
  });
  const circle3Classnames = classNames('progress-icons__circle', {
    disabled: currentStep !== 3,
  });

  const iconText1Classnames = classNames('progress-icons__icon-text', {
    active: currentStep === 1,
  });
  const iconText2Classnames = classNames('progress-icons__icon-text', {
    active: currentStep === 2,
  });
  const iconText3Classnames = classNames('progress-icons__icon-text', {
    active: currentStep === 3,
  });

  const progressIconClassnames = classNames('progress-icons', {
    // 'd-none': currentStep === 4,
  })

  return (
    <div className={progressIconClassnames}>
      <div className="progress-icons__icon" onClick={() => window.location.hash = 'parent-information'}>
        <div className="progress-icons__circle">
          {
            currentStep >= 2 ?
              <i className="fas fa-check" />
              :
              1
          }
        </div>
        <div className={iconText1Classnames}>Parent Info</div>
      </div>
      <div className="progress-icons__line" />
      <div className="progress-icons__icon">
        <div className={circle2Classnames}>
          {
            currentStep === 3 ?
              <i className="fas fa-check" />
              :
              2
          }
        </div>
        <div className={iconText2Classnames}>Who's coding?</div>
      </div>
      <div className="progress-icons__line" />
      <div className="progress-icons__icon">
        <div className={circle3Classnames}>
          3
        </div>
        <div className={iconText3Classnames}>Set Password</div>
      </div>
    </div>
  );
};

export default ProgressIcons;
