const levels = {
  1: '▃',
  2: '▃▄',
  3: '▃▄▅',
  4: '▃▄▅▆',
  5: '▃▄▅▆▇'
};

export function printNetworkQualityStats(networkQualityLevel: any, networkQualityStats: any) {
  // Print in console the networkQualityLevel using bars

  if (networkQualityStats) {
    // Print in console the networkQualityStats, which is non-null only if Network Quality
    // verbosity is 2 (moderate) or greater
    console.log('Network Quality statistics:', networkQualityStats);
  }
  // @ts-ignore
  return levels[networkQualityLevel];
}