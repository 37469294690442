import React, { useState } from 'react';
import { Button } from '@codeverse/react-helios-ui';
import ReactTooltip from 'react-tooltip';

import TextAreaInput from 'components/Shared/TextAreaInput';

type Props = {
  childName?: any;
  history?: any;
  location?: any;
  parentNote?: any;
  parentNoteHeader?: any;
  setCurrentStep?: any;
  setParentNote?: any;
  setSubtext?: any;
  setTitle?: any;
};

const Step3: React.FC<Props> = ({ childName, parentNote, parentNoteHeader, setCurrentStep, setParentNote, setSubtext, setTitle }) => {
  const [labelText, setLabelText] = useState(`What did ${childName} work on today? What should ${childName} work on next?`);

  return (
    <div className="step3">
      <div className="question">Write a note to {childName}'s parents about this session:</div>
      <div className="premade-message">
        <div className="premade-message__text-container">
          <span className="premade-message__text">
            {parentNoteHeader}
          </span>
          <i data-tip data-for='infoI' data-event='click mouseenter' className="fas fa-info premade-message__info-i"></i>
          <ReactTooltip id='infoI' aria-haspopup='true' effect='solid' type="light" globalEventOff='click' clickable={true} className="info-tooltip">
            This greeting will be automatically <br /> generated by our system.
          </ReactTooltip>
        </div>
      </div>
      <div className="step3__text-input-container">
        <TextAreaInput
          setInputText={setParentNote}
          inputText={parentNote}
          labelText={labelText}
          onRenderFunction={() => {
            if (parentNote.length > 0) {
              setLabelText('');
            }
          }}
          onFocusFunction={() => {
            setLabelText('');
          }}/>
      </div>
      <Button
        context="primary"
        className="w-100"
        disabled={parentNote === ''}
        onClick={() => {
          setCurrentStep('3b');
          setSubtext(null);
          setTitle(`Review your Message for ${childName}'s Parents`);
        }}
      >
        Continue
      </Button>
    </div>
  );
}

export default Step3;