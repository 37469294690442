import moment from "moment-timezone";
import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import { RootState } from 'store/state';

const numberStyle = {
  width: '28px',
  height: '28px',
  margin: '2px',
  borderRadius: '50%',
  background: 'white',
  color: '#000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '15px',
};

const textStyle = {
  flex: 1,
  marginLeft: '16px',
};


const Overview: React.FC<any> = () => {
  const currentChildIndex = useSelector((state: RootState) => state.checkout.currentChildIndex);
  const checkoutChildren = useSelector((state: RootState) => state.checkout.children);

  const timezone = useMemo(() => {
    let userTimezone = "";
    const zonenameAbbr = moment.tz(moment.tz.guess()).zoneAbbr();
    switch (zonenameAbbr) {
      case "CST":
        userTimezone = "America/Chicago";
        break;
      case "MST":
        userTimezone = "America/Denver";
        break;
      case "PST":
        userTimezone = "America/Los_Angeles";
        break;
      case "EST":
        userTimezone = "America/New_York";
        break;
      case "CDT":
        userTimezone = "America/Chicago";
        break;
      case "MDT":
        userTimezone = "America/Denver";
        break;
      case "PDT":
        userTimezone = "America/Los_Angeles";
        break;
      case "EDT":
        userTimezone = "America/New_York";
      default:
        userTimezone = "America/Chicago";
        break;
    }
    return userTimezone;
  }, []);
    
  const date = useMemo(() => {
    const slotInstanceMomentStartObj = moment
      .tz(
        `${checkoutChildren[0].slot_instance.start_date}T${checkoutChildren[0].slot_instance.start_time}`,
        "America/Chicago"
      )
      .tz(timezone);

    return slotInstanceMomentStartObj.format('MMM D');
  }, [timezone]);

  return (
    <div className="m-width-checkout" style={{ padding: '0px 24px' }}>
      <div className="header" style={{ fontSize: '35px', color: '#0E1540' }}>What to expect for {checkoutChildren[0].name.split(' '[0])}'s class?</div>
      
      <div style={{ backgroundColor: '#EEF6FD', padding: '24px 24px 0px 34px', height: 'auto', borderRadius: '8px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <div className="downward" style={{ width: '32px' }}>
            <div style={Object.assign({}, { ...numberStyle })}>1</div>
            <div style={Object.assign({}, { ...numberStyle }, { marginTop: '80px', background: 'rgba(255, 255, 255, .4)', color: 'white' })}>2</div>
            <div style={Object.assign({}, { ...numberStyle }, { marginTop: '84px', background: 'rgba(255, 255, 255, .4)', color: 'white' })}>3</div>
            <div style={Object.assign({}, { ...numberStyle }, { marginTop: '84px', background: 'rgba(255, 255, 255, .4)', color: 'white' })}>4</div>
          </div>
          <div>
            <div className="" style={textStyle}>
              <div className="font-poppins-bold block" style={{ fontSize: '14px', color: '#0E1540', lineHeight: '24px', marginTop: '4px' }}>Verify class time</div>
              <div className="font-poppins-regular" style={{ fontSize: '14px', color: '#474C72', lineHeight: '20px' }}>Reply to our text to confirm your 1:1 coding session</div>
            </div>
            <div className="" style={textStyle}>
              <div className="font-poppins-bold block" style={{ fontSize: '14px', color: '#0E1540', marginTop: '24px', lineHeight: '24px' }}>Start coding today</div>
              <div className="font-poppins-regular" style={{ fontSize: '14px', color: '#474C72', lineHeight: '20px' }}>Your kid has complete access to begin exploring Codeverse</div>
            </div>
            <div className="" style={textStyle}>
              <div className="font-poppins-bold block" style={{ fontSize: '14px', color: '#0E1540', marginTop: '28px', lineHeight: '24px' }}>Show up on {date}</div>
              <div className="font-poppins-regular" style={{ fontSize: '14px', color: '#474C72', lineHeight: '20px' }}>Log into Codeverse on a computer or tablet and click “Join”</div>
            </div>
            <div className="" style={textStyle}>
              <div className="font-poppins-bold block" style={{ fontSize: '14px', color: '#0E1540', marginTop: '28px', lineHeight: '24px' }}>Pick your memberships</div>
              <div className="font-poppins-regular" style={{ fontSize: '14px', color: '#474C72', lineHeight: '20px', marginBottom: '24px' }}>After your class, we will help you choose one of our flexible plans to continue your kid's coding journey</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;