import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input } from '@codeverse/react-helios-ui';
import { PasswordValidation } from '@codeverse/helios-solarwind';
import { motion, AnimatePresence } from 'framer-motion';
import queryString from 'query-string';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { login } from 'store/modules/Session/actions';
import LoginLayout from 'components/Layout/LoginLayout';

import { POST_PASSWORD_RESET_ACCESS_TOKEN_MODEL_CONFIG, UPDATE_USER_PASSWORD_MODEL_CONFIG } from 'models/User';
import { dataClientRequest } from 'store/data-client';
import { AppDispatch } from 'store';

type IProps = {
  history?: any;
  location?: any;
};


const NewPasswordForm: React.FC<IProps> = ({
  location,
  history,
}) => {
  const [password, setPassword] = useState('');
  const [inputType, setInputType] = useState('password');
  const [showPass, setShowPass] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [resetError, setResetError] = useState(false);
  const query = queryString.parse(location.search);
  const [passwordResetUserId, setPasswordResetUserId] = useState('');
  const [passwordResetUserEmail, setPasswordResetUserEmail] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const [headerText, setHeaderText] = useState('Choose a password');
  const [passwordValid, setPasswordValid] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      localStorage.removeItem('accessToken');
    }
  }, []);

  useEffect(() => {
    const password_reset_token = query.password_reset_token;
    if (password_reset_token) {
      dispatch(dataClientRequest({
        ...POST_PASSWORD_RESET_ACCESS_TOKEN_MODEL_CONFIG,
        data: {
          token: password_reset_token,
        },
      }))
        .then((data: any) => {
          localStorage.setItem('accessToken', data.response.data.data.access_token.access_token)
          localStorage.setItem('currentUserId', data.response.data.data.user.id)
          setPasswordResetUserId(data.response.data.data.user.id);
          setPasswordResetUserEmail(data.response.data.data.user.email);
          setHeaderText('Reset password');
        })
        .catch((error: any) => {
          toast.error('Authentication failed because you accessed this page from an expired email. Go to account.codeverse.com/login to send yourself a new email.', {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  }, []);

  const hasNumber = (myString: string) => {
    return /\d/.test(myString);
  };

  const hasLetter = (myString: string) => {
    return /[a-z]/i.test(myString);
  };

  const handleUpdateUserPassword = (e: any) => {
    e.preventDefault();
    if (password.length < 8 || !hasNumber(password) || !hasLetter(password)) {
      toast.error('Your new password does not meet character requirements', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      dispatch(dataClientRequest({
        ...UPDATE_USER_PASSWORD_MODEL_CONFIG,
        data: {
          id: passwordResetUserId,
          password: password,
        },
      }))
        .then(() => {
          return dispatch(login({ email: passwordResetUserEmail, password, history }, '/', false));
        })
        .catch((error: any) => {
          toast.error('Failed to change your password.', {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const showPassword = () => {
    setShowPass(!showPass);
    setInputType(!showPass ? 'text' : 'password');
  };

  const iconClassnames = classNames('far fa-lg btn-icon',
    {
      'fa-eye-slash': !showPass,
      'fa-eye': showPass,
    },
  );

  return (
    <LoginLayout>
      <div className="org-login__form-container">
        <div className="org-login__header d-flex justify-content-center">
          <img src="/img/codeverse-logo-full-color.svg" width="217" height="48" alt="Codeverse" />
        </div>
        <AnimatePresence>
          <motion.div
            className="org-login__information-form-container"
            key={1}
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -1000, opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <h1 className="org-login__information-header header-two-tone pb-4">{headerText}</h1>
            <p className="org-login__subtext pb-2">
              Create a new password for your account. Make it a strong one!
            </p>
            <div className="login-field mt-3">
              <Input
                className="org-login__name-input"
                name="password"
                label="Password"
                onChange={(e: any) => setPassword(e.target.value)}
                type={inputType}
                value={password}
                showError={authError || resetError}
              />
              <i onClick={() => showPassword()} className={iconClassnames} />
            </div>
            <div className="org-login__password-validation mt-2">
              <PasswordValidation
                value={password}
                passwordValid={passwordValid}
                setPasswordValid={setPasswordValid}
                minLength={8}
                minLetters={1}
                minNumbers={1}
              />
            </div>
            <div className="mt-3">
              <Button
                onClick={(e: any) => handleUpdateUserPassword(e)}
                className="org-login__button w-100"
                context="primary"
                disabled={!passwordValid}
                withoutSpan={
                  <>
                    <span>
                      Sign In
                    </span>
                    <i className="fas fa-chevron-right" />
                  </>
                }
              />
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </LoginLayout>
  );
};

export default NewPasswordForm;
