import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Button, Col, Row } from '@codeverse/react-helios-ui';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { toast } from 'react-toastify';

import { closeModal, openModal } from 'store/modules/UI/actions';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';
import { CREATE_BOOKING, UPDATE_BOOKING } from 'models/Bookings';

type Props = {
  history?: any;
  location?: any;
  match?: any;
  bookings?: any;
  currentBooking: any;
  setBookingsUpdated?: any;
  currentBookingInstance?: any;
  currentSlotInstance?: any;
  onlyOneDay?: any;
  notRecurring?: any;
  userBookingInstances?: any;
};

const SAFE_NUMBER = 8;

const Step3: React.FC<Props> = ({ setBookingsUpdated, bookings, currentBooking, currentBookingInstance, currentSlotInstance, onlyOneDay, notRecurring, userBookingInstances }) => {
  const dispatch: AppDispatch = useDispatch();
  const [guideUser, setGuideUser] = useState(null);
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const timezone = useSelector((state: RootState) => state.user.currentUser.time_zone);
  const bookingDate = moment(currentBookingInstance.start_date, 'YYYY-MM-DD').format('M/DD');
  const oldStartTime = moment.tz(currentBookingInstance.start_time, 'HH:mm', 'America/Chicago').tz(timezone).format('h:mm A');
  const newStartTime = moment.tz(currentSlotInstance.start_time, 'HH:mm', 'America/Chicago').tz(timezone).format('h:mm A');
  const newEndTime = moment.tz(currentSlotInstance.end_time, 'HH:mm', 'America/Chicago').tz(timezone).format('h:mm A');
  const timeZone = moment.tz(timezone).format('z');

  const slotInstancePreferredGuideObj = currentSlotInstance.meta.preferred_guides.reduce(function(prev: any, current: any) {
    return (prev.position <= current.position) ? prev : current;
  }, SAFE_NUMBER);

  let guidePhotoStyle = null;

  if (slotInstancePreferredGuideObj !== SAFE_NUMBER) {
    if (slotInstancePreferredGuideObj.photo) {
      guidePhotoStyle = {
        backgroundImage: `url(${slotInstancePreferredGuideObj.photo})`
      }
    } else {
      guidePhotoStyle = {
        backgroundImage: 'url(/icons/new-guide-icon.png)',
      }
    }
  } else {
    guidePhotoStyle = {
      backgroundImage: 'url(/icons/new-guide-icon.png)',
    }
  }

  const handleReschedule = () => {
    const currentStudent = currentBooking.user.id;
    const foundBookingInstance = userBookingInstances.find((userBookingInstance: any) => {
      return userBookingInstance.start_date === currentSlotInstance.start_date && userBookingInstance.start_time === currentSlotInstance.start_time;
    });

    if (foundBookingInstance && currentStudent === foundBookingInstance.user_id) {
      toast.error('Failed to update booking because a session already exists at this time.', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (notRecurring) {
      dispatch(dataClientRequest({
        ...UPDATE_BOOKING,
        data: {
          id: currentBooking.id,
          completed_reason: 'superseded',
        }
      }))
      .then(() => {
        dispatch(dataClientRequest({
          ...CREATE_BOOKING,
          data: {
            start_hour: parseInt(moment(currentSlotInstance.start_time, 'HH:mm').format('H')),
            start_minute: parseInt(moment(currentSlotInstance.start_time, 'HH:mm').format('mm')),
            end_hour: parseInt(moment(currentSlotInstance.end_time, 'HH:mm').format('H')),
            end_minute: parseInt(moment(currentSlotInstance.end_time, 'HH:mm').format('mm')),
            first_visit_at: currentSlotInstance.start_date,
            last_visit_at: currentSlotInstance.start_date,
            relationships: {
              user: { type: 'users', id: currentStudent },
              occasion: { type: 'occasions', id:  currentSlotInstance.occasion_id },
            }
          }
        }))
        .then(() => {
          dispatch(closeModal('rescheduleModal'));
          setBookingsUpdated(true);
        })
        .catch((error) => {
          toast.error(`Failed to update booking: ${error}`, {
            position: toast.POSITION.TOP_CENTER,
          });
          dispatch(closeModal('rescheduleModal'));
          console.log('error 1')
        })
      })
      .catch((error) => {
        toast.error(`Failed to create new booking: ${error}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(closeModal('rescheduleModal'));
      })
    } else {
      if (onlyOneDay) {
        dispatch(dataClientRequest({
          ...CREATE_BOOKING,
          data: {
            start_hour: parseInt(moment(currentSlotInstance.start_time, 'HH:mm').format('H')),
            start_minute: parseInt(moment(currentSlotInstance.start_time, 'HH:mm').format('mm')),
            end_hour: parseInt(moment(currentSlotInstance.end_time, 'HH:mm').format('H')),
            end_minute: parseInt(moment(currentSlotInstance.end_time, 'HH:mm').format('mm')),
            first_visit_at: currentSlotInstance.start_date,
            last_visit_at: currentSlotInstance.start_date,
            relationships: {
              user: { type: 'users', id: currentStudent },
              occasion: { type: 'occasions', id:  currentSlotInstance.occasion_id },
            }
          }
        }))
        .then(() => {
          dispatch(dataClientRequest({
            ...UPDATE_BOOKING,
            data: {
              id: currentBooking.id,
              excluded_dates: [...currentBooking.excluded_dates, currentBookingInstance.start_date],
            }
          }))
          .then(() => {
            dispatch(closeModal('rescheduleModal'));
            setBookingsUpdated(true);
          })
          .catch((error) => {
            toast.error(`Failed to update booking: ${error}`, {
              position: toast.POSITION.TOP_CENTER,
            });
            dispatch(closeModal('rescheduleModal'));
            console.log('error 2');
          })
        })
        .catch((error) => {
          toast.error(`Failed to create new booking: ${error}`, {
            position: toast.POSITION.TOP_CENTER,
          });
          dispatch(closeModal('rescheduleModal'));
        })
      } else {
        let futureNonRecurringBookings = bookings.filter((booking: any) => {
          return booking.user.id === currentStudent && moment(booking.first_visit_at, 'YYYY-MM-DD').isAfter(currentSlotInstance.start_date) && booking.last_visit_at === booking.first_visit_at
        });
        dispatch(dataClientRequest({
          ...CREATE_BOOKING,
          data: {
            start_hour: parseInt(moment(currentSlotInstance.start_time, 'HH:mm').format('H')),
            start_minute: parseInt(moment(currentSlotInstance.start_time, 'HH:mm').format('mm')),
            end_hour: parseInt(moment(currentSlotInstance.end_time, 'HH:mm').format('H')),
            end_minute: parseInt(moment(currentSlotInstance.end_time, 'HH:mm').format('mm')),
            first_visit_at: currentSlotInstance.start_date,
            last_visit_at: currentBooking.last_visit_at,
            excluded_dates: futureNonRecurringBookings.map((booking: any) => booking.first_visit_at),
            relationships: {
              user: { type: 'users', id: currentStudent },
              occasion: { type: 'occasions', id:  currentSlotInstance.occasion_id },
            }
          }
        }))
        .then(() => {
          let futureRecurringBookings = bookings.filter((booking: any) => {
            return booking.user.id === currentStudent && moment(booking.first_visit_at, 'YYYY-MM-DD').isAfter(currentSlotInstance.start_date) && (booking.last_visit_at === null || moment(bookings.last_visit_at, 'YYYY-MM-DD').isAfter(moment(booking.first_visit_at, 'YYYY-MM-DD')))
          })
          if (moment(currentBooking.first_visit_at, 'YYYY-MM-DD').isSame(moment(currentSlotInstance.start_date, 'YYYY-MM-DD'),'week')) {
            dispatch(dataClientRequest({
              ...UPDATE_BOOKING,
              data: {
                id: currentBooking.id,
                completed_reason: 'superseded',
              }
            }))
            .then(() => {
              // if (futureRecurringBookings && futureRecurringBookings.length > 0) {
              //   futureRecurringBookings.forEach((booking: any) => {
              //     dispatch(dataClientRequest({
              //       ...UPDATE_BOOKING,
              //       data: {
              //         id: booking.id,
              //         completed_reason: 'superseded',
              //       }
              //     }))
              //     .then(() => {
              //       dispatch(closeModal('rescheduleModal'));
              //       setBookingsUpdated(true);
              //     })
              //   })
              // } else {
              dispatch(closeModal('rescheduleModal'));
              setBookingsUpdated(true);
              // }
            })
            .catch((error) => {
              toast.error(`Failed to update booking: ${error}`, {
                position: toast.POSITION.TOP_CENTER,
              });
              dispatch(closeModal('rescheduleModal'));
              console.log('error 3');
            })
          } else {
            dispatch(dataClientRequest({
              ...UPDATE_BOOKING,
              data: {
                id: currentBooking.id,
                last_visit_at: moment(currentSlotInstance.start_date, 'YYYY-MM-DD').startOf('week').format('YYYY-MM-DD'),
              }
            }))
            .then(() => {
              // if (futureRecurringBookings && futureRecurringBookings.length > 0) {
              //   futureRecurringBookings.forEach((booking: any) => {
              //     dispatch(dataClientRequest({
              //       ...UPDATE_BOOKING,
              //       data: {
              //         id: booking.id,
              //         completed_reason: 'superseded',
              //       }
              //     }))
              //     .then(() => {
              //       dispatch(closeModal('rescheduleModal'));
              //       setBookingsUpdated(true);
              //     })
              //   })
              // } else {
              dispatch(closeModal('rescheduleModal'));
              setBookingsUpdated(true);
              // }
            })
            .catch((error) => {
              toast.error(`Failed to update booking: ${error}`, {
                position: toast.POSITION.TOP_CENTER,
              });
              dispatch(closeModal('rescheduleModal'));
              console.log('error 3');
            })
          }
        })
        .catch((error) => {
          toast.error(`Failed to create new booking: ${error}`, {
            position: toast.POSITION.TOP_CENTER,
          });
          dispatch(closeModal('rescheduleModal'));
        })
      }
    }
  }

  return (
    <div className="step3">
      <div className="step3__subtext">Your {!notRecurring && !onlyOneDay && 'recurring'} session{!notRecurring && !onlyOneDay && 's'} {!notRecurring && !onlyOneDay ? 'starting' : 'on'} <b>{bookingDate} @{oldStartTime}</b> {!notRecurring && !onlyOneDay && 'and moving forward'} will be rescheduled to the following:</div>
      <div className="step3__slot-instance-box">
        <div className="step3__slot-instance-box--header">
          New Session Date and Time
        </div>
        <hr />
        <div className="step3__slot-instance-box--info">
          <div className="step3__slot-instance-box--text-box">
            <div className="step3__slot-instance-box--info1">
              {moment(currentSlotInstance.start_date, 'YYYY-MM-DD').format('LL')}
            </div>
            <div className="step3__slot-instance-box--info2">
              {newStartTime}&nbsp;-&nbsp;{newEndTime}&nbsp;{timeZone}
            </div>
          </div>
          {
            (slotInstancePreferredGuideObj && slotInstancePreferredGuideObj !== SAFE_NUMBER && slotInstancePreferredGuideObj.bookable) &&
            <div className="step3__preferred-guide">
              <div className="step3__preferred-guide--info-container">
                <div className="step3__preferred-guide--title">GUIDE</div>
                {
                  slotInstancePreferredGuideObj &&
                  <div className="step3__preferred-guide--name">{slotInstancePreferredGuideObj.name.split(' ')[0]}&nbsp;{(slotInstancePreferredGuideObj.name.split(' ')[slotInstancePreferredGuideObj.name.split(' ').length - 1]).charAt(0)}.</div>
                }
                {
                  moment.tz(currentSlotInstance.start_date, 'America/Chicago').isBefore(moment.tz('America/Chicago').add(14, 'days')) &&
                  <div className="session-card__preferred-guide--status">Confirmed</div>
                }
              </div>
              <div style={guidePhotoStyle} className="step3__preferred-guide--picture"></div>
            </div>
          }
        </div>
      </div>
      <div className="reschedule-modal__actions">
        <Row>
          <Col md={6} className="pr-1">
            <Button onClick={() => dispatch(closeModal('rescheduleModal'))} context="secondary" className="w-100 step3__cancel-button">
              Cancel
            </Button>
          </Col>
          <Col md={6} className="pl-1">
            <Button onClick={() => handleReschedule()} context="primary" className="w-100">
              Reschedule
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Step3;

