import React from 'react';
import { CopyField } from '@codeverse/react-helios-ui';
import ClassNames from 'classnames';

type Props = {
  history?: any;
  location?: any;
  referralCode?: any;
  addSpacing?: any;
};

export const ReferralBox: React.FC<Props> = (props) => {
  const astronautStyle = {
    left: '50%',
    transform: 'translateX(-50%)',
  };

  const cardClassNames = ClassNames('card fadein-short', {
    'referral-box': props.addSpacing,
  });


  return (
    <div className={cardClassNames}>
      <div className="card-body pb-3">
        <img className="pb-4 mt-2 position-relative" style={astronautStyle} src="/img/astronaut.svg" />
        <h4 className="card-title text-center">Create With Friends</h4>
        <p className="card-text text-center pb-3">Tell a friend to enter this code at checkout and you’ll each get <br /><strong id="referralEmphasis" style={{ display: 'inline-block' }}>25% off your next month!</strong></p>
        <div className="form-group">
          <CopyField value={`${props.referralCode}`} />
        </div>
      </div>
    </div>
  );
};

