import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Input } from '@codeverse/react-helios-ui';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { dataClientRequest } from '@codeverse/redux-data-client';

import { AppDispatch } from 'store';
import { RootState } from 'store/state';
import { UPDATE_USER_MODEL_CONFIG, CREATE_USER_ADDRESS_MODEL_CONFIG, UPDATE_USER_ADDRESS_MODEL_CONFIG, CREATE_USER_PHONE_NUMBER_MODEL_CONFIG, UPDATE_USER_PHONE_NUMBER_MODEL_CONFIG } from 'models/User';
import { getCookie, saveCookie } from 'store/auth';


import getCountryName from 'utils/determineCountryName';

const EditAccountModal: React.FC<any> = ({ currentUser, handleClose }) => {
  const dispatch: AppDispatch = useDispatch();
  const userPhoneNumbers = useSelector((state: RootState) => state.user.userPhoneNumbers);
  const userAddresses = useSelector((state: RootState) => state.user.userAddresses);

  const [fullName, setFullName] = useState(currentUser.name || '');
  const [emailAddress, setEmailAddress] = useState(currentUser.email || '');

  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [country, setCountry] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryNumber, setCountryNumber] = useState('');

  const [infoChanged, setInfoChanged] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);
  const [phoneUpdated, setPhoneUpdated] = useState(false);
  const [addressUpdated, setAddressUpdated] = useState(false);
  const [updateSucceeded, setUpdateSucceeded] = useState(0);

  const mainAddress = userAddresses[0];
  const mainPhoneNumber = userPhoneNumbers[0];

  useEffect(() => {
    if (userPhoneNumbers.length > 0) {
      setPhoneNumber(userPhoneNumbers[0].phone_number);
      setCountryNumber(userPhoneNumbers[0].dialing_code);
    }
  }, [userPhoneNumbers])

  useEffect(() => {
    if (userAddresses.length > 0) {
      setStreetAddress(userAddresses[0].address1);
      setStreetAddress2(userAddresses[0].address2);
      setCity(userAddresses[0].city);
      setState(userAddresses[0].state);
      setZipcode(userAddresses[0].zip_code);
      setCountry(getCountryName(userAddresses[0].country_code));
    }
  }, [userAddresses])

  const handleSave = (e: any) => {
    const currentUserId = localStorage.getItem('currentUserId') || '';

    if (userUpdated) {
      dispatch(dataClientRequest({
        ...UPDATE_USER_MODEL_CONFIG,
        data: {
          name: fullName,
          email: emailAddress,
          id: currentUserId,
        },
      }))
        .then((payload: any) => {
          if (payload.response) {
            const user = payload.response.data.data;
            const cookie = getCookie();
            if (cookie && cookie.access_token && cookie.user.id) {
              if (cookie.access_token.access_token) {
                cookie.user = user;
                saveCookie(cookie);
              }
            }
            setUpdateSucceeded(updateSucceeded * 2 + 1);
          }
        })
        .catch(() => {
          setUpdateSucceeded(updateSucceeded * 2);
        })
    }

    if (!mainPhoneNumber && phoneUpdated) {
      dispatch(dataClientRequest({
        ...CREATE_USER_PHONE_NUMBER_MODEL_CONFIG,
        data: {
          phone_number: phoneNumber,
        },
      }))
        .then(() => {
          setUpdateSucceeded(updateSucceeded * 2 + 1);
        })
        .catch(() => {
          setUpdateSucceeded(updateSucceeded * 2);
        })
    } else if (phoneUpdated) {
      dispatch(dataClientRequest({
        ...UPDATE_USER_PHONE_NUMBER_MODEL_CONFIG,
        data: {
          phone_number: phoneNumber,
          id: mainPhoneNumber.id
        },
      }))
        .then(() => {
          setUpdateSucceeded(updateSucceeded * 2 + 1);
        })
        .catch(() => {
          setUpdateSucceeded(updateSucceeded * 2);
        })
    }

    if (!mainAddress && addressUpdated) {
      dispatch(dataClientRequest({
        ...CREATE_USER_ADDRESS_MODEL_CONFIG,
        data: {
          name: fullName,
          address1: streetAddress,
          address2: streetAddress2,
          city: city,
          state: state,
          zip_code: zipcode,
          country_code: 'US',
        },
      }))
        .then(() => {
          setUpdateSucceeded(updateSucceeded * 2 + 1);
        })
        .catch(() => {
          setUpdateSucceeded(updateSucceeded * 2);
        })
    } else if (addressUpdated) {
      dispatch(dataClientRequest({
        ...UPDATE_USER_ADDRESS_MODEL_CONFIG,
        data: {
          id: mainAddress.id,
          name: fullName,
          address1: streetAddress,
          address2: streetAddress2,
          city: city,
          state: state,
          zip_code: zipcode,
          country_code: 'US',
        },
      }))
        .then(() => {
          setUpdateSucceeded(updateSucceeded * 2 + 1);
        })
        .catch(() => {
          setUpdateSucceeded(updateSucceeded * 2);
        })
    }

    setUserUpdated(false);
    setPhoneUpdated(false);
    setAddressUpdated(false);
    setInfoChanged(false);
  };

  useEffect(() => {
    if (updateSucceeded !== 0) {
      if (updateSucceeded % 2 === 1) { 
        toast.success('Successfully updated profile', {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (updateSucceeded % 2 === 0) {
        toast.error('Failed to updated profile', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }, [updateSucceeded])

  return (
    <>
      <div className="edit-account-modal__content">
        <Row>
          <Col sm="12">
            <div className="mb-2 pb-1">
              <Input
                name="fullName"
                label="Full Name"
                onChange={(e: any) => { setFullName(e.target.value); setInfoChanged(true); setUserUpdated(true); }}
                value={fullName}
              />
            </div>
            {/* <div className="mb-2 pb-1">
              <Input
                name="streetAddress"
                label="Street Address"
                onChange={(e: any) => { setStreetAddress(e.target.value); setInfoChanged(true); setAddressUpdated(true); }}
                value={streetAddress}
              />
            </div>
            <div className="mb-2 pb-1">
              <Input
                name="streetAddress2"
                label="Street Address 2"
                onChange={(e: any) => { setStreetAddress2(e.target.value); setInfoChanged(true); setAddressUpdated(true); }}
                value={streetAddress2}
              />
            </div> */}
            {/* <Row>
              <Col sm="8">
                <div className="mb-2 pb-1">
                  <Input
                    name="city"
                    label="City"
                    onChange={(e: any) => { setCity(e.target.value); setInfoChanged(true); setAddressUpdated(true); }}
                    value={city}
                  />
                </div>
              </Col>
              <Col sm="4" className="pl-0">
                <div className="mb-2 pb-1">
                  <Input
                    name="state"
                    label="State"
                    onChange={(e: any) => { setState(e.target.value); setInfoChanged(true); setAddressUpdated(true); }}
                    value={state}
                  />
                </div>
              </Col>
            </Row>
            <div className="mb-2 pb-1">
              <Input
                name="zipcode"
                label="Zipcode"
                onChange={(e: any) => { setZipcode(e.target.value); setInfoChanged(true); setAddressUpdated(true); }}
                value={zipcode}
              />
            </div>
            <div className="mb-2 pb-3">
              <Input
                name="country"
                label="Country"
                onChange={(e: any) => { setCountry(e.target.value); setInfoChanged(true); setAddressUpdated(true); }}
                value={country}
                disabled
              />
            </div> */}
          {/* </Col>
          <Col sm="6"> */}
            {/* <div className="mb-2 pb-1">
              <Input
                name="accountType"
                label="Account Type"
                // onChange={(e: any) => { setFullName(e.target.value); setInfoChanged(true); setUserUpdated(true); }}
                value={"Primary"}
                disabled
              // showError={userNameError}
              />
            </div> */}
            <div className="mb-2 pb-1">
              <Input
                name="emailAddress"
                label="Email Address"
                value={emailAddress}
                disabled
              />
            </div>
            <Row>
              <Col sm="4" className="pr-0">
                <div className="mb-2 pb-1">
                  <Input
                    name="country#"
                    label="Country #"
                    onChange={(e: any) => { setCountryNumber(e.target.value); setInfoChanged(true); setPhoneUpdated(true); }}
                    value={countryNumber}
                    disabled
                  />
                </div>
              </Col>
              <Col sm="8">
                <div className="mb-2 pb-1">
                  <Input
                    name="phoneNumber"
                    label="Phone Number"
                    onChange={(e: any) => { setPhoneNumber(e.target.value); setInfoChanged(true); setPhoneUpdated(true); }}
                    value={phoneNumber}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="d-flex justify-content-between">
        <Button
            className="parent-profile__save-button"
            context="secondary"
            onClick={(e: any) => handleClose(e)}
            size="sm"
          >
            Cancel
          </Button>
        <Button
            className="parent-profile__save-button"
            context="primary"
            disabled={!infoChanged}
            onClick={(e: any) => handleSave(e)}
            size="sm"
          >
            Save Changes
          </Button>
        </div>
      </div>
    </>
  );
}

export default EditAccountModal;