import React from 'react';
import { Button, Col, Row } from '@codeverse/react-helios-ui';

type Props = {
  createNote?: any;
  history?: any;
  input1Text?: any;
  location?: any;
  parentNote?: any;
  parentNoteHeader?: any;
  setCurrentStep?: any;
  setShowHeader?: any;
  setBigMoney?: any;
  setTitle?: any;
  updateMeeting?: any;
};

const Step3b: React.FC<Props> = ({ createNote, input1Text, parentNote, parentNoteHeader, setCurrentStep, setShowHeader, setBigMoney, setTitle, updateMeeting }) => {
  const handleSend = () => {
    createNote('parent_update', parentNote);
    createNote('progress', input1Text);
    updateMeeting();
    setCurrentStep('3c');
    setShowHeader(false);
    setBigMoney(true);
  }

  return (
    <div className="step3">
      <div className="text-message">
        <div className="text-message__text">
          {parentNoteHeader} <br />
          <br />
          {parentNote}
        </div>
      </div>
      <div className="w-100">
        <Row>
          <Col className="px-0 pl-3" sm="4">
            <Button
              context="secondary"
              className="w-100 edit-button"
              onClick={() => {
                setCurrentStep('3a');
                setTitle('End of Session Survey');
                setShowHeader(true);
              }}
              withoutSpan={
                <>
                  <i className="fas fa-chevron-left back-arrow"></i>
                  <span>              
                    Edit
                  </span>
                </>
              }
            >
              Edit
            </Button>
          </Col>
          <Col sm="8">
            <Button
              context="primary"
              className="w-100"
              onClick={() => {
                handleSend();
              }}
            >
              Send Text
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Step3b;