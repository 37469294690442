import React from 'react';
import { ToastContainer } from 'react-toastify';

import Decorations from './Decorations';

const LoginLayout: React.FC<any> = ({
  children,
}) => {
  return (
    <div className="org-login h-100 w-100">
      <div className="org-login__container">
        <div className="org-login__panel-left">
          <ToastContainer autoClose={4000} />
          {children}
        </div>
        <Decorations />
      </div>
    </div>
  );
};

export default LoginLayout;
