import { GetClientConfig } from 'store/data-client';

export const GET_MEETING: GetClientConfig = {
  method: 'get',
  model: 'meetings',
  reducerKey: 'user',
  stateKey: 'currentMeeting',
}

export const GET_MEETING_PARTICIPATIONS: GetClientConfig = {
  method: 'get',
  submodel: 'participations',
  model: 'meetings',
  reducerKey: 'user',
  stateKey: 'participations',
}
export const UPDATE_MEETING: GetClientConfig = {
  method: 'put',
  model: 'meetings',
  reducerKey: 'user',
  stateKey: 'meetings',
}
