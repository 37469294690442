import React from 'react';
import { Button } from '@codeverse/react-helios-ui';

import FiveSmiley from 'components/Shared/FiveSmiley';

type Props = {
  history?: any;
  location?: any;
  setCurrentStep?: any;
  setSelectedIcon?: any;
  setSubtext?: any;
  selectedIcon?: any;
};

const Step2: React.FC<Props> = ({ setCurrentStep, setSelectedIcon, setSubtext, selectedIcon }) => {

  return (
    <div className="step2">
      <div className="question">How was your child’s session today?</div>
      <div className="my-4">
        <FiveSmiley setSelectedIcon={setSelectedIcon} selectedIcon={selectedIcon} />
      </div>
      <Button
        className="w-100"
        context="primary"
        disabled={!selectedIcon}
        onClick={() => {
          setSubtext("This message will be sent to your child's guide for the next session.");
          setCurrentStep('3');
        }}
      >
        Submit
      </Button>
    </div>
  );
}

export default Step2;