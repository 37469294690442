import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@codeverse/react-helios-ui';

import { RootState } from 'store/state';

import ViewSelector from 'components/Guide/Meeting/Header/ViewSelector';

import { CURRENT_VIEW } from 'containers/Guide/Meeting';

type Props = {
  history?: any;
  location?: any;
  currentFocusedParticipant?: any;
  currentView: CURRENT_VIEW;
  setCurrentView: any;
  handleLeaveRoom: any;
  connected: boolean;
  networkMeter: string;
  networkQualityLevel: number;
  followingCursor: boolean;
  setFollowingCursor: any;
};

const Header: React.FC<Props> = ({ history, location, currentFocusedParticipant, currentView, setCurrentView, handleLeaveRoom, connected, networkMeter, followingCursor, setFollowingCursor, networkQualityLevel }) => {
  const dispatch = useDispatch();

  return (
    <div className="meeting-header">
      <div className="meeting-header__actions">
        <div className="small-btn-action" onClick={() => {
          handleLeaveRoom();
          history.push('/guide/survey');
        }}>
          <div className="fas fa-sign-out" />
        </div>
      </div>
      {connected && <div style={{
        width: '226px',
        height: '22px',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        left: '132px',
        top: '15px',
        color: 'white'
      }}>
        <div className="network-header">Network</div>
        <div style={{ color: networkQualityLevel < 3 ? '#c00037' : '#17e898' }}>{networkMeter}</div>
      </div>
      }
      <div className="current-participant-name d-flex justify-content-center">
        {connected ? (currentFocusedParticipant.name ? currentFocusedParticipant.name.split(' ')[0] : 'Connected') : 'Connecting...'}
      </div>
      {/* {connected && <div
        style={{
          width: '226px',
          height: '22px',
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          right: '200px',
          top: '11px',
          minWidth: '176px',
        }}
      >
        {followingCursor ? <Button onClick={() => setFollowingCursor(false)} setFollowingCursor context="primary" size="sm">Unfollow Cursor</Button> : <Button onClick={() => setFollowingCursor(true)} context="primary" size="sm">Follow Cursor</Button>}
      </div>} */}
      <ViewSelector currentView={currentView} setCurrentView={setCurrentView} />
    </div>
  );
};

export default Header;
