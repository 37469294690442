import React from 'react';
import { Button, Card, CardHeader, CardBody, Col, Row } from '@codeverse/react-helios-ui';
import { useSelector } from 'react-redux';

import { RootState } from 'store/state';

type Props = {
  history?: any;
  location?: any;
  stripeAccount?: any;
}

export const StripeIntegrationSection: React.FC<Props> = ({ history, location, stripeAccount }) => {
  const userEmail = useSelector((state: RootState) => state.user.currentUser.email);

  const stateKey = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  const handleRedirect = () => {
    if (Object.keys(stripeAccount).length === 0) {
      window.open(`https://connect.stripe.com/express/oauth/authorize?client_id=ca_HeHBoynf8cQEpFzABDwV1HT9CgYdOwIQ&state=${stateKey}&suggested_capabilities[]=transfers&stripe_user[email]=${userEmail}`, '_blank');
    } else {
      window.open(stripeAccount.response.data.data.account_link, '_blank');
    }
  };

  return (
    <div className="stripe-integration portal-section fadein-short pb-3 pb-md-0 mb-5">
      <Card>
        <CardBody>
          <h4 className="portal-section__header">
            Payment and Authorizations
          </h4>
          <Row>
            <Col sm="6">
              <div className="portal-section__checkbox-container mb-2">
                <input type="checkbox" checked={Object.keys(stripeAccount).length !== 0} readOnly />
                <label className="ml-3">Bank Account Setup</label><br />
              </div>
            </Col>
            <Col sm="6">
              <Button
                className="parent-profile__save-button"
                context="primary"
                onClick={(e: any) => handleRedirect()}
                size="lg"
              >
                {
                  Object.keys(stripeAccount).length === 0 &&
                  'Connect\xa0to\xa0Stripe'
                }
                {
                  Object.keys(stripeAccount).length !== 0 &&
                  'Login\xa0to\xa0Stripe'
                }
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
