import React, { useState, useEffect, useMemo } from "react";
import ClassNames from "classnames";
import { Card, Button } from "@codeverse/react-helios-ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/state";
import { toast } from "react-toastify";
import { closeModal, openModal } from "store/modules/UI/actions";
import moment from "moment-timezone";

const SUPERNOVA_STARTER = "SupernovaStarter";
const SUPERNOVA_LITE = "SupernovaLite";
const SUPERNOVA = "Supernova";
const SUPERNOVA_PRO = "SupernovaPro";

const TrialFeature: React.FC<any> = ({ children }) => {
  return (
    <div className="trial-feature">
      <div className="trial-feature__check">
        <i className="fas fa-check" />
      </div>
      <div className="trial-feature__text">{children}</div>
    </div>
  );
};

type Props = {
  history: any;
  trial?: boolean;
};

const TrialCard: React.FC<Props> = ({ history, trial }) => {
  const dispatch = useDispatch();
  const childUsers = useSelector((state: RootState) => state.user.childUsers);
  const organization = useSelector(
    (state: RootState) => state.organizations.organization
  );
  const subscriptions = useSelector(
    (state: RootState) => state.organizations.subscriptions
  );

  const currentSubscription = useMemo(() => {
    return subscriptions.find(
      (subscription: any) =>
        subscription.status === "trial" || subscription.status === "new"
    );
  }, [subscriptions]);

  const sessionCount = useMemo(() => {
    if (!trial) {
      return "One";
    }
    if (currentSubscription) {
      const subName = currentSubscription.name
        .split("(")[0]
        .split(" ")
        .join("");
      switch (subName) {
        case SUPERNOVA_STARTER:
          return "One";
        case SUPERNOVA_LITE:
          return "Two";
        case SUPERNOVA:
          return "Four";
        case SUPERNOVA_PRO:
          return "Eight";
      }
    }
  }, [currentSubscription]);

  const headerText = useMemo(() => {
    if (trial) {
      const daysLeft = moment(organization.trial_period_end_at).diff(
        moment(),
        "days"
      );
      return `You have ${daysLeft} days left of your 7 day free trial`;
    }
    return "Sign up for a 7-day Membership Trial and unlock more!";
  }, []);

  const trialHeaderContentHeader = useMemo(() => {
    if (trial) {
      if (currentSubscription) {
        const subName = currentSubscription.name
          .split("(")[0]
          .split(" ")
          .join("");
        switch (subName) {
          case SUPERNOVA_STARTER:
            return "1 SESSION PER MONTH & USER";
          case SUPERNOVA_LITE:
            return "2 SESSIONS PER MONTH & USER";
          case SUPERNOVA:
            return "4 SESSIONS PER MONTH & USER";
          case SUPERNOVA_PRO:
            return "8 SESSIONS PER MONTH & USER";
        }
      }
    }
    return "7 DAY FREE TRIAL";
  }, [currentSubscription]);

  const trialHeaderPrice = useMemo(() => {
    if (trial) {
      if (currentSubscription) {
        const subName = currentSubscription.name
          .split("(")[0]
          .split(" ")
          .join("");
        switch (subName) {
          case SUPERNOVA_STARTER:
            return "$79/month";
          case SUPERNOVA_LITE:
            return "$159/month";
          case SUPERNOVA:
            return "$269/month";
          case SUPERNOVA_PRO:
            return "$489/month";
        }
      }
    }
    return "$0";
  }, [currentSubscription]);
  const trialHeaderBtnText = useMemo(() => {
    return "Start Free Trial";
  }, []);

  const handleTrialCheckout = () => {
    // if (childUsers.length > 1) {
    dispatch(openModal("addChildCheckout"));
    // } else {
    //   history.push('/parent/checkout/#select-time')
    // }
  };
  const handleNoSub = () => {
    history.push("/parent/checkout/existing");
  };
  return (
    <div className="trial-card">
      <h4 className="trial-card__header">{headerText}</h4>
      {trial && currentSubscription && (
        <p className="trial-card__sub-header">
          Your account will automatically convert to the plan below after your
          trial period. Please email support@codeverse.com to update your chosen
          plan.
        </p>
      )}
      {trial && !currentSubscription && (
        <p className="trial-card__sub-header">
          You currently do not have a membership selected, please click below to
          select a membership.
        </p>
      )}

      <Card className="inner-card">
        <div className="inner-card__header flex-sm-column flex-lg-row">
          <div className="trial-signup" />
          <div className="trial-header-content" style={{ flex: 1 }}>
            {trial && currentSubscription && (
              <div className="trial-header-content__header">
                {trialHeaderContentHeader}
              </div>
            )}
            {trial && currentSubscription && (
              <div className="trial-header-content__price">
                {trialHeaderPrice}
              </div>
            )}
            <div className="trial-header-content__btn">
              {!trial && (
                <Button
                  onClick={() => handleTrialCheckout()}
                  context="tertiary"
                >
                  {trialHeaderBtnText}
                </Button>
              )}
              {trial && !currentSubscription && (
                <Button
                  className="select-membership-btn w-100"
                  onClick={() => handleNoSub()}
                  context="tertiary"
                >
                  Sign up
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="trial-features">
          <h6>
            {trial ? "INCLUDED IN MEMBERSHIP" : "INCLUDED IN YOUR FREE TRIAL"}:
          </h6>

          <TrialFeature>
            {currentSubscription && (
              <span className="word-highlight">{sessionCount}</span>
            )}{" "}
            {!currentSubscription ? "Private" : "private"} 50-minute coding
            sessions led by a dedicated Codeverse Guide.
          </TrialFeature>
          <TrialFeature>
            Unlock more Missions and get unlimited access to all that Codeverse
            has to offer.
          </TrialFeature>
          <TrialFeature>
            Personalized note from your Codeverse Guide after your session.
          </TrialFeature>
          <TrialFeature>
            Track your child’s progress, book new sessions, and reschedule
            sessions any time.
          </TrialFeature>
        </div>
      </Card>
    </div>
  );
};

export default TrialCard;
