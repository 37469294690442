import React from 'react';
import { CopyField } from '@codeverse/react-helios-ui';

type Props = {
  history?: any;
  location?: any;
  referralCode?: any;
  selectedIcon?: any;
};

const Step4b: React.FC<Props> = ({ history, referralCode, selectedIcon }) => {
  return (
    <div className="step4">
      <div className="header-icon-container">
        <div className="astronaut-icon" />
      </div>
      <div className="header">
        Thank you for your feedback!
      </div>
      {
        (selectedIcon === 1 || selectedIcon === 2 || selectedIcon === 3) &&
        <div className="customer-support-box">
          <div className="customer-support-box__header">
            Codeverse Customer Support
          </div>
          <div className="customer-support-box__body">
            <div className="customer-support-box__picture" />
            <div className="customer-support-box__info-box">
              <div className="customer-support-box__info-header">
                Have a question?
              </div>
              <div className="customer-support-box__CTA">
                Email our support team!
              </div>
              <div className="customer-support-box__phone-number">
                <a href="mailto:support@codeverse.com" className="customer-support-box__phone-number">support@codeverse.com</a>
              </div>
            </div>
          </div>
        </div>
      }
      {
        (selectedIcon === 4 || selectedIcon === 5) &&
        <>
          <div className="my-2">
            <div className="small-text">Create With Friends</div>
          </div>
          <div className="py-2">
            <CopyField value={`${referralCode}`} />
          </div>
          <div className="my-2">
            <div className="small-text">Tell a friend to enter this code at checkout and you’ll each get <strong id="referralEmphasis" style={{ display: 'inline-block' }}>25% off your next month.</strong>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default Step4b;
