import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Select } from "@codeverse/react-helios-ui";
import { useDispatch, useSelector } from "react-redux";
import { dataClientRequest } from "@codeverse/redux-data-client";

import { openModal } from "store/modules/UI/actions";
import { setTimezone } from "store/modules/User/actions";
import { AppDispatch } from "store";
import { RootState } from "store/state";
import { UPDATE_USER_MODEL_CONFIG } from "models/User";

const tzOptions = [
  { value: "Pacific/Honolulu", label: "Honolulu" },
  { value: "America/Los_Angeles", label: "Los Angeles" },
  { value: "America/Phoenix", label: "Phoenix" },
  { value: "America/Denver", label: "Denver" },
  { value: "America/Chicago", label: "Chicago" },
  { value: "America/New_York", label: "New York" },
  { value: "Europe/London", label: "London" },
  { value: "Australia/Sydney", label: "Sydney" },
  { value: "Pacific/Auckland", label: "Auckland" },
];

const ParentAccountInfo: React.FC<any> = ({ currentUser }) => {
  const dispatch: AppDispatch = useDispatch();

  const userPhoneNumbers = useSelector(
    (state: RootState) => state.user.userPhoneNumbers
  );
  const userAddresses = useSelector(
    (state: RootState) => state.user.userAddresses
  );
  const timezone = useSelector(
    (state: RootState) => state.user.currentUser.time_zone
  );

  const [emailAddress, setEmailAddress] = useState(currentUser.email || "");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddress2, setStreetAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [timezoneChanged, setTimezoneChanged] = useState(false);

  useEffect(() => {
    if (userPhoneNumbers.length > 0) {
      setPhoneNumber(userPhoneNumbers[0].phone_number);
    }
  }, [userPhoneNumbers]);

  useEffect(() => {
    if (userAddresses.length > 0) {
      setStreetAddress(userAddresses[0].address1);
      setStreetAddress2(userAddresses[0].address2);
      setCity(userAddresses[0].city);
      setState(userAddresses[0].state);
      setZipcode(userAddresses[0].zip_code);
    }
  }, [userAddresses]);

  useEffect(() => {
    if (timezoneChanged) {
      const currentUserId = localStorage.getItem("currentUserId") || "";
      dispatch(
        dataClientRequest({
          ...UPDATE_USER_MODEL_CONFIG,
          data: {
            time_zone: timezone,
            id: currentUserId,
          },
        })
      ).then((response) => {
        setTimezoneChanged(false);
      });
    }
  }, [timezoneChanged, timezone]);

  const handleCopy = () => {
    const range = document.createRange();
    range.selectNode(document.getElementById("referral"));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges(); // to deselect
  };

  return (
    <Card className="account-info mb-4">
      <CardHeader>
        <div className="account-info__header">Account Info</div>
        <i
          className="fas fa-pen account-info__edit-button"
          onClick={() => dispatch(openModal("editAccount"))}
        />
      </CardHeader>
      <CardBody>
        <div className="account-info__name">{currentUser.name}</div>
        <div className="account-info__email">{emailAddress}</div>
        <div className="account-info__phone-number">{phoneNumber}</div>
        <br />
        {streetAddress && (
          <>
            <div className="account-info__address-line-1">
              {streetAddress}&nbsp;{streetAddress2}
            </div>
            <div className="account-info__address-line-2">
              {city},&nbsp;{state}&nbsp;{zipcode}
            </div>
          </>
        )}
      </CardBody>
      <div className="account-info__info-row">
        <div className="account-info__info-row--label">Password</div>
        <div className="d-flex flex-row align-items-center">
          <div className="account-info__info-row--content">
            &#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
          </div>
          <div className="pl-3">
            <i
              onClick={() => dispatch(openModal("editPassword"))}
              className="fas fa-pen account-info__edit-button"
            />
          </div>
        </div>
      </div>
      <div className="account-info__info-row white">
        <div className="account-info__info-row--label">Time Zone</div>
        <div className="account-info__info-row--content">
          <div className="timezone-dropdown">
            <Select
              placeholder=""
              label=""
              options={tzOptions}
              onChange={(option: any) => {
                dispatch(setTimezone(option.value));
                setTimezoneChanged(true);
              }}
              value={tzOptions.find((option) => option.value === timezone)}
            />
          </div>
        </div>
      </div>
      <div className="account-info__info-row">
        <div className="account-info__info-row--label">Referral Code</div>
        <div className="d-flex flex-row align-items-center">
          <div
            onClick={() => handleCopy()}
            id="referral"
            className="account-info__info-row--referral-code"
          >
            {currentUser.referral_code}
          </div>
          <div className="pl-3">
            <i
              onClick={() => handleCopy()}
              className="fas fa-copy neptune-dark"
            />
          </div>
          {/* <div className="pl-3">
            <i onClick={() => dispatch(openModal('editReferralCode'))} className="fas fa-pen account-info__edit-button"></i>
          </div> */}
        </div>
      </div>
    </Card>
  );
};

export default ParentAccountInfo;
