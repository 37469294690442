import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Button, Col, Row } from '@codeverse/react-helios-ui';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { toast } from 'react-toastify';

import { closeModal, openModal } from 'store/modules/UI/actions';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';
import { CREATE_BOOKING, UPDATE_BOOKING } from 'models/Bookings';
import { GET_ORGANIZATION_BOOKINGS } from 'models/Organization';

type Props = {
  history?: any;
  location?: any;
  match?: any;
  currentSlotInstance?: any;
  curentChildId: string;
};

const SAFE_NUMBER = 8;

const Step3: React.FC<Props> = ({ currentSlotInstance, curentChildId }) => {
  const dispatch: AppDispatch = useDispatch();
  const [guideUser, setGuideUser] = useState(null);
  const timezone = useSelector((state: RootState) => state.user.currentUser.time_zone);
  const newStartTime = moment.tz(currentSlotInstance.start_time, 'HH:mm', 'America/Chicago').tz(timezone).format('h:mm A');
  const newEndTime = moment.tz(currentSlotInstance.end_time, 'HH:mm', 'America/Chicago').tz(timezone).format('h:mm A');
  const timeZone = moment.tz(timezone).format('z');

  const slotInstancePreferredGuideObj = currentSlotInstance.meta.preferred_guides.reduce(function (prev: any, current: any) {
    return (prev.position < current.position) ? prev : current;
  }, SAFE_NUMBER);

  let guidePhotoStyle = {
    backgroundImage: 'url(/icons/new-guide-icon.png)',
  }

  const handleReschedule = () => {
    dispatch(dataClientRequest({
      ...CREATE_BOOKING,
      data: {
        start_hour: parseInt(moment(currentSlotInstance.start_time, 'HH:mm').format('H')),
        start_minute: parseInt(moment(currentSlotInstance.start_time, 'HH:mm').format('mm')),
        end_hour: parseInt(moment(currentSlotInstance.end_time, 'HH:mm').format('H')),
        end_minute: parseInt(moment(currentSlotInstance.end_time, 'HH:mm').format('mm')),
        first_visit_at: currentSlotInstance.start_date,
        last_visit_at: currentSlotInstance.start_date,
        relationships: {
          user: { type: 'users', id: curentChildId },
          occasion: { type: 'occasions', id: currentSlotInstance.occasion_id },
        }
      }
    }))
      .then(() => {
        dispatch(dataClientRequest({
          ...GET_ORGANIZATION_BOOKINGS,
          data: {
            id: localStorage.getItem('currentOrganizationId'),
          }
        }));
        dispatch(closeModal('bookNewSession'));
      })
      .catch((error: any) => {
        toast.error(`Failed to create booking: ${error}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        dispatch(closeModal('bookNewSession'));
      })
  }

  return (
    <div className="step3">
      <div className="step3__slot-instance-box">
        <div className="step3__slot-instance-box--header">
          New Session Date and Time
        </div>
        <hr />
        <div className="step3__slot-instance-box--info">
          <div className="step3__slot-instance-box--text-box">
            <div className="step3__slot-instance-box--info1">
              {moment(currentSlotInstance.start_date, 'YYYY-MM-DD').format('LL')}
            </div>
            <div className="step3__slot-instance-box--info2">
              {newStartTime}&nbsp;-&nbsp;{newEndTime}&nbsp;{timeZone}
            </div>
          </div>
          {
            (slotInstancePreferredGuideObj && slotInstancePreferredGuideObj !== SAFE_NUMBER && slotInstancePreferredGuideObj.bookable) &&
            <div className="step3__preferred-guide">
              <div className="step3__preferred-guide--info-container">
                <div className="step3__preferred-guide--title">GUIDE</div>
                {
                  slotInstancePreferredGuideObj &&
                  <div className="step3__preferred-guide--name">{slotInstancePreferredGuideObj.name.split(' ')[0]}&nbsp;{(slotInstancePreferredGuideObj.name.split(' ')[slotInstancePreferredGuideObj.name.split(' ').length - 1]).charAt(0)}.</div>
                }
              </div>
              <div style={guidePhotoStyle} className="step3__preferred-guide--picture"></div>
            </div>
          }
        </div>
      </div>
      <div className="reschedule-modal__actions">
        <Row>
          <Col md={6} className="pr-1">
            <Button onClick={() => dispatch(closeModal('rescheduleModal'))} context="secondary" className="w-100 step3__cancel-button">
              Cancel
            </Button>
          </Col>
          <Col md={6} className="pl-1">
            <Button onClick={() => handleReschedule()} context="primary" className="w-100">
              Book
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Step3;

