import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/state';

import determineAvatarImage from 'utils/determineAvatarImage';

interface ChooseChildrenProps {
  hashKey?: any;
  goToStepHash?: (hash: string) => void;
  setCurrentSelectedChildren: any;
  currentSelectedChildren: any;
}

type ChildUserProps = {
  user: any;
  handleSetChild: any;
  index: number;
  currentSelectedChildren: any;
};

export const ChildUser: React.FC<ChildUserProps> = ({
  user,
  handleSetChild,
  index,
  currentSelectedChildren,
}) => {
  const [profileImageStyle, setProfileImageStyle] = useState(null);
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    if (currentSelectedChildren[user.id]) {
      setSelected(true);
    }
  }, []);
  useEffect(() => {
    determineAvatarImage(user, setProfileImageStyle);
  }, [user]);

  return (
    <div
      className="card"
      style={{ cursor: 'pointer', marginTop: index === 0 ? '0px' : '16px' }}
      onClick={() => {
        handleSetChild(user.id, !selected);
        setSelected(!selected);
      }}
    >
      <div style={profileImageStyle} className="avatar" />
      <div className="ml-3 child-name">{user.name}</div>
      <div className="ml-auto mr-3 d-flex justify-content-center align-items-center">
        <div className="checkbox">
          {selected ? <i className="fas fa-check" /> : null}
        </div>
      </div>
    </div>
  );
};

const ChooseChildren: React.FC<ChooseChildrenProps> = ({
  goToStepHash,
  currentSelectedChildren,
  setCurrentSelectedChildren,
}) => {
  const childUsers = useSelector((state: RootState) => state.user.childUsers);
  const subscriptionType = useSelector(
    (state: RootState) => state.checkout.subscriptionType,
  );
  const handleSetChild = (userId: string, selected: boolean) => {
    setCurrentSelectedChildren(
      Object.assign({}, { ...currentSelectedChildren }, { [userId]: selected }),
    );

  };

  useEffect(() => {
    if (childUsers.length === 1) {
      setCurrentSelectedChildren(
        Object.assign(
          {},
          { ...currentSelectedChildren },
          { [childUsers[0].id]: true },
        ),
      );
      goToStepHash('review');
    }
  }, [childUsers]);

  const ChooseChildCards = childUsers.map((childUser, index) => {
    return (
      <ChildUser
        user={childUser}
        handleSetChild={handleSetChild}
        index={index}
        currentSelectedChildren={currentSelectedChildren}
      />
    );
  });

  const additionalCost = useMemo(() => {
    switch (subscriptionType) {
      case 'supernova_lite':
        return 116;
      case 'supernova':
        return 179;
      case 'supernova_pro':
        return 314;
      default:
        return '';
    }
  }, [subscriptionType]);

  return (
    <div className="m-width-checkout choose-children">
      <h5
        style={{ fontSize: '30px', lineHeight: '38px' }}
        className="header mt-5"
      >
        Which kids will you be adding to the membership?
      </h5>
      <p>
        Each additional child add ${additionalCost} to your monthly payment.
      </p>
      <div className="mt-2">{ChooseChildCards}</div>
    </div>
  );
};

export default ChooseChildren;
