import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/state";
import { closeModal, openModal } from "store/modules/UI/actions";
import { Button, Card, Input } from "@codeverse/react-helios-ui";
import { useHistory } from "react-router-dom";
import SatelliteClient from "@codeverse/satellite-client";
import {
  getOrganizationMembershipsData,
  getOrganizationSubscriptionsData,
  getUserData,
} from "store/modules/Organizations/actions";

import {
  deleteChildBooking,
  resetCheckout,
  setCurrentChildIndex,
} from "store/modules/Checkout/actions";

import moment from "moment-timezone";
import classNames from "classnames";
import { toast } from "react-toastify";
import Payment from "./Payment";

type Props = {
  hashKey: string;
  goToStepHash?: any;
  getParentDataFunc?: any;
};

const trialTerms =
  'By clicking "Complete" below, you will activate your free 7-day trial, enroll in Codeverse, and agree to our Terms & Conditions. Your free trial begins today.  Upon completion of trial, you will automatically be enrolled as a Member for a monthly fee charged to the payment provided.  If you do not wish to become a Member, you may cancel at any time during your trial period by emailing support@codeverse.com';

export type PAYMENT_METHOD = "credit card" | "paypal";

const Review: React.FC<Props> = ({ goToStepHash, getParentDataFunc }) => {
  const dispatch = useDispatch();
  const children = useSelector((state: RootState) => state.checkout.children);
  const subscriptionType = useSelector(
    (state: RootState) => state.checkout.subscriptionType
  );

  const childUsers = useSelector((state: RootState) => state.user.childUsers);
  const history = useHistory();
  const [authorizationToken, setAuthorizationToken] = useState("");
  const [braintreeReady, setBraintreeReady] = useState(false);
  const [braintreeClientInstance, setBraintreeClientInstance] = useState({});
  const [braintreePaypalInstance, setBraintreePaypalInstance] = useState({});
  const [paypalPaymentDetails, setPaypalPaymentDetails] = useState({});
  const [paypalPaymentNonce, setPaypalPaymentNonce] = useState("");
  const [tokenRef, setTokenRef] = useState<any>({});
  const [isLoading, setLoading] = useState(false);

  const [showCouponField, setShowCouponField] = useState(false);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponValid, setCouponValid] = useState(false);
  const [attemptedCoupon, setAttemptedCoupon] = useState(false);

  const timezone = useMemo(() => {
    let userTimezone = "";
    const zonenameAbbr = moment.tz(moment.tz.guess()).zoneAbbr();
    switch (zonenameAbbr) {
      case "CST":
        userTimezone = "America/Chicago";
        break;
      case "MST":
        userTimezone = "America/Denver";
        break;
      case "PST":
        userTimezone = "America/Los_Angeles";
        break;
      case "EST":
        userTimezone = "America/New_York";
        break;
      case "CDT":
        userTimezone = "America/Chicago";
        break;
      case "MDT":
        userTimezone = "America/Denver";
        break;
      case "PDT":
        userTimezone = "America/Los_Angeles";
        break;
      case "EDT":
        userTimezone = "America/New_York";
        break;
      default:
        userTimezone = "America/Chicago";
        break;
    }
    return userTimezone;
  }, []);

  const [selectedPaymentMethod, updateSelectedPaymentMethod] =
    useState<PAYMENT_METHOD>("credit card");

  const paymentRequired = true;

  const creditCardTabClassnames = classNames("btn btn-secondary card-tab", {
    active: selectedPaymentMethod === "credit card",
  });
  const paypalTabClassnames = classNames("btn btn-secondary card-tab", {
    active: selectedPaymentMethod === "paypal",
  });

  const firstMemberCost = useMemo(() => {
    console.log('couponCode', couponCode)
    switch (subscriptionType) {
      case "supernova_starter":
        return couponValid && couponCode === 'BTS-2023' ? 99 : 99;
      case "supernova_lite":
        return couponValid && couponCode === 'BTS-2023' ? 129 : 159;
      case "supernova":
        return couponValid && couponCode === 'BTS-2023' ? 199 : 269;
      case "supernova_pro":
        return couponValid && couponCode === 'BTS-2023' ? 349 : 489;
      default:
        return 1;
    }
  }, [subscriptionType, couponCode, couponValid]);

  const additionalCost = useMemo(() => {
    switch (subscriptionType) {
      case "supernova_starter":
        return couponValid && couponCode === 'BTS-2023' ? 89 : 89;
      case "supernova_lite":
        return couponValid && couponCode === 'BTS-2023' ? 116 : 139;
      case "supernova":
        return couponValid && couponCode === 'BTS-2023' ? 179 : 239;
      case "supernova_pro":
        return couponValid && couponCode === 'BTS-2023' ? 314 : 439;
      default:
        return 1;
    }
  }, [subscriptionType]);

  const planPrice = useMemo(() => {
    const selectedChildren = Object.values(children).filter(
      (bool) => bool
    ).length;
    const realAdditionalCost =
      selectedChildren > 1 ? (selectedChildren - 1) * additionalCost : 0;
    switch (subscriptionType) {
      case "supernova_lite":
        return firstMemberCost + realAdditionalCost;
      case "supernova":
        return firstMemberCost + realAdditionalCost;
      case "supernova_pro":
        return firstMemberCost + realAdditionalCost;
      default:
        return firstMemberCost
    }
  }, [subscriptionType, additionalCost, children, firstMemberCost]);


  const PlanOverview = useMemo(() => {
    switch (subscriptionType) {
      case "supernova_starter":
        return (
          <div className="plan-overview">
            <div className="plan-overview--main">Your Plan</div>
            <div className="plan-overview--sub">1 sessions /month</div>
          </div>
        );
      case "supernova_lite":
        return (
          <div className="plan-overview">
            <div className="plan-overview--main">Your Plan</div>
            <div className="plan-overview--sub">2 sessions /month</div>
          </div>
        );
      case "supernova":
        return (
          <div className="plan-overview">
            <div className="plan-overview--main">Recommended Plan</div>
            <div className="plan-overview--sub">4 sessions /month</div>
          </div>
        );
      case "supernova_pro":
        return (
          <div className="plan-overview">
            <div className="plan-overview--main">Your Plan</div>
            <div className="plan-overview--sub">8 sessions /month</div>
          </div>
        );
    }
  }, [subscriptionType]);

  // Component {BookingCards}
  const BookingCards = useMemo(() => {
    return children.map((child, index) => {
      const childUser = childUsers.find((user) => user.id === child.user_id);

      if (!childUser) return null;
      const childFirstName = childUser.name.split(" ")[0];
      const slotInstanceMomentStartObj = moment
        .tz(
          `${child.slot_instance.start_date}T${child.slot_instance.start_time}`,
          "America/Chicago"
        )
        .tz(timezone);

      const slotInstanceMomentEndObj = moment
        .tz(
          `${child.slot_instance.end_date}T${child.slot_instance.end_time}`,
          "America/Chicago"
        )
        .tz(timezone);

      return (
        <Card className="checkout-card">
          <div className="checkout-card__header">
            <div>{childFirstName}'s Trial Information</div>
            <div className="" style={{ position: "absolute", right: "12px" }}>
              <i
                className="fas fa-times-circle pl-2 cursor-pointer"
                style={{ color: "#5225ee", fontSize: "24px" }}
                onClick={() => {
                  if (children.length === 1) {
                    dispatch(deleteChildBooking(index));
                    history.push("/parent/checkout/#select-child");
                  } else {
                    dispatch(deleteChildBooking(index));
                  }
                }}
              />
            </div>
          </div>

          <div className="checkout-card__information">
            <div className="checkout-card__information-section">
              <div className="checkout-card__information-section--main">
                Trial Period
              </div>
              <div className="checkout-card__information-section--sub">
                Trial Start: Today
              </div>
              <div className="checkout-card__information-section--sub">
                Trial End: {moment().add(6, "days").format("dddd, MMM D")}
              </div>

              {/* <div className="edit-icn information-section-edit-icn" /> */}
            </div>
            <div className="checkout-card__information-section">
              <div className="checkout-card__information-section--main">
                First Session
              </div>
              <div className="checkout-card__information-section--sub">
                {" "}
                {`${slotInstanceMomentStartObj.format("dddd, MMM D")}`}
              </div>
              <div className="checkout-card__information-section--sub">
                {" "}
                {`${slotInstanceMomentStartObj.format("h:mma z")}`}
              </div>

              <div
                className="edit-icn information-section-edit-icn"
                onClick={() => {
                  goToStepHash("select-time");
                  dispatch({ type: "SET_CURRENT_CHILD_INDEX", index });
                }}
              />
            </div>
          </div>
        </Card>
      );
    }, []);
  }, [children, childUsers, timezone]);

  const validateCoupon = (couponCode: any) => {
    if (!couponCode) {
      return;
    }
    SatelliteClient.platform
      .get("/occasions/d2e9f65b-c86f-40c8-b9ce-4d3afc6dc8e8/coupons/validate", {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
        query: {
          coupon_code: couponCode,
        },
      })
      .then(() => {
        const splitCoupon = couponCode.split("-");
        const couponPrice = splitCoupon[1];
        setCouponDiscount(couponPrice);
        setCouponValid(true);
        setAttemptedCoupon(true);
      })
      .catch(() => {
        setCouponValid(false);
        setCouponDiscount(0);
        setAttemptedCoupon(true);
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    const bookings = children.map((child) => {
      const startHour = child.slot_instance.start_time.split(":")[0];
      const startMinute = child.slot_instance.start_time.split(":")[1];
      const endHour = child.slot_instance.end_time.split(":")[0];
      const endMinute = child.slot_instance.end_time.split(":")[1];
      return {
        user_id: child.user_id,
        start_hour: startHour,
        start_minute: startMinute,
        end_hour: endHour,
        end_minute: endMinute,
        first_visit_at: child.slot_instance.start_date,
        last_visit_at: child.slot_instance.end_date,
        occasion_id: child.slot_instance.occasion_id,
      };
    });
    if (paymentRequired) {
      const subscription = Object.assign(
        {},
        {
          cadence: "monthly",
          first_add_on: subscriptionType,
        },
        couponCode && couponValid && { coupon_code: couponCode }
      );
      const data = {
        bookings,
        subscription,
      };
      if (selectedPaymentMethod === "credit card") {
        tokenRef
          .ref()
          .then((response: any) => {
            const dataWithPayment = Object.assign(
              {},
              {
                ...data,
                payment_method_nonce: response.nonce,
              }
            );
            createReservation(dataWithPayment);
          })
          .catch((error: any) => {
            setLoading(false);
            toast.error("Please check your payment information", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          });
      } else if (selectedPaymentMethod === "paypal") {
        if (!paypalPaymentNonce) {
          setLoading(false);
          toast.error("Please check your payment information", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          return;
        }
        const dataWithPayment = Object.assign(
          {},
          {
            ...data,
            payment_method_nonce: paypalPaymentNonce,
          }
        );
        createReservation(dataWithPayment);
      }
    } else {
      setLoading(false);
    }
  };

  const createReservation = (data: any) => {
    return SatelliteClient.platform
      .post("/homepage/checkout", {
        headers: Object.assign(
          {},
          { Accept: "application/json" },
          { "content-type": "application/json" }
        ),
        data,
      })
      .then((response: any) => {
        setLoading(false);
        getParentDataFunc();
        const access_token = localStorage.getItem("accessToken");
        const current_user_id = localStorage.getItem("currentUserId");
        const current_organization_id = localStorage.getItem(
          "currentOrganizationId"
        );
        dispatch(
          getOrganizationSubscriptionsData(
            access_token,
            current_user_id,
            current_organization_id
          )
        );
        //@ts-ignore
        if (window.dataLayer) {
          //@ts-ignore
          window.dataLayer.push({
            event: "checkout-complete",
            conversionValue: 0,
          });
        }
        goToStepHash("booked");
      })
      .catch((err: any) => {
        if (err && err.response) {
          if (err.response.data) {
            if (err.response.data.errors) {
              console.log(
                err.response.data.errors[0].detail
                  .split(" ")
                  .slice(0, 7)
                  .join(" ")
              );
              if (
                err.response.data.errors[0].detail
                  .split(" ")
                  .slice(0, 7)
                  .join(" ") === "cannot create booking because session is full"
              ) {
                toast.error("No space available. Please choose another slot.", {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              } else {
                toast.error("Please try again or email support@codeverse.com", {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
            } else {
              toast.error("Please try again or email support@codeverse.com", {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            }
          } else {
            toast.error("Please try again or email support@codeverse.com", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        } else {
          toast.error("Please try again or email support@codeverse.com", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
        setLoading(false);
      });
  };

  const handleAddAnotherChild = () => {
    dispatch(openModal("addChildCheckout"));
  };

  return (
    <div className="m-width-checkout">
      <h3>
        <span className="highlight">Review</span> and Confirm
      </h3>

      {BookingCards}

      <div className="card add-button" onClick={() => handleAddAnotherChild()}>
        <div className="add-button-icon" />
        <i className="fas fa-plus" />
        <div className="add-button-title">Add Another Child</div>
      </div>

      <Card className="checkout-card">
        <div className="checkout-card__plan-information subscription-field">
          {PlanOverview}

          <div className="plan-price">${planPrice}/mo</div>

          <div
            className="edit-icn plan-information-edit-icn"
            onClick={() => goToStepHash("choose-plan")}
          />
        </div>

        <div className="checkout-card__due-today subscription-field">
          <div className="due-today-text">Due Today</div>
          <div className="due-today-price">$0</div>
        </div>

        <div className="payment-method">
          <div className="card-body payment-method-information">
            <div className="btn-group btn-group-sm selector-tabs">
              <div
                onClick={() => updateSelectedPaymentMethod("credit card")}
                className={creditCardTabClassnames}
              >
                <span>Card</span>
              </div>
              <div
                onClick={() => updateSelectedPaymentMethod("paypal")}
                className={paypalTabClassnames}
              >
                <span>PayPal</span>
              </div>
            </div>
          </div>

          <Payment
            selectedPaymentMethod={selectedPaymentMethod}
            authorizationToken={authorizationToken}
            setAuthorizationToken={setAuthorizationToken}
            braintreeReady={braintreeReady}
            setBraintreeReady={setBraintreeReady}
            braintreeClientInstance={braintreeClientInstance}
            setBraintreeClientInstance={setBraintreeClientInstance}
            braintreePaypalInstance={braintreePaypalInstance}
            setBraintreePaypalInstance={setBraintreePaypalInstance}
            paypalPaymentDetails={paypalPaymentDetails}
            setPaypalPaymentDetails={setPaypalPaymentDetails}
            paypalPaymentNonce={paypalPaymentNonce}
            setPaypalPaymentNonce={setPaypalPaymentNonce}
            tokenRef={tokenRef}
            setTokenRef={setTokenRef}
          />
        </div>

        <div className="coupon-code">
          <div
            className="coupon-code-title"
            onClick={() => {
              setShowCouponField(!showCouponField);
            }}
          >
            Got a Code?
            <i
              className={`ml-2 fas ${
                showCouponField ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            />
          </div>

          {showCouponField && (
            <div className="coupon-code-field mt-3">
              <Input
                showError={attemptedCoupon && !couponValid}
                showSuccess={attemptedCoupon && couponValid}
                name="couponCode"
                label="Code"
                onChange={(e: any) =>
                  setCouponCode(e.target.value.toUpperCase())
                }
                value={couponCode}
                onBlur={() => validateCoupon(couponCode)}
                onKeyPress={(event: any) => {
                  console.log(event);
                  if (event.key === "Enter") {
                    event.preventDefault();
                    validateCoupon(couponCode);
                  }
                }}
              />
              {couponCode && attemptedCoupon && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setCouponCode("");
                    setCouponDiscount(0);
                    setAttemptedCoupon(false);
                    setCouponValid(false);
                  }}
                  href="#"
                  className="reset-coupon"
                >
                  Reset
                </a>
              )}
              {couponCode && !attemptedCoupon && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    validateCoupon(couponCode);
                  }}
                  href="#"
                  className="reset-coupon"
                >
                  Check
                </a>
              )}
            </div>
          )}
        </div>
      </Card>

      <div className="terms-and-conditions">
        By clicking “Finish” you will activate a 7-day trial and agree to the{" "}
        <a
          href="https://www.codeverse.com/terms-of-service"
          target="_blank"
          className=""
          style={{ color: "#ffa34e" }}
        >
          Terms and Conditions{" "}
        </a>
        . Upon completion of trial you will be auto-enrolled as a Member for a
        monthly fee charged to the payment provided. Cancel at anytime during
        the trial period by going to the ‘My Account’ tab in your Portal or by
        emailing{" "}
        <a href="mailto:support@codeverse.com" style={{ color: "#ffa34e" }}>
          support@codeverse.com
        </a>
      </div>

      <Button
        disabled={isLoading}
        onClick={() => handleSubmit()}
        className="w-100 checkout-complete-btn"
        context="tertiary"
      >
        Complete
      </Button>
    </div>
  );
};

export default Review;
