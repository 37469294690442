import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Button, Card } from "@codeverse/react-helios-ui";
import PlanCard from "components/Checkout/ChoosePlan/PlanCard";

type Props = {
  hashKey: string;
  goToStepHash?: any;
};

const ChoosePlan: React.FC<Props> = ({ goToStepHash }) => {
  const mobileSettings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    centerMode: true,
    arrows: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          centerPadding: "16px",
          slidesToShow: 1,
          // variableWidth: true,
        },
      },
    ],
    beforeChange(currentSlide: any, nextSlide: any) {},
  };
  return (
    <div className="choose-plan">
      <div className="choose-plan__header">
        <h3>Choose Your Codeverse Plan</h3>
      </div>

      <div className="plan-carousel">
        <Slider {...mobileSettings} className="d-lg-none d-xl-none">
          <PlanCard goToStepHash={goToStepHash} sessionCount={2} price={159} />
          <PlanCard goToStepHash={goToStepHash} sessionCount={4} price={269} />
          <PlanCard goToStepHash={goToStepHash} sessionCount={8} price={489} />
        </Slider>

        <div className="plan-cards d-none d-lg-block d-xl-flex justify-content-center">
          <PlanCard goToStepHash={goToStepHash} sessionCount={2} price={159} />
          <PlanCard goToStepHash={goToStepHash} sessionCount={4} price={269} />
          <PlanCard goToStepHash={goToStepHash} sessionCount={8} price={489} />
        </div>
      </div>
    </div>
  );
};

export default ChoosePlan;
