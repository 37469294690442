import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import SatelliteClient from "@codeverse/satellite-client";
import { Button } from "@codeverse/react-helios-ui";
import { toast } from "react-toastify";
import classNames from "classnames";
import { CREATE_PASSWORD_RESET_MODEL_CONFIG } from "models/User";
import { dataClientRequest } from "store/data-client";

import { SetCurrentStepFunction, SetEmailFunction } from "./SignIn";

const checkEmail = (myString: string) => {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    myString
  );
};

type Props = {
  currentStep: number;
  history: any;
  header: any;
  location: any;
  parentEmail: string;
  setCurrentStep: SetCurrentStepFunction;
  setEmail: SetEmailFunction;
  subtext: any;
  setScope?: any;
};

export const EmailForm: React.FC<Props> = ({
  currentStep,
  history,
  header,
  location,
  parentEmail,
  setCurrentStep,
  setEmail,
  subtext,
  setScope,
}) => {
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const isMobile = useMemo(() => {
    return window.innerWidth < 992;
  }, [window.innerWidth]);
  const emailClassnames = classNames("login-field mt-4", {
    "has-value": parentEmail !== "",
    "has-focus": inputFocus,
    "has-danger": emailError,
  });
  const handleButtonSubmit = (e: any) => {
    const functionEmail = parentEmail.trim();
    const usingEmail = functionEmail.includes("@");
    const query = {
      username: functionEmail,
      email: functionEmail,
    };
    if (usingEmail) {
      delete query.username;
    } else {
      delete query.email;
    }
    if (!functionEmail) {
      toast.error("Please enter an email or username", {
        position: toast.POSITION.TOP_CENTER,
      });
      setEmailError(true);
    } else if (usingEmail && !checkEmail(functionEmail)) {
      toast.error("Please enter a valid email", {
        position: toast.POSITION.TOP_CENTER,
      });
      setEmailError(true);
    } else if (functionEmail) {
      SatelliteClient.platform
        .get("/users/exists", {
          headers: Object.assign(
            {},
            { Accept: "application/vnd.api+json" },
            { "content-type": "application/vnd.api+json" }
          ),
          query,
        })
        .then((response: any) => {
          setScope(response.data.scope);
          if (response.data.can_login) {
            setCurrentStep(currentStep + 1);
            history.push(`${location.pathname}?step=2`);
          } else {
            dispatch(
              dataClientRequest({
                ...CREATE_PASSWORD_RESET_MODEL_CONFIG,
                data: {
                  email: functionEmail,
                },
              })
            );
            setCurrentStep(currentStep + 3);
            history.push(`${location.pathname}?step=4`);
          }
        })
        .catch((error: any) => {
          const loginType = usingEmail ? "email" : "username";
          const errorMsg = `We can't find your ${loginType} in our system. Please try a different ${loginType}, ask a parent, or contact support@codeverse.com`;
          toast.error(errorMsg, {
            position: toast.POSITION.TOP_CENTER,
            onClick: () =>
              window.location.replace("mailto:support@codeverse.com"),
          });
          setEmailError(true);
        });
    }
  };
  return (
    <div
      style={{
        marginLeft: isMobile ? "24px" : "0px",
        marginRight: isMobile ? "24px" : "0px",
      }}
    >
      <h1 className="org-login__information-header header-two-tone mb-4">
        {header}
      </h1>
      <p className="org-login__information-subtext mb-5">{subtext}</p>
      <div className={emailClassnames}>
        <label>Username/Email</label>
        <input
          autoFocus
          className="login-field__control border"
          onChange={(e: any) => {
            setEmailError(false);
            setEmail(e.target.value);
          }}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          onKeyDown={(e) => {
            if (e.nativeEvent.keyCode === 13) {
              handleButtonSubmit(e);
            }
          }}
          value={parentEmail}
        />
      </div>
      <div className="mt-3">
        <Button
          onClick={(e: any) => handleButtonSubmit(e)}
          className="org-login__button icon-right w-100"
          context="primary"
          withoutSpan={
            <>
              <span>Next</span>
              <i className="fas fa-chevron-right" />
            </>
          }
        />
      </div>
      {/* <div className="org-login__forgot-password text-center mt-3">
        <a className="font-poppins-semibold cursor-pointer">I forgot my username</a>
      </div> */}
    </div>
  );
};

export default EmailForm;
