import React, { useEffect, useState } from 'react';
import { Button } from '@codeverse/react-helios-ui';

import FormElement from 'components/Shared/FormElement';
import TextAreaInput from 'components/Shared/TextAreaInput';

type Props = {
  history?: any;
  location?: any;
  setCurrentStep?: any;
  kidConcerns?: any;
  setKidConcerns?: any;
  technologyIssues?: any;
  setTechnologyIssues?: any;
  parentConcerns?: any;
  setParentConcerns?: any;
  otherConcernsExist?: any;
  setOtherConcernsExist?: any;
  otherConcerns?: any;
  setOtherConcerns?: any;
};

const Step1b: React.FC<Props> = ({ 
  setCurrentStep,
  kidConcerns,
  setKidConcerns,
  technologyIssues,
  setTechnologyIssues,
  parentConcerns,
  setParentConcerns,
  otherConcernsExist,
  setOtherConcernsExist,
  otherConcerns,
  setOtherConcerns 
}) => {
  const [labelText, setLabelText] = useState('Type feedback here...');

  useEffect(() => {
    if (otherConcerns.length !== 0) {
      setLabelText('');
    } else {
      setLabelText('Type feedback here...');
    }
  }, [otherConcerns]);

  return (
    <div className="step1">
      <div className="question">
        What was the biggest issue during this session?
      </div>
      <div className="my-4">
        <div className="mb-2">
          <FormElement checked={kidConcerns} setChecked={setKidConcerns} text={'Learning/Growth'}/>
        </div>
        <div className="mb-2">
          <FormElement checked={technologyIssues} setChecked={setTechnologyIssues} text={'Technology Issues'}/>
        </div>
        <div className="mb-2">
          <FormElement checked={parentConcerns} setChecked={setParentConcerns} text={'Parent Feedback'}/>
        </div>
        <div className="mb-2">
          <FormElement checked={otherConcernsExist} setChecked={setOtherConcernsExist} text={'Other'}/>
        </div>
        {
          otherConcernsExist &&
          <TextAreaInput small setInputText={setOtherConcerns} inputText={otherConcerns} labelText={labelText}/>
        }
      </div>
      <Button
        context="primary"
        className="w-100"
        onClick={() => {
          if (technologyIssues) {
            setCurrentStep('1c');
          } else {
            setCurrentStep('2');
          }
        }}
      >
        Continue
      </Button>
    </div>
  );
}

export default Step1b;