import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Modal from "components/Shared/NewModal";
import { RootState } from "store/state";
import { Button, Input } from "@codeverse/react-helios-ui";
import { dataClientRequest } from "@codeverse/redux-data-client";
import moment from "moment-timezone";
import { closeModal, openModal } from "store/modules/UI/actions";
import { CREATE_USER } from "models/User";
import { AppDispatch } from "store";
import { addChild as addChildToChildUsers } from "store/modules/User/actions";

const calculateAge = (birthday: Date) => {
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const calculateDifficulty = (age: number, hasExperience: boolean) => {
  if (age < 8) {
    return "easy";
  } else if (age < 11) {
    if (hasExperience) {
      return "medium";
    } else {
      return "easy";
    }
  } else if (hasExperience) {
    return "hard";
  } else {
    return "medium";
  }
};

const AddChildModal: React.FC<any> = ({ children, isOpen, handleClose }) => {
  const addChild = useSelector((state: RootState) => state.ui.addChild);
  const dispatch: AppDispatch = useDispatch();
  const [childName, setChildName] = useState("");
  const [birthday, setBirthday] = useState("");
  const childUsers = useSelector((state: RootState) => state.user.childUsers);

  const createUser = () => {
    if (!childName || !birthday) {
      return toast.error("Please complete all the fields", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    const firstName = childName.split(" ")[0];

    if (childUsers.length > 1) {
      let foundDuplicateChild = false;
      const lowercaseChildName = childName.toLowerCase();
      childUsers.map((child) => {
        if (lowercaseChildName === child.name) {
          foundDuplicateChild = true;
        }
      });
      if (foundDuplicateChild) {
        toast.error(`${firstName} already has an account!`, {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
    }
    let DOB = birthday.replace(/ /g, "");
    const [month, day, year] = DOB.split("/");
    DOB = `${year}-${month}-${day}`;
    const age = calculateAge(new Date(DOB));
    const difficulty = calculateDifficulty(age, false);
    const dateOfBirth = moment(birthday, "MM/DD/YYYY").format("YYYY-MM-DD");

    dispatch(
      dataClientRequest({
        ...CREATE_USER,
        data: {
          name: childName,
          date_of_birth: dateOfBirth,
          // difficulty_level: difficulty,
          scope: "child",
        },
      })
    ).then((payload: any) => {
      dispatch(closeModal("addChild"));
      dispatch(addChildToChildUsers(payload.response.data.data));
      toast.success(`${firstName} has been added`);
    });
  };

  return (
    <>
      <Modal
        isOpen={addChild}
        title={"Add Child"}
        modalName="editGuideAvailability"
        handleClose={() => {
          dispatch(closeModal("addChild"));
        }}
        className="add-child-modal"
        showHeader
      >
        <div className="add-child-modal-contents w-100">
          <div className="row">
            <div className="col-12">
              <Input
                className="w-100"
                name="childName"
                label="Full Name"
                onChange={(e: any) => {
                  setChildName(e.target.value);
                }}
                value={childName}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Input
                className="w-100"
                name="childName"
                label="Birthday (MM/DD/YYYY)"
                onChange={(e: any) => {
                  setBirthday(e.target.value);
                }}
                value={birthday}
                mask="99/99/9999"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Button
                onClick={createUser}
                className="w-100 mt-5"
                size="lg"
                context="primary"
              >
                Add Child
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddChildModal;
