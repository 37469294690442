import { RelationshipType, MetaType } from '../types/JSONAPI';
import { GetClientConfig } from 'store/data-client';

export interface User {
  id?: string;
  type?: string;
  name?: string;
  email?: string;
  referral_code?: string;
  gender?: string;
  date_of_birth?: string;
  grade?: string;
  ambassador?: false
  t_shirt_size?: string;
  do_not_email?: false
  do_not_phone_call?: false
  do_not_text_message?: false
  scope?: string;
  difficulty_level?: string;
  time_zone?: string;
  photo?: string;
  photo_2x?: string;
  created_at?: string;
  how_heard_about?: string;
  rank?: RelationshipType;
  avatar?: RelationshipType;
  links?: RelationshipType;
  meta?: MetaType;
}

export const GET_USER_GUIDE_PREFERENCES: GetClientConfig = {
  method: 'get',
  model: 'users',
  submodel: 'guide_preferences',
}

export const GET_USER_STRIPE_ACCOUNT: GetClientConfig = {
  method: 'get',
  model: 'users',
  submodel: 'stripe_account',
}

export const GET_USER_BOOKING_INSTANCES: GetClientConfig = {
  method: 'get',
  model: 'users',
  submodel: 'booking_instances',
  reducerKey: 'user',
  stateKey: 'userBookingInstances',
}

export const GET_RANKS: GetClientConfig = {
  method: 'get',
  model: 'ranks',
}

export const GET_USER_CURRICULUM_SCORES: GetClientConfig = {
  method: 'get',
  model: 'users',
  submodel: 'curriculum_scores'
}

export const GET_USER_GUIDE_PROFILE: GetClientConfig = {
  method: 'get',
  model: 'users',
  submodel: 'guide_profile',
}

export const CREATE_GUIDE_PROFILE: GetClientConfig = {
  method: 'post',
  model: 'guide_profiles',
}

export const UPDATE_GUIDE_PROFILE: GetClientConfig = {
  method: 'put',
  model: 'guide_profiles',
}

export const GET_GUIDE_SCHEDULABLES: GetClientConfig = {
  method: 'get',
  model: 'users',
  submodel: 'guide_schedulables',
  reducerKey: 'user',
  stateKey: 'guide_schedulables'
}

export const GET_GUIDE_AVAILABILITY: GetClientConfig = {
  method: 'get',
  submodel: 'guide_availabilities',
  model: 'users',
  reducerKey: 'user',
  stateKey: 'guide_availabilities',
}

export const CREATE_GUIDE_AVAILABILITY: GetClientConfig = {
  method: 'post',
  model: 'guide_availabilities',
  reducerKey: 'user',
  stateKey: 'guide_availabilities',
}

export const UPDATE_GUIDE_AVAILABILITY: GetClientConfig = {
  method: 'put',
  model: 'guide_availabilities',
  reducerKey: 'user',
  stateKey: 'guide_availabilities',
}

export const GET_USERS_PARTICIPATIONS: GetClientConfig = {
  method: 'get',
  submodel: 'participations',
  model: 'users',
  reducerKey: 'user',
  stateKey: 'participations',
}

export const GET_USERS_MEETINGS: GetClientConfig = {
  method: 'get',
  submodel: 'meetings',
  model: 'users',
  reducerKey: 'user',
  stateKey: 'meetings',
}

export const UPDATE_SUBSCRIPTION_WITH_PAYMENT_METHOD: GetClientConfig = {
  method: 'put',
  model: 'subscriptions',
}

export const CREATE_PAYMENT_METHOD_CONFIG: GetClientConfig = {
  method: 'post',
  model: 'payment_methods',
  reducerKey: 'organizations',
  stateKey: 'paymentMethods',
}

export const GET_BRAINTREE_TOKEN_CONFIG: GetClientConfig = {
  method: 'get',
  model: 'homepage/braintree_token',
  reducerKey: 'user',
  stateKey: 'braintreeToken',
}

export const GET_USER_MODEL_CONFIG: GetClientConfig = {
  method: 'get',
  model: 'users',
  reducerKey: 'user',
  stateKey: 'currentUser',
}

export const GET_OTHER_USER_MODEL_CONFIG: GetClientConfig = {
  method: 'get',
  model: 'users',
}

export const UPDATE_USER_MODEL_CONFIG: GetClientConfig = {
  method: 'put',
  model: 'users',
  reducerKey: 'user',
  stateKey: 'currentUser',
}

export const UPDATE_USER_MODEL_CONFIG_NO_STORE: GetClientConfig = {
  method: 'put',
  model: 'users',
}

export const CREATE_USER_ADDRESS_MODEL_CONFIG: GetClientConfig = {
  method: 'post',
  model: 'user_addresses',
  reducerKey: 'user',
  stateKey: 'userAddresses',
}

export const UPDATE_USER_ADDRESS_MODEL_CONFIG: GetClientConfig = {
  method: 'put',
  model: 'user_addresses',
  reducerKey: 'user',
  stateKey: 'userAddresses',
}

export const CREATE_USER_PHONE_NUMBER_MODEL_CONFIG: GetClientConfig = {
  method: 'post',
  model: 'phone_numbers',
  reducerKey: 'user',
  stateKey: 'userPhoneNumbers',
}

export const UPDATE_USER_PHONE_NUMBER_MODEL_CONFIG: GetClientConfig = {
  method: 'put',
  model: 'phone_numbers',
  reducerKey: 'user',
  stateKey: 'userPhoneNumbers',
}

export const RESET_USER_PASSWORD_MODEL_CONFIG: GetClientConfig = {
  method: 'post',
  model: 'password_resets',
}

export const POST_PASSWORD_RESET_ACCESS_TOKEN_MODEL_CONFIG: GetClientConfig = {
  method: 'post',
  model: 'access_token_password_resets',
}

export const UPDATE_USER_PASSWORD_MODEL_CONFIG: GetClientConfig = {
  method: 'put',
  model: 'users',
}

export const CREATE_PASSWORD_RESET_MODEL_CONFIG: GetClientConfig = {
  method: 'post',
  model: 'password_resets',
}

export const CREATE_USER: GetClientConfig = {
  method: 'post',
  model: 'users',
}
