import { Store, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./store/reducers";
import { RootState } from "./store/state";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import thunk from "redux-thunk";
import LogRocket from "logrocket";

if (process.env.NODE_ENV === "production") {
  if (LogRocket) {
    LogRocket.init("t5grki/portal-wdbai");
  }
}

import { dataClientMiddleware } from "@codeverse/redux-data-client";

import { rehydrateSessionFromCookies } from "store/modules/Session/actions";
import { getCookie } from "store/auth";

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

export function configureStore(initialState?: RootState): Store<RootState> {
  const cookie = getCookie();
  let middleware = applyMiddleware(
    thunk,
    dataClientMiddleware
    // LogRocket.reduxMiddleware()
  );

  if (process.env.NODE_ENV !== "production") {
    middleware = composeWithDevTools(middleware);
  }

  const store = createStore(
    rootReducer as any,
    initialState as any,
    middleware
  ) as Store<RootState>;

  if (module.hot) {
    module.hot.accept("./store/reducers", () => {
      const nextReducer = require("./store/reducers");
      store.replaceReducer(nextReducer);
    });
  }
  console.log("cookie", cookie);
  if (cookie && cookie.access_token && cookie.user.id) {
    if (cookie.access_token.access_token) {
      store.dispatch(rehydrateSessionFromCookies(cookie));
    }
  }

  return store;
}
