import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment-timezone';
import { RootState } from 'store/state';
import PastSessionCard from 'components/Parent/Schedule/PastSessionCard';
import { Subscription } from 'models/Subscription';

type BillingCycle = {
  startMoment: Moment
  endMoment: Moment
  displayName: string
}

const createBillingCycle = (startMoment: any, endMoment: any) => {
  let endDisplay;
  if (endMoment.isSame(moment())) {
    endDisplay = 'Today'
  } else {
    endDisplay = `${endMoment.format('MMMM DD, YYYY')}`
  }
  const displayName = `${startMoment.format('MMMM DD, YYYY')} - ${endDisplay}`;
  return {
    startMoment,
    endMoment,
    displayName,
  };
}

const getMostRecentBillingCycle = (subscription: Subscription) => {
  return createBillingCycle(moment(subscription.braintree_billing_period_start_date, 'YYYY-MM-DD'), moment(subscription.braintree_billing_period_end_date, 'YYYY-MM-DD'))
}

const skippedParticipationStatuses = [
  'guide_abandoned',
  'guide_no_show',
];

const allowableMeetingStatus = [
  'ended',
  'children_no_show',
]

const PastSessions = () => {
  const subscriptions = useSelector((state: RootState) => state.organizations.subscriptions);
  const bookings = useSelector((state: RootState) => state.user.bookings);
  const participations = useSelector((state: RootState) => state.user.participations);

  const activeSubscription = useMemo(() => {
    const activeSubscription = subscriptions.find((subscription: any) => {
      return subscription.status === 'active'
    });

    if (activeSubscription) {
      return activeSubscription;
    }
    return null;
  }, [subscriptions]);


  const PastSessionCards = useMemo(() => {
    if (activeSubscription) {
      const billingCycle = getMostRecentBillingCycle(activeSubscription);
      const currentParticipations = participations.filter((participation: any) => {
        const firstVisitAtMoment = moment(participation.meeting_start_at);
        const isWithinCycle = firstVisitAtMoment.isSameOrAfter(billingCycle.startMoment) && firstVisitAtMoment.isSameOrBefore(billingCycle.endMoment);
        return isWithinCycle && !skippedParticipationStatuses.includes(participation.status) && allowableMeetingStatus.includes(participation.meeting_status);
      });

      return (
        <div>
          <div className="billing-cycle-headers">{billingCycle.displayName}</div>
          {currentParticipations.length === 0 && <h6>No Past Sessions</h6>}
          {currentParticipations.map((participation: any) => <PastSessionCard participation={participation} />)}
        </div>
      )
    }
  }, [participations, activeSubscription])


  return (
    <div className="past-sessions">
      <div>
        <h2 className="mt-5 mb-4 pb-2">Past Sessions</h2>
      </div>

      {PastSessionCards}
    </div>
  )
};

export default PastSessions;
