import { GetClientConfig } from 'store/data-client';

export type Subscription = {
  id: string
  type: string
  name: string
  status: string
  start_at: string
  cadence: string
  trial_period: boolean
  cancel_at: string,
  cancellation_code: string,
  braintree_balance: string
  braintree_billing_day_of_month: number,
  braintree_billing_period_end_date: string
  braintree_billing_period_start_date: string
  braintree_current_billing_cycle: number,
  braintree_days_past_due: string,
  braintree_failure_count: number,
  braintree_next_billing_date: string
  braintree_next_billing_period_amount: string
  organization: {
    type: string
    id: string
  },
  location: string,
  payment_method: {
    type: string
    id: string
  },
  links: {
    self: string
  },
  meta: {
    deletable: true,
    deleted_at: string
  }
}

export const GET_SUBSCRIPTION_ADD_ONS: GetClientConfig = {
  method: 'get',
  model: 'subscriptions',
  submodel: 'subscription_add_ons',
  reducerKey: 'organizations',
  stateKey: 'subscriptionAddOns',
}