import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Button, Card } from "@codeverse/react-helios-ui";
import PlanCard from "components/NewCheckout/ChoosePlan/PlanCard";

type Props = {
  hashKey: string;
  goToStepHash?: any;
  noBack?: boolean;
  hash?: any;
};

const ChoosePlan: React.FC<Props> = ({
  goToStepHash,
  noBack,
  hash = "review",
}) => {
  const mobileSettings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    initialSlide: 1,
    focusOnSelect: true,
    variableWidth: true,
    dots: true,
    arrows: false,
    appendDots: (dots: any) => (
      <div className="Testimonials-slider-controls">
        <ul className="Testimonials-slider-controls-dots">{dots}</ul>
      </div>
    ),
    dotsClass: "Testimonials-slider-controls",
    // responsive: [
    //   {
    //     breakpoint: 480,
    // centerPadding: '16px',
    //     settings: {
    //       centerMode: true,
    //       slidesToShow: 1,
    //       // variableWidth: true,
    //     },
    //   },
    // ],
  };

  return (
    <div className="choose-plan">
      <div className="header-container">
        {!noBack && (
          <div
            onClick={() => goToStepHash("review")}
            className="back-arrow-button"
          >
            <i className="fas fa-chevron-left pr-2" />
            Back
          </div>
        )}
        <h1 className="header mb-0">Choose your plan</h1>
      </div>

      <div className="plan-carousel">
        <Slider {...mobileSettings} className="d-lg-none">
          <PlanCard
            goToStepHash={goToStepHash}
            sessionCount={2}
            price={159}
            hash={hash}
          />
          <PlanCard
            goToStepHash={goToStepHash}
            sessionCount={4}
            price={269}
            hash={hash}
          />
          <PlanCard
            goToStepHash={goToStepHash}
            sessionCount={8}
            price={489}
            hash={hash}
          />
        </Slider>

        <div className="plan-cards d-none d-lg-flex justify-content-center">
          <PlanCard
            goToStepHash={goToStepHash}
            sessionCount={2}
            price={159}
            hash={hash}
          />
          <PlanCard
            goToStepHash={goToStepHash}
            sessionCount={4}
            price={269}
            hash={hash}
          />
          <PlanCard
            goToStepHash={goToStepHash}
            sessionCount={8}
            price={489}
            hash={hash}
          />
        </div>
      </div>
    </div>
  );
};

export default ChoosePlan;
