export default function (
  toast: any,
  userHasAccessToPlatform: boolean,
  activeSubscription: any,
  subscriptionAddOns: any,
  access_token: string,
  childUser: any,
  currentOrganizationId: string,
  isMobile: boolean,
  priorityMembership: any
) {
  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (!isChrome && !isSafari && !iOS) {
    toast.error("Please use Chrome version 83 or higher", {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: "A",
    });
  } else if (userHasAccessToPlatform) {
    // @ts-ignore
    if (window.heap && typeof window.heap.resetIdentity === "function") {
      // @ts-ignore
      window.heap.resetIdentity();
    }
    // @ts-ignore
    if (window.heap) {
      // @ts-ignore
      window.heap.identify(childUser.referral_code);
    }
    const start_at = activeSubscription ? activeSubscription.start_at : null;
    const add_on = subscriptionAddOns[0] ? subscriptionAddOns[0].product : null;
    return `${process.env.REACT_APP_ESCAPE_POD_URL}/home?access_token=${access_token}&current_user_id=${childUser.id}&current_organization_id=${currentOrganizationId}`;
  }
}

export function streamSignIn(childUser: any) {
  const access_token = localStorage.getItem("accessToken");
  const currentOrganizationId = localStorage.getItem("currentOrganizationId");
  const currentUserId = localStorage.getItem("currentUserId");
  // @ts-ignore
  if (window.heap) {
    // @ts-ignore
    window.heap.resetIdentity();
  }
  // @ts-ignore
  if (window.heap) {
    // @ts-ignore
    window.heap.identify(childUser.referral_code);
  }
  window.location.assign(
    `${process.env.REACT_APP_ESCAPE_POD_URL}/stream/6f274879-e134-4934-8bc7-4b840ce632e1?access_token=${access_token}&current_user_id=${childUser.id}&current_organization_id=${currentOrganizationId}&parent_user_id=${currentUserId}`
  );
}
