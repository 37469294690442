import React, { useEffect, useState, useMemo, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from '@codeverse/react-helios-ui';
import Modal from 'components/Shared/NewModal';
import { closeModal, openModal } from 'store/modules/UI/actions';
import transformHourInteger from 'utils/transformHourInteger';
import { dataClientRequest } from '@codeverse/redux-data-client';
import { AppDispatch } from 'store';
import { RootState } from 'store/state';

type Props = {
  history?: any;
  location?: any;
  match?: any;
  currentBooking: any;
  currentBookingInstance?: any;
  setCurrentStep: any;
  setOnlyOneDay?: any;
};

const Step1: React.FC<Props> = ({ currentBooking, currentBookingInstance, setCurrentStep, setOnlyOneDay }) => {
  const rescheduleModal = useSelector((state: RootState) => state.ui.rescheduleModal);
  const timezone = useSelector((state: RootState) => state.user.currentUser.time_zone);
  const dispatch: AppDispatch = useDispatch();

  const bookingMomentObj = useMemo(() => {
    return moment.tz(`${currentBookingInstance.start_date}T${currentBookingInstance.start_time}`, 'America/Chicago').tz(timezone)
  }, [currentBooking]);

  return (
    <>
      <div className="d-flex justify-content-center ">
        {bookingMomentObj && <h4>{bookingMomentObj.format('dddd MM/DD/YYYY')} <br /> @ {bookingMomentObj.format('h:mm A z')}</h4>}
      </div>

      <div className="w-100 reschedule-modal__actions">
        <Button
          onClick={() => {
            setCurrentStep(2);
            setOnlyOneDay(true);
          }}
          className="w-100 mb-3"
          context="primary"
        >
          Only On This Date
        </Button>
        <Button
          onClick={() => {
            setCurrentStep(2)
            setOnlyOneDay(false);
          }}
          style={{ backgroundColor: '#fc5f54', fontSize: '14px' }}
          className="w-100"
          context="status-danger"
        >
          Every week at this date and time going forward
        </Button>
      </div>
    </>
  );
}

export default Step1;

