// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const Step = ({
  children,
  isActive,
  transitions,
}) => (
  <div className={`step ${transitions} ${isActive ? 'step-active' : 'hidden'}`.trim()}>
    {children}
  </div>
);

const StepWizard = ({
  className,
  children,
  initialStep,
  isHashEnabled,
}) => {
  const [activeStep, setActiveStepState] = useState(initialStep);
  const [hashKeys, setHashKeys] = useState({});
  const [classes, setClasses] = useState({});

  const currentStep = activeStep + 1;
  const totalSteps = children.length;

  const isInvalidStep = next => (next < 0 || next >= totalSteps);

  const transitions = {
    enterRight: 'animated fadeInRight',
    enterLeft: 'animated fadeInLeft',
    exitRight: 'animated fadeOutRight',
    exitLeft: 'animated fadeOutLeft',
  };

  const updateHash = (step, keys) => {
    if (keys[step]) {
      window.location.hash = keys[step];
    }
  };

  const onStepChange = (step, keys) => {
    // User callback
    // this.props.onStepChange(stats);
    // Update hash if prop set
    if (isHashEnabled) updateHash(step, keys);
  };

  const setActiveStep = (next, keys) => {
    const active = activeStep;
    if (Object.keys(keys).length === 0) return;
    if (active === next) {
      return updateHash(active, keys);
    }

    if (isInvalidStep(next)) {
      console.error(`${next + 1} is an invalid step`);
      return;
    }
    const newClasses = Object.assign({}, { ...classes });

    if (active < next) {
      // slide left
      newClasses[active] = transitions.exitLeft;
      newClasses[next] = transitions.enterRight;
    } else {
      // slide right
      newClasses[active] = transitions.exitRight;
      newClasses[next] = transitions.enterLeft;
    }
    setClasses(newClasses);
    setActiveStepState(next);
    onStepChange(next, keys);
  };

  const setActiveStepHash = (hash) => {
    const stepNumber = hashKeys[hash];
    setActiveStep(stepNumber, hashKeys);
  };


  /** Functions */
  const nextStep = () => setActiveStep(activeStep + 1, hashKeys);
  const previousStep = () => setActiveStep(activeStep - 1, hashKeys);
  const goToStep = step => setActiveStep(step - 1, hashKeys);
  const goToStepHash = hash => setActiveStepHash(hash);
  const firstStep = () => goToStep(1);
  const lastStep = () => goToStep(totalSteps);

  const isReactComponent = ({ type }) => (
    typeof type === 'function' || typeof type === 'object'
  );

  const props = {
    currentStep,
    totalSteps,
    /** Functions */
    nextStep,
    previousStep,
    goToStep,
    goToStepHash,
    firstStep,
    lastStep,
  };

  const getHash = () => decodeURI(window.location.hash).replace(/^#/, '');

  useEffect(() => {
    const hash = typeof window === 'object' ? getHash() : '';
    const newHashkeys = {};

    children.forEach((child, i) => {
      newHashkeys[i] = (child.props && child.props.hashKey) || `step${i + 1}`;
      newHashkeys[newHashkeys[i]] = i;
    });
    setHashKeys(newHashkeys);

    setClasses({ [activeStep]: '' });
  }, []);

  const windowLocationHash = getHash();


  useEffect(() => {
    const hash = getHash();
    if (hash.length === 0) {
      setActiveStep(1, hashKeys);
    } else {
      const next = hashKeys[getHash()];
      if (next !== undefined) setActiveStep(next, hashKeys);
    }
  }, [windowLocationHash, hashKeys]);


  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (!child) return null;
    props.isActive = (i === activeStep);
    props.transitions = classes[i];
    if (props.isActive || child.props.alwaysMount) {
      return (
        <Step key={child.props.hashKey} {...props}>{
          isReactComponent(child)
            ? React.cloneElement(child, props)
            : child
        }</Step>
      );
    }
    return null;
  });

  return (
    <div className={className}>
      <div className={'step-wrapper'}>
        {childrenWithProps.length === 0 ? <div /> : childrenWithProps}
      </div>
    </div>
  );
};

StepWizard.propTypes = {
  children: PropTypes.node,
  initialStep: PropTypes.number,
};

export default StepWizard;
