import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from '@codeverse/react-helios-ui';
import classNames from 'classnames';

import { CURRENT_VIEW } from 'containers/Guide/Meeting';
import { RootState } from 'store/state';

type Props = {
  history?: any;
  location?: any;
  currentView: CURRENT_VIEW;
  setCurrentView: any;
};

const ViewSelector: React.FC<Props> = ({ history, location, currentView, setCurrentView }) => {
  const dispatch = useDispatch();
  const screenClassnames = classNames('screen-toggle', {
    'screen-toggle__active': currentView === 'screen',
  });
  const editorClassnames = classNames('editor-toggle', {
    'editor-toggle__active': currentView === 'editor',
  });
  const screenIconClassnames = classNames('screen-icon');
  const editorIconClassnames = classNames('editor-icon');
  return (
    <div className="view-selector">
      <div onClick={() => setCurrentView('screen')} className={screenClassnames}>
        <div className={screenIconClassnames} />
        <div className="screen-text">
          Screen
        </div>
      </div>
      <div onClick={() => setCurrentView('editor')} className={editorClassnames}>
        <i className={editorIconClassnames} />
        Editor
      </div>
    </div>
  );
};

export default ViewSelector;
