import React from 'react';
import { Button } from '@codeverse/react-helios-ui';
import { VIRTUAL_TRIAL } from 'data/occasions';

import TextAreaInput from 'components/Shared/TextAreaInput';

type Props = {
  childName?: any;
  createNote?: any;
  input1Text?: any;
  meeting?: any;
  setInput1Text?: any;
  history?: any;
  location?: any;
  setBigMoney?: any;
  setShowHeader?: any;
  setCurrentStep?: any;
  setSubtext?: any;
  updateMeeting?: any;
};

const Step2: React.FC<Props> = ({ childName, createNote, input1Text, meeting, setInput1Text, setBigMoney, setCurrentStep, setShowHeader, setSubtext, updateMeeting }) => {
  return (
    <div className="step2">
      <div className="question">
        Leave an internal note about {childName}'s session:
      </div>
      <div className="my-4">
        <TextAreaInput inputText={input1Text} setInputText={setInput1Text} labelText={'Write your note here'} />
      </div>
      <Button
        context="primary"
        className="w-100"
        onClick={() => {
          if (meeting.occasion.id === VIRTUAL_TRIAL) {
            updateMeeting();
            createNote('progress', input1Text);
            setShowHeader(false);
            setBigMoney(true);        
            setCurrentStep('3c');
            setSubtext('');
          } else {
            setCurrentStep('3a');
            setSubtext(<a target="_blank" rel="noopener noreferrer" href="https://codeverse.gitbook.io/guide-landing/-ME4jRPapBu1HUKSSEyJ/resources/communication-scripts/session-update-note-writing-tips">Tips for writing session note to parents</a>);
          }
        }}
      >
        Continue
      </Button>
    </div>
  );
}

export default Step2;