import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from 'store/modules/UI/actions';
import 'styles/components/_modal.scss';

type Props = {
  history?: any;
  isOpen?: any;
  location?: any;
  title?: any;
  modalName: string;
  icon?: any;
  className?: string;
};

export const Modal: React.FC<Props> = ({ children, isOpen, title, modalName, icon, className }) => {
  const dispatch = useDispatch();

  const modalClassName = classNames('modal fade', {
    show: isOpen,
  });

  const modalContentClassName = classNames('modal-content modal-responsive', className);

  const modalStyles = Object.assign({},
    isOpen && { display: 'block' },
  );

  const iconBtnStyles = {
    top: '10px',
    right: '10px',
    zIndex: 10000,
  };

  const iconStyles = {
    top: '9px',
    right: '8px',
    zIndex: 10000,
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-container" onClick={() => dispatch(closeModal(modalName))}>
      <div className={modalClassName} style={{ ...modalStyles }} role="dialog">
        <div className="modal-dialog">
          <div className={modalContentClassName} onClick={e => e.stopPropagation()}>
            {title && <div className="modal-header pr-0 pl-0">
              {typeof icon === 'function'
                ? icon()
                : <div onClick={() => dispatch(closeModal(modalName))} style={iconBtnStyles} className="btn-icon position-absolute" >
                  <i onClick={() => dispatch(closeModal(modalName))} style={iconStyles} className="fas fa-lg fa-times position-absolute" />
                </div>}
              {title && <h5 className="modal-title col-12 text-center pr-0 pl-0">{title}</h5>}
            </div>}
            <div className="modal-body">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

