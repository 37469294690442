import React, { useEffect, useState } from 'react';
import SatelliteClient from '@codeverse/satellite-client';
import { SearchSelect } from '@codeverse/react-helios-ui';

const CustomOption: React.FC<any> = ({ innerProps, isDisabled, data, ...rest }) => {
  return (!isDisabled ? (
    <div className="select-search-dropdown__custom-option-heading d-flex justify-content-center flex-column" {...innerProps}>
      <div className="select-search-dropdown__custom-option-heading--main">{data.label}</div>
      <div className="select-search-dropdown__custom-option-heading--sub">{data.location}</div>
    </div>
  ) : null);
};

const SchoolDropdown: React.FC<any> = ({
  currentOrganizationId,
  handleInputChange,
  setSchools,
  schools,
  query,
  queryAllSchools,
  label = 'Select Your School',
}) => {
  const [inputVal, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const handleUpdateOptions = (inputValue: any) => {
    if (inputVal.length === 0 && currentOrganizationId) {
      return schools.filter((i: any) => i.value === currentOrganizationId);
    }
    if (inputVal.length === 0) {
      return [];
    }
    return schools.filter((i: any) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const promiseOptions = (inputValue: any) => {
    if (queryAllSchools) {
      if (inputValue.length > 0) {
        return SatelliteClient.platform.get('/schools', {
          headers: Object.assign({},
            { Accept: 'application/vnd.api+json' },
            { 'content-type': 'application/vnd.api+json' },
          ),
          query: {
            filter: {
              q: inputVal,
            },
          },
        }).then((response: any) => {
          const schoolOptions: any = [];
          response.data.data.map((school: any) => {
            if (!school.city && !school.state) {
              schoolOptions.push({ value: school.id, label: school.name, location: '' });
            } else {
              schoolOptions.push({ value: school.id, label: school.name, location: `${school.city}, ${school.state}` });
            }
          });
          setSchools(schoolOptions);
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(schoolOptions);
            }, 100);
          });
        });
      } else {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve([]);
          }, 1000);
        });
      }
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(handleUpdateOptions(inputValue));
        }, 1000);
      });
    }
  };

  useEffect(() => {
    if (!queryAllSchools) {
      SatelliteClient.platform.get('/schools', {
        headers: Object.assign({},
          { Accept: 'application/vnd.api+json' },
          { 'content-type': 'application/vnd.api+json' },
        ),
        query: {
          filter: {
            automatic_account_creation: true,
          },
        },
      }).then((response: any) => {
        const schoolOptions: any = [];
        response.data.data.map((school: any) => {
          if (!school.city && !school.state) {
            schoolOptions.push({ value: school.id, label: school.name, location: `${school.city}, ${school.state}` });
          } else {
            schoolOptions.push({ value: school.id, label: school.name, location: `${school.city}, ${school.state}` });
          }
        });
        setSchools(schoolOptions);
      });
    }
  }, []);
  return (
    <SearchSelect
      placeholder=""
      label={label}
      CustomOption={CustomOption}
      loadOptions={promiseOptions}
      options={schools}
      onChange={(val: any) => {
        handleInputChange(val);
        setIsFocused(false);
      }}
      value={schools.find((school: any) => school.value === currentOrganizationId)}
      noOptionsMessage={() => {
        if (!currentOrganizationId && !inputVal) {
          return 'Start typing to find your school';
        } else if (currentOrganizationId && !inputVal) {
          return 'Start typing to find another school';
        } else {
          return 'School entered not found';
        }
      }}
      onInputChange={(val: any) => {
        setInputValue(val);
        return val;
      }}
      isFocused={isFocused}
      setIsFocused={setIsFocused}
    />
  );
};

SchoolDropdown.defaultProps = {
  automatic_account_creation: true,
};

export default SchoolDropdown;