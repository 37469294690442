import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from '@codeverse/react-helios-ui';

import { closeModal } from 'store/modules/UI/actions';
import HalfModal from 'components/Shared/HalfModal';


const PasswordConfirmation: React.FC<any> = ({ onSignIn }) => {
  const isOpen = useSelector((state: any) => state.ui.passwordConfirmation);
  const characterImage = useSelector((state: any) => state.ui.currentPasswordObject && state.ui.currentPasswordObject.imgPath);
  const color = useSelector((state: any) => state.ui.currentCardColor);
  const dispatch = useDispatch();

  const handleSignin = (e: any) => {
    e.preventDefault();
    onSignIn();
    dispatch(closeModal('passwordConfirmation'));
  };

  const cardStyles = {
    backgroundColor: color,
  };

  return (
    <div className="password-confirmation-modal">
      <HalfModal
        isOpen={isOpen}
        handleClose={() => dispatch(closeModal('passwordConfirmation'))}
      >
        <Card style={cardStyles} className="password-confirmation-modal__container">
          <div onClick={() => dispatch(closeModal('passwordConfirmation'))} className="password-confirmation-modal__icon-container">
            <i className="far fa-times fa-2x" />
          </div>
          <img className="password-confirmation-modal__image" src={characterImage} />
          <p className="password-confirmation-modal__text">
            Remember this Character! This will be your secret character for logging into your account.
          </p>
          <Button
            className="password-confirmation-modal__button"
            context="secondary"
            onClick={(e: any) => handleSignin(e)}
          >
            OK, Got it!
          </Button>
        </Card>
      </HalfModal>
    </div>
  );
};

export default PasswordConfirmation;
