import React, { useEffect, useState } from 'react';
import { Button, Badge, Input, Select } from '@codeverse/react-helios-ui';
import { useHistory } from "react-router-dom";

const Booked: React.FC<any> = ({ }) => {
  const history = useHistory();
  const handleSubmit = () => {
    history.push('/parent/dashboard');
  }
  return (
    <div className="m-width-checkout booked">
      <h2 className="step-title">
        <span style={{ color: '#FDBA17' }}>You're</span> <br />
        <span style={{ color: 'white' }}>Confirmed!</span>
      </h2>

      <div className="confirmation">
        <p>You will receive a confirmation email shortly, followed by a welcome text or phone call soon! We look forward to seeing you!</p>
      </div>



      <div className="action mt-5">
        <Button
          className="w-100"
          context="tertiary"
          onClick={() => handleSubmit()}
        >
          Go to Homepage
          </Button>
      </div>
    </div>
  );
};

export default Booked;
