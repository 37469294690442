import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dataClientRequest } from "@codeverse/redux-data-client";
import { start } from "@codeverse/public-session-tracker";
import { getCookie, removeCookie, saveCookie } from "store/auth";

import {
  getOrganizationMembershipsData,
  getOrganizationSubscriptionsData,
  getUserData,
} from "store/modules/Organizations/actions";
import { UPDATE_USER_MODEL_CONFIG } from "models/User";
import {
  getParentData,
  setPriorityMembership,
} from "store/modules/User/actions";

import { GET_USERS_PARTICIPATIONS } from "models/User";

import Login from "containers/Login";
import NewPasswordForm from "containers/NewPasswordForm";

import { requireAuth } from "components/Shared/Authenticated";
import Navbar from "components/Shared/Navbar";
import { GuideProfile } from "containers/Guide/Profile";
import { GuideSchedule } from "containers/Guide/Schedule";
import Meeting from "containers/Guide/Meeting";
import { UpcomingSessions } from "containers/Guide/UpcomingSessions";
import { StudentProfile } from "containers/Guide/StudentProfile";
import { Dashboard as ParentDashboard } from "containers/Parent/Dashboard";
import Schedule from "containers/Parent/Schedule";
import ParentProfile from "containers/Parent/ParentProfile";
import ParentSurvey from "containers/Parent/ParentSurvey";
import GuideSurvey from "containers/Guide/GuideSurvey";
import SchoolAccess from "containers/SchoolAccess";
import Checkout from "containers/Checkout";
import NewCheckout from "containers/NewCheckout";
import NoCCTrialCheckout from "containers/NoCCTrialCheckout";
import AddChildModal from "components/Parent/AddChildModal";
import AddChildCheckoutModal from "components/Parent/AddChildCheckoutModal";
import ChildSettings from "components/Parent/ChildSettings";

import BookFirstSessionModal from "components/Parent/Schedule/BookFirstSessionModal/BookFirstSessionModal";

import { hot } from "react-hot-loader";
import { RootState } from "store/state";
import moment from "moment-timezone";
import { setTimezone } from "store/modules/User/actions";
import { AppDispatch } from "store";
import JoinStreamChild from "components/Parent/JoinStreamChild";

declare const window: any;

const GuideLayoutContainer: React.FC<any> = ({
  history,
  location,
  children,
}) => {
  const { path, url } = useRouteMatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  useEffect(() => {
    if (
      currentUser.scope !== "administrator" &&
      currentUser.scope !== "guide"
    ) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("guideLogin");
      removeCookie();
      window.location.reload();
    }
  }, []);
  return (
    <>
      <Navbar history={history} location={location} guide />
      <div className="guide-portal container">
        <Switch>
          <Route exact path={`${path}/profile`} component={GuideProfile} />
          <Route exact path={`${path}/schedule`} component={GuideSchedule} />
          <Route
            exact
            path={`${path}/upcoming-sessions`}
            component={UpcomingSessions}
          />
          <Route
            exact
            path={`${path}/student/:id`}
            component={StudentProfile}
          />
          <Route exact path={`${path}/survey`} component={GuideSurvey} />
        </Switch>
      </div>
    </>
  );
};

const ParentDataContainer: React.FC<any> = ({
  history,
  location,
  children,
}) => {
  const [hasRetrievedParentData, setHasRetrievedParentData] = useState(false);
  const [dataRetrieved, setDataRetrieved] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const accessToken =
    useSelector(
      (state: RootState) => state.session.access_token.access_token
    ) || "";
  const userMemberships = useSelector(
    (state: RootState) => state.user.userMemberships
  );
  const priorityMembership = useSelector(
    (state: RootState) => state.user.priorityMembership
  );
  const subscriptions = useSelector(
    (state: RootState) => state.organizations.subscriptions
  );
  const memberships = useSelector(
    (state: RootState) => state.organizations.memberships
  );
  const fetchedUsers = useSelector(
    (state: RootState) => state.organizations.fetchedUsers
  );
  const participations = useSelector(
    (state: RootState) => state.user.participations
  );
  const users = useSelector((state: RootState) => state.organizations.users);

  const [hasSetChildUsers, setHasSetChildUsers] = useState(false);
  const [pastDueSubscription, setPastDueSubscription] = useState(false);
  const [noActiveSubscriptions, setNoActiveSubscriptions] = useState(true);
  const [currentSubscriptions, setCurrentSubscriptions] = useState([]);
  const [currentMemberships, setCurrentMemberships] = useState([]);
  const [childUsers, setChildUsers] = useState([]);
  const [hasRetrievedMemberships, setHasRetrievedMemberships] = useState(false);
  const [hasRetrievedUsers, setHasRetrievedUsers] = useState(false);
  const [hasRetrievedUsersParticipations, setHasRetrievedUsersParticipations] =
    useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [hasRetrievedSubscriptionAddOns, setHasRetrievedSubscriptionAddOns] =
    useState(false);
  const [hasRetrievedPaymentMethods, setHasRetrievedPaymentMethods] =
    useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, []);

  useEffect(() => {
    if (childUsers.length > 0) {
      dispatch({ type: "SET_CHILD_USERS", childUsers });
    }
  }, [childUsers]);

  useEffect(() => {
    if (width) {
      if (width < 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [width]);

  const getParentDataFunc = () => {
    setHasRetrievedParentData(false);
    let userId = currentUser.id;
    if (localStorage.getItem("replaceSession") === "true") {
      userId = localStorage.getItem("currentUserId");
    }
    Promise.all([dispatch(getParentData(accessToken, userId))])
      .then(() => {
        setHasRetrievedParentData(true);
      })
      .catch(() => {
        setHasRetrievedParentData(true);
      });
  };

  useEffect(() => {
    getParentDataFunc();
  }, [currentUser]);

  useEffect(() => {
    if (
      hasRetrievedParentData &&
      userMemberships &&
      userMemberships.length > 0
    ) {
      if (userMemberships.length === 1) {
        dispatch(setPriorityMembership(userMemberships[0]));
      } else {
        let ownerMembership = null;
        let instructorMembership = null;
        userMemberships.forEach((membership: any) => {
          if (membership.role === "ownership") {
            ownerMembership = membership;
          } else if (membership.role === "instructorship") {
            instructorMembership = membership;
          }
        });
        if (ownerMembership) {
          dispatch(setPriorityMembership(ownerMembership));
        } else if (instructorMembership) {
          dispatch(setPriorityMembership(instructorMembership));
        }
      }
    }
  }, [hasRetrievedParentData, userMemberships]);

  useEffect(() => {
    if (priorityMembership) {
      Promise.all([
        dispatch(
          getOrganizationSubscriptionsData(
            accessToken,
            currentUser.id || "",
            priorityMembership.organization.id
          )
        ),
      ]).then(() => {});
    }
  }, [priorityMembership, accessToken, currentUser]);

  useEffect(() => {
    if (priorityMembership) {
      setHasRetrievedMemberships(false);
      setHasRetrievedUsers(false);
      setHasRetrievedSubscriptionAddOns(false);
      setHasRetrievedPaymentMethods(false);
      setHasSetChildUsers(false);
      setCurrentMemberships([]);
      setCurrentSubscriptions([]);
    }
  }, []);

  useEffect(() => {
    if (priorityMembership) {
      dispatch({
        type: "SET_ORGANIZATION",
        organization: priorityMembership.organization,
      });
    }
  }, [priorityMembership]);

  useEffect(() => {
    if (priorityMembership) {
      setHasRetrievedMemberships(false);
      setHasRetrievedUsers(false);
      setHasRetrievedSubscriptionAddOns(false);
      setHasRetrievedPaymentMethods(false);
      setHasSetChildUsers(false);
      setCurrentMemberships([]);
    }
  }, [priorityMembership]);

  useEffect(() => {}, [subscriptions]);

  useEffect(() => {
    if (priorityMembership && subscriptions.length > 0) {
      const tempCurrentSubscriptions = subscriptions.filter(
        (subscription: any) => {
          return (
            subscription.organization.id === priorityMembership.organization.id
          );
        }
      );
      if (tempCurrentSubscriptions.length > 0) {
        setCurrentSubscriptions(tempCurrentSubscriptions);
      } else {
      }
    }
  }, [subscriptions, priorityMembership]);

  useEffect(() => {
    if (priorityMembership && memberships.length > 0) {
      const tempCurrentMemberships = memberships.filter((membership: any) => {
        return (
          membership.organization.id === priorityMembership.organization.id
        );
      });
      if (tempCurrentMemberships.length > 0) {
        setCurrentMemberships(tempCurrentMemberships);
        setHasRetrievedMemberships(true);
      } else if (!hasRetrievedMemberships) {
        Promise.all([
          dispatch(
            getOrganizationMembershipsData(
              accessToken,
              currentUser.id || "",
              priorityMembership.organization.id
            )
          ),
        ])
          .then(() => {
            setHasRetrievedMemberships(true);
          })
          .catch(() => {
            setHasRetrievedMemberships(true);
          });
      }
    } else if (priorityMembership && !hasRetrievedMemberships) {
      Promise.all([
        dispatch(
          getOrganizationMembershipsData(
            accessToken,
            currentUser.id || "",
            priorityMembership.organization.id
          )
        ),
      ])
        .then(() => {
          setHasRetrievedMemberships(true);
        })
        .catch(() => {
          setHasRetrievedMemberships(true);
        });
    }
  }, [memberships, priorityMembership, hasRetrievedMemberships]);

  useEffect(() => {
    if (hasRetrievedMemberships && currentMemberships.length > 0) {
      const tempCurrentUsers: any = [];
      const tempMissingMembers: any = [];
      currentMemberships.forEach((membership: any) => {
        const existingUser = users.find((user: any) => {
          return user.id === membership.user.id;
        });
        if (existingUser) {
          tempCurrentUsers.push(existingUser);
        } else {
          tempMissingMembers.push(membership);
        }
      });
      if (tempMissingMembers.length > 0 && !hasRetrievedUsers) {
        Promise.all([dispatch(getUserData(accessToken, tempMissingMembers))])
          .then(() => {
            setHasRetrievedUsers(true);
          })
          .catch(() => {
            setHasRetrievedUsers(true);
          });
      } else if (tempMissingMembers.length === 0) {
        setHasRetrievedUsers(true);
      }
    }
  }, [hasRetrievedMemberships, currentMemberships]);

  useEffect(() => {
    if (currentSubscriptions.length > 0) {
      currentSubscriptions.map((subscription: any) => {
        if (
          subscription.status === "active" ||
          subscription.status === "pending" ||
          subscription.status === "canceling" ||
          subscription.status === "updating_in_braintree"
        ) {
          setNoActiveSubscriptions(false);
        } else if (subscription.status === "past_due") {
          setPastDueSubscription(true);
        }
      });
    }
  }, [currentSubscriptions]);

  useEffect(() => {
    if (hasRetrievedUsers) {
      const tempChildUsers: any = [];
      currentMemberships.map((membership: any) => {
        if (membership.role === "studentship") {
          const currentUser = users.find((user: any) => {
            return user.id === membership.user.id;
          });
          if (
            currentUser &&
            currentUser.scope === "child" &&
            !currentUser.meta.deleted_at
          ) {
            tempChildUsers.push(currentUser);
          }
        }
      });
      setHasSetChildUsers(true);
      setChildUsers(tempChildUsers);
    }
  }, [hasRetrievedUsers, users]);

  let userHasAccessToPlatform = true;
  if (priorityMembership) {
    // userHasAccessToPlatform = !isMobile;
  }

  useEffect(() => {
    // if (currentUser) {
    //   if (localStorage.getItem('replaceSession') === null) {
    //     localStorage.setItem('currentUserId', currentUser.id || '');
    //   }
    // }
  }, [currentUser]);

  useEffect(() => {
    setChildUsers([]);
    if (priorityMembership) {
      localStorage.setItem(
        "currentOrganizationId",
        priorityMembership.organization.id
      );
      setDataRetrieved(true);
    }
  }, [priorityMembership]);

  useEffect(() => {
    // Cap at 5 for now to prevent network hammering
    const usersToGetParticipations = childUsers.slice(0, 5);

    const orgId = localStorage.getItem("currentOrganizationId");
    // Don't do this for internal org as its too many users
    if (
      priorityMembership &&
      priorityMembership.organization.id ===
        "6d1eab91-827e-4dd2-b43c-0bf95fc4dc77"
    ) {
      return;
    }
    if (
      hasRetrievedUsers &&
      childUsers.length > 0 &&
      priorityMembership &&
      fetchedUsers
    ) {
      Promise.all(
        usersToGetParticipations.map((user: any) => {
          return dispatch(
            dataClientRequest({
              ...GET_USERS_PARTICIPATIONS,
              data: {
                id: user.id,
              },
              query: {
                filter: {
                  include_past: true,
                },
              },
            })
          );
        })
      );
    }
  }, [childUsers, hasRetrievedUsers, priorityMembership, fetchedUsers]);

  const props = {
    hasSetChildUsers,
    pastDueSubscription,
    noActiveSubscriptions,
    currentSubscriptions,
    currentMemberships,
    childUsers,
    hasRetrievedMemberships,
    hasRetrievedUsers,
    width,
    isMobile,
    hasRetrievedSubscriptionAddOns,
    hasRetrievedPaymentMethods,
    setHasSetChildUsers,
    setPastDueSubscription,
    setNoActiveSubscriptions,
    setCurrentSubscriptions,
    setCurrentMemberships,
    setChildUsers,
    setHasRetrievedMemberships,
    setHasRetrievedUsers,
    setWidth,
    setIsMobile,
    setHasRetrievedSubscriptionAddOns,
    setHasRetrievedPaymentMethods,
    userHasAccessToPlatform,
    dataRetrieved,
    history,
    location,
    getParentDataFunc,
  };

  return (
    <>
      {React.cloneElement(children, props, { history, location })}
      <AddChildModal />
      <AddChildCheckoutModal />
      <JoinStreamChild />
    </>
  );
};

const ParentLayoutContainer: React.FC<any> = ({
  history,
  location,
  children,
  dataRetrieved,
  ...rest
}) => {
  const { path, url } = useRouteMatch();
  return (
    <>
      <Navbar history={history} location={location} />
      <div className="parent-portal container">
        <Switch>
          {dataRetrieved ? (
            <>
              <Route
                exact
                path={`${path}/dashboard`}
                render={(routeProps: any) => (
                  <ParentDashboard {...routeProps} {...rest} />
                )}
              />
              <Route
                exact
                path={`${path}/settings/:id`}
                component={ChildSettings}
              />
              <Route exact path={`${path}/schedule`} component={Schedule} />
              <Route exact path={`${path}/profile`} component={ParentProfile} />
              <Route
                exact
                path={`${path}/survey/:id`}
                component={ParentSurvey}
              />
            </>
          ) : (
            <div>
              <div className="spacer" />
              <div className="spacer" />
              <div className="spacer" />
              <div className="nova-portal m-auto loader" />
            </div>
          )}
        </Switch>
        <BookFirstSessionModal />
      </div>
    </>
  );
};

const Homepage: React.FC<any> = ({ history, location, children }) => {
  const { path, url } = useRouteMatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const guideLogin = localStorage.getItem("guideLogin") === "true";
  const hasGuideAccess =
    currentUser.scope === "administrator" || currentUser.scope === "guide";

  return (
    <>
      {guideLogin && hasGuideAccess ? (
        <Redirect to="/guide/profile" />
      ) : (
        <Redirect to="/parent/dashboard" />
      )}
    </>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const App = hot(module)(() => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const timezone = useSelector(
    (state: RootState) => state.user.currentUser.time_zone
  );
  const [impactTracked, setImpactTracked] = useState(false);

  useEffect(() => {
    localStorage.setItem("timezone", timezone);
    const currentUserId = localStorage.getItem("currentUserId") || "";
    if (timezone) {
      dispatch(
        dataClientRequest({
          ...UPDATE_USER_MODEL_CONFIG,
          data: {
            time_zone: timezone,
            id: currentUserId,
          },
        })
      ).then((response: any) => {
        const oldCookie = getCookie();

        if (oldCookie) {
          const newCookie = Object.assign(oldCookie, {
            user: response.response.data.data,
          });
          saveCookie(newCookie);
        }
      });
    }
    moment.tz.setDefault(timezone);
  }, [timezone]);

  const ParentLayoutContainerWithData: React.FC<any> = ({
    history,
    location,
    hasSetChildUsers,
  }) => {
    return (
      <ParentDataContainer history={history} location={location}>
        <ParentLayoutContainer hasSetChildUsers={hasSetChildUsers} />
      </ParentDataContainer>
    );
  };

  const ParentCheckoutContainerWithData: React.FC<any> = ({
    history,
    location,
  }) => {
    return (
      <ParentDataContainer history={history} location={location}>
        <Checkout />
      </ParentDataContainer>
    );
  };

  const ParentNoCCTrialCheckoutContainerWithData: React.FC<any> = ({
    history,
    location,
  }) => {
    return (
      <ParentDataContainer history={history} location={location}>
        <NoCCTrialCheckout />
      </ParentDataContainer>
    );
  };

  const NewParentCheckoutContainerWithData: React.FC<any> = ({
    history,
    location,
  }) => {
    return (
      <div>
        <NewCheckout history={history} location={location} />
      </div>
    );
  };

  useEffect(() => {
    window.ire("identify", { customerId: "", customerEmail: "" });
  }, []);

  useEffect(() => {
    async function makeSHA1(message: string) {
      const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
      const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
      const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string
      return hashHex;
    }
    if (currentUser && currentUser.email && !impactTracked) {
      setImpactTracked(true);
      const digestBuffer = makeSHA1(currentUser.email);
      digestBuffer.then((response) => {
        window.ire("identify", {
          customerId: currentUser.id,
          customerEmail: response,
        });
      });
    }
  }, [currentUser]);

  useEffect(() => {
    start("https://api.codeverse.com");
  }, []);

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route
        exact
        path="/login/guide"
        render={(routeProps: any) => <Login {...routeProps} guide />}
      />
      <Route
        exact
        path="/password_reset"
        render={(routeProps: any) => <NewPasswordForm {...routeProps} />}
      />

      <Route path="/guide/meeting/:id" component={requireAuth(Meeting)} />
      <Route path="/guide" component={requireAuth(GuideLayoutContainer)} />
      <Route
        exact
        path="/parent/checkout"
        component={requireAuth(ParentCheckoutContainerWithData)}
      />
      <Route
        exact
        path="/parent/checkout/existing"
        component={requireAuth(ParentNoCCTrialCheckoutContainerWithData)}
      />
      <Route
        path="/parent"
        component={requireAuth(ParentLayoutContainerWithData)}
      />
      <Route
        exact
        path="/trial-signup"
        component={NewParentCheckoutContainerWithData}
      />
      <Route
        exact
        path="/schools/register"
        render={(routeProps: any) => <SchoolAccess create {...routeProps} />}
      />
      <Route
        exact
        path="/schools/sign-in"
        render={(routeProps: any) => <SchoolAccess signin {...routeProps} />}
      />
      <Route exact path="/" component={requireAuth(Homepage)} />
    </Switch>
  );
});
