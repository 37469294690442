import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from '@codeverse/react-helios-ui';
import { dataClientRequest } from '@codeverse/redux-data-client';
import moment from 'moment-timezone';

import SessionCard from 'components/Guide/UpcomingSessions/SessionCard';
import { ReferralBox } from 'components/Shared/ReferralBox';

import { AppDispatch } from 'store';
import { RootState } from 'store/state';
import { GET_USERS_PARTICIPATIONS, GET_USERS_MEETINGS, GET_OTHER_USER_MODEL_CONFIG } from 'models/User';
import { GET_MEETING_PARTICIPATIONS } from 'models/Meeting';
import { User } from 'models/User'

export type MeetingCustomRecord = {
  meeting_start_at: string,
  meeting_end_at: string,
  meeting_starting_in_seconds: string,
  meeting_id: string,
  sentiment?: string,
  users: Array<User>,
}

type Props = {
  history?: any;
  location?: any;
};

export const UpcomingSessions: React.FC<Props> = ({ history, location }) => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const meetings = useSelector((state: RootState) => state.user.meetings);
  const accessToken = useSelector((state: RootState) => state.session.access_token.access_token) || '';
  const timezone = useSelector((state: RootState) => state.user.currentUser.time_zone);

  const [pastSessions, setPastSessions] = useState([]);
  const [futureSessions, setFutureSessions] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMeeting();
    const getUserMeetingInterval = setInterval(() => {
      getMeeting();
    }, 60000);
    return () => {
      clearInterval(getUserMeetingInterval);
    }
  }, []);

  useEffect(() => {
    if (meetings && meetings.length > 0) {
      let pastMeetingRecords: any = [];
      let futureMeetingRecords: any = [];
      meetings.forEach((meeting: any) => {
        if (moment.tz(meeting.start_at, "America/Chicago").isAfter(
          moment().tz('America/Chicago').subtract(50, 'minutes')
        )) {
          futureMeetingRecords.push(meeting);
        } else {
          pastMeetingRecords.push(meeting);
        }
      })
      setPastSessions(pastMeetingRecords.sort((a: any, b: any) => {
        //@ts-ignore
        return new Date(b.start_at) - new Date(a.start_at);
      }));
      setFutureSessions(futureMeetingRecords.sort((a: any, b: any) => {
        //@ts-ignore
        return new Date(a.start_at) - new Date(b.start_at);
      }));
    }
  }, [meetings])

  const getMeeting = () => {
    dispatch(dataClientRequest({
      ...GET_USERS_MEETINGS,
      data: {
        id: localStorage.getItem('currentUserId'),
      },
      query: {
        filter: {
          include_past: true,
        },
        sort_by: 'start_at_desc',
        page: 1,
        per_page: 150,
      }
    }))
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }


  return (
    <>
      {
        !loading
          ?
          <Row className="mt-5">
            <Col md={8}>
              <div>
                <h2 className="mt-4 mb-4 pb-2">Upcoming Sessions</h2>
              </div>
              <div className="upcoming-sessions">
                <Row>
                  {futureSessions.map((meeting: any, index: any) => {
                    return <SessionCard key={`card${meeting.id}`} history={history} meeting={meeting} />
                  })}
                  {futureSessions.length === 0 && <p className="ml-4">No upcoming sessions!</p>}
                </Row>
              </div>
              <div>
                <h2 className="mt-4 mb-4 pb-2">Past Sessions</h2>
              </div>
              <div className="upcoming-sessions">
                <Row>
                  {pastSessions.map((meeting: any, index: any) => {
                    if (meeting.meta.child_participations.length > 0) {
                      const userParticipation = meeting.meta.child_participations.filter((child_participation: any) => !child_participation.abandoned_at)[0];
                      if (userParticipation) {
                        return <SessionCard past key={`card${meeting.id}`} history={history} meeting={meeting} userParticipation={userParticipation} />
                      }
                    }
                  })}
                  {pastSessions.length === 0 && <p className="ml-4">No past sessions!</p>}
                </Row>
              </div>
            </Col>
            <Col className="my-3" md={4}>
              <ReferralBox referralCode={currentUser.referral_code} />
            </Col>
          </Row>
          :
          <div>
            <div className="nova-portal m-auto loader fadeIn" />
          </div>
      }
    </>
  );
}