import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button } from '@codeverse/react-helios-ui';
import SatelliteClient from '@codeverse/satellite-client';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import LogRocket from 'logrocket';

import { isValidDate } from 'components/NewCheckout/AddChild/AddChild';
import { closeModal, openModal } from 'store/modules/UI/actions';
import Modal from 'components/Shared/NewModal';
import StepWizard from 'components/Shared/StepWizard';
import AddChild from 'components/NewCheckout/AddChild/AddChild';
import ChoosePlan from 'components/NewCheckout/ChoosePlan/ChoosePlan';
import OnlyCCReview from 'components/NewCheckout/Review/OnlyCCReview';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/state';
import ParentInformation from 'components/NewCheckout/ParentInformation/ParentInformation';
import ProgressIcons from 'components/NewCheckout/ProgressIcons/ProgressIcons';
import SetPassword from 'components/NewCheckout/SetPassword/SetPassword';
import Cookies from 'js-cookie';
import ChooseChildren from '../components/NewCheckout/ChooseChildren/ChooseChildren'

type Props = {
  history?: any;
  location?: any;
  getParentDataFunc?: any;
};

const hasNumber = (string: string) => {
  return /\d/.test(string);
};

const hasLetter = (string: string) => {
  return /[a-z]/i.test(string);
};

export const PASSWORD_ERROR = 'Passwords must be minimum of 8 characters and include at least one letter and one number.';

declare const window: any;

const Checkout: React.FC<Props> = ({ location, history, getParentDataFunc }) => {
  const dispatch = useDispatch();
  const checkoutHelp = useSelector((state: RootState) => state.ui.checkoutHelp);
  const childUsers = useSelector((state: RootState) => state.user.childUsers);
  const currentChildIndex = useSelector((state: RootState) => state.checkout.currentChildIndex);
  const checkoutChildren = useSelector((state: RootState) => state.checkout.children);
  const checkout = useSelector((state: RootState) => state.checkout);
  const timezone = useSelector((state: RootState) => state.user.currentUser.time_zone);

  const [isLoading, setLoading] = useState(false);
  const [scrollContainer, setScrollContainer] = useState(true);
  const [nextLocation, setNextLocation] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedSlotInstance, setSelectedSlotInstance] = useState(null);
  const [buttonText, setButtonText] = useState('Next');
  const [password, setPassword] = useState('');
  const [childEditMode, setChildEditMode] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);

  const [currentSelectedChildren, setCurrentSelectedChildren] = useState({})


  const query: any = queryString.parse(location.search);

  useEffect(() => {
    if (query.parent_name) {
      dispatch({ type: 'UPDATE_PARENT_INFORMATION', key: 'name', value: query.parent_name });
    }
    if (query.email_address) {
      dispatch({ type: 'UPDATE_PARENT_INFORMATION', key: 'email', value: query.email_address });
    }
    if (query.phone_number) {
      dispatch({ type: 'UPDATE_PARENT_INFORMATION', key: 'phone_number', value: query.phone_number });
    }
  }, []);

  useEffect(() => {
    setSelectedSlotInstance(checkoutChildren[currentChildIndex].slot_instance);
  }, [currentChildIndex]);

  useEffect(() => {
    localStorage.setItem('checkoutState', JSON.stringify(checkout));
  }, [checkout]);

  useEffect(() => {
    switch (location.hash) {
      case '#parent-information':
        setNextLocation('add-child');
        setCurrentStep(1);
        break;
      case '#add-child':
        setNextLocation('set-password');
        setCurrentStep(2);
        break;
      case '#set-password':
        setNextLocation('review');
        setCurrentStep(3);
        break;
      case '#review':
        setCurrentStep(4);
        break;
      default:
        setNextLocation('parent-information');
    }
    // if (location.hash === '#choose-plan') {
    //   setScrollContainer(false)
    // } else {
    setScrollContainer(true);
    // }
  }, [location.hash]);

  useEffect(() => {
    localStorage.setItem('checkoutState', JSON.stringify(checkout));
  }, [checkout]);

  useEffect(() => {
    newCheckoutContainerRef.current.scrollTo(0, 0);
  }, [window.location.hash]);

  const newCheckoutContainerRef: any = useRef();

  const submitCheckoutRef: any = useRef();

  useEffect(() => {
    if (childEditMode) {
      setButtonText('Save');
    }
  }, [childEditMode]);

  const Icon = useMemo(() => {
    if (buttonText === 'Next') {
      return <i className="ml-2 fas fa-chevron-right" />
    } else {
      return null;
    }
  }, [buttonText]);

  console.log('currentSelectedChildren', currentSelectedChildren)

  return (
    <>
      <div className="new-checkout-container" ref={newCheckoutContainerRef}>
        <div className={scrollContainer ? 'checkout-container-scroll' : 'center'}>
          <div className="nav">
            <div onClick={() => history.push('/parent/dashboard')} className="nova" />
            <div onClick={() => history.push('/parent/dashboard')} className="codeverse" />

            <div onClick={() => dispatch(openModal('checkoutHelp'))} className="help-button">
              <span className="help-button__text">Help</span>
              <div className="question-mark" />
            </div>
          </div>

          {/* {
            location.hash !== '#choose-plan' &&
            <ProgressIcons currentStep={currentStep} />
          } */}

          <StepWizard initialStep={1} isHashEnabled className={`checkout-step-container${!scrollContainer ? ' center' : ''}`}>
            <div />
            <ChoosePlan hashKey={'choose-plan'} noBack hash={'choose-children'} />
            <ChooseChildren hashKey={'choose-children'} setCurrentSelectedChildren={setCurrentSelectedChildren} currentSelectedChildren={currentSelectedChildren} />
            <OnlyCCReview currentSelectedChildren={currentSelectedChildren} setChildEditMode={setChildEditMode} hashKey={'review'} setLoading={setLoading} submitCheckoutRef={submitCheckoutRef} isLoading={isLoading} history={history} />
          </StepWizard>
        </div>
        { location.hash === '#choose-children' &&
          <div className="bottom-button-container">
            <Button
              disabled={Object.values(currentSelectedChildren).filter((bool) => bool).length === 0}
              className="w-100 bottom-button"
              context="primary"
              onClick={() => {
                window.location.hash = 'review';
              }}
            >
              {buttonText}
              {Icon}
            </Button>
          </div>
        }
      </div>
      <Modal
        isOpen={checkoutHelp}
        modalName="checkoutHelp"
        handleClose={() => {
          dispatch(closeModal('checkoutHelp'));
        }}
        className="checkout-help"
      >
        <div className="d-flex flex-direction-column">
          <div className="header">
            We would love to help!
            <div className="subtext">
            Give us a call or text at <a href="tel:8446442633" className="underlined">844&#8209;644&#8209;2633</a> or email us at <a href="mailto:support@codeverse.com" className="underlined">support@codeverse.com</a>.
            </div>
          </div>

        </div>
      </Modal>
    </>
  );
};

export default Checkout;
