import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@codeverse/react-helios-ui';

import capitalizeFirstLetter from 'utils/captializeFirstLetter';
import { RootState } from 'store/state';
import { AppDispatch } from "store";

const SetPassword: React.FC<any> = ({ password, setPassword, fieldErrors }) => {
  const dispatch: AppDispatch = useDispatch();

  const parentInformation = useSelector((state: RootState) => state.checkout.parent_information);
  const { name, email, phone_number } = parentInformation;
  const [passwordError, setPasswordError] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [passwordSet, setPasswordSet] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [inputType, setInputType] = useState('password');
  const children = useSelector((state: RootState) => state.checkout.children);
  
  const showPassword = () => {
    setShowPass(!showPass);
    setInputType(!showPass ? 'text' : 'password');
  };

  const iconClassnames = classNames('far fa-lg btn-icon',
    {
      'fa-eye-slash': !showPass,
      'fa-eye': showPass,
    },
  );

  const passwordClassnames = classNames('login-field mt-2',
    {
      'has-value': password !== '',
      'has-focus': inputFocus,
      'has-danger': passwordError && !inputFocus,
    },
  );

  const determineKidsName = () => {
    if (children.length === 1) {
      return capitalizeFirstLetter(children[0].name.split(' ')[0]);
    } else if (children.length === 2) {
      return `${capitalizeFirstLetter(children[0].name.split(' ')[0])} and ${capitalizeFirstLetter(children[1].name.split(' ')[0])}`;
    } else {
      return 'your kids';
    }
  }

  const phoneNumMissing = useMemo(() => {
    // remove all non-numeric characters
    return phone_number.replace(/[^0-9]/g, '').length < 11;
  }, [])

  console.log('phoneNumMissing', phoneNumMissing)
  console.log('phoneNumMissing', phone_number.replace(/[^0-9]/g, '').length)

  return (
    <div className="m-width-checkout">
      <div className="header">Set password</div>
      <div className="subtext">
        Choose a password to protect your account. You will use this email and password to log {determineKidsName()} into their accounts, join their 1-on-1 sessions, track their progress and view their projects.
      </div>
      <div className="input-container">
        <Input
          id="disabled-input"
          name="emailAddress"
          label="Email Address"
          value={parentInformation.email}
          disabled
          className="disabled-input"
        />
        <div className={passwordClassnames}>
          <label>Password</label>
          <input
            className="login-field__control password-field"
            onChange={(e: any) => { setPasswordError(false); setPassword(e.target.value); }}
            onFocus={() => setInputFocus(true)}
            onBlur={() => {
              if (password.length > 8) {
                setPasswordSet(true);
              }
              setInputFocus(false);
            }}
            onKeyDown={(e: any) => {
              if (e.nativeEvent.keyCode === 13) {
                // handleLogin(e);
              }
            }}
            type={inputType}
            value={password}
            // showError={passwordError}
          />
          <i onClick={() => showPassword()} className={iconClassnames} />
        </div>
        <div className="mt-2-5">
          <div className="small-print">
            Passwords must be minimum of 8 characters and include at least one letter and one number.
          </div>
        </div>

        {phoneNumMissing && passwordSet && <div className="" style={{ marginTop: '32px' }}>
          <div className="font-poppins-bold" style={{ fontSize: '24px', color: '#0E1540' }}>Verify phone number</div>
          <div className="font-poppins-regular" style={{ fontSize: '16px', lineHeight: '24px' }}>In a few minutes, we’ll send you a text message at this number to verify that you’re a real person. Once you reply, your free class will be confirmed.</div>

          <div style={{ marginTop: '24px' }}>
            <Input
              className="w-100"
              name="phoneNumber"
              mask="+1 999-999-9999"
              label="Parent Phone Number"
              inputMode="numeric"
              pattern="[0-9]*"
              onChange={(e: any) =>
                dispatch({
                  type: "UPDATE_PARENT_INFORMATION",
                  key: "phone_number",
                  value: e.target.value,
                })
              }
              value={phone_number}
              showError={fieldErrors.parentPhoneNumberHasError}
              // onBlur={() => handleBlur()}
            />

          </div>
          
          <div style={{ marginTop: '12px', color: '#8D98C6', fontSize: '12px', fontFamily: 'SF Pro Regular' }}>
           By clicking “Finish” you consent to receive text messages and alerts from Codeverse about your class as well as follow up communications from our team. Standard messaging rates apply. 
          </div>
        </div>}
      </div>
    </div>
  );
};

export default SetPassword;
