import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import EmailForm from 'components/SignIn/EmailForm';
import PasswordForm from 'components/SignIn/PasswordForm';
import ConfirmationForm from 'components/SignIn/ConfirmationForm';
import { UPDATE_USER_MODEL_CONFIG, CREATE_USER_ADDRESS_MODEL_CONFIG, RESET_USER_PASSWORD_MODEL_CONFIG } from 'models/User';
import { dataClientRequest } from 'store/data-client';
import { AppDispatch } from 'store';
import LoginLayout from 'components/Layout/LoginLayout';

export type SetCurrentStepFunction = React.Dispatch<
  React.SetStateAction<number>
>;
export type SetEmailFunction = React.Dispatch<
  React.SetStateAction<string>
>;
export type SetPasswordFunction = React.Dispatch<
  React.SetStateAction<string>
>;
export type SetEmailLinkFunction = React.Dispatch<
  React.SetStateAction<string>
>;
export type SetEmailTypeFunction = React.Dispatch<
  React.SetStateAction<string>
>;
export type SetLastStepFunction = React.Dispatch<
  React.SetStateAction<number>
>;

type Props = {
  currentOrganizationId?: string;
  currentOrganizationName?: string;
  currentStep: number;
  lastStep: number;
  emailLink: string;
  emailType: string;
  guide: boolean;
  location: any;
  parentEmail: string;
  password: string;
  redirect: string;
  setCurrentStep: SetCurrentStepFunction;
  setEmail: SetEmailFunction;
  setPassword: SetPasswordFunction;
  setEmailLink: SetEmailLinkFunction;
  setEmailType: SetEmailTypeFunction;
  setLastStep: SetLastStepFunction;
  history: any;
  loginFailed: boolean;
  setLoginFailed: () => void;
};

export const SignIn: React.FC<Props> = ({
  currentOrganizationId,
  currentOrganizationName,
  setEmail,
  currentStep,
  lastStep,
  emailLink,
  emailType,
  guide,
  location,
  parentEmail,
  password,
  redirect,
  setCurrentStep,
  setPassword,
  setEmailLink,
  setEmailType,
  setLastStep,
  history,
  loginFailed,
  setLoginFailed,
}) => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (/@gmail\.com$/.test(parentEmail)) {
      setEmailLink('https://mail.google.com');
      setEmailType('Gmail');
    }
    if (/@yahoo\.com$/.test(parentEmail)) {
      setEmailLink('https://mail.yahoo.com');
      setEmailType('Yahoo');
    }
    if (/@icloud\.com$/.test(parentEmail)) {
      setEmailLink('https://www.icloud.com/#mail');
      setEmailType('iCloud');
    }
    if (/@aol\.com$/.test(parentEmail)) {
      setEmailLink('https://mail.aol.com');
      setEmailType('AOL');
    }
    if (/@comcast\.net$/.test(parentEmail)) {
      setEmailLink('https://xfinityconnect.email.comcast.net');
      setEmailType('Comcast');
    }
  }, [parentEmail]);

  const onForgotPasswordClick = () => {
    dispatch(dataClientRequest({
      ...RESET_USER_PASSWORD_MODEL_CONFIG,
      data: {
        email: parentEmail,
      },
    })).then(() => {
      setCurrentStep(currentStep + 1);
    });
  };

  const [scope, setScope] = useState(null);

  return (
    <LoginLayout>
      <div className="org-login__form-container">
        <div className="org-login__header text-center justify-content-center">
          <img src="/img/codeverse-logo-full-color.svg" width="217" height="48" alt="Codeverse" className="mx-auto" />
        </div>
        <AnimatePresence>
          {currentStep === 1 && !guide && (
            <motion.div
              className="org-login__information-form-container w-wider"
              key={1}
              initial={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -1000, opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <EmailForm // like step 1
                currentStep={currentStep}
                header={['Sign in to ', <span>your account</span>]}
                history={history}
                location={location}
                parentEmail={parentEmail}
                setCurrentStep={setCurrentStep}
                setEmail={setEmail}
                setScope={setScope}
                subtext={'Kids and parents can login here to access the Codeverse coding studio and account settings.'}
              />
            </motion.div>
          )}
          {currentStep === 1 && guide && (
            <motion.div
              className="org-login__information-form-container w-wider"
              key={1}
              initial={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -1000, opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <EmailForm // like step 1
                currentStep={currentStep}
                header={`Guide Sign\xa0In`}
                history={history}
                location={location}
                parentEmail={parentEmail}
                setCurrentStep={setCurrentStep}
                setEmail={setEmail}
                setScope={setScope}
                subtext={'Access your guide account to access your sessions and schedule.'}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {currentStep === 2 && (
            <motion.div
              className="org-login__information-form-container w-wider"
              key={2}
              initial={{ x: 1000, opacity: 0 }}
              animate={{ x: 0, opacity: 1, position: 'absolute', transitionEnd: { position: 'relative'} }}
              exit={{ x: -1000, opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <PasswordForm
                guide={guide}
                history={history}
                location={location}
                loginFailed={loginFailed}
                parentEmail={parentEmail}
                password={password}
                redirect={redirect}
                setLoginFailed={setLoginFailed}
                setPassword={setPassword}
                scope={scope}
                onForgotPasswordClick={onForgotPasswordClick}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {currentStep === 3 && (
            <motion.div
              className="org-login__information-form-container w-wider"
              key={3}
              initial={{ x: 1000, opacity: 0 }}
              animate={{ x: 0, opacity: 1, position: 'absolute', transitionEnd: { position: 'relative' } }}
              exit={{ x: lastStep < currentStep ? 1000 : -1000, opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <ConfirmationForm
                emailType={emailType}
                emailLink={emailLink}
                header="Email&nbsp;Sent!"
                subtext="We just sent an email to reset your password. Check your email to get the link and access your parent account."
              />
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {currentStep === 4 && (
            <motion.div
              className="org-login__information-form-container"
              key={3}
              initial={{ x: 1000, opacity: 0 }}
              animate={{ x: 0, opacity: 1, position: 'absolute' }}
              exit={{ x: lastStep < currentStep ? 1000 : -1000, opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <ConfirmationForm
                emailType={emailType}
                emailLink={emailLink}
                header="Welcome!"
                subtext="We just sent you an email to confirm your account so you can access your account information and kid accounts."
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {!guide && <div className="org-login__footer justify-content-center">
        Not a Codeverse member yet?&nbsp;
        <span onClick={() => window.open('https://codeverse.com/', '_blank')} className="org-login__footer--link">
          Learn More
        </span>
      </div>}
      {!guide && <div className="org-login__footer justify-content-center">
        Are you a Codeverse Guide?&nbsp;
        <span onClick={() => history.push('/login/guide')} className="org-login__footer--link">
          Login Here
        </span>
      </div>}
      {guide && <div className="org-login__footer justify-content-center">
        Want to Sign In as a Parent?&nbsp;
        <span onClick={() => history.push('/login')} className="org-login__footer--link">
          Login Here
        </span>
      </div>}
    </LoginLayout>
  );
};

export default SignIn;
