
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import Create from 'components/schools/Create/Create';
import SignIn from 'components/schools/SignIn/SignIn';

type Props = {
  signin: boolean,
  create: boolean,
  location: any,
  history: any,
}

const SchoolAccess: React.FC<Props> = ({ signin, create, location, history }) => {
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const subUrl = signin ? 'sign-in' : 'register';

  const [fullName, setFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [currentOrganizationId, setCurrentOrganizationId] = useState('');
  const [schoolsList, setSchools] = useState([]);
  const [lastStep, setLastStep] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);
  const [passcode, setPasscode] = useState('');
  const [currentOrganizationName, setCurrentOrganizationName] = useState('');
  // const [] = useState('');

  useEffect(() => {
    if (!query.step) {
      history.push(`/schools/${subUrl}?step=1`);
    }
    if (query.step === '2' && !userName && !currentOrganizationId) {
      history.push(`/schools/${subUrl}?step=1`);
    }
    //@ts-ignore
    window.Intercom('shutdown');
  }, []);

  useEffect(() => {
    //@ts-ignore
    const stepNumber = parseInt(query.step, 10);
    if (!query.step) {
      history.push(`/schools/${subUrl}?step=1`);
    }
    if (query.step === '2' && !userName && !currentOrganizationId) {
      history.push(`/schools/${subUrl}?step=1`);
    }
    setCurrentStep(stepNumber);
  }, [query.step]);


  const props = {
    currentStep,
    currentOrganizationName,
    setCurrentOrganizationName,
    lastStep,
    currentOrganizationId,
    fullName,
    schools: schoolsList,
    setUserName,
    setFullName,
    setSchools,
    setCurrentStep,
    setLastStep,
    setCurrentOrganizationId,
    setPasscode,
    passcode,
    userName,
    history,
  };

  if (!query.step) {
    return <></>;
  }
  if (create) {
    return <Create {...props} />;
  }
  if (signin) {
    return <SignIn {...props} />;
  }
};

export default SchoolAccess;