import React from 'react';

interface ClassHighlightProps {
  icon: any,
  header: string,
  description: string,
  ref?: any
}

const ClassHighlight: React.FC<ClassHighlightProps> = ({
  icon,
  header,
  description,
  ref,
}) => {
  return (
    <div ref={ref} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '24px' }}>
      <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', minWidth: '40px' }}>
        {icon}
      </div>
        
      <div className="" style={{ fontSize: '12px', paddingLeft: '18px' }}>
        <div>
          {header}
        </div>
        <div className="font-poppins-regular">
          {description}
        </div>
      </div>
    </div>
  );
};

export default ClassHighlight;