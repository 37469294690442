import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from '@codeverse/react-helios-ui';

import ParticipantVideo from 'components/Guide/Meeting/ParticipantVideo';

import { RootState } from 'store/state';

type Props = {
  participantVideoTracks: any;
  setShowSettingsPanel: any;
  showSettingsPanel: any;
  shareScreen: any;
  stopSharingScreen: any;
  screenEnabled: boolean;
  participants: any;
}

const VideoSidePanel: React.FC<Props> = ({ participantVideoTracks, setShowSettingsPanel, showSettingsPanel, shareScreen, stopSharingScreen, screenEnabled, participants }) => {
  const dispatch = useDispatch();
  const showVideoSidePanel = useSelector((state: RootState) => state.ui.showVideoSidePanel);

  useEffect(() => {
    dispatch({ type: 'SHOW_VIDEO_SIDE_PANEL' });
  }, []);

  return (
    <div className="video-side-panel" style={{ display: showVideoSidePanel ? 'block' : 'none' }}>
      <div className="video-actions">
        <div className="small-btn-action settings" onClick={() => {
          if (showSettingsPanel) {
            setShowSettingsPanel(false)
          } else {
            setShowSettingsPanel(true)
          }
        }}>
          <div className="settings-icon" />
        </div>
        <div className="small-btn-action share-desktop" onClick={() => {
          if (screenEnabled) {
            stopSharingScreen();
          } else {
            shareScreen();
          }
        }}>
          {screenEnabled ? <div className="share-desktop-icon disabled" /> : <div className="share-desktop-icon" />}
        </div>
        {/* <div className="small-btn-action close-action" onClick={() => dispatch({ type: 'HIDE_VIDEO_SIDE_PANEL' })}>
          <div className="close-icon" />
        </div> */}
      </div>
      {
        Object.keys(participantVideoTracks).length === 0
          ? <div className="video-placeholder" />
          : <>
            {Object.keys(participantVideoTracks).map((key: any) => (
              <ParticipantVideo participants={participants} participantId={key} key={key} participantVideoTrack={participantVideoTracks[key]} />
            ))}
          </>
      }
    </div>
  );
};

export default VideoSidePanel;
