import React from 'react';

type Props = {
  args: any;
}

const AddSlot: React.FC<Props> = ({ args }) => {
  return (
    <>
      <div className="add-icon"></div>
      Add Slot
    </>
  )
}

export default AddSlot;
