export const openModal = (name: string) => ({
  type: 'OPEN_MODAL',
  name,
});

export const closeModal = (name: string) => ({
  type: 'CLOSE_MODAL',
  name,
});

export const setCurrentCardColor = (color: any) => ({
  type: 'SET_CURRENT_CARD_COLOR',
  color,
});

export const setCurrentPasswordObject = (object: any) => ({
  type: 'SET_CURRENT_PASSWORD_OBJECT',
  object,
});