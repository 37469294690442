import React, { useState, useEffect } from "react";
import ClassNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

import { toast } from "react-toastify";

import determineAvatarImage from "utils/determineAvatarImage";
import kidSignIn from "utils/kidSignIn";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/state";
import { useHistory } from "react-router";
import {
  resetCheckout,
  setCurrentChildIndex,
  updateChildByIndex,
} from "store/modules/Checkout/actions";
import { closeModal } from "store/modules/UI/actions";
import ChildSignInButton from "./ChildSignInButton";

declare var heap: any;

type Props = {
  accessToken?: any;
  childUser?: any;
  currentOrganizationId?: any;
  history?: any;
  index?: any;
  isMobile?: any;
  location?: any;
  userHasAccessToPlatform?: any;
  priorityMembership?: any;
  subscriptionAddOns: any;
  activeSubscription: any;
};

const ChildCard: React.FC<Props> = ({
  accessToken,
  childUser,
  currentOrganizationId,
  history,
  index,
  isMobile,
  userHasAccessToPlatform,
  priorityMembership,
  subscriptionAddOns,
  activeSubscription,
}) => {
  const [profileImgStyle, setProfileImageStyle] = useState({});
  const organization = useSelector(
    (state: RootState) => state.organizations.organization
  );
  const access_token = localStorage.getItem("accessToken");
  useEffect(() => {
    determineAvatarImage(childUser, setProfileImageStyle);
  }, [childUser]);

  const handleKidSignIn = () => {
    const signInLink = kidSignIn(
      toast,
      userHasAccessToPlatform,
      activeSubscription,
      subscriptionAddOns,
      access_token,
      childUser,
      currentOrganizationId,
      isMobile,
      priorityMembership
    );
    window.open(signInLink, "_blank");
  };

  const colClassnames = ClassNames(
    "child-card fadein-short col-md-6 col-12 pb-3 px-0",
    {
      "pr-2": index % 2 === 0 && !isMobile,
      "pl-2": index % 2 === 1 && !isMobile,
    }
  );

  const iconStyle = {
    right: "16px",
    top: "9px",
    color: "#FFFFFF",
  };
  const textStyle = {
    right: "10px",
    color: "#FFFFFF",
    fontSize: "14px",
  };

  const btnStyle = {
    width: "106px",
  };

  const dispatch = useDispatch();
  const handleFirstCheckoutUser = (userId: string) => {
    dispatch(resetCheckout());
    dispatch(closeModal("addChildCheckout"));
    dispatch(updateChildByIndex(0, { user_id: userId }));
    dispatch(setCurrentChildIndex(0));
    history.push("/parent/checkout/#select-time");
  };

  return (
    <div className={colClassnames}>
      <div style={{ cursor: "pointer" }} className="card">
        <div
          style={profileImgStyle}
          onClick={() => handleKidSignIn()}
          className="child-card__img-box"
        />
        <div className="card-body p-0">
          <div
            onClick={() => handleKidSignIn()}
            className="d-inline-flex align-items-center justify-content-between w-100 p-3"
          >
            <h5
              className="card-text align-left mb-0 overflow-hidden"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "190px",
              }}
            >
              {childUser.name}
            </h5>

            <ChildSignInButton
              childUser={childUser}
              isMobile={isMobile}
              activeSubscription={activeSubscription}
              subscriptionAddOns={subscriptionAddOns}
              priorityMembership={priorityMembership}
              userHasAccessToPlatform
            />
          </div>
          {organization.status === "freemium" && (
            <div
              className="border-top px-3 py-1"
              style={{ borderColor: "#EBECEF" }}
            >
              <a
                onClick={() => handleFirstCheckoutUser(childUser.id)}
                className="d-inline-flex align-items-center justify-content-between w-100 py-2"
                style={{ textDecoration: "none" }}
              >
                <div className="align-middle flex text-moon-dark font-poppins-medium">
                  <span className="inline-block" style={{ marginRight: "8px" }}>
                    🚀
                  </span>
                  <span className="inline-block" style={{ color: "#FF8500" }}>
                    Book their FREE 1:1 trial class
                  </span>
                </div>
                <div
                  className="flex align-items-center justify-content-center rounded bg-moon-lightest w-32 h-32 text-center"
                  style={{ height: "32px" }}
                >
                  <FontAwesomeIcon icon={faChevronRight} color="#8D98C6" />
                </div>
              </a>
            </div>
          )}
          <div
            className="border-top px-3 py-1"
            style={{ borderColor: "#EBECEF" }}
          >
            <a
              onClick={() =>
                history.push(`/parent/settings/${childUser.referral_code}`)
              }
              className="d-inline-flex align-items-center justify-content-between w-100 py-2"
              style={{ textDecoration: "none" }}
            >
              <div className="align-middle flex text-moon-dark font-poppins-medium">
                <span className="inline-block">Manage&nbsp;</span>
                <span
                  className="inline-block overflow-hidden align-middle whitespace-nowrap overflow-ellipsis"
                  style={{ maxWidth: "120px", textOverflow: "ellipsis" }}
                >
                  {childUser.name}
                </span>
                <span className="inline-block">'s settings</span>
              </div>
              <div
                className="flex align-items-center justify-content-center rounded bg-moon-lightest w-32 h-32 text-center"
                style={{ height: "32px" }}
              >
                <FontAwesomeIcon icon={faChevronRight} color="#8D98C6" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChildCard;
