import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { dataClientRequest } from "@codeverse/redux-data-client";

import { checkSession } from "store/modules/Session/actions";
import { setTimezone } from "store/modules/User/actions";
import { UPDATE_USER_MODEL_CONFIG } from "models/User";
import { Select } from "@codeverse/react-helios-ui";
import { AppDispatch } from "store";
import { useDebounce } from "hooks/useDebounce";

import { RootState } from "store/state";
import { setPriorityMembership } from "store/modules/User/actions";

import { removeCookie } from "store/auth";

declare var heap: any;

type Props = {
  guide?: any;
  history?: any;
  location?: any;
};

const options = [
  { value: "America/Los_Angeles", label: "Pacific" },
  { value: "America/Denver", label: "Mountain" },
  { value: "America/Chicago", label: "Central" },
  { value: "America/New_York", label: "Eastern" },
];

const getInitials = function (string: any) {
  const names = string.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

const Navbar: React.FC<Props> = ({ history, location, guide }) => {
  const [pressed, setPressed] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const userName = useSelector(
    (state: RootState) => state.user.currentUser.name
  );
  const timezone = useSelector(
    (state: RootState) => state.user.currentUser.time_zone
  );
  const memberships = useSelector(
    (state: RootState) => state.user.userMemberships
  );
  const priorityMembership = useSelector(
    (state: RootState) => state.user.priorityMembership
  );

  const { pathname } = location;

  const dropdownClass = classNames("dropdown-toggle", { show: pressed });
  const dropdownMenuClass = classNames("dropdown-menu", { show: pressed });
  const name = userName || "Hello Inkling";

  const upcomingClassName = classNames("nav-item", {
    "nav-focus": pathname === "/guide/upcoming-sessions",
  });
  const guideScheduleClassName = classNames("nav-item", {
    "nav-focus": pathname === "/guide/schedule",
  });
  const profileClassName = classNames("nav-item", {
    "nav-focus": pathname === "/guide/profile",
  });

  const parentDashboardClassName = classNames("nav-item", {
    "nav-focus":
      pathname === "/parent/dashboard" ||
      pathname.includes("/parent/settings/"),
  });
  const parentScheduleClassName = classNames("nav-item", {
    "nav-focus": pathname === "/parent/schedule",
  });
  const parentAccountClassName = classNames("nav-item", {
    "nav-focus": pathname === "/parent/profile",
  });

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    // @ts-ignore
    if (window.heap) {
      // @ts-ignore
      window.heap.resetIdentity();
    }
    dispatch(
      checkSession({
        access_token: null,
        current_user_id: null,
        redirectAfterLogin: null,
        history,
      })
    );
  };

  const [timezoneChanged, setTimezoneChanged] = useState(false);
  const [showTimezone, setShowTimezone] = useState(false);

  const debouncedTimeZone = useDebounce(timezone, 2000);

  const value = useMemo(() => {
    return options.find((option) => option.value === timezone);
  }, [options, timezone]);

  const debouncedValue = useDebounce(value, 2000);

  // useEffect(() => {
  //   if (timezoneChanged) {
  //     const currentUserId = localStorage.getItem("currentUserId") || "";
  //     console.log('updating to', timezone)
  //     dispatch(
  //       dataClientRequest({
  //         ...UPDATE_USER_MODEL_CONFIG,
  //         data: {
  //           time_zone: timezone,
  //           id: currentUserId,
  //         },
  //       })
  //     ).then((response) => {
  //       setTimezoneChanged(false);
  //     });
  //   }
  // }, [timezoneChanged, timezone]);

  return (
    <>
      <div className="portal-nav">
        <div className="portal-nav__mobile-nav d-flex d-md-none">
          <ul className="nav nav-tabs d-xs-flex">
            {guide && (
              <>
                <li className={upcomingClassName}>
                  <a
                    onClick={() => history.push("/guide/upcoming-sessions")}
                    className="nav-link"
                  >
                    Upcoming
                  </a>
                </li>
                <li className={guideScheduleClassName}>
                  <a
                    onClick={() => history.push("/guide/schedule")}
                    className="nav-link"
                  >
                    Schedule
                  </a>
                </li>
                <li className={profileClassName}>
                  <a
                    onClick={() => history.push("/guide/profile")}
                    className="nav-link"
                  >
                    Profile
                  </a>
                </li>
              </>
            )}
            {!guide && (
              <>
                <li className={parentDashboardClassName}>
                  <a
                    onClick={() => history.push("/parent/dashboard")}
                    className="nav-link nav-link-small"
                  >
                    Dashboard
                  </a>
                </li>
                <li className={parentScheduleClassName}>
                  <a
                    onClick={() => history.push("/parent/schedule")}
                    className="nav-link nav-link-small"
                  >
                    Schedule
                  </a>
                </li>
                <li className={parentAccountClassName}>
                  <a
                    onClick={() => history.push("/parent/profile")}
                    className="nav-link nav-link-small"
                  >
                    My Account
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
        <nav className="portal-nav__container">
          <div className="portal-nav__brand-box">
            <a href="/" className="portal-nav__nova-nav" />
            <a
              href="/"
              className="portal-nav__codeverse-nav d-none d-md-block"
            />
          </div>

          <ul className="nav nav-tabs d-md-flex d-none">
            {guide && (
              <>
                <li className={upcomingClassName}>
                  <a
                    onClick={() => history.push("/guide/upcoming-sessions")}
                    className="nav-link"
                  >
                    Sessions
                  </a>
                </li>
                <li className={guideScheduleClassName}>
                  <a
                    onClick={() => history.push("/guide/schedule")}
                    className="nav-link"
                  >
                    Schedule
                  </a>
                </li>
                <li className={profileClassName}>
                  <a
                    onClick={() => history.push("/guide/profile")}
                    className="nav-link"
                  >
                    Profile
                  </a>
                </li>
              </>
            )}
            {!guide && (
              <>
                <li className={parentDashboardClassName}>
                  <a
                    onClick={() => history.push("/parent/dashboard")}
                    className="nav-link"
                  >
                    Dashboard
                  </a>
                </li>
                <li className={parentScheduleClassName}>
                  <a
                    onClick={() => history.push("/parent/schedule")}
                    className="nav-link"
                  >
                    Schedule
                  </a>
                </li>
                <li className={parentAccountClassName}>
                  <a
                    onClick={() => history.push("/parent/profile")}
                    className="nav-link"
                  >
                    My Account
                  </a>
                </li>
              </>
            )}
          </ul>

          <div className="timezone-dropdown">
            {guide && (
              <Select
                className="fadein"
                placeholder=""
                label="Timezone"
                options={options}
                onChange={(option: any) => {
                  dispatch(setTimezone(option.value));
                  // setShowTimezone(true);
                  // setTimezoneChanged(true);
                }}
                value={debouncedValue}
              />
            )}
          </div>

          <div className="sign-out justify-content-end align-items-center">
            <a
              className="party"
              onClick={() => handleLogout()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign Out
            </a>
          </div>

          {!priorityMembership && (
            <div className="position-right navbar-user position-absolute">
              {getInitials(name)}
            </div>
          )}
          {priorityMembership && (
            <div className="position-right dropleft float-right position-absolute">
              <a
                id="dropdownMenuLink"
                className={dropdownClass}
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => setPressed(!pressed)}
              >
                <div style={{ cursor: "pointer" }} className="navbar-user">
                  {getInitials(name)}
                </div>
              </a>
              <div
                className={dropdownMenuClass}
                aria-labelledby="dropdownMenuLink"
              >
                <div className="list-group list-group-sm list-group-bold">
                  {memberships.map((membership: any) => {
                    if (membership.id !== priorityMembership.id) {
                      return (
                        <a
                          onClick={() =>
                            dispatch(setPriorityMembership(membership))
                          }
                          key={membership.id}
                          className="list-group-item list-group-item-action"
                        >
                          {membership.organization.name}
                        </a>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          )}
        </nav>
      </div>
    </>
  );
};

export default Navbar;
