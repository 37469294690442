import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { configureStore } from './store';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { App } from './app';

import './styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';

// prepare store
const history = createBrowserHistory();
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <>
        <App />
        <ToastContainer autoClose={4000} />
      </>
    </Router>
  </Provider>,
  document.getElementById('root')
);