import React, { useEffect, useState } from "react";

import ChildCard from "./ChildCard";

type Props = {
  accessToken?: any;
  childUsers?: any;
  currentOrganizationId?: any;
  history?: any;
  isMobile: any;
  location?: any;
  userHasAccessToPlatform?: any;
  priorityMembership?: any;
  subscriptionAddOns: any;
  activeSubscription: any;
};

export const ChildUsersBox: React.FC<Props> = ({
  accessToken,
  childUsers,
  currentOrganizationId,
  history,
  isMobile,
  userHasAccessToPlatform,
  priorityMembership,
  subscriptionAddOns,
  activeSubscription,
}) => {
  return (
    <>
      {childUsers &&
        childUsers.length > 0 &&
        childUsers.map((childUser: any, index: any) => {
          return (
            <ChildCard
              accessToken={accessToken}
              currentOrganizationId={currentOrganizationId}
              history={history}
              index={index}
              isMobile={isMobile}
              key={`childUser${index}`}
              childUser={childUser}
              userHasAccessToPlatform={userHasAccessToPlatform}
              priorityMembership={priorityMembership}
              subscriptionAddOns={subscriptionAddOns}
              activeSubscription={activeSubscription}
            />
          );
        })}
    </>
  );
};
