import { handleActions } from "redux-actions";
import { createReducer } from "typesafe-actions";
import { omit } from "lodash";
import { SessionAction, SessionState } from "./types";

import {
  SESSION_LOGIN_REQUEST,
  SESSION_LOGIN_SUCCESS,
  SESSION_LOGIN_FAILURE,
} from "./constants";

// initalState
const initialState: SessionState = {
  isAuthenticated: false,
  message: "",
  userEmail: "",
  passwordResetUserId: "",
  passwordResetAccessToken: "",
  access_token: {},
};

export function SessionReducer(
  state = initialState,
  action: SessionAction
): SessionState {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        ...omit(action.payload, ["user"]),
        isAuthenticated: true,
      };
    case "SESSION_CHECK_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SESSION_CHECK_FAILED":
      return { ...state, isAuthenticated: false };
    case "REHYDRATE_SESSION_FROM_COOKIES":
      localStorage.setItem(
        "accessToken",
        action.cookie.access_token.access_token
      );
      console.log("new state from rehydration", {
        ...state,
        ...omit(action.cookie, ["user"]),
        ...action.cookie,
      });
      return { ...state, ...omit(action.cookie, ["user"]), ...action.cookie };
    default:
      return state;
  }
}
