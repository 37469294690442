import React, { useEffect, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from '@codeverse/react-helios-ui';

import { RootState } from 'store/state';

type Props = {
  children: ReactNode;
};

const MainScreen: React.FC<Props> = ({ children }) => {
  return (
    <div className="main-screen">
      {children}
    </div>
  );
};

export default MainScreen;
